import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {injectAppStore} from "../../common/app.store.consumer";
import {AppStore} from "../../stores/app.store";

@injectAppStore()
export default class DiagnosticNotPaid extends React.Component<{appStore?: AppStore}> {
	render() {
		return (
			<div className="mt--flex mt--notification-center">
				<FontAwesomeIcon className="tint-orange" icon={["fas", "bell-exclamation"]} />
				{this.props.appStore.translationStore.translate(
					"Your Team Diagnostic is completed but the results are not available yet as team report has not yet been purchased. Please contact Meta Team Administration.",
				)}
			</div>
	)}
}
