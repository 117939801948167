/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import {injectAppStore} from "../../common/app.store.consumer";
import {AppStore} from "../../stores/app.store";
import {guard, isLoggedIn} from "../../common/guard";
import {RouteComponentProps, withRouter} from "react-router";
import {getDebugPanelFormat} from "../../utils/date.utils";

type PublicDebugStatusPanelProps = {appStore?: AppStore} & RouteComponentProps<{teamId: string}>;

@guard(isLoggedIn)
@injectAppStore()
class PublicDebugStatusPanel extends React.Component<PublicDebugStatusPanelProps> {
	private store = this.props.appStore.debugPanelStore;

	render() {
		const teamInfo = this.store.teamInfo;

		return (
			<React.Fragment>
				<div className="test_panel_wrap">
					<div className="pos-rel">
						<div className="test_panel">
							<div className="tp_tabs">
								<a style={{cursor: "pointer"}}>Team Information</a>
								<a className="btn-link tab-close" onClick={this.store.toggleTeamInfoPanel}>
									<i className="fal fa-times"></i>
								</a>

								<table>
									<tbody>
										<tr>
											<td>
												<b>Name:</b> {teamInfo.teamName}
											</td>
										</tr>
										<tr>
											<td>
												<b>Team leaders:</b> {teamInfo.teamLeadersName}
											</td>
										</tr>
										<tr>
											<td>
												<b>Team type:</b> {teamInfo.teamType}
											</td>
										</tr>
										<tr>
											<td>
												<b>Team status:</b> {teamInfo.teamStatus}
											</td>
										</tr>
										<tr>
											<td>
												<b>Feedback providers setup deadline:</b> {getDebugPanelFormat(teamInfo.feedbackProvidersSetupDeadline)}
											</td>
										</tr>
										<tr>
											<td>
												<b>Assessment starting date:</b>{" "}
												{teamInfo.assessmentStartingDate ? getDebugPanelFormat(teamInfo.assessmentStartingDate) : "-"}
											</td>
										</tr>
										<tr>
											<td>
												<b>Assessment deadline:</b>{" "}
												{teamInfo.assessmentDeadline ? getDebugPanelFormat(teamInfo.assessmentDeadline) : "-"}
											</td>
										</tr>
										<tr>
											<td>
												<b>Assessment completed date:</b>{" "}
												{teamInfo.assessmentCompletedDate ? getDebugPanelFormat(teamInfo.assessmentCompletedDate) : "-"}
											</td>
										</tr>
										<tr>
											<td>
												<b>Assessment failed date:</b>{" "}
												{teamInfo.assessmentFailedDate ? getDebugPanelFormat(teamInfo.assessmentFailedDate) : "-"}
											</td>
										</tr>
										<tr>
											<td>
												<b>Submitted:</b>{" "}
												{`${teamInfo.submittedTeamSurveys ? teamInfo.submittedTeamSurveys : "0"}/${teamInfo.teamParticipantsCount}`}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default withRouter(PublicDebugStatusPanel);
