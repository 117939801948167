import React from "react";
import {AppStore} from "../../stores/app.store";
import {injectAppStore} from "../../common/app.store.consumer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface IProps {
	appStore?: AppStore;
	isOnDXToolbar?: boolean;
	isCompanyPage?: boolean;
}

const BackButton: React.FC<IProps> = injectAppStore()(({isCompanyPage, appStore, isOnDXToolbar = true}) => {
	if (isCompanyPage && appStore.clientStore?.currentClientId === appStore.userStore?.clientId) {
		return null;
	}

	return (
		<div className={`mt--flex ${isOnDXToolbar ? "mt--dx-toolbar" : ""}`} onClick={() => window.history.back()}>
			<div className="mt--btn btn-med btn-dx-like">
				<FontAwesomeIcon className="mt--ico" icon={["far", "angle-left"]} flip={appStore.translationStore.isRTL ? "horizontal" : null} />
				<span>Back</span>
			</div>
		</div>
	);
});
export default BackButton;
