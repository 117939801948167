import * as React from "react";
import {AppStore} from "../../stores/app.store";
import {injectAppStore} from "../app.store.consumer";
import {HabitMicroHabitParticipantRoleAnswersDetails} from "../webapicall";

interface IProps {
	appStore?: AppStore;
	values: HabitMicroHabitParticipantRoleAnswersDetails[];
	participantRole: string;
}

const IndividualValuesBar: React.FC<IProps> = injectAppStore()((props: IProps) => {
	const barConfig = props.appStore.barConfigStore;

	let indicatorClasses = ["value-indicator"];
	let indicatorColorClass = barConfig.getIndicatorColorClass(props.participantRole);
	indicatorClasses.push(indicatorColorClass);

	let indicatorCountClasses = ["indicator-count"];
	indicatorCountClasses.push(indicatorColorClass);

	return (
		<div className="chart">
			<div className="line"></div>
			<div className="tick" style={{ marginInlineStart: 0 }}></div>

			{barConfig.getAllTicksPosition.map((tickWidth, i) => (
				<div key={i} className="tick" style={{ marginInlineStart: `${tickWidth}%` }}></div>
			))}

			{props.values.map((item, i) => (
				<div key={i}>
					<div className="individual-bar">
						<div className={indicatorClasses.join(" ")} style={{ marginInlineStart: `${barConfig.calculateWidth(item.value)}%` }}>
							{item.count > 1 ? <div className={indicatorCountClasses.join(" ")}>{item.count}</div> : null}
							<svg version="1.1" x="0px" y="0px" viewBox="0 0 96.154 96.154" width="20" height="15">
								<path
									d="M0.561,20.971l45.951,57.605c0.76,0.951,2.367,0.951,3.127,0l45.956-57.609c0.547-0.689,0.709-1.716,0.414-2.61
		                        c-0.061-0.187-0.129-0.33-0.186-0.437c-0.351-0.65-1.025-1.056-1.765-1.056H2.093c-0.736,0-1.414,0.405-1.762,1.056
		                        c-0.059,0.109-0.127,0.253-0.184,0.426C-0.15,19.251,0.011,20.28,0.561,20.971z"
								/>
							</svg>
						</div>
					</div>
				</div>
			))}
		</div>
	);
});

export default IndividualValuesBar;
