import * as React from "react";
import {AppStore} from "../stores/app.store";
import {ModalRenderer} from "./modal";
import {injectAppStore} from "./app.store.consumer";

const OutDatedClientModal: React.FC<{reloadCallback: () => void; appStore?: AppStore}> = injectAppStore()(({reloadCallback, appStore}) => {
	return <ModalRenderer content={getOutDatedClientModalLayout(reloadCallback, appStore)} isShow={true} />;
});

const getOutDatedClientModalLayout = (reloadCallback: () => void, appStore: AppStore) => {
	return (
		<div className="info-modal-content">
			<div className="info-modal-header  paint-lgrey">
				<h5 className="info-modal-title ink-dgrey">{appStore.translationStore.translate("Outdated client")}</h5>
			</div>
			<div className="info-modal-border"></div>
			<div className="info-modal-body">
				<div className="info-modal-text">
					{appStore.translationStore.translate("Your client application is outdated. Please refresh to continue your work!")}
				</div>
				<div className="vspace30"></div>
				<button onClick={reloadCallback} className="info-modal-button btn-base btn-primary  btn-fit-mobile  anim-btn-hover">
					{appStore.translationStore.translate("Refresh")}
				</button>
			</div>
		</div>
	);
};

@injectAppStore()
export class OutdatedClient extends React.Component<{appStore?: AppStore}> {
	private refresh = () => {
		window.location.reload();
	};

	render() {
		let appStore = this.props.appStore;
		return appStore.outDatedClient && <OutDatedClientModal reloadCallback={this.refresh} />;
	}
}
