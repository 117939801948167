import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {TeamJourneyHabit, TeamJourneyStatus} from "../../../common/webapicall";
import TeamJourneyOctagon from "../../../common/team-journey-octagon/team.journey.octagon";
import {injectAppStore} from "../../../common/app.store.consumer";
import {AppStore} from "../../../stores/app.store";

interface IProps {
	appStore?: AppStore;
	habits: TeamJourneyHabit[];
	hideAllProgress?: boolean;
	inverted?: boolean;
}

const PublicSurveyResultsRecommendedTeamJourney: React.FC<IProps> = injectAppStore()(({appStore, habits, hideAllProgress = false, inverted = false}) => {
	const resultsStore = appStore.surveyResultsStore;

	const getStatusIcon = (status: TeamJourneyStatus) => {
		switch (status) {
			case TeamJourneyStatus.InProgress:
				return <FontAwesomeIcon icon={["far", "angle-right"]} />;
			case TeamJourneyStatus.Completed:
				return <FontAwesomeIcon icon={["fal", "check-circle"]} />;
			default:
				return null;
		}
	};

	return (
		<ul className={`mt--journey-container page-break-inside-avoid ${inverted ? " mt--fill-blue" : ""}`}>
			{habits.map(h => (
				<li className="mt--journey-habit" key={h.habitId}>
					<div className={`mt--habit-name${inverted ? " tint-white" : ""}`}>{appStore.translationStore.translate(h.habitName)}</div>
					<TeamJourneyOctagon habitName={h.habitName} hideProgress={hideAllProgress} appearWhite={inverted} />
					<div className={`status${inverted ? " tint-white" : ""}`}>
						{getStatusIcon(h.habitStatus)}
						<span>{resultsStore.getStatusText(h.habitStatus)}</span>
					</div>
				</li>
			))}
		</ul>
	);
});

export default PublicSurveyResultsRecommendedTeamJourney;
