import React, {useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import {NavigationItem} from "../../common/navigation-items/navigation.item";
import {AppStore} from "../../stores/app.store";
import {injectAppStore} from "../../common/app.store.consumer";

interface IProps {
	title: string;
	clientId?: string;
	navigationItems?: NavigationItem[];
	hasSimpleHeader: boolean;
	appStore?: AppStore;
}

const AdminPageHeader: React.FC<IProps> = injectAppStore()(props => {
	const [title, setTitle] = useState("");

	useEffect(() => {
		const load = async () => {
			if (props.clientId) {
				await Promise.all([
					props.appStore.dashboardStore.getDashboardInfo(props.clientId),
					props.appStore.dashboardStore.getBreadCrumbInfo(props.clientId),
				]);
			}
		};
		load();
	}, [props.appStore.dashboardStore, props.clientId]);

	useEffect(() => {
		const currentClientName = props.appStore.dashboardStore.dashboardInfo.currentClientName;
		const currentClientTypeId = props.appStore.dashboardStore.dashboardInfo.currentClientTypeId;
		let currentClientType = props.appStore.dashboardStore.dashboardInfo.currentClientType;

		let title = "";
		if (props.clientId && currentClientName) {
			title = currentClientName + " ";
		}
		title += props.title;

		if (props.clientId && currentClientTypeId && currentClientType) {
			title += ` (${currentClientType})`;
		}

		document.title = title;
		setTitle(title);
	}, [
		props.appStore.dashboardStore.dashboardInfo.currentClientName,
		props.appStore.dashboardStore.dashboardInfo.currentClientType,
		props.appStore.dashboardStore.dashboardInfo.currentClientTypeId,
		props.title,
		props.clientId,
	]);

	return (
		<div className={`mt--area-wrap mt--admin ${props.hasSimpleHeader ? "mt--pattern-grey" : "mt--pattern-blue"}`}>
			<div className="mt--layout mt--flex mt--align-center mt--justify-content-between mt--h-100 mt--py-0">
				<div className="head">
					<div className="mt--lead mt--px-0">
						<div>{title}</div>
					</div>
					{props.navigationItems && (
						<nav className="mt--navbar mt--navbar-secondary admin">
							<div id="navbarNav">
								<ul className="mt--navbar-nav mt--flex">
									{props.navigationItems.map((item, index) => (
										<li key={index}>
											<NavLink className="mt--btn-corner-outline" activeClassName="active" to={item.to}>
												{item.name}
											</NavLink>
										</li>
									))}
								</ul>
							</div>
						</nav>
					)}
				</div>
			</div>
		</div>
	);
});

export default AdminPageHeader;
