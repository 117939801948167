import {flow, makeObservable, observable} from "mobx";
import i18next from "i18next";
import {defaultNs} from "../../i18n";
import {TranslationJsonStore} from "./translation.json.store";
import {loadMessages} from "devextreme/localization";

export type TranslationType = "Selected" | "Default";

export class UiTranslationsStore {
	private translationsCache: Map<string, any> = new Map<string, any>();

	@observable public translationLoadedState: Map<TranslationType, boolean> = new Map<TranslationType, boolean>();

	constructor(private translationsBaseUrl: string, private fileVersions: {[key: string]: string}) {
		makeObservable(this);
	}

	public isTranslationLoaded(languageCode: string) {
		const translations = this.translationsCache.get(languageCode);
		if (!translations) {
			return false;
		}

		return Object.keys(translations).length > 0;
	}

	@flow
	public *loadUiTranslations(languageCode: string | null | undefined, translationType: TranslationType, preload: boolean): any {
		if (!languageCode) {
			return;
		}

		const fileName = `${languageCode}.json`;
		const fileVersion = this.fileVersions[fileName];
		const fileContent = fileVersion ? yield this.downloadUiTranslations(languageCode!, fileVersion) : {};

		const resourceBundle = {...fileContent};

		if (resourceBundle) {
			i18next.addResourceBundle(languageCode, defaultNs, resourceBundle);
			this.translationLoadedState.set(translationType, true);
		} else {
			this.translationLoadedState.set(translationType, false);
		}

		const object = {};
		object[languageCode] = resourceBundle;
		loadMessages(object);
	}

	private async downloadUiTranslations(languageCode: string, version: string | null) {
		let fileContent = this.translationsCache.get(languageCode);
		if (!fileContent) {
			fileContent = await TranslationJsonStore.loadSystemResource(this.translationsBaseUrl, languageCode, version);
			this.translationsCache.set(languageCode, fileContent);
		}

		return fileContent;
	}
}
