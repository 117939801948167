import * as React from "react";
import {injectAppStore} from "../common/app.store.consumer";
import {AppStore} from "../stores/app.store";

@injectAppStore()
export class UserDropdown extends React.Component<{appStore?: AppStore}> {
	private onUserSelected = async (e: React.ChangeEvent<HTMLSelectElement>) => {
		let userId = e.target.value;
		let userStore = this.props.appStore.userStore;
		userStore.changeUser(userId);
	};

	render() {
		let isVisible = this.props.appStore.configuration.showUserDropdown;
		if (!isVisible) {
			return null;
		}

		let usersTeam = this.props.appStore.userStore.users;
		let userId = this.props.appStore.userStore.userId;
		return (
			<React.Fragment>
				<div className="wrap_stripe tmp-login-container mt--fill-dgrey">
					<div className="wrap_layout">
						<span>Simulate login as:</span>
						<select className="tmp-element" onChange={this.onUserSelected} defaultValue={userId ? userId.toString() : null}>
							{usersTeam.map(team => (
								<optgroup label={team.teamName} key={team.teamName + team.participants.length}>
									{team.participants.map(u => (
										<option value={u.id} key={u.id+team.teamName}>
											{u.fullName}
										</option>
									))}
								</optgroup>
							))}
						</select>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
