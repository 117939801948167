import React from "react";
import {Link} from "react-router-dom";
import {AdminMapRoutes} from "../../stores/site.map";
import {injectAppStore} from "../../common/app.store.consumer";
import {AppStore} from "../../stores/app.store";
import icon from "../../assets/img/ico-dashb-team.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type IProps = {appStore?: AppStore} & {count: number; clientId: string};
@injectAppStore()
//type IProps = {count: number, clientId: string};
export class TeamsTile extends React.Component<IProps> {
	async componentDidMount() {}

	render() {
		return (
			<div className="dashboard-tile">
				<div>
					<div className="tile-icon"><img src={icon} alt="" /></div>
				</div>
				<div className="tile-title"><span>Teams </span><span>({this.props.count})</span></div>
				<div className="tile-subtitle">of {this.props.appStore.dashboardStore.dashboardInfo.currentClientName}</div>
				<div className="tile-link">
					<Link to={`${AdminMapRoutes.Teams}/${this.props.clientId}`}>
						<span>VIEW/ADD</span>
						<FontAwesomeIcon icon={["far", "angle-right"]} />
					</Link>
				</div>
			</div>
		);
	}
}
