export const SiteMapRoutes = {
	Home: "/",
	Public: "/team-insights",
	Admin: "/admin",

	// public routes
	ParticipantsHowTo: "participants-how-to",
	ParticipantsList: "participants-list",
	DiagnosticHowTo: "survey-how-to",
	Diagnostic: "survey",
	DiagnosticResultsRecommendations: "survey-results/recommendations",
	DiagnosticResultsSummary: "survey-results/summary",
	DiagnosticResultsMicroHabits: "survey-results/micro-habits",
	DiagnosticResultsTeamJourney: "survey-results/team-journey",

	//admin routes
	Teams: "teams",
	TeamsAll: "teams-all",
	TeamDetailsOverview: "team/overview",
	TeamDetailsResultsRecommendations: "team/results/recommendations",
	TeamDetailsResultsSummary: "team/results/summary",
	TeamDetailsResultsMicroHabits: "team/results/micro-habits",
	TeamDetailsResultsTeamJourney: "team/results/team-journey",
	Clients: "clients",
	Dashboard: "dashboard",
	Report: "report",
	AdminUsers: "adminusers"
};

export const PublicMapRoutes = {
	Home: `${SiteMapRoutes.Public}`,
	ParticipantsHowTo: `${SiteMapRoutes.Public}/${SiteMapRoutes.ParticipantsHowTo}`,
	ParticipantsList: `${SiteMapRoutes.Public}/${SiteMapRoutes.ParticipantsList}`,
	DiagnosticHowTo: `${SiteMapRoutes.Public}/${SiteMapRoutes.DiagnosticHowTo}`,
	Diagnostic: `${SiteMapRoutes.Public}/${SiteMapRoutes.Diagnostic}`,
	DiagnosticResultsRecommendations: `${SiteMapRoutes.Public}/${SiteMapRoutes.DiagnosticResultsRecommendations}`,
	DiagnosticResultsSummary: `${SiteMapRoutes.Public}/${SiteMapRoutes.DiagnosticResultsSummary}`,
	DiagnosticResultsMicroHabits: `${SiteMapRoutes.Public}/${SiteMapRoutes.DiagnosticResultsMicroHabits}`,
	DiagnosticResultsTeamJourney: `${SiteMapRoutes.Public}/${SiteMapRoutes.DiagnosticResultsTeamJourney}`,
};

export const AdminMapRoutes = {
	Dashboard: `${SiteMapRoutes.Admin}/${SiteMapRoutes.Dashboard}`,
	Teams: `${SiteMapRoutes.Admin}/${SiteMapRoutes.Teams}`,
	TeamsAll: `${SiteMapRoutes.Admin}/${SiteMapRoutes.TeamsAll}`,

	//Team Details
	TeamDetailsOverview: `${SiteMapRoutes.Admin}/${SiteMapRoutes.TeamDetailsOverview}`,
	TeamDetailsResultsRecommendations: `${SiteMapRoutes.Admin}/${SiteMapRoutes.TeamDetailsResultsRecommendations}`,
	TeamDetailsResultsSummary: `${SiteMapRoutes.Admin}/${SiteMapRoutes.TeamDetailsResultsSummary}`,
	TeamDetailsResultsMicroHabits: `${SiteMapRoutes.Admin}/${SiteMapRoutes.TeamDetailsResultsMicroHabits}`,
	TeamDetailsResultsTeamJourney: `${SiteMapRoutes.Admin}/${SiteMapRoutes.TeamDetailsResultsTeamJourney}`,

	//Clients
	Clients: `${SiteMapRoutes.Admin}/${SiteMapRoutes.Clients}`,
	Report: `${SiteMapRoutes.Admin}/${SiteMapRoutes.Report}`,
	AdminUsers: `${SiteMapRoutes.Admin}/${SiteMapRoutes.AdminUsers}`,
};
