import {action, makeObservable} from "mobx";
import {IApiClient} from "../common/api.client";
import {StoreBase} from "./store.base";

export class EmailStore extends StoreBase {
	constructor(private apiClient: IApiClient) {
		super(apiClient);
		makeObservable(this);
	}

	@action
	async resendSurveyInvitation(teamId: string, participantId: string) {
		await this.apiClient.emailClient.sendSurveyInvitation(teamId, {participantId});
	}

	@action
	async resendSurveyReminder(teamId: string, participantId: string) {
		await this.apiClient.emailClient.sendSurveyReminder(teamId, {participantId});
	}

	@action
	async resendSurveyReminderTeam(teamId: string) {
		await this.apiClient.emailClient.sendSurveyReminderTeam(teamId);
	}
}
