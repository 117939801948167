import React from "react";
import {injectAppStore} from "../../common/app.store.consumer";
import {AppStore} from "../../stores/app.store";
import {guard, isLoggedIn} from "../../common/guard";
import {NavLink, Link} from "react-router-dom";
import {AuthorizableActions} from "../../common/webapicall";
import {authorizableActionsContains} from "../../common/helpers";
import {AdminMapRoutes, PublicMapRoutes} from "../../stores/site.map";
import imageMetaTeamLogo from "../../assets/img/logo_metateam.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {BelowTabletLandscape} from "../../common/responsive.queries";
import {withRouter} from "react-router-dom";
import * as H from "history";
import OutsideClick from "../../common/outside.click";
import Profile from "../../common/profile/profile";
import {BreadCrumb} from "../../admin/navigation/breadcrumb";
import {flowResult} from "mobx";

interface IProps {
	appStore?: AppStore;
	location: H.Location;
}
interface IState {
	isMobileOpen: boolean;
	isDesktopOpen: boolean;
}

@guard(isLoggedIn)
@injectAppStore()
class PublicNavigation extends React.Component<IProps, IState> {
	private readonly SURVEY_RESULTS_URL = "survey-results";
	private readonly SURVEY_URL = "survey/";
	private readonly SURVEY_HOW_TO_URL = "survey-how-to";
	private readonly PARTICIPANTS_URL = "participants";

	state = {isMobileOpen: false, isDesktopOpen: false};

	private toggleNavigationMenu = (isDesktop: boolean = false) => {
		isDesktop ? this.setState({isDesktopOpen: !this.state.isDesktopOpen}) : this.setState({isMobileOpen: !this.state.isMobileOpen});
	};

	private closeNavigationMenu = () => {
		this.setState({isMobileOpen: false, isDesktopOpen: false});
	};

	private getNavLinkClass = (paths: string[], classes: string[]): string => {
		var activeClass = "";
		paths.forEach(path => {
			if (this.props.location.pathname.includes(path)) activeClass = "active";
		});
		classes.push(activeClass);
		return classes.join(" ");
	};

	private getMenuToggleClasses = (classes: string[], isDesktop: boolean = false): string => {
		let className = this.state.isMobileOpen ? "expanded" : "collapsed";
		if (isDesktop) {
			className = this.state.isDesktopOpen ? "d-expanded" : "d-collapsed";
		}

		classes.push(className);
		return classes.join(" ");
	};

	private logout = () => {
		this.props.appStore.userStore.logout();
	};

	render() {
		const contextStore = this.props.appStore.contextStore;
		const teamId = contextStore.contextModel.teamModel.teamId;
		const pSurveyId = contextStore.contextModel.teamModel.loggedInUserParticipantSurveyId;
		const isAdmin = this.props.appStore.userStore.isAdmin;
		const haveTeams = this.props.appStore.userStore.hasMoreTeam;
		const canViewParticipants = authorizableActionsContains(contextStore, [AuthorizableActions.CanViewParticipants]);

		const canViewRecommendationsResults = authorizableActionsContains(contextStore, [AuthorizableActions.CanViewRecommendationsResults]);
		const canViewSummaryResults = authorizableActionsContains(contextStore, [AuthorizableActions.CanViewSummaryResults]);
		const canViewMicroHabitResults = authorizableActionsContains(contextStore, [AuthorizableActions.CanViewMicroHabitResults]);
		const canViewTeamSurveyResultsPage = canViewRecommendationsResults || canViewSummaryResults || canViewMicroHabitResults;

		// await this.props.appStore.dashboardStore.getBreadCrumbInfo(props.clientId)

		return (
			<OutsideClick handleOutsideClick={this.closeNavigationMenu}>
				<div className="mt--area-wrap mt--fill-white">
					<div className="mt--layout padding-header">
						<nav className="mt--navbar">
							<div className="mt--flex mt--top-navbar">
								<div className="mt--logo-wrap">
									<Link
										className="mt--navbar-brand"
										to={isAdmin ? AdminMapRoutes.Clients + "/" + this.props.appStore?.userStore?.clientId : PublicMapRoutes.Home}
										onClick={this.closeNavigationMenu}
									>
										<img src={imageMetaTeamLogo} alt="meta-team-logo" width="175" className="mt--align-top" />
									</Link>
								</div>
								<button
									className={`mt--navbar-toggler mobile ${this.state.isMobileOpen ? "selected" : ""}`}
									type="button"
									data-target="#navbarNav"
									onClick={() => this.toggleNavigationMenu()}
								>
									<div className="mt--hamburger">
										<FontAwesomeIcon icon={["fas", "bars"]} />
									</div>
								</button>
							</div>

							<Profile />

							<div className={this.getMenuToggleClasses(["mt--navbar-collapse"])} id="navbarNav">
								<ul className="mt--navbar-nav mt--flex mt--flex-col">
									<BelowTabletLandscape>
										{canViewTeamSurveyResultsPage ? (
											<ul className={this.getNavLinkClass([this.SURVEY_RESULTS_URL], ["mt--nav-item"])}>
												<div className="mt--btn-corner nav-link">{this.props.appStore.translationStore.translate("Team Results")}</div>
												<React.Fragment>
													{canViewRecommendationsResults && (
														<li>
															<NavLink
																className={`mt--btn-corner nav-link
																${this.props.appStore.translationStore.isRTL ? "flip" : ""}
																`}
																onClick={this.closeNavigationMenu}
																to={`${PublicMapRoutes.DiagnosticResultsRecommendations}/${teamId}`}
															>
																{this.props.appStore.translationStore.translate("Recommendations")}
															</NavLink>
														</li>
													)}
													{canViewSummaryResults && (
														<li>
															<NavLink
																className={`mt--btn-corner nav-link
																${this.props.appStore.translationStore.isRTL ? "flip" : ""}
																`}
																onClick={this.closeNavigationMenu}
																to={`${PublicMapRoutes.DiagnosticResultsSummary}/${teamId}`}
															>
																{this.props.appStore.translationStore.translate("Summary")}
															</NavLink>
														</li>
													)}
													{canViewMicroHabitResults && (
														<li>
															<NavLink
																className={`mt--btn-corner nav-link
																${this.props.appStore.translationStore.isRTL ? "flip" : ""}
																`}
																onClick={this.closeNavigationMenu}
																to={`${PublicMapRoutes.DiagnosticResultsMicroHabits}/${teamId}`}
															>
																{this.props.appStore.translationStore.translate("Micro Habits")}
															</NavLink>
														</li>
													)}
													{canViewRecommendationsResults && (
														<li>
															<NavLink
																className={`mt--btn-corner nav-link
																${this.props.appStore.translationStore.isRTL ? "flip" : ""}
																`}
																onClick={this.closeNavigationMenu}
																to={`${PublicMapRoutes.DiagnosticResultsTeamJourney}/${teamId}`}
															>
																{this.props.appStore.translationStore.translate("Team Journey")}
															</NavLink>
														</li>
													)}
												</React.Fragment>
											</ul>
										) : null}
										{pSurveyId ? (
											<ul className={this.getNavLinkClass([this.SURVEY_URL, this.SURVEY_HOW_TO_URL], ["mt--nav-item"])}>
												<div className={`mt--btn-corner nav-link
																${this.props.appStore.translationStore.isRTL ? "flip" : ""}
																`}>
													{this.props.appStore.translationStore.translate("Team Diagnostic")}
												</div>
												<React.Fragment>
													<li>
														<NavLink
															className={`mt--btn-corner nav-link
																${this.props.appStore.translationStore.isRTL ? "flip" : ""}
																`}
															onClick={this.closeNavigationMenu}
															to={`${PublicMapRoutes.DiagnosticHowTo}/${pSurveyId}`}
														>
															{this.props.appStore.translationStore.translate("How To")}
														</NavLink>
													</li>
													{!isAdmin && (
														<li>
															<NavLink
																className="mt--btn-corner nav-link"
																onClick={this.closeNavigationMenu}
																to={`${PublicMapRoutes.Diagnostic}/${pSurveyId}`}
															>
																{this.props.appStore.translationStore.translate("My Diagnostic")}
															</NavLink>
														</li>
													)}
												</React.Fragment>
											</ul>
										) : null}
										{canViewParticipants ? (
											<ul className={this.getNavLinkClass([this.PARTICIPANTS_URL], ["mt--nav-item"])}>
												<div className={`mt--btn-corner nav-link
																${this.props.appStore.translationStore.isRTL ? "flip" : ""}
																`}>{this.props.appStore.translationStore.translate("Team Setup")}</div>

												<React.Fragment>
													<li>
														<NavLink
															className={`mt--btn-corner nav-link
																${this.props.appStore.translationStore.isRTL ? "flip" : ""}
																`}
															onClick={this.closeNavigationMenu}
															to={`${PublicMapRoutes.ParticipantsHowTo}/${teamId}`}
														>	
															{this.props.appStore.translationStore.translate("How To")}
														</NavLink>
													</li>
													<li>
														<NavLink
															className={`mt--btn-corner nav-link
																${this.props.appStore.translationStore.isRTL ? "flip" : ""}
																`}
															onClick={this.closeNavigationMenu}
															to={`${PublicMapRoutes.ParticipantsList}/${teamId}`}
														>
															{this.props.appStore.translationStore.translate("Feedback providers")}
														</NavLink>
													</li>
												</React.Fragment>
											</ul>
										) : null}
										{haveTeams ? (
											<li className="mt--nav-item">
												<NavLink
													className={`mt--btn-corner nav-link
																${this.props.appStore.translationStore.isRTL ? "flip" : ""}
																`}
													onClick={this.closeNavigationMenu} to={PublicMapRoutes.Home}
												>
													{this.props.appStore.translationStore.translate("My Teams")}
												</NavLink>
											</li>
										) : null}
										<li className="mt--nav-item" onClick={this.logout}>
											<div className="mt--btn-corner nav-link">
												{this.props.appStore.translationStore.translate("Logout")}
											</div>
										</li>

										<ul className="mt--language mt--flex mt--flex-col">
											<li className="item-not-link">{this.props.appStore.translationStore.translate("Preferred language:")}</li>
											{this.props.appStore.configuration.availableLanguages.map(lang => (
												<li
													className=""
													key={lang}
													onClick={async () => {
														this.props.appStore.userStore.saveLanguage(lang);
														await flowResult(this.props.appStore.translationStore.Load(lang, "Selected"));
														this.props.appStore.translationStore.setSelectedLanguage(lang);
														this.props.appStore.translationStore.changeLanguage();
													}}
												>
													<span>
														{this.props.appStore.translationStore.selectedLanguage === lang && (
															<FontAwesomeIcon icon={["far", "check"]} />
														)}
													</span>
													{this.props.appStore.translationStore.translate(lang)}
												</li>
											))}
										</ul>
									</BelowTabletLandscape>
								</ul>
							</div>

							<button
								className={this.getMenuToggleClasses(["mt--navbar-user"], true)}
								type="button"
								data-target="#desktopNavbarNav"
								onClick={() => this.toggleNavigationMenu(true)}
							>
								<div className="mt--hamburger">
									<FontAwesomeIcon icon={["fas", "bars"]} />
								</div>
								<div className={this.getMenuToggleClasses(["mt--navbar-collapse"], true)} id="desktopNavbarNav">
									<ul className="mt--navigation">
										{haveTeams ? (
											<li>
												<NavLink
													className="link"
													exact
													activeClassName="active"
													onClick={this.closeNavigationMenu}
													to={PublicMapRoutes.Home}
												>
													<FontAwesomeIcon icon={["far", "users"]} />
													{this.props.appStore.translationStore.translate("My Teams")}
												</NavLink>
											</li>
										) : null}
										{isAdmin ? (
											<li className="mt--nav-item">
												<NavLink
													className="link"
													exact
													activeClassName="active"
													onClick={this.closeNavigationMenu}
													to={AdminMapRoutes.TeamsAll}
												>
													<FontAwesomeIcon icon={["far", "chart-network"]} />
													{this.props.appStore.translationStore.translate("All Teams")}
												</NavLink>
											</li>
										) : null}
										<li>
											<div className="link" onClick={this.logout}>
												<FontAwesomeIcon icon={["far", "arrow-to-right"]} />
												{this.props.appStore.translationStore.translate("Logout")}
											</div>
										</li>
										<ul className="mt--language">
											<li className="item-not-link">{this.props.appStore.translationStore.translate("Preferred language:")}</li>
											{this.props.appStore.configuration.availableLanguages.map(lang => (
												<li
													key={lang}
													onClick={async () => {
														this.props.appStore.userStore.saveLanguage(lang);
														await flowResult(this.props.appStore.translationStore.Load(lang, "Selected"));
														this.props.appStore.translationStore.setSelectedLanguage(lang);
														this.props.appStore.translationStore.changeLanguage();
													}}
												>
													<span>
														{this.props.appStore.translationStore.selectedLanguage === lang && (
															<FontAwesomeIcon icon={["far", "check"]} />
														)}
													</span>
													{this.props.appStore.translationStore.translate(lang)}
												</li>
											))}
										</ul>
									</ul>
								</div>
							</button>
						</nav>
					</div>
				</div>
				{isAdmin ? (
					<div className="mt--fill-bgreen">
						<div className="mt--layout mt--area-wrap mt--breadcrumb-area">
							<BreadCrumb></BreadCrumb>
						</div>
					</div>
				) : null}
			</OutsideClick>
		);
	}
}

export default withRouter(PublicNavigation);
