import {action, flow, makeObservable, observable} from "mobx";
import {Moment} from "moment";
import {IApiClient} from "../common/api.client";
import {TeamSurveyStatus, TokenResponse, UserInfo, UserTeamDto} from "../common/webapicall";
import * as Cookies from "js-cookie";
import {IConfigurationProvider} from "../common/public.configuration";
import DataSource from "devextreme/data/data_source";
import {StoreBase} from "./store.base";
import {TranslationStore} from "./translation/translation.store";

export class UserStore extends StoreBase {
	constructor(private apiClient: IApiClient, private configurationProvider: IConfigurationProvider, private translation: TranslationStore) {
		super(apiClient);
		makeObservable(this);
	}

	@observable isLoggedIn = false;
	@observable expiration: Moment = null;
	@observable userId: string = null;
	@observable userName: string = null;
	@observable firstName: string = null;
	@observable lastName: string = null;
	@observable isLoginVisible: boolean = false;
	@observable users: UserTeamDto[] = [];
	@observable isAdmin: boolean = false;
	@observable hasMoreTeam: boolean = false;
	@observable canAddTeam: boolean = false;
	@observable defaultTeamId: string = null;
	@observable permissions: string[] = [];
	@observable usersDropdown: any[] = [];
	@observable clientId?: string = null;
	@observable companyName: string = null;
	@observable companyRole: string = null;
	@observable companyRoleId: string = null;
	@observable language?: string = null;
	@observable email?: string = null;

	@action
	async getUsersForDropdown() {
		// const response = await this.apiClient.userClient.getUsersForDropdown();
		// if (response) {
		// 	runInAction(() => {
		// 		this.usersDropdown = response;
		// 		// add no client
		// 		this.usersDropdown.push({value: null, name: "No Client Admin", addedToTeam: false});
		// 	});
		// }
	}

	async logAuditTrail(screenName: string) {
		await this.apiClient.userClient.logAuditTrail({
			screenName: screenName,
			url: window.location.href,
		});
	}

	async loginByEmail(email: string, language: string, redirectUrl: string): Promise<boolean> {
		return await this.apiClient.userClient.loginByEmail(email, language, redirectUrl);
	}

	@flow
	*init() {
		let token = this.getAuthToken();
		let refreshToken = window.localStorage.getItem("refreshToken");
		if (token) {
			this.apiClient.setAuthToken(token, refreshToken);

			try {
				let userInfo: UserInfo = yield this.apiClient.userClient.getInfo();
				this.isLoggedIn = true;
				this.userId = userInfo.userId;
				this.userName = `${userInfo.firstName} ${userInfo.lastName}`;
				this.firstName = userInfo.firstName;
				this.lastName = userInfo.lastName;
				this.isAdmin = userInfo.isAdmin;
				this.hasMoreTeam = userInfo.hasMoreTeam;
				this.defaultTeamId = userInfo.defaultTeamId;
				this.canAddTeam = userInfo.canAddTeam;
				this.permissions = userInfo.permissions ?? [];
				this.clientId = userInfo.clientId;
				this.companyName = userInfo.companyName;
				this.companyRoleId = userInfo.companyRoleId;
				this.companyRole = userInfo.companyRole;
				this.language = userInfo.language;
				this.email = userInfo.email;

				Cookies.set("authToken", token);
				Cookies.set("refreshToken", refreshToken);
			} catch (error) {
				Cookies.remove("authToken");
				Cookies.remove("refreshToken");
				window.localStorage.removeItem("authToken");
				window.localStorage.removeItem("refreshToken");
			}
		} else {
			Cookies.remove("authToken");
			Cookies.remove("refreshToken");
		}
		if (this.configurationProvider.configuration.showUserDropdown && this.isLoggedIn) {
			this.users = yield this.apiClient.lookupClient.getAllUsers();
		}
	}

	async refreshToken(refreshToken: string) {
		let successful = true;
		let tokenResponse: TokenResponse;
		await this.apiClient.userClient
			.refreshToken({
				refreshToken: refreshToken,
			})
			.then(r => {
				successful = true;
				tokenResponse = r;
			})
			.catch(() => {
				successful = false;
			});

		if (successful) {
			window.localStorage.setItem("authToken", tokenResponse.token);
			window.localStorage.setItem("refreshToken", tokenResponse.refreshToken);
			this.apiClient.setAuthToken(tokenResponse.token, tokenResponse.refreshToken);
		}

		return successful;
	}

	getLoginUrl(): string {
		if (this.isLoginVisible) {
			return window.location.hash;
		} else {
			return this.getLoginRedirectUrl(window.location.hash);
		}
	}

	canChangeUser(): boolean {
		return this.configurationProvider.configuration.showUserDropdown;
	}

	hasPermission(permission: string): boolean {
		return this.permissions.includes(permission);
	}

	async changeUser(userId: string) {
		if (this.canChangeUser()) {
			let tokenResponse = await this.apiClient.userClient.getToken(userId);
			this.updateTokens(tokenResponse);
		}
	}

	private updateTokens(tokenResponse: TokenResponse) {
		if (this.canChangeUser()) {
			window.localStorage.setItem("authToken", tokenResponse.token);
			window.localStorage.setItem("refreshToken", tokenResponse.refreshToken);
			window.location.href = "/";
		}
	}

	private getLoginRedirectUrl(redirectUrl?: string): string {
		let loginUrl = this.configurationProvider.configuration.loginUrl;
		if (!redirectUrl) {
			return loginUrl;
		}
		if (redirectUrl[0] !== "/") {
			redirectUrl = `/${redirectUrl}`;
		}
		return `${loginUrl}/${encodeURIComponent(redirectUrl)}`;
	}

	async logout() {
		await this.apiClient.userClient.logout(this.userId);
		window.localStorage.removeItem("authToken");
		window.localStorage.removeItem("refreshToken");
		window.location.reload();
	}

	getAuthToken(): string {
		return window.localStorage.getItem("authToken");
	}

	hasAccessToPublic(): boolean {
		return this.isLoggedIn;
	}

	hasAccessToAdmin(): boolean {
		return this.isLoggedIn && this.isAdmin;
	}

	async refreshDefaultTeamId() {
		let userInfo = await this.apiClient.userClient.getInfo();
		this.defaultTeamId = userInfo.defaultTeamId;
	}

	getUsersTeamListDataSource(userId: string) {
		return new DataSource({
			key: "teamId",
			load: async loadOptions => {
				return await this.apiClient.userClient.getUserTeams(userId, loadOptions);
			},
			map: dataItem => {
				return {
					...dataItem,
					participantRole: this.translation.translate(dataItem.participantRole),
					surveyStatusText: this.getSurveyStatusText(dataItem.surveyStatus),
				};
			},
		});
	}

	private getSurveyStatusText(surveyStatus: TeamSurveyStatus): string {
		switch (surveyStatus) {
			case TeamSurveyStatus.NotStarted:
				return this.translation.translate("Team Survey not started yet");
			case TeamSurveyStatus.NotSubmitted:
				return this.translation.translate("Team Survey not submitted");
			case TeamSurveyStatus.Submitted:
				return this.translation.translate("Team Survey submitted");
			default:
				return "";
		}
	}

	@action
	public setIsLoginVisible(value: boolean) {
		this.isLoginVisible = value;
	}

	@action
	async saveLastVisitedId(newTeamId: string) {
		const res = await this.apiClient.userClient.saveLastVisitedTeam(newTeamId);
		if (res) {
			this.defaultTeamId = newTeamId;
		}
	}

	getFilteredClientAdminDropdown(clientAdminId: string) {
		return this.usersDropdown.filter(u => !u.addedToTeam || u.value !== clientAdminId);
	}

	@action
	changeAddedToTeamInClientAdminDropdown(clientAdminId: string, oldClientAdminId: string) {
		this.changeAddedToTeam(oldClientAdminId, false);
		this.changeAddedToTeam(clientAdminId, true);
	}

	@action
	changeAddedToTeam(clientAdminId: string, value: boolean) {
		const clientAdmin = this.usersDropdown.find(u => u.value === clientAdminId);
		if (clientAdminId) {
			clientAdmin.addedToTeam = value;
		}
	}

	@flow
	public *saveLanguage(lang: string) {
		yield this.apiClient.userClient.saveLastSelectedLanguage(lang);
	}
}
