import {computed, makeObservable, observable} from "mobx";
import {SurveyResultBandIndex} from "../common/webapicall";

export enum Keys {
	Low = 1,
	Medium = 2,
	Good = 3,
	Best = 4,
}

export interface IBarHeader {
	number: number;
	width: number;
}

export class BarConfigStore {
	@observable private _maxValue: number = 10;
	@observable private _skippedTicks: number[] = [1, 3, 5];

	constructor() {
		makeObservable(this);
	}

	@computed get getMaxValue(): number {
		return this._maxValue;
	}

	@computed get getTicksPosition(): number[] {
		let ticksPosition = [] as number[];
		const averageTickPosition = 100 / this._maxValue;
		let tickPositionBuffer = averageTickPosition;
		let currentPosition = 0;
		for (let i = 1; i <= this._maxValue; i++) {
			if (!this._skippedTicks.includes(i)) {
				if (tickPositionBuffer !== 0) {
					const width = tickPositionBuffer + currentPosition;
					ticksPosition.push(width);
					tickPositionBuffer = averageTickPosition;
					currentPosition = width;
				} else {
					const width = averageTickPosition + currentPosition;
					ticksPosition.push(width);
					currentPosition = width;
				}
			} else {
				tickPositionBuffer += averageTickPosition;
			}
		}

		return ticksPosition;
	}

	@computed get getAllTicksPosition(): number[] {
		let ticksPosition = [] as number[];
		const averageTickPosition = 100 / this._maxValue;
		let currentPosition = 0;
		for (let i = 1; i <= this._maxValue; i++) {
			const width = averageTickPosition + currentPosition;
			ticksPosition.push(width);
			currentPosition = width;
		}

		return ticksPosition;
	}

	@computed get getHeaderNumbers(): IBarHeader[] {
		let headerNumbers = [] as IBarHeader[];
		const averagePosition = 100 / this._maxValue;
		let positionBuffer = averagePosition;
		let currentPosition = 0;
		for (let i = 1; i <= this._maxValue; i++) {
			if (!this._skippedTicks.includes(i)) {
				if (positionBuffer !== 0) {
					const width = positionBuffer + currentPosition;
					headerNumbers.push({number: i, width: width});
					positionBuffer = averagePosition;
					currentPosition = width;
				} else {
					const width = averagePosition + currentPosition;
					headerNumbers.push({number: i, width: width});
					currentPosition = width;
				}
			} else {
				positionBuffer += averagePosition;
			}
		}

		return headerNumbers;
	}

	@computed get getHeaderNumbersIncludeAll(): IBarHeader[] {
		let headerNumbers = [] as IBarHeader[];
		const averagePosition = 100 / this._maxValue;
		let currentPosition = 0;
		for (let i = 1; i <= this._maxValue; i++) {
			const width = averagePosition + currentPosition;
			headerNumbers.push({number: i, width: width});
			currentPosition = width;
		}

		return headerNumbers;
	}

	calculateWidth(value: number): number {
		return (value / this._maxValue) * 100;
	}

	getBandIndexClass(index: SurveyResultBandIndex): string {
		switch (index) {
			case SurveyResultBandIndex.Band4:
				return "low-key";
			case SurveyResultBandIndex.Band3:
				return "medium-key";
			case SurveyResultBandIndex.Band2:
				return "good-key";
			case SurveyResultBandIndex.Band1:
				return "best-key";
		}

		return "";
	}

	getBandIndexHexColor(index: SurveyResultBandIndex): string {
		switch (index) {
			case SurveyResultBandIndex.Band4:
				return "#F64545";
			case SurveyResultBandIndex.Band3:
				return "#FAA42B";
			case SurveyResultBandIndex.Band2:
				return "#F2D026";
			case SurveyResultBandIndex.Band1:
				return "#80C95C";
		}

		return "";
	}

	getIndicatorColorClass(participantId: string): string {
		switch (participantId) {
			case "LM":
				return "line-manager-color";
			case "TL":
				return "team-leader-color";
			case "TM":
				return "team-member-color";
			case "SH":
				return "stakeholder-color";
			default:
				return "";
		}
	}

	getIndicatorCountColorClass(participantId: string): string {
		switch (participantId) {
			case "LM":
				return "line-manager-text-color";
			case "TL":
				return "team-leader-text-color";
			case "TM":
				return "team-member-text-color";
			case "SH":
				return "stakeholder-text-color";
			default:
				return "";
		}
	}
}
