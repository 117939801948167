import DataSource from "devextreme/data/data_source";
import {action, computed, makeObservable, observable, runInAction} from "mobx";
import {IApiClient} from "../common/api.client";
import {DropdownDto, LoadResult, TeamDetailsDto, TeamStatus, TeamUpdateResponse, WebApiErrorResponse} from "../common/webapicall";
import {FeedbackProvidersStore} from "./feedback.providers.store";
import moment from "moment";
import {StoreBase} from "./store.base";
import {WebErrorMessage} from "./weberrormessage";
import {TranslationStore} from "./translation/translation.store";
import {IConfigurationProvider} from "../common/public.configuration";

export class TeamStore extends StoreBase {
	constructor(
		private apiClient: IApiClient,
		private fbStore: FeedbackProvidersStore,
		private translation: TranslationStore,
		private configurationProvider: IConfigurationProvider,
	) {
		super(apiClient);
		makeObservable(this);
	}

	@observable team: TeamDetailsDto = null;

	@action
	async reloadCurrentTeam() {
		if (!this.team) return;
		await this.getTeamDetails(this.team.id);
	}

	@action
	async getTeamDetails(teamId: string) {
		const response = await this.apiClient.teamClient.getTeam(teamId);
		runInAction(() => {
			if (response) {
				this.team = response;
			}
		});
	}

	@action
	async changeTeamDeadlineForAdmin(teamId: string, newDeadline: moment.Moment, sendReminders: boolean) {
		await this.apiClient.teamClient.changeDeadline(teamId, {
			newDeadline: newDeadline,
			sendReminders: sendReminders,
		});
		const isSurveyFinished = this.team && (this.team.status === TeamStatus.TeamInsightsCompleted || this.team.status === TeamStatus.TeamInsightsFailed);

		// get feedback providers info
		if (isSurveyFinished) {
			await this.fbStore.getFeedbackProvidersInfo(this.team.id);
		}

		runInAction(() => {
			this.team.deadline = newDeadline;
			if (isSurveyFinished) {
				this.team.status = TeamStatus.TeamInsightsInProgress;
				this.team.isTeamSurveyCompleted = false;
				this.team.isTeamSurveyFinished = false;
			}
		});
	}

	@action
	async forceFinishTeamSurvey(teamId: string) {
		await this.apiClient.teamClient.forceFinishTeamSurvey(teamId);
	}

	getTeamsDataSource(clientId: string, readAll: boolean) {
		return new DataSource({
			key: "id",
			load: async loadOptions => {
				try {
					if (readAll) {
						return await this.apiClient.teamClient.getAllTeams(clientId, loadOptions);
					}

					return await this.apiClient.teamClient.getTeams(clientId, loadOptions);
				} catch (error) {
					if (error instanceof WebApiErrorResponse) {
						if (error.status === 204) {
							return {data: [], totalCount: 0} as LoadResult;
						}
					}
					throw error;
				}
			},
			map: dataItem => {
				return {
					...dataItem,
					statusText: this.getStatusText(dataItem?.status),
				};
			},
			insert: async values => {
				if (values.clientId === undefined) {
					values.isCreatedByClientAdmin = true;
				}
				values.clientId = clientId;
				return await this.apiClient.teamClient.addTeam(values);
			},
			update: async (key, values) => {
				try {
					return await this.apiClient.teamClient.updateTeam(key, {
						id: key,
						teamName: values.teamName,
						teamTypeId: values.teamTypeId,
						purchased: values.purchased,
						preferredLanguage: values.preferredLanguage,
					});
				} catch (error) {
					if (error instanceof WebApiErrorResponse) {
						if (error.status === 400) {
							const response = JSON.parse(error.response) as TeamUpdateResponse | {};

							switch (response) {
								case TeamUpdateResponse.IdMismatch:
									throw await this.getLoggedException("Posted team's ids are mismatches", error);
								case TeamUpdateResponse.DiagnosticCompleted:
									throw await this.getLoggedException(
										"Team Name cannot be edited after the diagnostic is completed to avoid confusion.",
										error,
									);
								case TeamUpdateResponse.InvalidUser:
									throw await this.getLoggedException("Invalid User", error);
								case TeamUpdateResponse.NotFound:
									throw await this.getLoggedException("Team not found", error);
								case TeamUpdateResponse.TeamReportPurchasedPermission:
									throw await this.getLoggedException("You have no permission to change purchased status", error);
								default:
									if (response === typeof "string") {
										throw await this.getLoggedException(JSON.stringify(response), error);
									}
									const keys = Object.keys(response);
									const msgKey = keys.find(s => (response[s] as Array<string>).length > 0);
									throw await this.getLoggedException(response[msgKey], error);
							}
						} else if (error.status === 404) {
							throw await this.getLoggedException("Team not found", error);
						}
					}

					throw await this.getLoggedException(WebErrorMessage.TranslateBadRequest(this.translation), error);
				}
			},
		});
	}

	@computed get isTeamSurveyRunning(): boolean {
		return this.team && this.team.status === TeamStatus.TeamInsightsInProgress;
	}

	get getTeamStatusDataSource() {
		return [
			{
				text: this.translation.translate("Not started yet"),
				value: "TeamInsightsNotStarted",
			},
			{
				text: this.translation.translate("In progress"),
				value: "TeamInsightsInProgress",
			},
			{
				text: this.translation.translate("Completed"),
				value: "TeamInsightsCompleted",
			},
			{
				text: this.translation.translate("Failed"),
				value: "TeamInsightsFailed",
			},
		];
	}

	get getTeamParticipantsDataSource() {
		return [
			{
				text: this.translation.translate("Less than 8"),
				value: ["participants", "<", 8],
			},
			{
				text: this.translation.translate("8 - 15"),
				value: [
					["participants", ">=", 8],
					["participants", "<", 15],
				],
			},
			{
				text: this.translation.translate("15 - 30"),
				value: [
					["participants", ">=", 15],
					["participants", "<", 30],
				],
			},
			{
				text: this.translation.translate("More than 30"),
				value: ["participants", ">=", 30],
			},
		];
	}

	@computed
	get getAvailablePreferredLanguages(): DropdownDto[] {
		return this.configurationProvider.configuration.availableLanguages.map(s => ({value: s, name: this.translation.translate(s)}));
	}

	get getPaymentStatuses() {
		return [
			{
				name: this.translation.translate("Not paid"),
				value: false,
			},
			{
				name: this.translation.translate("Paid"),
				value: true,
			},
		];
	}

	getStatusText(teamStatus?: TeamStatus): string {
		switch (teamStatus) {
			case TeamStatus.TeamInsightsCompleted:
				return this.translation.translate("Completed");
			case TeamStatus.TeamInsightsFailed:
				return this.translation.translate("Failed");
			case TeamStatus.TeamInsightsInProgress:
				return this.translation.translate("In Progress");
			case TeamStatus.TeamInsightsNotStarted:
				return this.translation.translate("Not Started");
			default:
				return "";
		}
	}
}
