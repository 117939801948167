import React from "react";
import { injectAppStore } from "../../common/app.store.consumer";
import { AppStore } from "../../stores/app.store";
import ResilienceIcon from "../habit-icons/resilience";
import AgilityIcon from "../habit-icons/agility";
import FocusIcon from "../habit-icons/focus";
import AdaptingIcon from "../habit-icons/adapting";
import InfluencingIcon from "../habit-icons/influencing";
import PurposeIcon from "../habit-icons/purpose";
import TakingActionIcon from "../habit-icons/taking.action";
import TrustIcon from "../habit-icons/trust";


interface IProps {
	appStore?: AppStore;
	habitName: string;
	isMastered?: boolean;
	appearBlue?: boolean;
	appearWhite?: boolean;
	hideProgress?: boolean;
}

const TeamJourneyOctagon: React.FC<IProps> = injectAppStore()(({appStore, habitName, isMastered = false, appearBlue = false, appearWhite = false, hideProgress = false}) => {
	const getColor = (): string => {
		if (isMastered) {
			return "#ff9900";
		}
		if (appearBlue) {
			return "#0F3250";
		}

		if (appearWhite) {
			return "#fff";
		}

		return "#697378";
	};

	switch (habitName.trim().toLocaleLowerCase()) {
		case "agility":
			return <AgilityIcon color={getColor()} hideProgress={hideProgress} />;
		case "focus":
			return <FocusIcon color={getColor()} hideProgress={hideProgress} />;
		case "adapting":
			return <AdaptingIcon color={getColor()} hideProgress={hideProgress} flipIcon={appStore.translationStore.isRTL} />;
		case "influencing":
			return <InfluencingIcon color={getColor()} hideProgress={hideProgress} />;
		case "purpose":
			return <PurposeIcon color={getColor()} hideProgress={hideProgress} />;
		case "resilience":
			return <ResilienceIcon color={getColor()} hideProgress={hideProgress} />;
		case "taking action":
			return <TakingActionIcon color={getColor()} hideProgress={hideProgress} flipIcon={appStore.translationStore.isRTL} />;
		case "trust":
			return <TrustIcon color={getColor()} hideProgress={hideProgress} />;
	}

	return (
		<svg className="mt--dial" width="100%" height="100%" preserveAspectRatio="xMidYMid meet" viewBox="0 0 56 56">
			<g className="dial-shape-group">
				<path
					id="line-1"
					fill={getColor()}
					fillRule="evenodd"
					d="M21.02579957 3.49307607h17.43876335l13.42367942 13.2867881 3.52877468-1.44635182L39.92679893 0H19.56356356l1.46223601 3.49307607z"
				/>
				<path
					id="line-2"
					fill={getColor()}
					fillRule="evenodd"
					d="M52.47053018 20.1650716v17.97103754L40.13613614 50.3642876l1.46252405 3.49907637L56 39.58603891V18.71514183l-3.52946982 1.44992977z"
				/>
				<path
					id="line-3"
					fill={getColor()}
					fillRule="evenodd"
					d="M37.66599028 52.4980628H17.99853694L5.18243698 39.77946464l-3.52878333 1.45111523L16.53629735 56h22.59082978l-1.46113685-3.5019372z"
				/>
				<path
					id="line-4"
					fill={getColor()}
					fillRule="evenodd"
					d="M3.52927681 36.67489815V17.8505352L17.43903904 4.05868755 15.97659496.55932881 0 16.40048842v21.72336296l3.52927681-1.44895323z"
				/>
				<path
					id="octa"
					fill={getColor()}
					fillRule="evenodd"
					d="M34.99334334 11.1865761H21.06271271l-9.8515015 9.82985418v13.90001998l9.8515015 9.82985417h13.93063063l9.8515015-9.82985417V21.01643028l-9.8515015-9.82985417z"
				/>
			</g>
		</svg>
	);
});

export default TeamJourneyOctagon;
