import moment from "moment";
import i18n from "../i18n";

export function getDevExtremeDateFormat() {
	return "dd/MM/yyyy";
}

export const getDebugPanelFormat = (date: moment.Moment) => {
	date.locale(i18n.language);
	return date.format("L");
};

export function getTeamDetailsDateFormat(date: moment.Moment) {
	if (date) {
		date.locale(i18n.language);
		return date.format("L");
	}
	return "-";
}

export function formatDate(date: moment.Moment): string {
	if (date) {
		date.locale(i18n.language);
		return date.format("L LT");
	}
	return "-";
}

export const formatDateOnly = (date: moment.Moment): string => {
	if (date) {
		date.locale(i18n.language);
		return date.format("L");
	}
	return "";
};

export function formatTime(time: moment.Moment): string {
	if (time) {
		time.locale(i18n.language);
		return time.format("h:mm A");
	}
	return "";
}
