import * as React from "react";
import {injectAppStore} from "../../common/app.store.consumer";
import {AppStore} from "../../stores/app.store";
import {guard, isLoggedIn} from "../../common/guard";
import PublicRedirect from "./redirect";
import DataGrid, {Column, LoadPanel} from "devextreme-react/data-grid";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PublicPageHeader from "../page-header/page.header";

interface PublicHomeStates {
	redirectToTeam: boolean;
	selectedTeamId: string;
}

@guard(isLoggedIn)
@injectAppStore()
export default class PublicHome extends React.Component<{appStore?: AppStore}, PublicHomeStates> {
	constructor(props) {
		super(props);
		this.state = {
			redirectToTeam: false,
			selectedTeamId: "",
		};
	}

	componentDidMount() {
		const currentTeamId = this.props.appStore.contextStore.contextModel.teamModel?.teamId;
		this.setState({selectedTeamId: currentTeamId});
	}

	private onRowPrepared = (e: any) => {
		e.rowElement.className += " my-teams-row";
		if (e.rowType === "data") {
			if (e.data.teamId === this.state.selectedTeamId) {
				e.rowElement.className += " selected-team";
			}
		}
	};

	private onRowClick = (e: any) => {
		this.setState({redirectToTeam: true, selectedTeamId: e.key});
	};

	private renderGridCell = data => {
		return (
			<div className="cell-icon mt--flex mt--align-items-center">
				<div className="ico">
					<FontAwesomeIcon icon={["fal", "users"]} />
				</div>
				<div>{data.data.teamName}</div>
			</div>
		);
	};

	render() {
		if (!this.props.appStore.userStore.hasMoreTeam) {
			return <PublicRedirect />;
		}

		if (this.state.redirectToTeam && this.state.selectedTeamId) {
			return <PublicRedirect teamId={this.state.selectedTeamId} />;
		}

		const userId = this.props.appStore.userStore.userId;
		const userTeamsDataSource = this.props.appStore.userStore.getUsersTeamListDataSource(userId);
		return (
			<React.Fragment>
				<PublicPageHeader title={this.props.appStore.translationStore.translate("List of Teams")} hideTeamName />
				<div className="mt--area-wrap mt--fill-white">
					<div className="mt--layout mt--min-height">
						<div className="mt--content">
							<div className="mt--block-title">{this.props.appStore.translationStore.translate("List of all teams")}</div>
							<div className="mt--flex mt--justify-content-center">
								<div className="mt--datagrid mt--three-quarters">
									<DataGrid
										rtlEnabled={this.props.appStore.translationStore.isRTL}
										dataSource={userTeamsDataSource}
										showBorders={false}
										remoteOperations={true}
										onRowPrepared={this.onRowPrepared}
										onRowClick={this.onRowClick}
										hoverStateEnabled={true}
									>
										<LoadPanel enabled />
										<Column
											dataField="teamName"
											caption={this.props.appStore.translationStore.translate("Team Name")}
											cellRender={this.renderGridCell}
											allowSorting={false}
										/>
										<Column
											dataField="participantRole"
											caption={this.props.appStore.translationStore.translate("Participant Role")}
											allowSorting={false}
										/>
										<Column
											dataField="surveyStatusText"
											caption={this.props.appStore.translationStore.translate("My Diagnostic Status")}
											calculateSortValue="surveyStatus"
											alignment="right"
											allowSorting={false}
										/>
									</DataGrid>
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
