import * as React from "react";
import * as ReactDOM from "react-dom";

class Backdrop extends React.Component<{
    show: boolean;
    modalClicked: (e?: React.MouseEvent<Element>) => void;
    classes: string;
}> {
    render() {
        return this.props.show ? <div className={this.props.classes} onClick={this.props.modalClicked}></div> : null;
    }
}

class Modal extends React.Component<{
    show: boolean;
    modalClicked: (e?: React.MouseEvent<Element>) => void;
}> {
    render() {
        console.log('modalrender')
        return (
            <React.Fragment>
                <Backdrop show={this.props.show} classes={"backdrop backdrop-color-67 backdrop-zindex-100"} modalClicked={this.props.modalClicked} />
                <div
                    className="info-modal"
                    style={{
                        transform: this.props.show ? "translateY(0)" : "translateY(-100vh)",
                        opacity: this.props.show ? 1 : 0,
                    }}
                >
                    {this.props.children}
                </div>
            </React.Fragment>
        );
    }
}

export const ModalRenderer: React.FC<{
    content: JSX.Element;
    isShow: boolean;
    toggleModal?: () => void;
}> = ({ content, isShow, toggleModal }) => {
    return ReactDOM.createPortal(
        <Modal show={isShow} modalClicked={toggleModal}>
            {content}
        </Modal>,
        document.body,
    );
};
