import * as React from "react";
import {AppStore} from "../../stores/app.store";
import {injectAppStore} from "../app.store.consumer";

interface IBarHeaderProps {
	appStore?: AppStore;
	includeAll?: boolean;
	addClass?: string;
}

const BarHeader: React.FC<IBarHeaderProps> = injectAppStore()((props: IBarHeaderProps) => {
	const barConfig = props.appStore.barConfigStore;
	const headers = props.includeAll ? barConfig.getHeaderNumbersIncludeAll : barConfig.getHeaderNumbers;

	const scaleSizeClass = props.addClass ? props.addClass : ""; //"xs, sm"

	return (
		<div className={"bar-scale" + scaleSizeClass}>
			<div className="bar-header-item" style={{ marginInlineStart: 0 }}>
				0
			</div>
			{headers.map(header => (
				<div key={header.number} className="bar-header-item" style={{ marginInlineStart: `${header.width}%` }}>
					{header.number}
				</div>
			))}
		</div>
	);
});

export default BarHeader;
