import * as React from "react";
import { guard, isAdmin } from "../../common/guard";
import imageFooterLogo from "../../assets/img/logo_on_blue.svg";
import { AdminMapRoutes } from "../../stores/site.map";
import { Link } from "react-router-dom";

@guard(isAdmin)
export default class AdminFooter extends React.Component {
    render() {
        return (
			<div className="mt--area-wrap mt--pattern-footer">
				<div className="mt--layout">
					<div className="mt--footer">
						<div className="mt--flex">
							<div className="mt--footer-brand mt--mb-1 mt--py-2">
								<Link to={AdminMapRoutes.Teams}>
									<img src={imageFooterLogo} alt="footer" width="120" className="mt-inline-block mt--align-top" />
								</Link>
							</div>
							<div className="mt--footer-nav mt--flex">
								<div className="col">
									<Link to={AdminMapRoutes.Teams}>Teams</Link>
								</div>
							</div>
						</div>
						<div className="txt-legal"><span>&copy; Meta Team Limited {new Date().getFullYear()}. </span><span> All rights reserved.</span></div>
					</div>
				</div>
			</div>
		)
    }
}