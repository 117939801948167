import * as React from "react";
import * as Yup from "yup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AppStore} from "../stores/app.store";
import { injectAppStore } from "./app.store.consumer";

export interface ValidatableFormProps<TSchema extends object> {
	children: (isValid: ValidationResult) => any;
	schema: Yup.ObjectSchema<TSchema>;
	model: TSchema;
	onSubmit?: (e?: React.FormEvent<HTMLFormElement>) => void | Promise<void>;
	onValidationErrorOnSubmit?: (validationResult?: ValidationResult) => void | Promise<void>;
	validateOnBlur?: boolean;
}

export interface ValidationResult {
	isValid: boolean;
	errorMessages?: {[key: string]: string};
}

/** Base component for validatable forms. It renders its children to the page, and adds some event handlers to them. */
export class ValidatableForm<TSchema extends object> extends React.Component<ValidatableFormProps<TSchema>, ValidationResult> {
	private focusedElements: string[] = [];

	constructor(props: Readonly<ValidatableFormProps<TSchema>>) {
		super(props);
		this.state = this.validate();
	}

	private validate(): ValidationResult {
		try {
			this.props.schema.validateSync(this.props.model, {
				abortEarly: false,
			});
			return {
				isValid: true,
				errorMessages: {},
			};
		} catch (e) {
			let error = e as Yup.ValidationError;
			let res: ValidationResult = {
				isValid: false,
				errorMessages: {},
			};

			let elements = this.state ? this.focusedElements : [];
			error.inner.filter(i => elements.some(fe => fe === i.path)).forEach(i => (res.errorMessages[i.path] = i.message));
			return res;
		}
	}

	private onFocus = (e: React.FocusEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
		let focusedElements = this.focusedElements;
		let fieldName = e.target.dataset["fieldName"];
		if (fieldName && !focusedElements.some(fe => fe === fieldName)) {
			focusedElements.push(fieldName);
		}
	};

	private onBlur = (e: React.FocusEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
		if (this.shouldValidateOnBlur()) {
			this.setState(this.validate());
		}
	};

	private onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		let elements = Object.keys(this.props.model);
		elements.forEach(e => {
			if (!this.focusedElements.some(fe => fe === e)) {
				this.focusedElements.push(e);
			}
		});

		let validationResult = this.validate();
		if (validationResult.isValid) {
			if (this.props.onSubmit) {
				this.setState(validationResult);
				this.props.onSubmit(e);
			}
		} else {
			if (this.props.onValidationErrorOnSubmit) {
				this.props.onValidationErrorOnSubmit(validationResult);
			}

			this.setState(validationResult);
		}
	};

	private onKeyup = () => {
		if (this.shouldValidateOnBlur()) {
			let validationResult = this.validate();
			// not updating the validation messages intentionally, as they should be updated upon focus lost or submission.
			// the form can be submitted this way without removing focus from any other element
			this.setState(s => ({
				isValid: validationResult.isValid,
				errorMessages: s.errorMessages,
			}));
		}
	};

	private shouldValidateOnBlur() {
		return this.props.validateOnBlur ?? true;
	}

	componentDidUpdate() {
		if (this.shouldValidateOnBlur()) {
			let validationResult = this.validate();
			let currentValidationResult = this.state;
			if (JSON.stringify(validationResult) !== JSON.stringify(currentValidationResult)) {
				this.setState(validationResult);
			}
		}
	}

	render() {
		if (this.props.onSubmit) {
			return React.cloneElement(this.props.children(this.state), {
				onSubmit: this.onSubmit,
				onBlur: this.onBlur,
				onFocus: this.onFocus,
				onKeyUp: this.onKeyup,
			});
		} else {
			return this.props.children(this.state);
		}
	}
}

export interface ValidationMessageProps {
	validationResult: ValidationResult;
	fieldName: string;
	className?: string;
	style?: React.CSSProperties;
	appStore?: AppStore;
}

/**
 * Validation message component.
 * @param props Takes vaidation results from props, and shows the error message, if there's any.
 */
export const ValidationMessage = injectAppStore()((props: ValidationMessageProps) => {
	let errorMessage = props.validationResult.errorMessages[props.fieldName];
	if (errorMessage) {
		return (
			<div className={props.className} style={props.style}>
				<FontAwesomeIcon icon={["fal", "info-circle"]} />
				<span>{props.appStore.translationStore.translate(errorMessage)}</span>
			</div>
		);
	} else return null;
});

export interface ValidationMessageWithIconProps {
	validationResult: ValidationResult;
	fieldName: string;
	classNames?: string;
	iconClassNames?: string;
	appStore?: AppStore;
}

/**
 * Validation message with icon component.
 * @param props Takes vaidation results from props, and shows the error message, if there's any.
 */
export const ValidationMessageWithIcon =  injectAppStore()((props: ValidationMessageWithIconProps) => {
	let errorMessage = props.validationResult.errorMessages[props.fieldName];
	return errorMessage ? (
		<div className={props.classNames}>
			<i className={props.iconClassNames}></i>
			<span>{props.appStore.translationStore.translate(errorMessage)}</span>
		</div>
	) : null;
});

export interface ValidatableInputProps<TModel> {
	model: TModel;
	fieldName: keyof TModel;
	onChanged: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void | Promise<void>;
	children: any;
	value?: any;
}

/**
 * Validatable input component. Renders its children, and puts some additional properties on them.
 * @param props Input props for handling events of the input field.
 */
export const ValidatableInput = <TModel extends any>(props: ValidatableInputProps<TModel>) => {
	let val = props.value !== undefined ? props.value : props.model[props.fieldName];
	let mappedProps: any = {
		onChange: props.onChanged,
		"data-field-name": props.fieldName,
	};

	if (props.children.type === "input" && props.children.props.type === "radio") {
		mappedProps.checked = val === props.children.props.value;
	} else {
		mappedProps.value = val || "";
	}

	return React.cloneElement(props.children, mappedProps);
};
