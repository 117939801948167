import {LoadIndicator} from "devextreme-react/load-indicator";
import React from "react";
import {RouteComponentProps, withRouter} from "react-router";
import {injectAppStore} from "../../../common/app.store.consumer";
import {guard, isAdmin} from "../../../common/guard";
import PublicSurveyResultsMicroHabits from "../../../public/survey-results/micro-habits/survey.results.micro.habits";
import {AppStore} from "../../../stores/app.store";
import AdminTeamDetailsWrapper from "../team.details.wrapper";

type IProps = {appStore?: AppStore} & RouteComponentProps<{teamId: string}>;

@guard(isAdmin)
@injectAppStore()
class AdminTeamDetailsResultsMicroHabits extends React.Component<IProps> {
	async componentDidMount() {
		const teamId = this.props.match.params.teamId;
		if (this.props.match.params.teamId) {
			await this.props.appStore.surveyResultsStore.getSurveyResults(teamId);
		}
	}

	async componentDidUpdate(prevProps: IProps) {
		const teamId = this.props.match.params.teamId;
		if (teamId !== prevProps.match.params.teamId) {
			this.props.appStore.surveyResultsStore.isLoaded = false;
			if (teamId) {
				await this.props.appStore.surveyResultsStore.getSurveyResults(teamId);
			}
		}
	}

	render() {
		const surveyResultsStore = this.props.appStore.surveyResultsStore;
		const teamId = this.props.match.params.teamId;

		return (
			<AdminTeamDetailsWrapper teamId={teamId} teamName={surveyResultsStore.teamName} isTeamSurveyCompleted>
				{surveyResultsStore.isLoaded ? (
					<PublicSurveyResultsMicroHabits />
				) : (
					<div className="mt--preloader">
						<LoadIndicator id="large-indicator" height={60} width={60} />
					</div>
				)}
			</AdminTeamDetailsWrapper>
		);
	}
}

export default withRouter(AdminTeamDetailsResultsMicroHabits);
