import React from "react";
import {AppStore} from "../../../stores/app.store";
import {injectAppStore} from "../../../common/app.store.consumer";

interface ResultsSummaryNavigationProps {
	changeSummaryType: () => void;
	isRadarOpen: boolean;
	appStore?: AppStore;
}

const PublicSurveyResultsSummaryNavigation: React.FC<ResultsSummaryNavigationProps> = injectAppStore()((props: ResultsSummaryNavigationProps) => {
	return (
		<React.Fragment>
			<div className="radio-group">
				<input className="in-radio" type="radio" name="type" value="radar" id="radar" checked={props.isRadarOpen} onChange={props.changeSummaryType} />
				<label htmlFor="radar">{props.appStore.translationStore.translate("Radar")}</label>
				<input className="in-radio" type="radio" name="type" value="bars" id="bars" checked={!props.isRadarOpen} onChange={props.changeSummaryType} />
				<label htmlFor="bars">{props.appStore.translationStore.translate("Bars")}</label>
			</div>
		</React.Fragment>
	);
});

export default PublicSurveyResultsSummaryNavigation;
