import React from "react";
import {injectAppStore} from "../app.store.consumer";
import {AppStore} from "../../stores/app.store";
import hu from "../../assets/img/habitsHive.hu-HU.svg";
import en from "../../assets/img/habitsHive.en-GB.svg";
import de from "../../assets/img/habitsHive.de-DE.svg";
import ar from "../../assets/img/habitsHive.ar.svg";
import el from "../../assets/img/habitsHive.el.svg";
import TImage, {IImgProps} from "./timage";


interface Props extends IImgProps {
    appStore?: AppStore;
}

const HabitsHive: React.FC<Props> = injectAppStore()(props  => {

    return <TImage src={{
        "de-DE": de,
        "en-GB": en,
        "hu-HU": hu,
        "ar": ar,
        "el": el
    }} {...props} />
});

export default HabitsHive;

