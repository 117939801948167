import React from "react";

interface IProps {
	color: string;
	hideProgress?: boolean;
}

const AgilityIcon: React.FC<IProps> = ({color, hideProgress = false}) => {

	const getViewBox = (): string => {
		if (hideProgress) {
			return "112 112 336 336";
		}
		else {
			return "0 0 560 560";
		}
	};

	return (
		<svg className="mt--dial" width="100%" height="100%" preserveAspectRatio="xMidYMid meet" viewBox={getViewBox()} x="0px" y="0px">
			<g className="dial-group">
				{ !hideProgress?
					<g className="progress-shape-group">
						<path id="line-1" fill={color} d="M210.257,34.93h174.389l134.241,132.869l35.289-14.467L399.271,0H195.636L210.257,34.93z" />
						<path id="line-2" fill={color} d="M524.711,201.651v179.713l-123.35,122.281l14.63,34.99L560,395.859V187.152L524.711,201.651z" />
						<path id="line-3" fill={color} d="M376.66,524.981H179.984L51.823,397.796l-35.289,14.509L165.363,560h225.908L376.66,524.981z" />
						<path id="line-4" fill={color} d="M35.294,366.749V178.505L174.389,40.586L159.763,5.591L0,164.005v217.229L35.294,366.749z" />
					</g>
					: ""
				}
				<g className="dial-shape-group">
					<polygon fill={color} points="436.744,149.455 298.61,149.455 367.678,272.655" />
					<polygon fill={color} points="410.667,298.494 287.467,367.565 410.667,436.627" />
					<polygon fill={color} points="149.333,123.387 149.333,261.524 272.533,192.458" />
					<polygon fill={color} points="192.57,287.593 123.503,410.788 261.637,410.788" />
					<polygon fill={color} points="191.23,157.579 218.144,130.667 341.856,130.667 369.978,158.788 396.377,158.788 349.59,112 
			210.41,112 174.314,148.097"/>
					<polygon fill={color} points="412.034,174.445 402.552,191.361 429.333,218.144 429.333,341.856 429.333,341.861 
			429.333,368.256 448,349.589 448,210.411"/>
					<polygon fill={color} points="157.696,368.886 130.667,341.856 130.667,218.144 130.667,218.139 130.667,191.739 112,210.411 
			112,349.589 148.213,385.803"/>
					<polygon fill={color} points="368.76,402.435 341.856,429.333 218.144,429.333 190.265,401.455 163.87,401.455 210.41,448 
			349.59,448 385.672,411.922"/>
				</g>
			</g>
		</svg>
	);
};

export default AgilityIcon;
