import {LoadIndicator} from "devextreme-react/load-indicator";
import React from "react";
import {injectAppStore} from "../../../common/app.store.consumer";
import {AppStore} from "../../../stores/app.store";
import ExcludedTeamJourney from "../common/survey.results.excluded.team.journey";
import RecommendedTeamJourney from "../common/survey.results.recommended.team.journey";

interface Props {
	appStore?: AppStore;
}

@injectAppStore()
export default class PublicSurveyResultsTeamJourney extends React.Component<Props> {
	render() {
		const surveyResultsStore = this.props.appStore.surveyResultsStore;

		return (
			<React.Fragment>
				{surveyResultsStore.isLoaded ? (
					<React.Fragment>
						<div className="mt--area-wrap mt--fill-white">
							<div className="mt--layout">
								<div className="mt--content mt--team-journey">
									<h1 className="mt--section-title mt--pt-0">
										{this.props.appStore.translationStore.translate("Sequencing your Team Journey")}
									</h1>
									<div className="mt--half">
										<p className="mt--paragraph-lead mt--pt-0">
											{this.props.appStore.translationStore.translate(
												"Team development is not always as simple as working on the weakest habit first. Using data from hundreds of teams, the algorithm in the Meta Team platform sequences the order in which your team should develop their weaker habits to have the biggest impact in the shortest time.",
											)}
										</p>
										<p>
											{this.props.appStore.translationStore.translate(
												"Based on the results of your team diagnostic, the habits that your team needs to work on for you to make the quickest gains are shown below.",
											)}
											<br />
											{this.props.appStore.translationStore.translate("Start with the first habit on the left.")}
										</p>
									</div>

									<div className="mt--journey-wrap mt--flex mt--justify-content-between mt--pt-3 mt--pb-2">
										{surveyResultsStore.getRecommendationsTeamJourneyGroupedByStatus.recommendedHabits.length !== 0 && (
											<RecommendedTeamJourney
												habits={surveyResultsStore.getRecommendationsTeamJourneyGroupedByStatus.recommendedHabits}
											/>
										)}
										{surveyResultsStore.getRecommendationsTeamJourneyGroupedByStatus.excludedHabits.length !== 0 && (
											<ExcludedTeamJourney habits={surveyResultsStore.getRecommendationsTeamJourneyGroupedByStatus.excludedHabits} />
										)}
									</div>
								</div>
							</div>
						</div>
					</React.Fragment>
				) : (
					<div className="mt--preloader">
						<LoadIndicator id="large-indicator" height={60} width={60} />
					</div>
				)}
			</React.Fragment>
		);
	}
}
