import React from "react";
import {guard, isAdmin} from "../../common/guard";
import {injectAppStore} from "../../common/app.store.consumer";
import {AppStore} from "../../stores/app.store";
import {observer} from "mobx-react";
import {Link} from "react-router-dom";
import {AdminMapRoutes} from "../../stores/site.map";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type Props = {appStore?: AppStore};

@injectAppStore()
@guard(isAdmin)
@observer
export class BreadCrumb extends React.Component<Props> {
	render() {
		return (
			<div className="mt--breadcrumb">
				<React.Fragment>
					{this.props.appStore.dashboardStore?.breadCrumbInfo?.map((bi, idx) => (
						<React.Fragment key={bi.clientId}>
							{idx > 0 && <FontAwesomeIcon className="mt--mx-1" icon={["far", "angle-left"]} flip={this.props.appStore.translationStore.isRTL ? "horizontal" : null } />}
							<Link to={`${AdminMapRoutes.Clients}/${bi.clientId}`} key={bi.clientId}>
								{idx === 0 ? 'Home' : bi.clientName}
							</Link>
						</React.Fragment>
					))}
				</React.Fragment>
			</div>
		);
	}
}
