import React from "react";

interface IProps {
	color: string;
	hideProgress?: boolean;
}

const PurposeIcon: React.FC<IProps> = ({ color, hideProgress = false }) => {

	const getViewBox = (): string => {
		if (hideProgress) {
			return "112 112 336 336";
		}
		else {
			return "0 0 560 560";
		}
	};

	return (
		<svg className="mt--dial" width="100%" height="100%" preserveAspectRatio="xMidYMid meet" viewBox={getViewBox()}>
			<g className="dial-group">
				{!hideProgress ?
					<g className="progress-shape-group">
						<path id="line-1" fill={color} d="M210.257,34.93h174.389l134.241,132.869l35.289-14.467L399.271,0H195.636L210.257,34.93z" />
						<path id="line-2" fill={color} d="M524.711,201.651v179.713l-123.35,122.281l14.63,34.99L560,395.859V187.152L524.711,201.651z" />
						<path id="line-3" fill={color} d="M376.66,524.981H179.984L51.823,397.796l-35.289,14.509L165.363,560h225.908L376.66,524.981z" />
						<path id="line-4" fill={color} d="M35.294,366.749V178.505L174.389,40.586L159.763,5.591L0,164.005v217.229L35.294,366.749z" />
					</g>
					: ""
				}
				<g className="dial-shape-group">
					<path fill={color} d="M341.852,130.667l87.481,87.481v123.708l-87.481,87.477H218.144l-87.477-87.477V218.148l87.477-87.481
			H341.852z M349.59,112H210.41L112,210.411v139.178L210.41,448h139.18L448,349.589V210.411L349.59,112z"/>
					<polygon fill={color} points="280,168 210.934,448 349.071,448" />
				</g>
			</g>
		</svg>
	);
};

export default PurposeIcon;
