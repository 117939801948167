import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DataGrid, {AsyncRule, Button, Column, Editing, EmailRule, Form, Label, LoadPanel, Lookup, Popup, RequiredRule} from "devextreme-react/data-grid";
import {Item} from "devextreme-react/form";
import {LoadIndicator} from "devextreme-react/load-indicator";
import DataSource from "devextreme/data/data_source";
import {confirm} from "devextreme/ui/dialog";
import {toJS} from "mobx";
import React from "react";
import {RouteComponentProps, withRouter} from "react-router";
import {injectAppStore} from "../../common/app.store.consumer";
import {guard, isLoggedIn} from "../../common/guard";
import {authorizableActionsContains} from "../../common/helpers";
import {getParticipantsNavigationItems} from "../../common/navigation-items/public.submenu.items";
import {
	AuthorizableActions,
	ParticipantEmailStatus,
	ParticipantSurveyStatus,
	PublicTeamParticipantListView,
	SharingDetailsDto,
	WebApiErrorResponse,
} from "../../common/webapicall";
import {AppStore} from "../../stores/app.store";
import {PublicMapRoutes} from "../../stores/site.map";
import {WebErrorMessage} from "../../stores/weberrormessage";
import PublicPageHeader from "../page-header/page.header";
import PublicParticipantsListEmailStatus from "./participants.list.email.status";
import PublicParticipantsListOverview from "./participants.list.overview";
import CheckBox from "devextreme-react/check-box";
import {ParticipantImport} from "./participant.import";

type ParticipantsListProps = {appStore: AppStore} & RouteComponentProps<{teamId: string}>;
interface ParticipantsListStates {
	participants: DataSource;
	isLoading: boolean;
	isSaveButtonDisabled: boolean;
	orderValue: string;

	all: boolean;
	tl: boolean;
	tm: boolean;
	sh: boolean;
	lm: boolean;
}

@guard(isLoggedIn)
@injectAppStore()
class PublicParticipantsList extends React.Component<ParticipantsListProps, ParticipantsListStates> {
	private fpStore = this.props.appStore.feedbackProvidersStore;
	private userStore = this.props.appStore.userStore;
	private contextStore = this.props.appStore.contextStore;
	private dataGridRef = React.createRef<DataGrid>();
	private rowKey = "";
	private allowedShareTargetParticipantRoles: string[] = [];
	private lang = this.props.appStore.translationStore.selectedLanguage;

	state = {
		isLoading: false,
		participants: null,
		isSaveButtonDisabled: true,
		orderValue: "orderValue",

		all: false,
		tl: false,
		tm: false,
		sh: false,
		lm: false,
	};

	async componentDidMount() {
		this.setState({isLoading: true});
		await this.props.appStore.participantRoleStore.getParticipantRolesForDropdown(this.contextStore.contextModel.progress.surveyCompleted);
		await this.initList();
		this.setState({isLoading: false});

		this.allowedShareTargetParticipantRoles = this.userStore.isAdmin
			? JSON.parse(this.props.appStore.configuration.adminCanShareWith)
			: JSON.parse(this.props.appStore.configuration.teamLeaderCanShareWith);
	}

	async componentDidUpdate(prevProps: ParticipantsListProps) {
		if (this.props.match.params.teamId !== prevProps.match.params.teamId || this.lang !== this.props.appStore.translationStore.selectedLanguage) {
			this.setState({isLoading: true});
			await this.initList();
			this.setState({isLoading: false});
		}
	}

	componentWillUnmount() {
		this.fpStore.emptyOutFeedbackProvidersInfo();
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = () => {
			return;
		};
	}

	private initList = async () => {
		this.lang = this.props.appStore.translationStore.selectedLanguage;

		const teamIdFromParam = this.props.match.params.teamId;
		if (teamIdFromParam) {
			await this.props.appStore.feedbackProvidersStore.getFeedbackProvidersInfo(teamIdFromParam);
			this.fpStore.setCanEditParticipantsListValue(false);

			const participantsDataSource = this.props.appStore.feedbackProvidersStore.getFeedbackProvidersDataSource(teamIdFromParam);
			this.setState({participants: participantsDataSource});

			if (authorizableActionsContains(this.contextStore, [AuthorizableActions.CanManageParticipants])) {
				this.fpStore.setCanEditParticipantsListValue(true);
			}

			if (authorizableActionsContains(this.contextStore, [AuthorizableActions.CanShareInsightResults])) {
				this.setState({orderValue: "sharingOrderValue"});
			}
		}
	};

	private onStartDiagnostic = async () => {
		let result = await confirm(
			`<div>${this.props.appStore.translationStore.translate("Are you ready to start the diagnostic?")}</div>`,
			this.props.appStore.translationStore.translate("Start your Meta Team diagnostic"),
		);
		if (result) {
			const teamId = this.contextStore.contextModel.teamModel.teamId;
			if (teamId) {
				try {
					this.setState({isLoading: true});
					const participantSurveyId = await this.props.appStore.feedbackProvidersStore.startDiagnostic(teamId);
					await this.props.appStore.feedbackProvidersStore.getFeedbackProvidersInfo(teamId);
					this.fpStore.setCanEditParticipantsListValue(false);
					await this.contextStore.loadContext(teamId, participantSurveyId);
					if (!this.props.appStore.userStore.isAdmin) {
						this.props.history.replace(`${PublicMapRoutes.DiagnosticHowTo}/${participantSurveyId}`);
					}
				} catch (error) {
					if (error instanceof WebApiErrorResponse) {
						throw new Error(WebErrorMessage.TranslateBadRequest(this.props.appStore.translationStore));
					} else {
						throw new Error(JSON.stringify(error));
					}
				} finally {
					this.setState({isLoading: false});
				}
			}
		}
	};

	private finishSurveyNow = async () => {
		let result = false;
		if (this.props.appStore.contextStore.contextModel.teamModel.isMinimalFeedbackCountConditionsMet) {
			result = await confirm(
				"<div style='min-width: 300px;'>" +
					this.props.appStore.translationStore.translate(
						"Do you really want to end the diagnostic? The deadline will be updated to the current date, the report will be calculated and users will not be able to submit their responses after this.",
					) +
					"</div>",
				this.props.appStore.translationStore.translate("End diagnostic and generate results"),
			);
		} else {
			// this case is prevented by hiding the button on this condition but leaving it here as the API supports it
			result = await confirm(
				"<div style='min-width: 300px;'>" +
					this.props.appStore.translationStore.translate(
						"Do you really want to end the diagnostic? There are insufficient number of feedbacks to calculate the results and the diagnostic result will not be available",
					) +
					"</div>",
				this.props.appStore.translationStore.translate("End diagnostic and generate results"),
			);
		}

		if (result) {
			this.setState({isLoading: true});
			await this.props.appStore.teamStore.forceFinishTeamSurvey(this.props.appStore.contextStore.contextModel.teamModel.teamId);
			await this.props.appStore.contextStore.loadContext(
				this.props.appStore.contextStore.contextModel.teamModel.teamId,
				this.props.appStore.contextStore.contextModel.participantSurveyModel?.participantSurveyId,
			);
			await this.initList();
			this.setState({isLoading: false});
		}
	};

	private onInitNewRow = () => {
		this.rowKey = "";
	};

	private onRowInserted = async () => {
		await this.updateCounts();
		if (this.contextStore.contextModel.progress.isSurveyRunning) {
			this.refreshGrid();
		}
	};

	private onEditorPreparing = (e: any) => {
		if (e.dataField === "relationshipId" && e.parentType === "dataRow") {
			e.editorOptions.disabled = e.row.data && e.row.data.relationship !== undefined && this.contextStore.contextModel.progress.isSurveyRunning;
		}

		if (e.dataField === "email" && e.parentType === "dataRow") {
			if (e.row.data.userId === this.userStore.userId) {
				e.editorOptions.disabled = true;
			} else {
				e.editorOptions.disabled = false;
			}
		}
	};

	private onEditingStart = (e: any) => {
		this.rowKey = e.key;
	};

	private onRowUpdating = (e: any) => {
		let modifiedNewData = {};
		["firstName", "lastName", "email", "relationshipId"].forEach(item => {
			modifiedNewData[item] = e.newData[item] ? e.newData[item] : e.oldData[item];
		});
		e.newData = modifiedNewData;
	};

	private onRowUpdated = async () => {
		await this.updateCounts();
	};

	private onRowRemoved = async (e: any) => {
		// if the TL deletes itself logout
		if (e.data.userId === this.userStore.userId) {
			await this.userStore.logout();
		}

		await this.updateCounts();
		if (this.contextStore.contextModel.progress.isSurveyRunning) {
			this.refreshGrid();
		}
	};

	private onContentReady = (e: any) => {
		const popupTitle =
			this.rowKey === ""
				? this.props.appStore.translationStore.translate("New Feedback Provider")
				: this.props.appStore.translationStore.translate("Feedback Provider Details");
		e.component.option("title", popupTitle);
	};

	private allowAdding = () => {
		return this.fpStore.canEditParticipantsList || this.contextStore.contextModel.progress.surveyCompleted;
	};

	private allowUpdating = (e: any) => {
		return this.fpStore.canEditParticipantsList && !this.fpStore.isTheLoggedInUsersRow(e.row.data.userId);
	};

	private allowDeleting = (e: any) => {
		const relationshipId = e.row.data.relationshipId;
		const canBeDeleted = !this.contextStore.contextModel.progress.isSurveyRunning || this.fpStore.canBeDeleted(relationshipId);
		return this.fpStore.canEditParticipantsList && !this.fpStore.isTheLoggedInUsersRow(e.row.data.userId) && canBeDeleted;
	};

	private updateCounts = async () => {
		const teamIdFromParam = this.props.match.params.teamId;
		if (teamIdFromParam) {
			await this.props.appStore.feedbackProvidersStore.getFeedbackProvidersInfo(teamIdFromParam);
		}
	};

	private emailUnique = async (params: any) => {
		const teamIdFromParam = this.props.match.params.teamId;
		if (teamIdFromParam) {
			const participantId = this.rowKey !== "" ? this.rowKey : null;
			const result = await this.props.appStore.feedbackProvidersStore.isEmailUnique(teamIdFromParam, params.value, participantId);
			return result;
		}
	};

	// TEAM LEADER SHARE FUNCTIONS
	private renderCheckbox = (cellData: any) => {
		const initialData = this.dataGridRef.current.instance
			.getDataSource()
			.items()
			.find(p => p.id === cellData.data.id);

		if (initialData && initialData[cellData.column.dataField] && initialData["isMicroHabitsShared"]) {
			return (
				<div>
					<span className="mt--mr-05 tint-chk-grey">
						<FontAwesomeIcon icon={["fas", "check"]} />
					</span>
					{this.props.appStore.translationStore.translate("Shared")}
				</div>
			);
		}

		// don't allow to share till paid
		if (!this.props.appStore.feedbackProvidersStore.isPurchashed) {
			return;
		}

		// empty cells if the participant is not team member and not teamleader
		// this condition must be in sync with the savesharing function (filter around line 241)
		// and also must be in sync with the instance config: TeamLeaderCanShareWith
		if (!this.allowedShareTargetParticipantRoles.includes(cellData.data.relationshipId)) {
			return;
		}

		return (
			<div>
				<input type="checkbox" onChange={() => this.onChangeShareCheckbox(initialData)} checked={initialData?.isRecommendationShared ?? false} />
			</div>
		);
	};

	private onChangeShareCheckbox = (value: any) => {
		value.isRecommendationShared = !value.isRecommendationShared;
		this.setState({isSaveButtonDisabled: false});
	};

	private saveSharing = async () => {
		let result = await confirm(
			`<div>${this.props.appStore.translationStore.translate("Are you ready to share Team Results with the selected participants?")}</div>`,
			this.props.appStore.translationStore.translate("Share Team Results"),
		);
		if (result) {
			const participantIds = this.dataGridRef.current.instance
				.getDataSource()
				.items()
				.filter(p => this.allowedShareTargetParticipantRoles.includes(p.relationshipId))
				.map(p => p.id);

			const instance = this.dataGridRef.current.instance as any;
			const results = await instance.getDataByKeys(participantIds);

			const teamId = this.props.match.params.teamId;
			if (results.length > 0 && teamId && !this.state.isSaveButtonDisabled) {
				const participants: SharingDetailsDto[] = [];
				results.forEach((r: any) =>
					participants.push({
						participantId: r.id,
						participantRoleId: r.relationshipId,
						isRecommendationsShared: r.isRecommendationShared,
						isSummaryShared: r.isSummaryShared,
						isMicroHabitsShared: r.isMicroHabitsShared,
					}),
				);

				let res: boolean = false;
				const apiPayload = {
					teamId: teamId,
					shareDetails: participants,
				};
				if (this.userStore.isAdmin) {
					res = await this.props.appStore.apiClient.resultsSharingClient.adminShare(teamId, apiPayload);
				} else {
					res = await this.props.appStore.apiClient.resultsSharingClient.teamLeaderShare(teamId, apiPayload);
				}

				if (res) {
					this.refreshGrid();
				}
				this.setState({isSaveButtonDisabled: true});
			}
		}
	};

	private emailsCellRender = (cellData: any) => {
		// empty cell if the participant is team leader
		if (cellData.data.userId === this.userStore.userId) return;

		return (
			<PublicParticipantsListEmailStatus
				teamId={this.props.match.params.teamId}
				participantId={cellData.data.id}
				participantSurveyStatus={cellData.data.participantSurveyStatus as ParticipantSurveyStatus}
				teamSurveyInvitationStatus={cellData.data.teamSurveyInvitationStatus as ParticipantEmailStatus}
				teamSurveyReminderStatus={cellData.data.teamSurveyReminderStatus as ParticipantEmailStatus}
				refreshGrid={this.refreshGrid}
			/>
		);
	};

	private refreshGrid = () => {
		return this.dataGridRef.current.instance.refresh();
	};

	private refreshTheWholeGrid = () => {
		this.refreshGrid();
		if (this.contextStore.contextModel.progress.isSurveyRunning) {
			this.updateCounts();
		}
	};

	private deleteClick = e => {
		let confirmMsg = this.props.appStore.translationStore.translate("Are you sure you want to remove this participant?");

		if (e.row.data.participantSurveyStatus === ParticipantSurveyStatus.Completed) {
			confirmMsg = this.props.appStore.translationStore.translate(
				"Are you sure to remove this participant who has already completed the diagnostic? This participant’s feedback will be also deleted permanently from this team.",
			);
		}
		if (
			this.contextStore.contextModel.progress.surveyCompleted &&
			this.props.appStore.feedbackProvidersStore.isPurchashed &&
			e.row.data.participantSurveyStatus === ParticipantSurveyStatus.Completed
		) {
			confirmMsg = this.props.appStore.translationStore.translate(
				"This participant has completed the diagnostic and included in the team results. If you would like to delete this participant, reopen the diagnostic, choose a new deadline, delete the user, and the results will be recalculated once the new deadline has passed.",
			);
		}

		let result = confirm(confirmMsg, this.props.appStore.translationStore.translate("Remove Feedback Provider"));
		result.then(dialogResult => {
			if (dialogResult) {
				e.component.deleteRow(e.row.rowIndex);
			}
		});
	};

	render() {
		const contextStore = this.props.appStore.contextStore;
		const participantRoles = toJS(
			this.props.appStore.participantRoleStore.participantRolesDropdown.map(s => ({
				value: s.value,
				name: this.props.appStore.translationStore.translate(s.name ? `${s.name} (${s.value === "SH" ? "S" : s.value})` : s.name),
			})),
		);
		const teamId = this.props.match.params.teamId;
		const canShareResuls = authorizableActionsContains(this.contextStore, [AuthorizableActions.CanShareInsightResults]);
		const sharingVisible =
			authorizableActionsContains(this.contextStore, [AuthorizableActions.CanShareInsightResults]) &&
			this.props.appStore.feedbackProvidersStore.isPurchashed;
		const surveyIsRunning = this.contextStore.contextModel.progress.isSurveyRunning;
		const surveyIsCompleted = this.contextStore.contextModel.progress.surveyCompleted;
		const isPaid = this.props.appStore.feedbackProvidersStore.isPurchashed;
		const pSurveyId = this.contextStore.contextModel.teamModel.loggedInUserParticipantSurveyId;
		const isTeamLeader = this.props.appStore.feedbackProvidersStore.isCurrentUserTeamLeader || this.props.appStore.userStore.isAdmin;

		const canViewRecommendationsResults = authorizableActionsContains(contextStore, [AuthorizableActions.CanViewRecommendationsResults]);
		const canViewSummaryResults = authorizableActionsContains(contextStore, [AuthorizableActions.CanViewSummaryResults]);
		const canViewMicroHabitResults = authorizableActionsContains(contextStore, [AuthorizableActions.CanViewMicroHabitResults]);

		const chkBoxSize = 20;

		return (
			<React.Fragment>
				<PublicPageHeader
					title={this.props.appStore.translationStore.translate("Diagnostic Management")}
					navigationItems={getParticipantsNavigationItems({
						translation: this.props.appStore.translationStore,
						teamId,
						pSurveyId,
						isTeamLeader,
						isRecommendationsShared: canViewRecommendationsResults,
						isSummaryShared: canViewSummaryResults,
						isMicroHabitsShared: canViewMicroHabitResults,
					})}
				/>

				{!this.state.isLoading ? (
					<React.Fragment>
						<PublicParticipantsListOverview
							onStartDiagnostic={this.onStartDiagnostic}
							finishSurveyNow={this.finishSurveyNow}
							isLoading={this.state.isLoading}
							teamId={teamId}
						/>
						<div className="mt--area-wrap mt-- mt--fill-white mt--min-height mt--team-list">
							<div className="mt--layout mt--min-height">
								<div className="mt--content">
									<div className="mt--datagrid-toolbar mt--flex mt--flex-col">
										<div className="mt--participant-import">

											{this.allowAdding() && this.props.appStore.userStore.isAdmin && (
												<>
													{!surveyIsRunning && !surveyIsCompleted && (
														<ParticipantImport
															teamId={this.props.match.params.teamId!}
															successImport={async () => {
																await this.updateCounts();
																this.refreshGrid();
															}}
														/>
													)}
												</>
											)}

										</div>
										<div className="mt--flex toolbar-cols">
											<div className={`column ${!sharingVisible && !surveyIsRunning ? "force-row" : ""}`}>
												<div className="mt--block-title mt--flex-between">
													<div className="mt--flex mt--align-items-base">
														<span>{this.props.appStore.translationStore.translate("List of Feedback Providers")}</span>
														<div onClick={this.refreshTheWholeGrid}>
															<div className="mt--ico">
																<i className="far fa-sync-alt"></i>
															</div>
														</div>
													</div>
												</div>

												{this.allowAdding() && (
													<>

														<button
															className="mt--btn btn-med btn-grid-add"
															onClick={() => this.dataGridRef.current?.instance.addRow()}
														>
															<div className="mt--ico">
																<i className="far fa-plus"></i>
															</div>
															{this.props.appStore.translationStore.translate("Add New Feedback Provider")}
														</button>
													</>
												)}
											</div>
											<div className="column">
												{sharingVisible && (
													<>
														<div className="mt--info">
															<FontAwesomeIcon
																className="tint-orange mt--mr-05"
																icon={["fas", "info-circle"]}
																transform="down-2"
															/>
															<span>
																{this.props.appStore.translationStore.translate(
																	"Please select to share the results with participants by using the checkboxes below.",
																)}
															</span>
														</div>
														<div className="mt--share-wrap">
															<div className="mt--share-controls">
																<div className="mt--share-checkbox-container">
																	<div>
																		<CheckBox
																			className="mt--dx-checkbox"
																			rtlEnabled={this.props.appStore.translationStore.isRTL}
																			value={this.state.all}
																			onValueChanged={e => {
																				this.setState({all: e.value});
																			}}
																			width={chkBoxSize}
																			height={chkBoxSize}
																		></CheckBox>
																		<span>{this.props.appStore.translationStore.translate("All")}</span>
																	</div>
																	<div>
																		<CheckBox
																			className="mt--dx-checkbox"
																			rtlEnabled={this.props.appStore.translationStore.isRTL}
																			value={this.state.tl}
																			onValueChanged={e => {
																				this.setState({tl: e.value});
																			}}
																			width={chkBoxSize}
																			height={chkBoxSize}
																		></CheckBox>
																		<span>{this.props.appStore.translationStore.translate("TL")}</span>
																	</div>
																	<div>
																		<CheckBox
																			className="mt--dx-checkbox"
																			rtlEnabled={this.props.appStore.translationStore.isRTL}
																			value={this.state.lm}
																			onValueChanged={e => {
																				this.setState({lm: e.value});
																			}}
																			width={chkBoxSize}
																			height={chkBoxSize}
																		></CheckBox>
																		<span>{this.props.appStore.translationStore.translate("LM")}</span>
																	</div>
																	<div>
																		<CheckBox
																			className="mt--dx-checkbox"
																			rtlEnabled={this.props.appStore.translationStore.isRTL}
																			value={this.state.tm}
																			onValueChanged={e => {
																				this.setState({tm: e.value});
																			}}
																			width={chkBoxSize}
																			height={chkBoxSize}
																		></CheckBox>
																		<span>{this.props.appStore.translationStore.translate("TM")}</span>
																	</div>
																	<div>
																		<CheckBox
																			className="mt--dx-checkbox"
																			rtlEnabled={this.props.appStore.translationStore.isRTL}
																			value={this.state.sh}
																			onValueChanged={e => {
																				this.setState({sh: e.value});
																			}}
																			width={chkBoxSize}
																			height={chkBoxSize}
																		></CheckBox>
																		<span>{this.props.appStore.translationStore.translate("S")}</span>
																	</div>
																</div>
																<div className="mt--button-container">
																	<button
																		className="mt--btn btn-med"
																		onClick={() => {
																			this.state.participants
																				.items()
																				.forEach((element: PublicTeamParticipantListView) => {
																					if (element.isMicroHabitsShared) {
																						return;
																					}
																					if (
																						this.state.all ||
																						(this.state.tl && element.relationshipId === "TL") ||
																						(this.state.tm && element.relationshipId === "TM") ||
																						(this.state.lm && element.relationshipId === "LM") ||
																						(this.state.sh && element.relationshipId === "SH")
																					)
																						element.isRecommendationShared = true;
																				});
																			this.setState({
																				isSaveButtonDisabled: false,
																				all: false,
																				tl: false,
																				lm: false,
																				sh: false,
																				tm: false,
																			});
																		}}
																	>
																		{this.props.appStore.translationStore.translate("SelectOnly")}
																	</button>
																	<button
																		className="mt--btn btn-med"
																		onClick={() => {
																			this.state.participants
																				.items()
																				.forEach((element: PublicTeamParticipantListView) => {
																					if (element.isMicroHabitsShared) {
																						return;
																					}
																					if (
																						this.state.all ||
																						(this.state.tl && element.relationshipId === "TL") ||
																						(this.state.tm && element.relationshipId === "TM") ||
																						(this.state.lm && element.relationshipId === "LM") ||
																						(this.state.sh && element.relationshipId === "SH")
																					) {
																						element.isRecommendationShared = false;
																					}
																				});
																			this.setState({all: false, tl: false, lm: false, sh: false, tm: false});
																		}}
																	>
																		{this.props.appStore.translationStore.translate("Clear")}
																	</button>
																</div>
															</div>
														</div>
													</>
												)}

												{surveyIsRunning && (
													<div className="mt--notification-status mt--reminder">
														<span>
															<FontAwesomeIcon
																className="tint-orange mt--mr-05"
																icon={["fas", "info-circle"]}
																transform="down-2"
															/>
															{this.props.appStore.translationStore.translate(
																"Immediately send reminders to all who have not yet submitted the diagnostic",
															)}
														</span>
														<button
															className="mt--btn btn-med"
															onClick={async () => {
																await this.props.appStore.emailStore.resendSurveyReminderTeam(this.props.match.params.teamId);
																this.refreshGrid();
															}}
														>
															{this.props.appStore.translationStore.translate("SEND REMINDERS")}
														</button>
													</div>
												)}
											</div>
										</div>
									</div>

									<div className="mt--flex mt--flex-col mt--align-items-end mt--flex-gr-1">
										{!canShareResuls && surveyIsCompleted && isPaid && (
											<div className="mt--notification-status mt--py-2">
												<FontAwesomeIcon className="tint-orange mt--mr-05" icon={["fas", "info-circle"]} transform="down-2" />
												<div>
													{this.props.appStore.translationStore.translate(
														"Your Team Diagnostic is completed, but the results are not available yet. Once your Administrator reviews the results, they will be made available to you so you can share them with your team members.",
													)}
													{this.props.appStore.translationStore.translate(
														"Your Team Diagnostic is completed, but the results are not available yet. Once your Administrator reviews the results, they will be made available to you so you can share them with your team members.",
													)}
												</div>
											</div>
										)}
									</div>

									<div className={`mt--datagrid wide alt-btn-header mt-grid-min-width mt--mt-1 ${sharingVisible ? "sharing" : ""}`}>
										<DataGrid
											rtlEnabled={this.props.appStore.translationStore.isRTL}
											ref={this.dataGridRef}
											dataSource={this.state.participants}
											remoteOperations={true}
											onInitNewRow={this.onInitNewRow}
											onRowInserted={this.onRowInserted}
											onEditorPreparing={this.onEditorPreparing}
											onEditingStart={this.onEditingStart}
											onRowUpdating={this.onRowUpdating}
											onRowUpdated={this.onRowUpdated}
											onRowRemoved={this.onRowRemoved}
											showBorders={false}
											columnAutoWidth={false}
											wordWrapEnabled
											paging={{enabled: false}}
										>
											<Editing
												mode="popup"
												useIcons={true}
												//allowAdding={this.allowAdding()}
												allowUpdating={true}
												allowDeleting={true}
												confirmDelete={false}
											>
												<Popup
													showTitle={true}
													minWidth={320}
													maxWidth={640}
													height={"auto"}
													dragEnabled={false}
													onContentReady={this.onContentReady}
												/>
												<Form>
													<Item itemType="group" colCount={1} colSpan={2}>
														<Item dataField="firstName">
															<Label text={this.props.appStore.translationStore.translate("First Name")} />
															<RequiredRule message={this.props.appStore.translationStore.translate("First name is required")} />
														</Item>
														<Item dataField="lastName">
															<Label text={this.props.appStore.translationStore.translate("Last Name")} />
															<RequiredRule message={this.props.appStore.translationStore.translate("Last name is required")} />
														</Item>
														<Item dataField="email">
															<Label text={this.props.appStore.translationStore.translate("Email")} />
															<RequiredRule message={this.props.appStore.translationStore.translate("Email is required")} />
															<EmailRule />
															<AsyncRule validationCallback={this.emailUnique} />
														</Item>
														<Item dataField="relationshipId">
															<Label text={this.props.appStore.translationStore.translate("Relationship")} />
															<RequiredRule
																message={this.props.appStore.translationStore.translate("Relationship is required")}
															/>
														</Item>
													</Item>
												</Form>
											</Editing>
											<LoadPanel enabled={true} />
											<Column type="buttons" width={80}>
												<Button
													cssClass="mt--icon-datagrid"
													name="edit"
													// hint={this.props.appStore.translationStore.translate("Edit")}
												/>
												<Button
													cssClass="mt--icon-datagrid"
													name="delete"
													// hint={this.props.appStore.translationStore.translate("Remove")}
													onClick={this.deleteClick}
												/>
											</Column>
											<Column dataField="name" min-width={160} caption={this.props.appStore.translationStore.translate("Name")} />
											<Column
												dataField="email"
												visible={!sharingVisible}
												caption={this.props.appStore.translationStore.translate("Email")}
											/>
											<Column dataField="relationship" caption={this.props.appStore.translationStore.translate("Relationship")} />
											<Column
												dataField="participantSurveyStatus"
												caption={this.props.appStore.translationStore.translate("Diagnostic Status")}
											/>

											{/* EMAIL STATUS */}
											<Column
												caption={this.props.appStore.translationStore.translate("Email Status")}
												cellRender={this.emailsCellRender}
												visible={surveyIsRunning}
												width={310}
											/>

											{/* SHARE CHECKBOXES */}
											<Column
												dataField="isRecommendationShared"
												caption={this.props.appStore.translationStore.translate("Share Result")}
												width="12.5%"
												cellRender={this.renderCheckbox}
												visible={sharingVisible}
											/>

											{/* ORDER VALUE */}
											<Column dataField={this.state.orderValue} visible={false} defaultSortOrder="asc" />

											{/* EDIT VALUES */}
											<Column dataField="firstName" visible={false} />
											<Column dataField="lastName" visible={false} />
											<Column dataField="relationshipId" visible={false}>
												<Lookup dataSource={participantRoles} displayExpr={"name"} valueExpr={"value"} />
											</Column>
										</DataGrid>
									</div>

									{sharingVisible && (
										<div className="mt--flex mt--justify-content-center">
											<button
												className="mt--btn btn-med mt--justify-content-center"
												disabled={this.state.isSaveButtonDisabled}
												onClick={this.saveSharing}
											>
												{this.props.appStore.translationStore.translate("Save")}
											</button>
										</div>
									)}
								</div>
							</div>
						</div>
					</React.Fragment>
				) : (
					<div className="mt--preloader">
						<LoadIndicator id="large-indicator" height={60} width={60} />
					</div>
				)}
			</React.Fragment>
		);
	}
}

export default withRouter(PublicParticipantsList);
