export interface HttpResponse<T> extends Response {
	parsedBody?: T;
}

export async function fetchJsonResource<T>(request: RequestInfo): Promise<HttpResponse<T> | null> {
	const init: RequestInit = {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	};

	try {
		const response: HttpResponse<T> = await fetch(request, init);
		response.parsedBody = await response.json();
		return response;
	} catch {
		return null;
	}
}

export async function fetchHtmlResource(request: RequestInfo): Promise<HttpResponse<string> | null> {
	const init: RequestInit = {
		method: "GET",
		headers: {
			"Content-Type": "text/html",
		},
	};

	try {
		const response: HttpResponse<string> = await fetch(request, init);
		response.parsedBody = await response?.text();
		return response;
	} catch {
		return null;
	}
}
