import {observable, action, runInAction, makeObservable} from "mobx";
import {IApiClient} from "../common/api.client";
import {BreadCrumbInfo, DashboardInfo} from "../common/webapicall";
import {StoreBase} from "./store.base";

export class DashboardStore extends StoreBase {
	@observable breadCrumbInfo: BreadCrumbInfo[] = [];
	@observable dashboardInfo: DashboardInfo = {};

	constructor(private apiClient: IApiClient) {
		super(apiClient);
		makeObservable(this);
	}

	@action
	async getDashboardInfo(clientId: string) {
		if (clientId === undefined) {
			clientId = "";
		}
		const response = await this.apiClient.dashboardClient.getDashboardData(clientId);
		runInAction(() => {
			if (response) {
				this.dashboardInfo = response;
			}
		});
	}

	@action
	async getBreadCrumbInfo(clientId: string) {
		if (clientId === undefined) {
			clientId = "";
		}
		const response = await this.apiClient.dashboardClient.getBreadCrumbData(clientId);
		runInAction(() => {
			if (response) {
				this.breadCrumbInfo = response;
			}
		});
	}
}
