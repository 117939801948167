import {LoadIndicator} from "devextreme-react/load-indicator";
import React from "react";
import {injectAppStore} from "../../../common/app.store.consumer";
import {guard, isLoggedIn} from "../../../common/guard";
import {AppStore} from "../../../stores/app.store";
import PublicSurveyResultsWrapper from "../survey.results.wrapper";
import PublicSurveyResultsRecommendations from "./survey.results.recommendations";

interface PublicSurveyResultsRecommendationsProps {
	appStore: AppStore;
}

@guard(isLoggedIn)
@injectAppStore()
export default class PublicSurveyResultsRecommendationsContainer extends React.Component<PublicSurveyResultsRecommendationsProps> {
	render() {
		const surveyResultsStore = this.props.appStore.surveyResultsStore;

		return (
			<PublicSurveyResultsWrapper>
				{surveyResultsStore.isLoaded ? (
					<PublicSurveyResultsRecommendations />
				) : (
					<div className="mt--preloader">
						<LoadIndicator id="large-indicator" height={60} width={60} />
					</div>
				)}
			</PublicSurveyResultsWrapper>
		);
	}
}
