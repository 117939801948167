export function trimSlashes(text: string | null | undefined) {
	if (text === null || text === undefined) {
		return text;
	}

	return text.replace(/^([/]+)/, "").replace(/[/]+$/, "");
}

export function trimFileExtension(fileName: string) {
	const extensionIndex = fileName.lastIndexOf(".");
	if (extensionIndex === -1) {
		return fileName;
	}

	return fileName.substring(0, extensionIndex);
}

export function stringIsNotInArray(text: string | null | undefined, arrayOfTexts: (string | null | undefined)[]): boolean {
	if (text === null || text === undefined) {
		return false;
	}

	if (arrayOfTexts?.length > 0) {
		return !arrayOfTexts.includes(text);
	}

	return true;
}

export function formatPercent(percent: number | null | undefined, decimalPlaces: number = 0): string {
	if (percent === null || percent === undefined) {
		return "0%";
	}

	return `${(percent * 100).toFixed(decimalPlaces)}%`;
}

export function getStringHash(input: string) {
	let hash = 0;

	for (let i = 0; i < input.length; i++) {
		hash = input.charCodeAt(i) + ((hash << 5) - hash);
	}

	return hash;
}

export function formatForResultTables(value: number|null): string {
	if (value === null || value === 0) { return "-"; }
	return value.toFixed(1);
}

export function formatForResultTablesHighLow(value: number|null): string {
	if (value === null || value === 0) { return "-"; }
	return value.toFixed(0);
}