import React from "react";
import {Link} from "react-router-dom";
import {AdminMapRoutes} from "../../stores/site.map";
import icon from "../../assets/img/ico-dashb-company.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type IProps = {count: number; clientId: string};

export class ClientsTile extends React.Component<IProps> {
	async componentDidMount() {}

	render() {
		return (
			<div className="dashboard-tile">
				<div>
					<div className="tile-icon"><img src={icon} alt="" /></div>
				</div>
				<div className="tile-title"><span>Companies </span><span>({this.props.count})</span></div>
				<div className="tile-link">
					<Link to={`${AdminMapRoutes.Clients}/${this.props.clientId}`}>
						<span>VIEW/ADD</span>
						<FontAwesomeIcon icon={["far", "angle-right"]} />
					</Link>
				</div>
			</div>
		);
	}
}
