import React from "react";
import { AppStore } from "../../stores/app.store";
import { injectAppStore } from "../../common/app.store.consumer";

interface PublicSurveyHelpersProps {
	renderCantSay: boolean;
	renderStatements?: boolean;
	appStore?: AppStore;
}

const PublicSurveyHelpers = injectAppStore()(({renderStatements = true, renderCantSay, appStore}: PublicSurveyHelpersProps) => {
	const values = [];
	for (let index = 1; index <= 10; index++) {
		values.push(<div key={index}>{index}</div>);
	}

	return (
		<div className={"question-headfoot-sticky" + (renderStatements ? "" : " last")}>
			<div className="question-headfoot tbl-header tbl-row">
				<div className="statements">{renderStatements ? appStore.translationStore.translate("Statements") : null}</div>
				<div className="values-helpers">
					<div className="helpers" style={renderStatements ? null : {order: 2}}>
						<div>{appStore.translationStore.translate("Completely disagree")}</div>
						<div>{appStore.translationStore.translate("Neither agree nor disagree")}</div>
						<div>{appStore.translationStore.translate("Agree entirely")}</div>
					</div>
					<div className="values">{values}</div>
				</div>
				{renderCantSay && <div className="cant-say">{appStore.translationStore.translate("Can't say")}</div>}
			</div>
		</div>
	);
});

export default PublicSurveyHelpers;
