import * as React from "react";
import {AppStore} from "../stores/app.store";
import {injectAppStore} from "./app.store.consumer";
import {DebugPanelOperation} from "../stores/debug.panel.store";

export type ActionButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {appStore?: AppStore; operation: DebugPanelOperation; disabled?: boolean};

export const ActionButton: React.FC<ActionButtonProps> = injectAppStore()((props: ActionButtonProps) => {
	let store = props.appStore.debugPanelStore;
	let disabled = !!props.disabled || store.isLoading;
	let buttonProps = {
		...props,
	};

	delete buttonProps.appStore;
	delete buttonProps.operation;
	return (
		<button {...buttonProps} disabled={disabled}>
			{props.operation === store.operation && disabled ? props.appStore.translationStore.translate("...") : props.appStore.translationStore.translate("GO")}
		</button>
	);
});
