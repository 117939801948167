import React from "react";
import {RouteComponentProps, withRouter} from "react-router";
import {injectAppStore} from "../../common/app.store.consumer";
import {guard, isLoggedIn} from "../../common/guard";
import {authorizableActionsContains} from "../../common/helpers";
import {getParticipantsNavigationItems} from "../../common/navigation-items/public.submenu.items";
import {AuthorizableActions} from "../../common/webapicall";
import {AppStore} from "../../stores/app.store";
import {PublicMapRoutes} from "../../stores/site.map";
import CallToActionArea from "../cta/call.to.action";
import PublicPageHeader from "../page-header/page.header";
import HowtoIllustration from "../../common/images/howtoIllustration";

type Props = {appStore: AppStore} & RouteComponentProps<{teamId: string}>;

@guard(isLoggedIn)
@injectAppStore()
class PublicParticipantsHowTo extends React.Component<Props> {
	async componentDidMount() {
		const teamIdFromParam = this.props.match.params.teamId;
		if (teamIdFromParam) {
			await this.props.appStore.feedbackProvidersStore.getFeedbackProvidersInfo(teamIdFromParam);
		}
	}

	async componentDidUpdate(prevProps: Props) {
		const teamIdFromParam = this.props.match.params.teamId;
		if (teamIdFromParam) {
			await this.props.appStore.feedbackProvidersStore.getFeedbackProvidersInfo(teamIdFromParam);
		}
	}

	render() {
		const teamId = this.props.appStore.contextStore.contextModel.teamModel.teamId;
		const pSurveyId = this.props.appStore.contextStore.contextModel.teamModel.loggedInUserParticipantSurveyId;
		const isTeamLeader = this.props.appStore.feedbackProvidersStore.isCurrentUserTeamLeader || this.props.appStore.userStore.isAdmin;		

		const canViewRecommendationsResults = authorizableActionsContains(this.props.appStore.contextStore, [
			AuthorizableActions.CanViewRecommendationsResults,
		]);
		const canViewSummaryResults = authorizableActionsContains(this.props.appStore.contextStore, [AuthorizableActions.CanViewSummaryResults]);
		const canViewMicroHabitResults = authorizableActionsContains(this.props.appStore.contextStore, [AuthorizableActions.CanViewMicroHabitResults]);
		return (
			<React.Fragment>
				<PublicPageHeader
					title={this.props.appStore.translationStore.translate("Diagnostic Management")}
					navigationItems={getParticipantsNavigationItems({
						translation: this.props.appStore.translationStore,
						teamId,
						pSurveyId,
						isTeamLeader,
						isRecommendationsShared: canViewRecommendationsResults,
						isSummaryShared: canViewSummaryResults,
						isMicroHabitsShared: canViewMicroHabitResults,
					})}

				/>
				<div className="mt--area-wrap mt--fill-white mt--howto">
					<div className="mt--layout">
						<div className="mt--content">
							<div className="mt--flex mt--flex-wrap">
								<div className="mt--two-thirds">
									<h1 className="mt--pt-0">
										{this.props.appStore.translationStore.translate("What is the process and why complete the diagnostic?")}
									</h1>
									<p className="mt--paragraph-lead">
										{this.props.appStore.translationStore.translate(
											"Successful team development starts with a deep understanding of your team’s strengths and weaknesses. These insights can then be used by the team to challenge themselves to grow, taking their performance to the next level.",
										)}
									</p>
								</div>
								<div className="mt--two-thirds">
									<p>
										{this.props.appStore.translationStore.translate(
											"To understand your team on a deeper level, you will use Meta Team’s diagnostic tool to invite different people to rate your team, as well as scoring the team yourself as the team leader. Your first step is to set up each of the people you wish to take part in giving feedback about your team.",
										)}
									</p>
									<p>
										{this.props.appStore.translationStore.translate(
											"Click on the Team Setup button below to add your feedback providers in these three categories:",
										)}
									</p>
									<ul className="mt--list">
										<li>{this.props.appStore.translationStore.translate("Your line manager")}</li>
										<li>{this.props.appStore.translationStore.translate("Your team members")}</li>
										<li>
											{this.props.appStore.translationStore.translate(
												"The key stakeholders and influencers that know and work with your team",
											)}
										</li>
									</ul>
									<p>
										{this.props.appStore.translationStore.translate(
											"Key stakeholders and influencers may include suppliers, customers, people from other teams, senior leaders, direct reports to your team, etc. Choose the people who will provide you with the most useful feedback on the team.",
										)}
									</p>
									<p>
										{this.props.appStore.translationStore.translate(
											"For each feedback provider, you need to enter their name, email address, and select which group they belong to. The platform will automatically email each of them with an invitation to take part in the diagnostic for your team. Each participant is asked to score your team on a 1-10 scale against 32 statements. This should take 5-to-10 minutes to complete.",
										)}
									</p>
									<p>
										{this.props.appStore.translationStore.translate(
											"The results from the diagnostic will enable your team to understand what they need to work on to improve their performance, and where to start.",
										)}
									</p>
								</div>

								<div className="mt--two-thirds">
									<h2 className="tint-txt-grey">
										{this.props.appStore.translationStore.translate("Here is an example of the diagnostic results you can expect:")}
									</h2>
									<div className="mt--flex mt--flex-wrap mt--align-items-center">
										<div className="mt--illustration mt--my-1">
											<div className="mt--img-retina">
												<HowtoIllustration  alt={this.props.appStore.translationStore.translate("Summary Chart")}/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<CallToActionArea
					title={this.props.appStore.translationStore.translate("Add feedback providers")}
					btnTitle={this.props.appStore.translationStore.translate("Team setup")}
					btnSubtitle={this.props.appStore.translationStore.translate("Add feedback providers")}
					link={`${PublicMapRoutes.ParticipantsList}/${teamId}`}
				></CallToActionArea>
			</React.Fragment>
		);
	}
}

export default withRouter(PublicParticipantsHowTo);
