import React from "react";
import {injectAppStore} from "../app.store.consumer";
import {AppStore} from "../../stores/app.store";

interface Props extends IImgProps {
	appStore?: AppStore;
	src: ISrcSet;
}

export interface IImgProps {
	fileName?: string;
	alt?: string;
	width?: string;
	className?: string;
}

export interface ISrcSet {
	"en-GB": string;
	"hu-HU": string;
	"de-DE": string;
	ar: string;
    el: string
}

const TImage: React.FC<Props> = injectAppStore()(props => {
	const [imageSrc, setImageSrc] = React.useState<string>("");

	React.useEffect(() => {
		setImageSrc(props.src[props.appStore.translationStore.getLanguageCode]);
	}, [props.src, props.appStore.translationStore.getLanguageCode]);

	if (!imageSrc) {
		// return placeholder
		return <div>{props.appStore.translationStore.translate("Loading...")}</div>;
	}

	return <img src={imageSrc} alt={props.alt} width={props.width} className={props.className} />;
});

export default TImage;
