import * as React from "react";
import { AppStore } from "../../../stores/app.store";
import { injectAppStore } from "../../../common/app.store.consumer";

const PublicSurveyResultsKeys: React.FC<{appStore?:AppStore}> = injectAppStore()((props) => {
    return (
        <React.Fragment>
            <span className="mt--key-detailed key-container mt--flex mt--flex-col mt--pos-rel">
                <div className="mt--flex">
                    <div className="key low-key">{props.appStore.translationStore.translate("Weak")}</div>
                    <div className="key medium-key"></div>
                    <div className="key good-key"></div>
                    <div className="key best-key">{props.appStore.translationStore.translate("Strong")}</div>
                </div>
            </span>
        </React.Fragment>
    )
});

export default PublicSurveyResultsKeys;