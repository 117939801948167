import React from "react";
import {RoleAverage, SummaryDetails} from "../../../common/webapicall";
import RadarChart from "../summary/radar/radar.chart";
import {injectAppStore} from "../../../common/app.store.consumer";
import {AppStore} from "../../../stores/app.store";
import {formatForResultTables} from "../../../utils/string.utils";

interface IProps {
	radarResultsForRole: SummaryDetails[];
	radarResultsForChart: SummaryDetails[];
	appStore?: AppStore;
}

const PublicSurveyResultsRecommendationsRadar: React.FC<IProps> = injectAppStore()((props: IProps) => {
	return (
		<React.Fragment>
			<div className="mt--2-cols mt--tbl-radar-wrap mt--flex mt--flex-wrap page-break-inside-avoid">
				<div className="col mt--flex mt--flex-col mt--justify-content-around">
					<h2 className="mt--mb-2 mt--py-0 tint-grey-2">
						{props.appStore.translationStore.translate("The chart below is a visual representation of your results")}
					</h2>
					<div className="radar-tbl-legend mt--input-group mt--flex mt--flex-wrap">
						<span>{props.appStore.translationStore.translate("Key")}</span>
						<div>
							<span>{props.appStore.translationStore.translate("TL")}</span>
							<span> - </span>
							<span>{props.appStore.translationStore.translate("Team Leader")}</span>
						</div>
						<div>
							<span>{props.appStore.translationStore.translate("S")}</span>
							<span> - </span>
							<span>{props.appStore.translationStore.translate("Stakeholders")}</span>
						</div>
						<div>
							<span>{props.appStore.translationStore.translate("O")}</span>
							<span> - </span>
							<span>{props.appStore.translationStore.translate("Overall")}</span>
						</div>
						<div>
							<span>{props.appStore.translationStore.translate("T")}</span>
							<span> - </span>
							<span>{props.appStore.translationStore.translate("Team")}</span>
						</div>
						<div>
							<span>{props.appStore.translationStore.translate("LM")}</span>
							<span> - </span>
							<span>{props.appStore.translationStore.translate("Line Manager")}</span>
						</div>
					</div>
					<div className="tbl-wrapper">
						<table className="tbl-summary">
							<thead>
								<tr>
									<th>{props.appStore.translationStore.translate("Habit")}</th>
									<th>{props.appStore.translationStore.translate("TL")}</th>
									<th>{props.appStore.translationStore.translate("T")}</th>
									<th>{props.appStore.translationStore.translate("S")}</th>
									<th>{props.appStore.translationStore.translate("LM")}</th>
									<th>{props.appStore.translationStore.translate("Overall")}</th>
								</tr>
							</thead>
							<tbody>
								{props.radarResultsForRole.map(item => (
									<tr key={item.habitId}>
										<td>{props.appStore.translationStore.translate(item.habitName)}</td>
										{item.roleAverages.map((r: RoleAverage) => (
											<td key={r.participantRoleId}>{formatForResultTables(r.value)}</td>
										))}
										<td>{formatForResultTables(item.overallAverage)}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
					<div className="mt--mt-auto">
						{props.appStore.translationStore.translate(
							"When you log onto the Meta Team platform, you'll be able to see the full detail and find out what is seen and unseen by the different groups who completed the diagnostic.",
						)}
					</div>
				</div>

				<div className="spc"></div>

				<div className="col mt--align-items-center">
					<span className="mt--key-detailed mt--flex mt--flex-col mt--mx-auto">
						<div className="mt--flex mt--pos-rel">
							<div className="key low-key">{props.appStore.translationStore.translate("Weak")}</div>
							<div className="key mt--pos-abs"></div>
							<div className="key medium-key"></div>
							<div className="key good-key"></div>
							<div className="key best-key">{props.appStore.translationStore.translate("Strong")}</div>
						</div>
					</span>
					<RadarChart data={props.radarResultsForChart} />
				</div>
			</div>
		</React.Fragment>
	);
});

export default PublicSurveyResultsRecommendationsRadar;
