import React, {useEffect, useState} from "react";
import {AppStore} from "../../stores/app.store";
import {injectAppStore} from "../app.store.consumer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface IProps {
	appStore?: AppStore;
}

const Profile: React.FC<IProps> = injectAppStore()(props => {
	const [role, setRole] = useState("");
	useEffect(() => {
		if (props.appStore.userStore.isAdmin) {
			props.appStore.clientStore.getAllRolesForDropdown();
		}
	}, [props.appStore.userStore.isAdmin, props.appStore.clientStore]);

	useEffect(() => {
		if (props.appStore.userStore.isAdmin) {
			setRole(props.appStore.translationStore.translate("A"));
		} else {
			setRole(props.appStore.translationStore.translate(props.appStore.contextStore.contextModel?.participantSurveyModel?.participantRoleId));
		}
	}, [
		props.appStore.userStore,
		props.appStore.userStore.isAdmin,
		props.appStore.contextStore.contextModel?.participantSurveyModel?.participantRoleId,
		props.appStore.translationStore,
		props.appStore.translationStore.getLanguageCode,
	]);

	return (
		<div className="mt--user-role-display">
			<div className="mt--flex mt--align-items-start">
				<div className="mt--role-icon">
					<FontAwesomeIcon icon={["fas", "octagon"]} />
					<FontAwesomeIcon icon={["fas", "user"]} className="mt--pos-abs" />
					<div className="mt--user-role">{role}</div>
				</div>
			</div>
			<div className="mt--user-info">
				<div>{`${props.appStore.userStore.firstName} ${props.appStore.userStore.lastName}`}</div>
				<div className="info-wrap">
					<div>{props.appStore.userStore.companyName}</div>
					<div>
						{props.appStore.userStore.isAdmin && (
							<>{props.appStore.translationStore.translate(props.appStore.clientStore.getRoleName(props.appStore.userStore.companyRoleId))}</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
});

export default Profile;
