import i18n from "i18next";
import {initReactI18next} from "react-i18next";

export const defaultNs = "ui";
export const defaultLangCode = "en-GB";

i18n.use(initReactI18next)
	.init({
		resources: {},
		debug: false,
		defaultNS: defaultNs,
		fallbackNS: defaultNs,
		interpolation: {
			escapeValue: false,
		},
		react: {
			transKeepBasicHtmlNodesFor: ["br", "strong", "i", "em", "p", "div", "b", "u", "span"],
			
		},
	});

export default i18n;
