export const UserPermissions = {
	SetTeamReportPurchased: "SetTeamReportPurchased",

	CreateChildCompany: "CreateChildCompany",
	DeleteChildCompany: "DeleteChildCompany",

	EditCompany: "EditCompany",
	ChangeCoreCompanyData: "ChangeCoreCompanyData",
	ChangeCompanySupportData: "ChangeCompanySupportData",
};
