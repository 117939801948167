import React from "react";
import { injectAppStore } from "../../../common/app.store.consumer";
import { RecommendationsHabitDetails } from "../../../common/webapicall";
import { AppStore } from "../../../stores/app.store";
import TeamJourneyOctagon from "../../../common/team-journey-octagon/team.journey.octagon";

interface IProps {
    appStore?: AppStore;
    recommendationsHabitDetails: RecommendationsHabitDetails[];
    emptyText: string;
    sectionTitle?: string;
    title: string;
    containerClass?: string;
}

const PublicSurveyResultsRecommendationsHabitDetailsPdf: React.FC<IProps> = injectAppStore()((props: IProps) => {
    const barConfig = props.appStore.barConfigStore;

    if (props.recommendationsHabitDetails.length === 0) {
        return (
            <div className={`mt--scores ${props.containerClass}`}>
                <div className="mt--two-thirds mt--bb-1">
                    <div className="mt--bb-0 page-break-inside-avoid">
                        <h2 className="score-title">{props.title}</h2>
                        <div className="mt--2-cols narrow-wide page-break-inside-avoid mt--py-0">
                            <div className="col">
                                <div className="mt--octa">
                                    <div className="mt--ico-cog"></div>
                                </div>
                            </div>
                                <div className="col">{props.emptyText}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={`mt--scores ${props.containerClass}`}>
            <div className="mt--two-thirds">
                {props.recommendationsHabitDetails.map((rhd, index) => (
                    <div key={rhd.habitId} >
                        {index === 0 && (
                            <>
                                {props.sectionTitle &&
                                    <div className="mt--section-title mt--pt-3 mt--pb-1">
                                        {props.sectionTitle}
                                    </div>}
                                <div className="page-break-inside-avoid">
                                    <h2 className="score-title">{props.title}</h2>
                                    <div className="mt--2-cols narrow-wide">
                                        <div className={"col mt--octa-score " + barConfig.getBandIndexClass(rhd.bandIndex)}>
                                            <div className="mt--flex mt--justify-content-center">
                                                <svg viewBox="0 0 105 105" width="100%" height="100%">
                                                    <path
                                                        fill="#808080"
                                                        d="M74.359,105H30.845L0,74.366V30.839L30.839,0h43.527L105,30.845v43.515L74.359,105z M33.318,99h38.557
								        L99,71.875V33.318L71.868,6H33.324L6,33.324v38.544L33.318,99z"
                                                    />
                                                    <text x="50%" y="50%" textAnchor="middle" dominantBaseline="central" fontFamily="Open-Sans" fontSize="22" fontWeight="800">
                                                        {rhd.average.toFixed(1)}
                                                    </text>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="spc"></div>
                                        <div className="col mt--score-desc">
                                            <div className="mt--habit-name-with-icon">
                                                <TeamJourneyOctagon habitName={rhd.habitName} appearBlue hideProgress />
                                                <div style={{ lineHeight: 0.7 }}>{props.appStore.translationStore.translate(rhd.habitName)}</div>
                                            </div>
                                            <p>{props.appStore.translationStore.translate(rhd.recommendationText)}</p>
                                            </div>
                                    </div>
                                </div>
                            </>
                        )}
                        {index !== 0 && (
                            <div className="mt--2-cols narrow-wide page-break-inside-avoid">
                                <div className={"col mt--octa-score " + barConfig.getBandIndexClass(rhd.bandIndex)}>
                                    <div className="mt--flex mt--justify-content-center">
                                        <svg viewBox="0 0 105 105" width="100%" height="100%">
                                            <path
                                                fill="#808080"
                                                d="M74.359,105H30.845L0,74.366V30.839L30.839,0h43.527L105,30.845v43.515L74.359,105z M33.318,99h38.557
								    L99,71.875V33.318L71.868,6H33.324L6,33.324v38.544L33.318,99z"
                                            />
                                            <text x="50%" y="50%" textAnchor="middle" dominantBaseline="central" fontFamily="Open-Sans" fontSize="22" fontWeight="800">
                                                {rhd.average.toFixed(1)}
                                            </text>
                                        </svg>
                                    </div>
                                </div>
                                <div className="spc"></div>
                                <div className="col mt--score-desc">
                                    <div className="mt--habit-name-with-icon">
                                        <TeamJourneyOctagon habitName={rhd.habitName} appearBlue hideProgress />
                                        <div style={{ lineHeight: 0.7 }}>{props.appStore.translationStore.translate(rhd.habitName)}</div>
                                    </div>
                                    <p>{props.appStore.translationStore.translate(rhd.recommendationText)}</p>
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
});

export default PublicSurveyResultsRecommendationsHabitDetailsPdf;
