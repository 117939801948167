import React from "react";
import {injectAppStore} from "../../common/app.store.consumer";
import {AppStore} from "../../stores/app.store";
import DataGrid, {
	Button,
	Column,
	CompareRule,
	Editing,
	EmailRule,
	FilterRow,
	Form,
	HeaderFilter,
	Label,
	Lookup,
	Pager,
	Paging,
	Popup,
	RequiredRule,
	Sorting,
	StateStoring,
	AsyncRule,
} from "devextreme-react/data-grid";
import {getDevExtremeDateFormat} from "../../utils/date.utils";
import {Item, SimpleItem, GroupItem} from "devextreme-react/form";
import {toJS} from "mobx";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {PublicMapRoutes} from "../../stores/site.map";
import {UserPermissions} from "../../stores/user.permissions";
import {Scrolling} from "devextreme-react/tree-list";
import "devextreme-react/ui/radio-group";

interface AdminTeamListProps {
	appStore?: AppStore;
	clientId: string;
	forceNew?: string;
}

@injectAppStore()
export default class AdminTeamList extends React.Component<AdminTeamListProps> {
	private userStore = this.props.appStore.userStore;
	private dataGridRef = React.createRef<DataGrid>();
	private rowKey = "";
	private teamLeaderDataItemGroupName = "teamLeader";
	private instructionsItemGroupName = "instructions";
	private teamNameInputName = "teamName";
	private teamTypeIdInputName = "teamTypeId";
	private purchasedInputName = "purchased";

	private isCreateNewRowMode = false;

	async componentDidMount() {
		if (this.props.clientId && this.props.appStore.clientStore.currentClientId !== this.props.clientId) {
			this.props.appStore.clientStore.setCurrentClientId(this.props.clientId);
		}
	}

	async componentDidUpdate(prevProps: AdminTeamListProps) {
		if (this.props.clientId && this.props.appStore.clientStore.currentClientId !== this.props.clientId) {
			this.props.appStore.clientStore.setCurrentClientId(this.props.clientId);
		}
		if (this.props.forceNew) {
			this.dataGridRef?.current?.instance?.addRow();
		}
	}

	private onToolbarPreparing = (e: any) => {
		e.toolbarOptions.items.forEach((item: any) => {
			if (item.name === "addRowButton") {
				item.showText = "always";
				item.options.text = "Add New";
				item.options.hint = "Add New";
			}
		});
	};

	private onInitNewRow = (e: any) => {
		this.rowKey = "";
		e.data.purchased = false;
		e.data.facilitatorRequested = false;
		e.data.packageId = this.props.appStore.packageStore.getDefaultPackageId;
		e.data.clientName = this.props.appStore.clientStore.getClientName(this.props.clientId);
		this.isCreateNewRowMode = true;
	};

	private onEditingStart = (e: any) => {
		this.rowKey = e.key;
		this.isCreateNewRowMode = false;
	};

	private onContentReady = (e: any) => {
		const popupTitle = this.rowKey === "" ? "New Team" : "Edit Team";
		e.component.option("title", popupTitle);

		const saveButtonText = this.rowKey === "" ? "Create" : "Save";
		e.component.option("toolbarItems[0].options.text", saveButtonText);

		// add class to popup to hide * from required fields
		e.component.content().classList.add("mt-admin-list-popup");
	};

	private checkEmailComparison = () => {
		const gridInstance = this.dataGridRef.current.instance;
		let index = gridInstance.getRowIndexByKey(this.rowKey);
		index = index === -1 ? 0 : index;
		return gridInstance.cellValue(index, "email");
	};

	private emailUnique = async (params: any) => {
		const result = await this.props.appStore.feedbackProvidersStore.isEmailUnique("", params.value, null);
		return result;
	};

	private teamNameCellRender = (param: any) => {
		return (
			<u>
				<Link to={`${PublicMapRoutes.ParticipantsList}/${param.data.id}`}>{param.value}</Link>
			</u>
		);
	};

	private customizeEditFormItem = (item: any) => {
		if (this.isCreateNewRowMode || (item.name !== this.teamLeaderDataItemGroupName && item.name !== this.instructionsItemGroupName)) {
			return;
		}

		item.visible = false;
	};

	private onEditorPreparing = (e: any) => {
		if (e.dataField === this.teamNameInputName || e.dataField === this.teamTypeIdInputName) {
			const status = this.getCellValue("status");
			e.editorOptions.disabled = status === "TeamInsightsCompleted";
			return;
		}

		if (e.dataField === this.purchasedInputName) {
			if (!this.props.appStore.userStore.hasPermission(UserPermissions.SetTeamReportPurchased)) {
				e.editorOptions.disabled = true;
			}
		}
	};

	private getCellValue(dataField: string): any {
		const gridInstance = this.dataGridRef.current.instance;
		const editRowKey = gridInstance.option("editing.editRowKey");
		const index = gridInstance.getRowIndexByKey(editRowKey);
		if (index === -1) {
			return null;
		}

		return gridInstance.cellValue(index, dataField);
	}

	render() {
		const teams = this.props.appStore.teamStore.getTeamsDataSource(this.props.clientId || "", false);
		const teamTypes = toJS(
			this.props.appStore.teamTypeStore.teamTypesDropdown.map(s => ({value: s.value, name: this.props.appStore.translationStore.translate(s.name)})),
		);
		const dateFormat = getDevExtremeDateFormat();

		return (
			<React.Fragment>
				<div className="mt--hide mt--block-title mt--pb-0">List of all teams</div>
				<div className="mt--datagrid">
					<DataGrid
						ref={this.dataGridRef}
						dataSource={teams}
						columnAutoWidth={true}
						showBorders
						showRowLines
						columnHidingEnabled={false}
						allowColumnReordering={false}
						remoteOperations={true}
						onToolbarPreparing={this.onToolbarPreparing}
						onInitNewRow={this.onInitNewRow}
						onEditingStart={this.onEditingStart}
						onEditorPreparing={this.onEditorPreparing}
						wordWrapEnabled
					>
						<Scrolling showScrollbar="always" useNative={false} />
						<Sorting mode="multiple" />
						<FilterRow visible={false} />
						<HeaderFilter visible={true} />
						<StateStoring enabled={true} type="localStorage" storageKey="meta-teams" />
						<Paging defaultPageSize={20} />
						<Pager visible={true} showPageSizeSelector={true} allowedPageSizes={[20, 50, 100]} />
						<Editing useIcons={true} mode="popup" allowAdding={this.userStore.canAddTeam} allowUpdating={true}>
							<Popup showTitle={true} minWidth={320} maxWidth={900} height={"auto"} maxHeight={700} onContentReady={this.onContentReady} />
							<Form colCount={2} customizeItem={this.customizeEditFormItem}>
								<Item itemType="group" caption="" colCount={2} colSpan={2}>
									<Item dataField="teamName" name={this.teamNameInputName}>
										<RequiredRule message="Team Name is required" />
									</Item>
									<Item dataField="clientName" disabled={true} cssClass="mt--disabled-display">
										<Label text="Company" />
									</Item>
									<Item dataField="teamTypeId">
										<Label text="Team Type" />
										<RequiredRule message="Team type is required" />
									</Item>
									<SimpleItem
										dataField="purchased"
										editorType="dxRadioGroup"
										editorOptions={{layout: "horizontal"}}
										cssClass="mt--radiogroup"
									>
										<Label text="Payment status" />
									</SimpleItem>
									<Item dataField="preferredLanguage">
										<Label text="Preferred language" />
										<RequiredRule message="Preferred language is required" />
									</Item>
								</Item>

								<GroupItem caption="Team Leader" colCount={2} colSpan={2} name={this.teamLeaderDataItemGroupName}>
									<SimpleItem dataField="firstName">
										<RequiredRule message="First Name is required" />
									</SimpleItem>
									<SimpleItem dataField="lastName">
										<RequiredRule message="Last Name is required" />
									</SimpleItem>
									<SimpleItem dataField="email">
										<RequiredRule message="Email is required" />
										<EmailRule />
										<AsyncRule validationCallback={this.emailUnique} />
									</SimpleItem>
									<SimpleItem dataField="retypeEmail">
										<RequiredRule message="Retype Email is required" />
										<EmailRule />
										<CompareRule message="Emails must be identical" comparisonTarget={this.checkEmailComparison} comparisonType="===" />
									</SimpleItem>
								</GroupItem>
								<Item dataField="instruction" colSpan={2} cssClass="form-info" name={this.instructionsItemGroupName}>
									<Label visible={false} />
									<FontAwesomeIcon className="tint-orange" icon={["far", "info-circle"]} />
									<span>
										After clicking the "Create Team" button, the new team will be created and a notification email will be sent to the
										entered email address with further instructions.
									</span>
								</Item>
							</Form>
						</Editing>

						<Column type="buttons" width={60} visible alignment="center" caption="Action">
							<Button cssClass="mt--icon-datagrid" name="edit" />
							<Button cssClass="mt--icon-datagrid" name="delete" hint="Remove" />
						</Column>
						<Column dataField="teamName" caption="Team Name" cellRender={this.teamNameCellRender} minWidth={175} />
						<Column dataField="teamLeadersFullName" caption="Team Leaders" width={240} />
						<Column dataField="participants" alignment="center" caption="No." width={110}>
							<HeaderFilter dataSource={this.props.appStore.teamStore.getTeamParticipantsDataSource} />
						</Column>
						<Column dataField="assessmentStartDate" width={150} alignment="center" caption="Start Date" dataType="date" format={dateFormat} />
						<Column dataField="assessmentDeadline" width={150} alignment="center" caption="Deadline" dataType="date" format={dateFormat} />
						<Column dataField="status" caption="Status" name="statusText" width={120}>
							<Lookup dataSource={this.props.appStore.teamStore.getTeamStatusDataSource} displayExpr={"text"} valueExpr={"value"} />
						</Column>
						<Column dataField="purchased" caption="Paid?" width={90}>
							<Lookup dataSource={this.props.appStore.teamStore.getPaymentStatuses} displayExpr={"name"} valueExpr={"value"} />
						</Column>

						{/* EDIT FIELDS */}
						<Column dataField="teamTypeId" visible={false}>
							<Lookup dataSource={teamTypes} displayExpr={"name"} valueExpr={"value"} />
						</Column>
						<Column dataField="firstName" visible={false} />
						<Column dataField="lastName" visible={false} />
						<Column dataField="email" visible={false} />
						<Column dataField="status" visible={false} />
						<Column dataField="clientName" visible={false} />
						<Column dataField="preferredLanguage" visible={false}>
							<Lookup dataSource={this.props.appStore.teamStore.getAvailablePreferredLanguages} displayExpr={"name"} valueExpr={"value"} />
						</Column>
						{/*<Column dataField="purchased" visible={false} />*/}
					</DataGrid>
				</div>
			</React.Fragment>
		);
	}
}
