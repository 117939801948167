import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {Link} from "react-router-dom";
import {AdminMapRoutes} from "../../stores/site.map";
import {injectAppStore} from "../../common/app.store.consumer";
import {AppStore} from "../../stores/app.store";
import icon from "../../assets/img/ico-dashb-admin.svg";

type IProps = {appStore?: AppStore} & {clientId: string};
@injectAppStore()
export class ManageTile extends React.Component<IProps> {
	async componentDidMount() {}

	render() {
		return (
			<div className="dashboard-tile">
				<div>
					<div className="tile-icon"><img src={icon} alt="" /></div>
				</div>
				<div className="tile-title">{this.props.appStore.dashboardStore.dashboardInfo.currentClientName}</div>
				<div className="tile-link">
					<div>
						<Link to={`${AdminMapRoutes.AdminUsers}/${this.props.clientId}`}>
							<span>Manage Admins</span>
							<FontAwesomeIcon icon={["far", "angle-right"]} />
						</Link>
					</div>
				</div>
			</div>
		);
	}
}
