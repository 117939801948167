import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { AppStore } from "../../stores/app.store";
import { injectAppStore } from "../../common/app.store.consumer";


interface IProps { title: string, btnTitle?: string, btnSubtitle?: string, link?: string, appStore?: AppStore, children?:React.ReactNode }

const CallToActionArea: React.FC<IProps> = injectAppStore()((props: IProps) => {
    return (
        <div className="mt--area-wrap area-cta mt--fill-bgrey">
            <div className="mt--layout">
                <div className="mt--content mt--cta mt--p-0">
                    <div className="col mt--pt-3 mt--align-self-start">
                        <div className="mt--title">{props.title}</div>
                        <div className="mt--txt mt--mt-1">{props.children}</div>
                    </div>
                    <div className="spc"></div>
                    <div className="col mt--flex mt--py-4">
                        {props.btnTitle && props.link ?
                            <Link to={props.link}>
                                <button className="mt--btn btn-cta">
                                    <div className="mt--align-self-center mt--ico"><FontAwesomeIcon icon={["far", "arrow-right"]} flip={props.appStore.translationStore.isRTL ? "horizontal" : null} /></div>
                                    <div>
                                        <div className="txt-main">{props.btnTitle}</div>
                                        {props.btnSubtitle &&
                                            <div className="txt-sub">
                                                {props.btnSubtitle}
                                            </div>
                                        }
                                    </div>
                                </button>
                            </Link> : null
                        }
                    </div>
                </div>
            </div>
        </div>
    );
});

export default CallToActionArea;