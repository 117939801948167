import React from "react";
import {Redirect, RouteComponentProps, StaticContext, withRouter} from "react-router";
import {AppStore} from "../stores/app.store";
import {SiteMapRoutes} from "../stores/site.map";
import {injectAppStore} from "./app.store.consumer";
import {MainBlank} from "./blank";
import {AuthorizableActions} from "./webapicall";

interface Props extends RouteComponentProps<any, StaticContext, any> {
	appStore?: AppStore;
	authorizableActions?: AuthorizableActions[];
	component: React.ExoticComponent | React.ComponentType;
}

interface State {
	initialized: boolean;
	pathname: string;
	redirect: boolean;
}

@injectAppStore()
class ProtectedPageWithoutMenu extends React.Component<Props, State> {
	state: State = {
		initialized: false,
		pathname: null,
		redirect: false,
	};

	private _prevComponent: JSX.Element = null;

	async componentDidMount() {
		await this.handleUrlChange();
	}

	private async handleUrlChange() {
		this.setState({
			initialized: false,
			pathname: this.props.location.pathname,
			redirect: false,
		});

		const teamIdFromParam = this.props.match.params.teamId;
		const pSurveyIdFromParam = this.props.match.params.pSurveyId;

		const teamId = teamIdFromParam !== undefined ? teamIdFromParam : null;
		const pSurveyId = pSurveyIdFromParam !== undefined ? pSurveyIdFromParam : null;
		const contextStore = this.props.appStore.contextStore;

		if (this.props.authorizableActions) {
			contextStore
				.loadContext(teamId, pSurveyId)
				.then(() => {
					if (!this.props.authorizableActions.every(aa => contextStore.contextModel.authorizedActions.includes(aa))) {
						this.setState({redirect: true});
					}
				})
				.catch(() => this.setState({redirect: true}));
		}

		this.setState({
			initialized: true,
			pathname: this.props.location.pathname,
		});
		window.scrollTo(0, 0);
	}

	static getDerivedStateFromProps(nextProps: Readonly<Props>, prevState: Readonly<State>): State {
		if (nextProps.location.pathname !== prevState.pathname) {
			return {
				initialized: false,
				pathname: nextProps.location.pathname,
				redirect: false,
			};
		} else {
			return null;
		}
	}

	async componentDidUpdate(prevProps: Props) {
		if (prevProps.location.pathname !== this.props.location.pathname) {
			await this.handleUrlChange();
		}
	}

	render() {
		let children: JSX.Element = null;
		let Component = this.props.component;
		if (this.state.initialized) {
			if (this.state.redirect) {
				children = (
					<React.Fragment>
						{alert(this.props.appStore.translationStore.translate("Invalid url! You will be redirected to your team's page."))}
						<Redirect to={SiteMapRoutes.Public} />
					</React.Fragment>
				);
			} else {
				children = <Component />;
				this._prevComponent = children;
			}
		} else {
			children = this._prevComponent ? this._prevComponent : <MainBlank />;
		}

		return <>{children}</>;
	}
}

export default withRouter(ProtectedPageWithoutMenu);
