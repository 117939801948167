import React from "react";
import {Moment} from "moment";
import {formatDate} from "../../utils/date.utils";
import { injectAppStore } from "../../common/app.store.consumer";
import { AppStore } from "../../stores/app.store";
//import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
//import {IconProp} from "@fortawesome/fontawesome-svg-core";

interface PublicSurveyStatusProps {
	isDiagnosticSubmitted: boolean;
	completionDate?: Moment;
	appStore?:AppStore;
}

const PublicSurveyStatus = injectAppStore()(({isDiagnosticSubmitted, completionDate, appStore}: PublicSurveyStatusProps) => {
	let status = appStore.translationStore.translate("Team Diagnostic deadline has expired.");
	let thankYouMsg = "";
	let type:string = "warning";
	let iconColor: string = "#FF9900";
	if (isDiagnosticSubmitted && completionDate !== null) {
		type = "success";
		iconColor = "#416D4F";
		thankYouMsg = appStore.translationStore.translate("Thank you for submitting your diagnostic. The results will be shared with you once the survey is completed by all participants.");
		status = appStore.translationStore.translate("The survey was submitted on {{date}}",{date: formatDate(completionDate)});
	}

	return (
		<div className={`mt--notification ${type}`}>
			<div className="stroke-octagon">
				<svg width="39px" height="39px" viewBox="-449 310.813 38.938 39">
					<path fill={iconColor} d="M-421.443,349.813h-16.162l-11.457-11.379v-16.168l11.455-11.453h16.166l11.379,11.456v16.162
		L-421.443,349.813z M-436.575,347.314h14.097l9.916-9.915v-14.101l-9.918-9.985h-14.091l-9.99,9.989v14.091L-436.575,347.314z"/>
					{
						type === "neutral" && <g /*transform="scale(.8)" transform-origin="-432 330"*/> <path id="neutral" fill="#0F3250" d="M-432.703,337.541h0.859v-6.207h-0.859c-0.475,0-0.859-0.385-0.859-0.859v-2.053c0-0.475,0.385-0.859,0.859-0.859h4.813
	c0.475,0,0.859,0.385,0.859,0.859v9.119h0.859c0.475,0,0.859,0.385,0.859,0.859v2.053c0,0.475-0.385,0.859-0.859,0.859h-6.531
	c-0.475,0-0.859-0.385-0.859-0.859V338.4C-433.563,337.926-433.178,337.541-432.703,337.541z M-429.438,319.313
	c-1.708,0-3.094,1.385-3.094,3.094c0,1.708,1.385,3.094,3.094,3.094c1.709,0,3.094-1.386,3.094-3.094
	C-426.344,320.697-427.729,319.313-429.438,319.313z"/> </g>
					}
					{type === "success" && <g /*transform="scale(.8)" transform-origin="-432 330"*/><path id="success" fill="#416D4F"d="M-432.973,337.998l-6.972-6.974c-0.421-0.42-0.421-1.097,0-1.516l1.517-1.518c0.418-0.42,1.098-0.42,1.516,0l4.698,4.696
		l10.063-10.062c0.418-0.418,1.098-0.418,1.517,0l1.517,1.518c0.419,0.418,0.419,1.099,0,1.519l-12.337,12.336
		C-431.874,338.417-432.553,338.417-432.973,337.998L-432.973,337.998z"/></g>
					}
					{type === "warning" && <g /*transform="scale(.8)" transform-origin="-432 330"*/><path id="warning" fill="#FF9900" d="M-426.094,337.875c0,1.896-1.542,3.438-3.438,3.438s-3.438-1.542-3.438-3.438s1.542-3.438,3.438-3.438
		S-426.094,335.979-426.094,337.875z M-432.571,320.396l0.584,11.688c0.027,0.549,0.48,0.979,1.03,0.979h2.851
		c0.549,0,1.002-0.431,1.03-0.979l0.584-11.688c0.029-0.589-0.44-1.083-1.03-1.083h-4.02
		C-432.131,319.313-432.6,319.807-432.571,320.396z"/></g>
					}
				</svg>
			</div>
			<div>
				{thankYouMsg !== "" && <div>{thankYouMsg}</div>}
				<div>{status}</div>
			</div>
		</div>
		//<div className="mt--notification mt--flex mt--align-items-center">
		//	<FontAwesomeIcon className="mt--mr-1" icon={iconClasses} size="2x" transform="grow-2" />
		//	<div>
		//		{thankYouMsg !== "" && <div>{thankYouMsg}</div>}
		//		<div>{status}</div>
		//	</div>
		//</div>
	);
});

export default PublicSurveyStatus;
