import {action, makeObservable, observable} from "mobx";
import {defaultLangCode} from "../i18n";

export class LoginStore {
	@observable isSuccessful = false;
	@observable isSuccessfulVisible = false;
	@observable isUnsuccessfulVisible = false;
	@observable email = "";
	@observable languageId = defaultLangCode;
	@observable hideInitialState = false;

	constructor() {
		makeObservable(this);
	}

	@action
	public init() {
		this.isSuccessful = false;
		this.isSuccessfulVisible = false;
		this.isUnsuccessfulVisible = false;
		this.email = "";
		this.languageId = defaultLangCode;
		this.hideInitialState = false;
	}

	@action
	setisSuccessful(value: boolean) {
		this.isSuccessful = value;
	}

	@action
	setisSuccessfulVisible(value: boolean) {
		this.isSuccessfulVisible = value;
	}

	@action
	setisUnsuccessfulVisible(value: boolean) {
		this.isUnsuccessfulVisible = value;
	}

	@action
	setemail(value: string) {
		this.email = value;
	}

	@action
	setlanguageId(value: string) {
		this.languageId = value;
	}

	@action
	sethideInitialState(value: boolean) {
		this.hideInitialState = value;
	}
}
