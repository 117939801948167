import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {LoadIndicator} from "devextreme-react/load-indicator";
import {confirm} from "devextreme/ui/dialog";
import React from "react";
import {RouteComponentProps, withRouter} from "react-router";
import {injectAppStore} from "../../../common/app.store.consumer";
import {guard, isAdmin} from "../../../common/guard";
import {AppStore} from "../../../stores/app.store";
import {getTeamDetailsDateFormat} from "../../../utils/date.utils";
import AdminTeamDetailsWrapper from "../team.details.wrapper";
import AdminTeamDetailsDeadline from "./team.details.deadline";
import AdminTeamDetailsParticipantList from "./team.details.participant.list";

type IProps = {appStore?: AppStore} & RouteComponentProps<{teamId: string}>;

@guard(isAdmin)
@injectAppStore()
class AdminTeamDetailsOverview extends React.PureComponent<IProps> {
	state = {
		isLoading: false,
	};

	private fpStore = this.props.appStore.feedbackProvidersStore;
	private teamStore = this.props.appStore.teamStore;

	async componentDidMount() {
		const teamId = this.props.match.params.teamId;
		if (teamId) {
			await this.teamStore.getTeamDetails(teamId);
			if (this.teamStore.isTeamSurveyRunning) {
				await this.fpStore.getFeedbackProvidersInfo(teamId);
			}
		}
	}

	componentWillUnmount() {
		this.props.appStore.teamStore.team = null;
		this.fpStore.emptyOutFeedbackProvidersInfo();
	}

	private finishSurveyNow = async () => {
		let result = false;
		if (this.teamStore.team.isMinimalFeedbackCountConditionsMet) {
			result = await confirm(
				"<div style='min-width: 300px;'>" +
					this.props.appStore.translationStore.translate(
						"Do you really want to end the diagnostic? The deadline will be updated to the current date, the report will be calculated and users will not be able to submit their responses after this.",
					) +
					"</div>",
				this.props.appStore.translationStore.translate("End diagnostic and generate results"),
			);
		} else {
			// this case is prevented by hiding the button on this condition but leaving it here as the API supports it
			result = await confirm(
				"<div style='min-width: 300px;'>" +
					this.props.appStore.translationStore.translate(
						"Do you really want to end the diagnostic? There are insufficient number of feedbacks to calculate the results and the diagnostic result will not be available",
					) +
					"</div>",
				this.props.appStore.translationStore.translate("End diagnostic and generate results"),
			);
		}

		if (result) {
			this.setState({isLoading: true});
			await this.teamStore.forceFinishTeamSurvey(this.teamStore.team.id);
			await this.teamStore.reloadCurrentTeam();
			this.setState({isLoading: false});
		}
	};

	render() {
		const team = this.props.appStore.teamStore.team;
		if (team === null) {
			return (
				<div className="mt--preloader">
					<LoadIndicator id="large-indicator" height={60} width={60} />
				</div>
			);
		}

		return (
			<AdminTeamDetailsWrapper teamId={team.id} teamName={team.teamName} isTeamSurveyCompleted={team.isTeamSurveyCompleted}>
				<div className="mt--area-wrap mt--fill-bgrey">
					<div className="mt--layout">
						<div
							className={`${
								team.isInstantCloseAvailable && team.isMinimalFeedbackCountConditionsMet ? "mt--pb-2" : "mt--pb-0"
							} mt--content mt--pt-0`}
						>
							<div className="mt--panel-overall">
								<div>
									<div>{this.props.appStore.translationStore.translate("Team Leaders:")}</div>
									<div>{team.teamLeadersName.join(", ")}</div>
								</div>
								<div>
									<div>{this.props.appStore.translationStore.translate("Client:")}</div>
									<div>{team.clientName}</div>
								</div>
								<div>
									<div>{this.props.appStore.translationStore.translate("Status:")}</div>
									<div>{this.props.appStore.teamStore.getStatusText(team.status)}</div>
								</div>
								<div>
									<div>{this.props.appStore.translationStore.translate("Team Type:")}</div>
									<div>{team.teamTypeName}</div>
								</div>
								<div>
									<div>{this.props.appStore.translationStore.translate("Package:")}</div>
									<div>{team.packageName}</div>
								</div>
								<div>
									<div>{this.props.appStore.translationStore.translate("Facilitation:")}</div>
									<div>
										{team.facilitation
											? this.props.appStore.translationStore.translate("Requested")
											: this.props.appStore.translationStore.translate("Not requested")}
									</div>
								</div>
								<div>
									<div>{this.props.appStore.translationStore.translate("Team survey deadline:")}</div>
									<div>{getTeamDetailsDateFormat(team.deadline)}</div>
									<div>
										<AdminTeamDetailsDeadline
											isAdminPart
											isTeamSurveyFinished={team.isTeamSurveyFinished}
											deadline={team.deadline}
											teamId={team.id}
											isTeamSurveyStarted={team.isTeamSurveyStarted}
										/>
									</div>
								</div>
							</div>
							{team.isInstantCloseAvailable && team.isMinimalFeedbackCountConditionsMet && (
								<div>
									<div className="mt--panel-btn">
										<button
											className="mt--btn btn-med wide mt--justify-content-center"
											onClick={this.finishSurveyNow}
											disabled={this.state.isLoading}
										>
											{this.state.isLoading && <FontAwesomeIcon className="mt--mr-05" icon={["fas", "sync"]} spin />}
											{this.props.appStore.translationStore.translate("End diagnostic and generate results")}
										</button>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
				<div className="mt--area-wrap mt--fill-white">
					<div className="mt--layout mt--min-height">
						<div className="mt--content">
							<AdminTeamDetailsParticipantList teamId={team.id} isTeamSurveyCompleted={team.isTeamSurveyCompleted} teamStatus={team.status} />
						</div>
					</div>
				</div>
			</AdminTeamDetailsWrapper>
		);
	}
}

export default withRouter(AdminTeamDetailsOverview);
