/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import {AppStore} from "../../stores/app.store";
import {injectAppStore} from "../../common/app.store.consumer";
import {DebugPanelOperation} from "../../stores/debug.panel.store";
import {RouteComponentProps, withRouter} from "react-router";
import {ActionButton} from "../../common/action.button";

export type DebugPanelProps = {appStore?: AppStore} & RouteComponentProps<{}>;

@injectAppStore()
export class DebugPanelWithoutWrapper extends React.Component<DebugPanelProps> {
	private store = this.props.appStore.debugPanelStore;

	private logout = async () => {
		await this.store.logout();
		this.props.history.push("/empty");
		this.props.history.replace("/");
	};

	render() {
		let isEnabled = this.props.appStore.configuration.isDebugPanelEnabled;
		let isVisible = this.store.debugPanelVisible;

		let content = (
			<>
				<div className="test_panel_toggle" onClick={this.store.toggleTestingPanel}>
					DEMO
				</div>
				{isVisible && (
					<div className="test_panel_wrap">
						<div className="pos-rel">
							<div className="test_panel">
								<div className="tp_tabs">
									<a style={{cursor: "pointer"}}>Actions</a>
									<a className="btn-link  tab-close" onClick={this.store.toggleTestingPanel}>
										<i className="fal  fa-times"></i>
									</a>
								</div>
								<div>
									<div className="vspace10"></div>
									<div className="tp-title">User actions</div>
									<label className="tp_label">
										Logout
										<ActionButton onClick={this.logout} operation={DebugPanelOperation.Logout} />
									</label>
								</div>
								<div style={{textAlign: "center", width: "100%"}}>
									<button style={{margin: "1.5em auto", width: "auto"}} onClick={this.store.toggleTestingPanel}>
										Close demo helper
									</button>
								</div>
							</div>
						</div>
					</div>
				)}
			</>
		);
		return isEnabled && content;
	}
}

export const AdminDebugPanel = withRouter(DebugPanelWithoutWrapper);
