import {UserStore} from "./user.store";
import {observable, action, runInAction, makeObservable} from "mobx";
import {FeedbackProvidersStore} from "./feedback.providers.store";
import {IApiClient} from "../common/api.client";
import {DropdownDto, TeamInfoDto} from "../common/webapicall";
import {TeamSurveyStore} from "./team.survey.store";
import moment from "moment";
import {SurveyResultsStore} from "./survey.results.store";
import {StoreBase} from "./store.base";

export enum DebugPanelOperation {
	Logout = 1,
	FillTeamWithParticipants = 2,
	ResetTeamParticipants = 3,
	ChangeTeam = 4,
	FillDiagnostic = 5,
	ResetDiagnostic = 6,
	SubmitAllSurvey = 7,
	SubmitAllSurveyExceptLoggedInUsers = 8,
	ResetSurvey = 9,
	GetTeamInfo = 10,
	ResetTeam = 11,
	SetTeamSurveyDeadline = 12,
	RunDailyProcesses = 13,
	SendFeedbackProvidersFeedReminder = 14,
	SendSurveySubmitReminder = 15,
	SendTeamNotification = 16,
	DownloadRecommendationsPdf = 17,
}

export class DebugPanelStore extends StoreBase {
	@observable debugPanelVisible = false;
	@observable debugPanelTeamInfoVisible = false;
	@observable isLoading = false;
	@observable operation: DebugPanelOperation | null = null;
	@observable changeTeamDropdown: DropdownDto[] = [];
	@observable teamInfo: TeamInfoDto = null;
	@observable assessmentDeadline: string = "";

	constructor(
		private userStore: UserStore,
		private apiClient: IApiClient,
		private fbStore: FeedbackProvidersStore,
		private teamSurveyStore: TeamSurveyStore,
		private surveyResultsStore: SurveyResultsStore,
	) {
		super(apiClient);
		makeObservable(this);
	}

	@action
	toggleTestingPanel = () => {
		this.debugPanelVisible = !this.debugPanelVisible;
	};

	@action
	toggleTeamInfoPanel = () => {
		this.debugPanelTeamInfoVisible = !this.debugPanelTeamInfoVisible;
	};

	@action
	async logout() {
		await this.userStore.logout();
	}

	@action
	async fillTeamWithParticipants(teamId: string) {
		this.setLoading(DebugPanelOperation.FillTeamWithParticipants);
		await this.fbStore.getFeedbackProvidersInfo(teamId);
		if (!this.fbStore.canDiagnosticStart) {
			await this.apiClient.publicDebugPanelClient.fillTeamWithParticipants(teamId);
		}
		this.setLoading(null);
	}

	@action
	async resetTeamParticipants(teamId: string) {
		this.setLoading(DebugPanelOperation.ResetTeamParticipants);
		await this.apiClient.publicDebugPanelClient.resetTeamParticipants(teamId);
		this.setLoading(null);
	}

	@action
	async fillDiagnostic(participantSurveyId: string) {
		this.setLoading(DebugPanelOperation.FillDiagnostic);
		await this.teamSurveyStore.getTeamSurvey(participantSurveyId);
		if (!this.teamSurveyStore.canSubmitSurvey) {
			await this.apiClient.publicDebugPanelClient.fillParticipantDiagnostic(participantSurveyId);
		}
		this.setLoading(null);
	}

	@action
	async resetDiagnostic(teamId: string) {
		this.setLoading(DebugPanelOperation.ResetDiagnostic);
		await this.apiClient.publicDebugPanelClient.resetParticipantDiagnostic(teamId);
		this.setLoading(null);
	}

	@action
	async resetSurvey(teamId: string) {
		this.setLoading(DebugPanelOperation.ResetSurvey);
		await this.apiClient.publicDebugPanelClient.resetTeamSurvey(teamId);
		this.surveyResultsStore.isLoaded = false;
		this.teamSurveyStore.canSubmitSurvey = false;
		this.setLoading(null);
	}

	@action
	async getTeamInfo(teamId: string) {
		this.setLoading(DebugPanelOperation.GetTeamInfo);
		const response = await this.apiClient.publicDebugPanelClient.getTeamInfo(teamId);
		runInAction(() => {
			if (response) {
				this.teamInfo = response;
			}
		});
		this.setLoading(null);
	}

	@action
	async getTeamSurveyDeadline(teamId: string) {
		const response = await this.apiClient.publicDebugPanelClient.getTeamAssessmentDeadline(teamId);
		runInAction(() => {
			if (response && response.deadline) {
				this.assessmentDeadline = response.deadline.format("yyyy-MM-DD");
			} else {
				this.assessmentDeadline = "";
			}
		});
	}

	@action
	async submitAllSurvey(teamId: string, exceptForThisUser: boolean = false) {
		if (exceptForThisUser) {
			this.setLoading(DebugPanelOperation.SubmitAllSurveyExceptLoggedInUsers);
			await this.apiClient.publicDebugPanelClient.submitAllSurveyExceptForLoggedInUser(teamId);
		} else {
			this.setLoading(DebugPanelOperation.SubmitAllSurvey);
			await this.apiClient.publicDebugPanelClient.submitAllSurvey(teamId);
		}
		this.fbStore.setCanEditParticipantsListValue(false);
		this.setLoading(null);
	}

	@action
	async resetTeam(teamId: string) {
		this.setLoading(DebugPanelOperation.ResetTeam);
		await this.apiClient.publicDebugPanelClient.resetTeam(teamId);
		this.surveyResultsStore.isLoaded = false;
		this.setLoading(null);
	}

	@action
	async setTeamSurveyDeadline(teamId: string) {
		this.setLoading(DebugPanelOperation.SetTeamSurveyDeadline);
		const deadline = moment(this.assessmentDeadline);
		await this.apiClient.publicDebugPanelClient.setTeamSurveyDeadline(teamId, deadline);
		this.setLoading(null);
	}

	@action
	async runDailyProcesses() {
		this.setLoading(DebugPanelOperation.RunDailyProcesses);
		await this.apiClient.publicDebugPanelClient.runDailyProcesses();
		this.setLoading(null);
	}

	@action
	async sendFeedbackProvidersFeedReminder(teamId: string) {
		this.setLoading(DebugPanelOperation.SendFeedbackProvidersFeedReminder);
		await this.apiClient.publicDebugPanelClient.sendFeedbackProvidersFeedReminder(teamId);
		this.setLoading(null);
	}

	@action
	async sendSurveySubmitReminder(teamId: string) {
		this.setLoading(DebugPanelOperation.SendSurveySubmitReminder);
		await this.apiClient.publicDebugPanelClient.sendParticipantSurveyReminder(teamId);
		this.setLoading(null);
	}

	@action
	async sendTeamNotification(teamId: string) {
		this.setLoading(DebugPanelOperation.SendTeamNotification);
		await this.apiClient.publicDebugPanelClient.sendTeamNotification(teamId);
		this.setLoading(null);
	}

	@action
	async downloadRecommendationsPdf(teamId: string) {
		this.setLoading(DebugPanelOperation.DownloadRecommendationsPdf);
		const fileContent = await this.apiClient.publicDebugPanelClient.generateRecommendationsPdf(teamId);
		this.setLoading(null);
		return fileContent;
	}

	@action
	private setLoading(operation: DebugPanelOperation | null) {
		this.isLoading = !!operation;
		this.operation = operation;
	}
}
