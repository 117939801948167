/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import {AppStore} from "../../stores/app.store";
import {injectAppStore} from "../../common/app.store.consumer";
import {DebugPanelOperation, DebugPanelStore} from "../../stores/debug.panel.store";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {ActionButton} from "../../common/action.button";
import {ActionDatePicker} from "../../common/action.date.picker";
import {authorizableActionsContains} from "../../common/helpers";
import {AuthorizableActions} from "../../common/webapicall";
import PublicDebugStatusPanel from "./debug.status.panel";
import {PublicMapRoutes} from "../../stores/site.map";
import {AppContextStore} from "../../stores/app.context.store";

export type DebugPanelProps = {appStore?: AppStore} & RouteComponentProps<{teamId?: string; pSurveyId?: string}>;

@injectAppStore()
export class DebugPanelWithoutWrapper extends React.Component<DebugPanelProps> {
	private store: DebugPanelStore = this.props.appStore.debugPanelStore;
	private contextStore: AppContextStore = this.props.appStore.contextStore;

	async componentDidMount() {
		const teamId = this.contextStore.contextModel?.teamModel.teamId;
		if (teamId && this.props.appStore.configuration.isDebugPanelEnabled) {
			await this.store.getTeamSurveyDeadline(teamId);
		}
	}

	async componentDidUpdate() {
		if (this.store.debugPanelVisible) {
			const teamId = this.contextStore.contextModel.teamModel.teamId;
			if (teamId && this.props.appStore.configuration.isDebugPanelEnabled) {
				await this.store.getTeamSurveyDeadline(teamId);
			}
		}
	}

	private logout = async () => {
		await this.store.logout();
		this.redirect("/");
	};

	private fillTeamWithParticipants = async () => {
		const teamId = this.contextStore.contextModel.teamModel.teamId;
		if (teamId) {
			await this.store.fillTeamWithParticipants(teamId);
			this.redirect(`${PublicMapRoutes.ParticipantsList}/${teamId}`);
		}
	};

	private resetTeamParticipants = async () => {
		const teamId = this.contextStore.contextModel.teamModel.teamId;
		if (teamId) {
			await this.store.resetTeamParticipants(teamId);
			this.redirect(`${PublicMapRoutes.ParticipantsList}/${teamId}`);
		}
	};

	private fillDiagnostic = async () => {
		const pSurveyId = this.contextStore.contextModel.teamModel.loggedInUserParticipantSurveyId;
		if (pSurveyId) {
			await this.store.fillDiagnostic(pSurveyId);
			this.props.history.push("/empty");
			this.props.history.replace({
				pathname: `${PublicMapRoutes.Diagnostic}/${pSurveyId}`,
				state: {scrollToSubmit: true},
			});
		}
	};

	private resetDiagnostic = async () => {
		const pSurveyId = this.contextStore.contextModel.teamModel.loggedInUserParticipantSurveyId;
		if (pSurveyId) {
			await this.store.resetDiagnostic(pSurveyId);
			this.redirect(`${PublicMapRoutes.Diagnostic}/${pSurveyId}`);
		}
	};

	private submitAllSurvey = async () => {
		const teamId = this.contextStore.contextModel.teamModel.teamId;
		if (teamId) {
			await this.store.submitAllSurvey(teamId);
			this.redirect(`${PublicMapRoutes.ParticipantsList}/${teamId}`);
		}
	};

	private submitAllSurveyExceptForThisUser = async () => {
		const teamId = this.contextStore.contextModel.teamModel.teamId;
		if (teamId) {
			await this.store.submitAllSurvey(teamId, true);
			const pSurveyId = this.contextStore.contextModel.teamModel.loggedInUserParticipantSurveyId;
			this.redirect(`${PublicMapRoutes.Diagnostic}/${pSurveyId}`);
		}
	};

	private resetSurvey = async () => {
		const teamId = this.contextStore.contextModel.teamModel.teamId;
		if (teamId) {
			await this.store.resetSurvey(teamId);
			this.redirect(`${PublicMapRoutes.ParticipantsList}/${teamId}`);
		}
	};

	private getTeamInfo = async () => {
		const teamId = this.contextStore.contextModel.teamModel.teamId;
		if (teamId) {
			await this.store.getTeamInfo(teamId);
			this.store.toggleTeamInfoPanel();
		}
	};

	private resetTeam = async () => {
		const teamId = this.contextStore.contextModel.teamModel.teamId;
		if (teamId) {
			await this.store.resetTeam(teamId);
			await this.reloadContext();
			this.store.assessmentDeadline = "";
			this.redirect(`${PublicMapRoutes.ParticipantsList}/${teamId}`);
		}
	};

	private setTeamSurveyDeadline = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const teamId = this.contextStore.contextModel.teamModel.teamId;
		if (teamId && event.target.value !== "") {
			this.store.assessmentDeadline = event.target.value;
			await this.store.setTeamSurveyDeadline(teamId);
			await this.reloadContext();
			const pSurveyId = this.contextStore.contextModel.teamModel.loggedInUserParticipantSurveyId;
			this.redirect(`${PublicMapRoutes.Diagnostic}/${pSurveyId}`);
		}
	};

	private runDailyProcesses = async () => {
		await this.store.runDailyProcesses();
	};

	private sendFeedbackProvidersFeedReminder = async () => {
		const teamId = this.contextStore.contextModel.teamModel.teamId;
		if (teamId) {
			await this.store.sendFeedbackProvidersFeedReminder(teamId);
		}
	};

	private sendSurveySubmitReminder = async () => {
		const teamId = this.contextStore.contextModel.teamModel.teamId;
		if (teamId) {
			await this.store.sendSurveySubmitReminder(teamId);
		}
	};

	private sendTeamNotification = async () => {
		const teamId = this.contextStore.contextModel.teamModel.teamId;
		if (teamId) {
			await this.store.sendTeamNotification(teamId);
		}
	};

	private downloadRecommendationsPdf = async () => {
		const teamId = this.contextStore.contextModel.teamModel.teamId;
		if (teamId) {
			const fileContent = await this.store.downloadRecommendationsPdf(teamId);
			const csvURL = window.URL.createObjectURL(fileContent.data);
			let tempLink = document.createElement("a");
			tempLink.href = csvURL;
			tempLink.setAttribute("download", "test_recommendations.pdf");
			tempLink.click();
		}
	};

	private redirect = (redirectTo: string) => {
		this.props.history.push("/empty");
		this.props.history.replace(redirectTo);
	};

	private reloadContext = async () => {
		const teamId = this.contextStore.contextModel.teamModel.teamId;
		await this.contextStore.loadContext(teamId);
	};

	render() {
		const teamIdFromParam = this.props.match.params.teamId;
		const pSurveyIdFromParam = this.props.match.params.pSurveyId;
		const assessmentDeadline = this.store.assessmentDeadline;

		let isEnabled = this.props.appStore.configuration.isDebugPanelEnabled;
		let isVisible = this.store.debugPanelVisible;

		let content = <PublicDebugStatusPanel />;

		if (!this.store.debugPanelTeamInfoVisible) {
			content = (
				<>
					<div className="test_panel_toggle" onClick={this.store.toggleTestingPanel}>
						DEMO
					</div>
					{isVisible && (
						<div className="test_panel_wrap">
							<div className="pos-rel">
								<div className="test_panel">
									<div className="tp_tabs">
										<a style={{cursor: "pointer"}}>Actions</a>
										<a className="btn-link  tab-close" onClick={this.store.toggleTestingPanel}>
											<i className="fal fa-times"></i>
										</a>
									</div>

									{teamIdFromParam || pSurveyIdFromParam ? (
										<div>
											<div className="vspace10"></div>
											<div className="tp-title">Team</div>
											<label className="tp_label">
												Show team information
												<ActionButton onClick={this.getTeamInfo} operation={DebugPanelOperation.GetTeamInfo} />
											</label>

											{this.contextStore.contextModel.progress.isSurveyStarted &&
											authorizableActionsContains(this.contextStore, [AuthorizableActions.CanViewParticipants]) ? (
												<label className="tp_label">
													Reset team
													<ActionButton onClick={this.resetTeam} operation={DebugPanelOperation.ResetTeam} />
												</label>
											) : null}

											{!this.contextStore.contextModel.progress.surveyCompleted && assessmentDeadline !== "" ? (
												<label className="tp_label">
													Set/reset survey deadline
													<ActionDatePicker
														value={assessmentDeadline}
														onChange={this.setTeamSurveyDeadline}
														operation={DebugPanelOperation.SetTeamSurveyDeadline}
													/>
												</label>
											) : null}

											<div className="vspace10"></div>
											<div className="tp-title">Emails</div>
											<label className="tp_label">
												Send team setup reminder email
												<ActionButton
													onClick={this.sendFeedbackProvidersFeedReminder}
													operation={DebugPanelOperation.SendFeedbackProvidersFeedReminder}
												/>
											</label>
											<label className="tp_label">
												Send survey reminder email
												<ActionButton
													onClick={this.sendSurveySubmitReminder}
													operation={DebugPanelOperation.SendSurveySubmitReminder}
												/>
											</label>
											{this.contextStore.contextModel.progress.isSurveyRunning && (
												<label className="tp_label">
													Send team notification email
													<ActionButton onClick={this.sendTeamNotification} operation={DebugPanelOperation.SendTeamNotification} />
												</label>
											)}

											{this.contextStore.contextModel.progress.surveyCompleted || this.contextStore.contextModel.progress.surveyFailed ? (
												<React.Fragment>
													<div className="vspace10"></div>
													<div className="tp-title">Survey actions</div>
													<label className="tp_label">
														Reset survey results
														<ActionButton onClick={this.resetSurvey} operation={DebugPanelOperation.ResetSurvey} />
													</label>
													{this.contextStore.contextModel.progress.surveyCompleted &&
														authorizableActionsContains(this.contextStore, [AuthorizableActions.CanViewRecommendationsResults]) && (
															<>
																<label className="tp_label">
																	Download Recommendations PDF
																	<ActionButton
																		onClick={this.downloadRecommendationsPdf}
																		operation={DebugPanelOperation.DownloadRecommendationsPdf}
																	/>
																</label>
															</>
														)}
												</React.Fragment>
											) : null}

											{!this.contextStore.contextModel.progress.isSurveyStarted ? (
												<React.Fragment>
													<div className="vspace10"></div>
													<div className="tp-title">Team participant actions</div>
													<label className="tp_label">
														Fill team with participants
														<ActionButton
															onClick={this.fillTeamWithParticipants}
															operation={DebugPanelOperation.FillTeamWithParticipants}
														/>
													</label>
													<label className="tp_label">
														Reset team participants
														<ActionButton
															onClick={this.resetTeamParticipants}
															operation={DebugPanelOperation.ResetTeamParticipants}
														/>
													</label>
												</React.Fragment>
											) : null}

											{this.contextStore.contextModel.progress.isSurveyRunning ? (
												<React.Fragment>
													<div className="vspace10"></div>
													<div className="tp-title">Team Insights actions</div>
													<label className="tp_label">
														Fill diagnostic
														<ActionButton onClick={this.fillDiagnostic} operation={DebugPanelOperation.FillDiagnostic} />
													</label>
													<label className="tp_label">
														Reset diagnostic
														<ActionButton onClick={this.resetDiagnostic} operation={DebugPanelOperation.ResetDiagnostic} />
													</label>
													<label className="tp_label">
														Submit all survey
														<ActionButton onClick={this.submitAllSurvey} operation={DebugPanelOperation.SubmitAllSurvey} />
													</label>
													{this.props.appStore.contextStore.contextModel.participantSurveyModel.participantSurveyId && (
														<label className="tp_label">
															Submit all survey except for this user
															<ActionButton
																onClick={this.submitAllSurveyExceptForThisUser}
																operation={DebugPanelOperation.SubmitAllSurveyExceptLoggedInUsers}
															/>
														</label>
													)}
												</React.Fragment>
											) : null}
										</div>
									) : null}

									<div>
										<div className="vspace10"></div>
										<div className="tp-title">User actions</div>
										<label className="tp_label">
											Logout
											<ActionButton onClick={this.logout} operation={DebugPanelOperation.Logout} />
										</label>
										<label className="tp_label">
											Run daily processes
											<ActionButton onClick={this.runDailyProcesses} operation={DebugPanelOperation.RunDailyProcesses} />
										</label>
									</div>

									<div style={{textAlign: "center", width: "100%"}}>
										<button style={{margin: "1.5em auto", width: "auto"}} onClick={this.store.toggleTestingPanel}>
											Close demo helper
										</button>
									</div>
								</div>
							</div>
						</div>
					)}
				</>
			);
		}

		return isEnabled && content;
	}
}

export const PublicDebugPanel = withRouter(DebugPanelWithoutWrapper);
