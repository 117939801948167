import React from 'react';

interface IProps {
    handleOutsideClick: () => void;
}

export default class OutsideClick extends React.Component<IProps> {
    private wrapperRef: HTMLDivElement;

    constructor(props: IProps) {
        super(props);

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef(node: HTMLDivElement) {
        this.wrapperRef = node;
    }

    handleClickOutside(event: any) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.props.handleOutsideClick();
        }
    }

    render() {
        return <div ref={this.setWrapperRef}>{this.props.children}</div>;
    }
}
