import {AppStore} from "../stores/app.store";
import {injectAppStore} from "./app.store.consumer";
import * as React from "react";
import {History} from "history";

interface TopHeaderProps {
	appStore?: AppStore;
	history?: History<any>;
}

@injectAppStore()
export class TopHeader extends React.Component<TopHeaderProps> {
	render() {
		return <div></div>;
	}
}
