import "core-js/stable";
import * as React from "react";
import * as ReactDOM from "react-dom";
import {AppStore} from "./stores/app.store";
import {MainFrame} from "./common/mainframe";
import "./i18n";
import "./scss/main.scss";
import "./common/fontawesome.library";
import {flowResult} from "mobx";
import moment from "moment";

export class App {
	private appStore: AppStore;

	constructor() {
		this.appStore = new AppStore();
		this.refreshTitle();
	}

	private refreshTitle() {
		document.title = `Meta Team`;
	}

	async start() {
		moment.defineLocale('ar', {
			parentLocale: 'ar',
			preparse: function (string) {
			  return string;
			},
			postformat: function (string) {
			  return string;
			}
		  });

		await flowResult(this.appStore.init(!window.location.hash || window.location.hash.includes("admin"))).catch(reason => {
			// ignore to let React get initialized
			console.error(reason);
		});		
		localStorage.removeItem("meta-teams");
		localStorage.removeItem("meta-teams-all");
		localStorage.removeItem("meta-clients");
		localStorage.removeItem("meta-adminusers");		
		ReactDOM.render(<MainFrame appStore={this.appStore} />, document.getElementById("root"));
	}
}
