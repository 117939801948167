import React, {useEffect} from "react";
import {injectAppStore} from "../../common/app.store.consumer";
import {AppStore} from "../../stores/app.store";
import {NavLink} from "react-router-dom";
import {NavigationItem} from "../../common/navigation-items/navigation.item";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface IProps {
	appStore?: AppStore;
	title: string;
	navigationItems?: NavigationItem[];
	hideTeamName?: boolean;
}

const PublicPageHeader: React.FC<IProps> = injectAppStore()(props => {
	const contextModel = props.appStore.contextStore.contextModel;
	const teamName = contextModel?.teamModel?.teamName;
	const isAdmin = props.appStore.userStore.isAdmin;
	const leftItems: NavigationItem[] = [];
	const rightItems: NavigationItem[] = [];

	if (isAdmin) {
		useEffect(() => {
			const load = async () => {
				if (props.appStore.contextStore.contextModel?.teamModel?.clientId) {
					await Promise.all([
						props.appStore.dashboardStore.getBreadCrumbInfo(props.appStore.contextStore.contextModel?.teamModel?.clientId),
					]);
				}
			};
			load();
		}, [props.appStore.dashboardStore, props.appStore.contextStore.contextModel?.teamModel?.clientId]);

	}

	props.navigationItems?.forEach(item => {
		if (item.align === "left") {
			leftItems.push(item);
		} else if (item.align === "right") {
			rightItems.push(item);
		}
	});

	return (
		<>
			<div className={`mt--area-wrap mt--fill-dblue ${isAdmin ? "mt--admin-header-wrap mt--pattern-grey" : "mt--pattern-blue"}`}>
				<div className="mt--layout">
					<div className="mt--header">
						<div className="mt--lead mt--flex mt--flex-col">
							<div className="lead-txt">
								{!isAdmin && <div>{props.title}</div>}
								{!props.hideTeamName && <div className="mt--lead-team">{teamName}</div>}
							</div>
						</div>
						{props.navigationItems && (
							<nav className="mt--navbar mt--navbar-secondary public">
								<div id="navbarNav" className="mt--full mt--flex mt--flex-between mt--gap-1">
									{leftItems && (
										<ul className={`mt--navbar-nav mt--flex left ${isAdmin && "admin-view"}`}>
											{leftItems.map((item, index) => (
												<li key={index}>
													<NavLink
														className={`${isAdmin ? "mt--btn-corner" : "mt--btn-corner-outline"}`}
														activeClassName="active"
														to={item.to}
													>
														{item.name}
													</NavLink>
												</li>
											))}
										</ul>
									)}

									{rightItems && (
										<ul className={`mt--navbar-nav mt--flex mt--justify-content-end right ${isAdmin && "admin-view"}`}>
											{rightItems.map((item, index) => (
												<li key={index}>
													<NavLink
														className={`${isAdmin ? "mt--btn-corner" : "mt--btn-corner-outline"}`}
														activeClassName="active"
														to={item.to}
													>
														{item.name}
													</NavLink>
												</li>
											))}
										</ul>
									)}
								</div>
							</nav>
						)}
					</div>
				</div>
			</div>
			{isAdmin && (
				<div className="mt--fill-bgrey mt--hide">
					<div className="mt--layout mt--diag-status-bar">
						<div className="tint-txt-dgrey">{props.appStore.translationStore.translate("Your diagnostic is completed.")}</div>
						<div>
							<FontAwesomeIcon className="tint-orange" icon={["far", "lock-open-alt"]} transform="grow-1 up-1" />
							<span className="diag-action">{props.appStore.translationStore.translate("Reopen Diagnostic")}</span>
						</div>
					</div>
				</div>
			)}
		</>
	);
});

export default PublicPageHeader;
