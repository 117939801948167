import {AdminMapRoutes} from "../../stores/site.map";
import {NavigationItem} from "./navigation.item";

export const getTeamDetailsNavigationItems = (teamId: string, isTeamSurveyCompleted: boolean): NavigationItem[] => {
	const navItems = [{name: "Overview", to: `${AdminMapRoutes.TeamDetailsOverview}/${teamId}`}];

	if (isTeamSurveyCompleted) {
		navItems.push({name: "Recommendations", to: `${AdminMapRoutes.TeamDetailsResultsRecommendations}/${teamId}`});
		navItems.push({name: "Summary", to: `${AdminMapRoutes.TeamDetailsResultsSummary}/${teamId}`});
		navItems.push({name: "Micro Habits", to: `${AdminMapRoutes.TeamDetailsResultsMicroHabits}/${teamId}`});
		navItems.push({name: "Team Journey", to: `${AdminMapRoutes.TeamDetailsResultsTeamJourney}/${teamId}`});
	}

	return navItems;
};
