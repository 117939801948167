import DataGrid, {
	Button,
	Column,
	Editing,
	FilterRow,
	Form,
	HeaderFilter,
	Label,
	LoadPanel,
	Lookup,
	Pager,
	Paging,
	Popup,
	RequiredRule,
	Scrolling,
	Sorting,
	StateStoring,
	Texts,
} from "devextreme-react/data-grid";
import {Item} from "devextreme-react/form";
import {ToolbarItem} from "devextreme-react/popup";
import {dxElement} from "devextreme/core/element";
import DataSource from "devextreme/data/data_source";
import dxDataGrid, {dxDataGridRowObject} from "devextreme/ui/data_grid";
import {toJS} from "mobx";
import React from "react";
import {Link} from "react-router-dom";
import {injectAppStore} from "../../common/app.store.consumer";
import {AppStore} from "../../stores/app.store";
import {AdminMapRoutes} from "../../stores/site.map";
import {UserPermissions} from "../../stores/user.permissions";
import {getDevExtremeDateFormat} from "../../utils/date.utils";

interface Props {
	appStore?: AppStore;
	clientId?: string;
}

@injectAppStore()
export default class AdminClientList extends React.Component<Props, {selectedTeamId: string}> {
	private clientStore = this.props.appStore.clientStore;
	private userStore = this.props.appStore.userStore;
	private dataGridRef = React.createRef<DataGrid>();
	private rowKey = "";

	private statuses = [
		{
			name: "Active",
			value: true,
		},
		{
			name: "Inactive",
			value: false,
		},
	];

	async componentDidMount() {
		await this.props.appStore.clientStore.getClientsForDropdown();
		await this.props.appStore.clientStore.getAllRolesForDropdown();
	}

	async componentDidUpdate(prevProps: Props) {
		if (prevProps.clientId !== this.props.clientId) {
			await this.props.appStore.clientStore.getClientsForDropdown();
			await this.props.appStore.clientStore.getAllRolesForDropdown();
		}
	}

	private onToolbarPreparing = (e: any) => {
		e.toolbarOptions.items.forEach((item: any) => {
			if (item.name === "addRowButton") {
				item.showText = "always";
				item.options.text = "Add New";
				item.options.hint = "Add New";
			}
		});
	};

	private onInitNewRow = (e: any) => {
		this.rowKey = "";
		e.data.isActive = true;
		e.data.parentId = this.props.clientId;
		e.data.parentClientName = this.clientStore.getClientName(this.props.clientId);
	};

	private onEditingStart = (e: any) => {
		this.rowKey = e.key;
		e.data.parentClientName = this.clientStore.getClientName(e.data.parentId);
	};

	private onRowUpdating = (e: any) => {
		let modifiedNewData = {};
		[
			"name",
			"city",
			"streetAddress",
			"phone",
			"websiteUrl",
			"supportEmail",
			"supportPhone",
			"supportPersonName",
			"companyRoleId",
			"country",
			"isActive",
		].forEach(item => {
			modifiedNewData[item] = e.newData[item] !== undefined ? e.newData[item] : e.oldData[item];
		});

		// handle separate because clientAdminId can be null
		modifiedNewData["clientAdminId"] = e.newData["clientAdminId"] !== undefined ? e.newData["clientAdminId"] : e.oldData["clientAdminId"];
		this.userStore.changeAddedToTeamInClientAdminDropdown(e.newData["clientAdminId"], e.oldData["clientAdminId"]);

		e.newData = modifiedNewData;
	};

	private onEditorPreparing = (e: {
		component?: dxDataGrid;
		element?: dxElement;
		model?: any;
		parentType?: string;
		value?: any;
		setValue?: any;
		updateValueTimeout?: number;
		width?: number;
		disabled?: boolean;
		rtlEnabled?: boolean;
		cancel?: boolean;
		editorElement?: dxElement;
		readOnly?: boolean;
		editorName?: string;
		editorOptions?: any;
		dataField?: string;
		row?: dxDataGridRowObject;
	}) => {
		// filter on usersdropdown
		if (e.parentType === "dataRow" && e.dataField === "clientAdminId") {
			e.editorOptions.dataSource = new DataSource({
				store: {
					type: "array",
					data: toJS(this.props.appStore.userStore.usersDropdown),
					key: "value",
				},
				filter: function (item: any) {
					if (!item.addedToTeam || item.value === e.value) {
						return true;
					}
					return false;
				},
			});
		}

		if (e.dataField === "companyRoleId" && this.rowKey !== "") {
			e.editorOptions.disabled = true;
			e.editorOptions.dataSource = new DataSource({
				store: {
					type: "array",
					data: toJS(this.props.appStore.clientStore.allRolesDropdown),
					key: "value",
				},
			});
		}

		if (e.dataField === "isActive" && e.row?.data?.id === this.props.appStore.userStore.clientId) {
			e.editorOptions.disabled = true;
		}

		if (
			this.rowKey !== "" &&
			!this.props.appStore.userStore.hasPermission(UserPermissions.ChangeCoreCompanyData) &&
			e.row?.data?.id === this.props.appStore.clientStore.currentClientId &&
			(e.dataField === "name" ||
				e.dataField === "companyRoleId" ||
				e.dataField === "city" ||
				e.dataField === "streetAddress" ||
				e.dataField === "country" ||
				e.dataField === "websiteUrl" ||
				e.dataField === "isActive")
		) {
			e.editorOptions.disabled = true;
		}

		if (
			this.rowKey !== "" &&
			!this.props.appStore.userStore.hasPermission(UserPermissions.ChangeCompanySupportData) &&
			(e.dataField === "supportEmail" || e.dataField === "supportPhone" || e.dataField === "supportPersonName")
		) {
			e.editorOptions.disabled = true;
		}

		// Future feature
		if (e.dataField === "isActive") {
			e.editorOptions.disabled = true;
		}
	};

	private onContentReady = (e: any) => {
		// add class to popup to hide * from required fields
		e.component.content().classList.add("mt-admin-list-popup");

		if (this.rowKey === "") {
			// create
			e.component.option("toolbarItems[2].visible", false);
			e.component.option("title", "New Company");
			e.component.option("toolbarItems[0].options.text", "Create");
		} else {
			// edit
			e.component.option("toolbarItems[2].visible", true);
			e.component.option("title", "Edit Company Details");
			e.component.option("toolbarItems[0].options.text", "Save");

			// set delete availability
			const grid = this.dataGridRef.current.instance;
			const rows = grid.getVisibleRows();
			let rowIndex = grid.getRowIndexByKey(this.rowKey);
			const row = rows[rowIndex];

			const canDelete = row.data.numberOfTeams === 0 && row.data.numberOfClients === 0;
			e.component.option("toolbarItems[2].options.disabled", !canDelete);
		}
	};

	private onRowRemoving = (e: any) => {
		this.userStore.changeAddedToTeam(e.data.clientAdminId, false);
	};

	private onRowInserting = (e: any) => {
		this.userStore.changeAddedToTeam(e.data.clientAdminId, true);
	};

	private linkRender = (param: any) => {
		return (
			<React.Fragment>
				{param.data.companyRoleId !== "Client" &&
				((this.props.clientId && param.data.id !== this.props.clientId) ||
					(!this.props.clientId && param.data.parentId === this.userStore.clientId)) ? (
					<Link to={`${AdminMapRoutes.Clients}/${param.data.id}`}>{param.data.name}</Link>
				) : (
					param.data.name
				)}
			</React.Fragment>
		);
	};

	private linkToTeamsRender = (param: any) => {
		return (
			<Link to={`${AdminMapRoutes.Teams}/${param.data.id}${param.data.numberOfTeams === 0 ? "/true" : ""}`}>
				{param.data.numberOfTeams === 0 ? "Create" : "View (" + param.data.numberOfTeams + ")"}
			</Link>
		);
	};

	private linkToAdminUsersRender = (param: any) => {
		return (
			<Link className="dx-link mt--icon-datagrid dx-link-icon mt--icon-inverse" title="View admins" to={`${AdminMapRoutes.AdminUsers}/${param.data.id}`}>
				A
			</Link>
		);
	};

	private onSaveDialogButtonClick = e => {
		this.dataGridRef.current!.instance.saveEditData();
	};

	private onCancelDialogButtonClick = e => {
		this.dataGridRef.current!.instance.cancelEditData();
	};

	private onDeleteDialogButtonClick = e => {
		const gridInstance = this.dataGridRef.current!.instance;
		const rowKey = gridInstance.option("editing.editRowKey");
		const rowIndex = gridInstance.getRowIndexByKey(rowKey);
		gridInstance.cancelEditData();
		gridInstance.deleteRow(rowIndex);
	};

	render() {
		const clients = this.clientStore.getClientsDataSource(this.props.clientId || "");
		const dateFormat = getDevExtremeDateFormat();
		const roles = toJS(this.props.appStore.clientStore.rolesDropdown.map(s => ({value: s.value, name: this.props.appStore.translationStore.translate(s.name)})));

		const saveOptions = {
			text: "Save",
			onClick: this.onSaveDialogButtonClick,
		};
		const cancelOptions = {
			text: "Cancel",
			onClick: this.onCancelDialogButtonClick,
		};
		const deleteOptions = {
			text: "Delete",
			onClick: this.onDeleteDialogButtonClick,
			icon: "trash",
			disabled: true,
			elementAttr: {
				class: "mt--ml-auto",
			},
		};

		return (
			<React.Fragment>
				<div className="mt--hide mt--block-title mt--pb-0">List of all companies</div>
				<div className="mt--datagrid">
					<DataGrid
						ref={this.dataGridRef}
						dataSource={clients}
						columnAutoWidth={true}
						showBorders
						showRowLines
						columnHidingEnabled={false}
						allowColumnReordering={false}
						remoteOperations={true}
						onToolbarPreparing={this.onToolbarPreparing}
						onInitNewRow={this.onInitNewRow}
						onEditingStart={this.onEditingStart}
						onRowUpdating={this.onRowUpdating}
						onEditorPreparing={this.onEditorPreparing}
						onRowRemoving={this.onRowRemoving}
						onRowInserting={this.onRowInserting}
						repaintChangesOnly={true}
					>
						<Scrolling showScrollbar="always" useNative={false} />
						<Sorting mode="multiple" />
						<FilterRow visible={true} />
						<HeaderFilter visible={true} />
						<StateStoring enabled={true} type="localStorage" storageKey="meta-clients" />
						<Paging defaultPageSize={20} />
						<Pager visible={true} showPageSizeSelector={true} allowedPageSizes={[20, 50, 100]} />

						<Editing
							useIcons={true}
							mode="popup"
							allowAdding={
								this.props.appStore.userStore.hasPermission(UserPermissions.CreateChildCompany) &&
								this.props.appStore.dashboardStore.dashboardInfo.currentClientTypeId !== "Client"
							}
							allowUpdating={
								this.props.appStore.userStore.hasPermission(UserPermissions.EditCompany) ||
								this.props.appStore.userStore.hasPermission(UserPermissions.ChangeCoreCompanyData) ||
								this.props.appStore.userStore.hasPermission(UserPermissions.ChangeCompanySupportData)
							}
							confirmDelete
						>
							<Texts confirmDeleteMessage="Are you sure you want to remove this company?" confirmDeleteTitle="Remove Company" />
							<Popup
								showTitle={true}
								minWidth={320}
								maxWidth={900}
								height={"auto"}
								maxHeight={700}
								onContentReady={this.onContentReady}
								dragEnabled={false}
							>
								<ToolbarItem toolbar="bottom" widget="dxButton" location="after" options={saveOptions}></ToolbarItem>
								<ToolbarItem toolbar="bottom" widget="dxButton" location="after" options={cancelOptions}></ToolbarItem>
								{this.props.appStore.userStore.hasPermission(UserPermissions.DeleteChildCompany) &&
									this.props.appStore.dashboardStore.dashboardInfo.currentClientTypeId !== "Client" && (
										<ToolbarItem toolbar="bottom" widget="dxButton" location="after" options={deleteOptions}></ToolbarItem>
									)}
							</Popup>
							<Form>
								<Item itemType="group" colCount={2} colSpan={2}>
									<Item dataField="name">
										<Label text="Company Name" />
										<RequiredRule message="Name is required" />
									</Item>
									<Item dataField="parentClientName" disabled cssClass="mt--disabled-display">
										<Label text="Parent Company" />
									</Item>
									<Item dataField="companyRoleId" cssClass="mt--disabled-display">
										<Label text="Type" />
										<RequiredRule message="Name is required" />
									</Item>
									<Item
										dataField="isActive"
										editorType="dxRadioGroup"
										editorOptions={{layout: "horizontal"}}
										cssClass="mt--radiogroup mt--label-icon mt--caption-disabled"
									>
										<Label text="Status" />
									</Item>
									<Item dataField="city">
										<Label text="City" />
									</Item>
									<Item dataField="streetAddress">
										<Label text="Street Address" />
									</Item>
									<Item dataField="country">
										<Label text="Country" />
									</Item>
									<Item dataField="websiteUrl">
										<Label text="Website URL" />
									</Item>
								</Item>
								<Item itemType="group" caption="Support Info" colCount={2} colSpan={2} cssClass="mt--dx-form-groupitem mt--caption-icon mt--caption-disabled">
									<Item dataField="supportEmail">
										<Label text="Support Email" />
									</Item>
									<Item dataField="supportPhone">
										<Label text="Phone" />
									</Item>
									<Item dataField="supportPersonName">
										<Label text="Contact Name" />
									</Item>
									<Item dataField="" cssClass="mt--invisible">
										Logo:
									</Item>
								</Item>
							</Form>
						</Editing>
						<LoadPanel enabled={true} />

						<Column visibleIndex={0} type="buttons" minWidth={60} alignment="left" caption="Action">
							<Button render={this.linkToAdminUsersRender} />
							<Button cssClass="mt--icon-datagrid" name="edit" hint="Edit company" />
						</Column>

						<Column visibleIndex={1} dataField="name" caption="Name" cellRender={this.linkRender} />

						<Column
							visibleIndex={2}
							dataField="companyRoleId"
							caption="Type"
							cellRender={e => <>{this.props.appStore.clientStore.getRoleName(e.value)}</>}
						>
							<Lookup dataSource={roles} displayExpr={"name"} valueExpr={"value"} />
						</Column>

						<Column visibleIndex={3} dataField="isActive" caption="Status" visible={false}>
							<Lookup dataSource={this.statuses} displayExpr={"name"} valueExpr={"value"} />
						</Column>

						<Column visibleIndex={4} dataField="numberOfTeams" caption="Teams" alignment="center" width={200} cellRender={this.linkToTeamsRender}>
							<HeaderFilter dataSource={this.clientStore.getNumberOfTeamFilters} />
						</Column>

						<Column visibleIndex={5} dataField="dateCreated" alignment="center" caption="Created" dataType="date" format={dateFormat} width={200} />

						{/* EDIT FIELDS */}
						<Column dataField="country" visible={false} />
						<Column dataField="city" visible={false} />
						<Column dataField="streetAddress" visible={false} />
						<Column dataField="phone" visible={false} />
						<Column dataField="websiteUrl" visible={false} />

						<Column dataField="supportEmail" visible={false} />
						<Column dataField="supportPhone" visible={false} />
						<Column dataField="supportPersonName" visible={false} />
						<Column dataField="parentClientName" visible={false} />
					</DataGrid>
				</div>
			</React.Fragment>
		);
	}
}
