import {locale} from "devextreme/localization";
import {TOptions} from "i18next";
import {action, computed, flow, flowResult, makeObservable, observable} from "mobx";
import moment from "moment";
import "moment/min/locales";
import {IConfigurationProvider} from "../../common/public.configuration";
import i18n, {defaultLangCode} from "../../i18n";
import {LoadingState} from "../../types/LoadingState";
import {stringIsNotInArray} from "../../utils/string.utils";
import {TranslationType, UiTranslationsStore} from "./ui.translations.store";

export class TranslationStore {
	@observable loadingState: LoadingState = "initial";
	@observable forceDefaultLanguage = false;
	@observable selectedLanguage: string | undefined = undefined;
	@observable private defaultLanguage: string | undefined = undefined;

	private uiTranslationsStore: UiTranslationsStore | null = null;

	constructor(private configurationProvider: IConfigurationProvider) {
		makeObservable(this);
	}

	@flow
	public *init(languageCode: string | null) {
		if (this.selectedLanguage === languageCode) {
			return;
		}

		this.loadingState = "loading";

		this.defaultLanguage = defaultLangCode;
		this.selectedLanguage = this.getLanguageByCode(languageCode);

		i18n.options.fallbackLng = this.getLoadedFallbackLanguages();

		if (!this.uiTranslationsStore) {
			const uiBaseUrl = this.getTranslationsBaseUrl();
			this.uiTranslationsStore = new UiTranslationsStore(uiBaseUrl, this.configurationProvider.configuration.translationFileVersions);
		}

		let promises: Promise<any>[] = [];
		for (const [, lang] of Object.entries(this.configurationProvider.configuration.availableLanguages)) {
			promises.push(flowResult(this.Load(lang, "Selected", false, true)));
		}
		yield Promise.all(promises);

		promises = [];
		promises.push(flowResult(this.Load(this.selectedLanguage, "Selected", false)));
		promises.push(flowResult(this.Load(this.defaultLanguage, "Default", false)));
		yield Promise.all(promises);

		this.loadingState = "completed";
	}

	@flow
	public *Load(languageCode: string, translationType: TranslationType, setLoadingState: boolean = true, preload: boolean = false) {
		if (setLoadingState) {
			this.loadingState = "loading";
		}
		yield this.uiTranslationsStore.loadUiTranslations(languageCode, translationType, preload);

		if (setLoadingState) {
			this.loadingState = "completed";
		}
	}

	@action
	public setSelectedLanguage(languageCode: string) {
		this.selectedLanguage = languageCode;
	}

	@action
	public setForceDefaultLanguage(value: boolean) {
		this.forceDefaultLanguage = value;
	}

	public async changeLanguage(forcedLanguageCode?: string) {
		const finalLanguageCode = forcedLanguageCode ?? this.getLanguageCode;
		await i18n.changeLanguage(finalLanguageCode);

		locale(finalLanguageCode);
		moment.locale(finalLanguageCode);

		window.document.documentElement.setAttribute("lang", finalLanguageCode);
		window.document.documentElement.setAttribute("dir", i18n.dir(finalLanguageCode));
	}

	public translate(key: string, options?: TOptions) {
		const translated = i18n.t(key, options);

		if (key && !i18n.exists(key)) {
			console.log(key);
		}

		return translated;
	}

	@computed
	get isRTL(): boolean {
		return i18n.dir(this.getLanguageCode) === "rtl";
	}

	@computed
	get getLanguageCode() {
		const selectedLanguage = this.uiTranslationsStore?.translationLoadedState.get("Selected");
		if (!this.forceDefaultLanguage && selectedLanguage) {
			return this.selectedLanguage!;
		}

		const defaultLanguage = this.uiTranslationsStore?.translationLoadedState.get("Default");
		if (defaultLanguage) {
			return this.defaultLanguage!;
		}

		return undefined;
	}

	private getTranslationsBaseUrl() {
		return this.configurationProvider.configuration.translationFilesLocation;
	}

	private getLoadedFallbackLanguages(): string[] {
		const languages = [];

		if (this.shouldLoadDefaultLanguage) {
			languages.push(this.defaultLanguage);
		}

		return languages;
	}

	private get shouldLoadDefaultLanguage(): boolean {
		return stringIsNotInArray(this.defaultLanguage, [this.selectedLanguage]);
	}

	private getLanguageByCode(languageCode: string | null | undefined): string | undefined {
		if (languageCode) {
			return this.configurationProvider.configuration.availableLanguages.find(l => l === languageCode);
		}

		return undefined;
	}
}
