import React from "react";
import { injectAppStore } from "../../common/app.store.consumer";
import { AppStore } from "../../stores/app.store";
import { Trans } from "react-i18next";

interface Props {
	renderCantSay: boolean;
	appStore?: AppStore
}

const SurveyNotificationBar = injectAppStore()(({renderCantSay, appStore}: Props) => {
	return (
		<div className="mt--notification neutral">
			<div className="stroke-octagon">
				<svg width="39px" height="39px" viewBox="-449 310.813 38.938 39">
					<path
						fill="#0F3250"
						d="M-421.443,349.813h-16.162l-11.457-11.379v-16.168l11.455-11.453h16.166l11.379,11.456v16.162
L-421.443,349.813z M-436.575,347.314h14.097l9.916-9.915v-14.101l-9.918-9.985h-14.091l-9.99,9.989v14.091L-436.575,347.314z"
					/>
					<g transform="scale(.8)" transform-origin="-432 330">
						<path
							d="M-432.703,337.541h0.859v-6.207h-0.859c-0.475,0-0.859-0.385-0.859-0.859v-2.053c0-0.475,0.385-0.859,0.859-0.859h4.813
c0.475,0,0.859,0.385,0.859,0.859v9.119h0.859c0.475,0,0.859,0.385,0.859,0.859v2.053c0,0.475-0.385,0.859-0.859,0.859h-6.531
c-0.475,0-0.859-0.385-0.859-0.859V338.4C-433.563,337.926-433.178,337.541-432.703,337.541z M-429.438,319.313
c-1.708,0-3.094,1.385-3.094,3.094c0,1.708,1.385,3.094,3.094,3.094c1.709,0,3.094-1.386,3.094-3.094
C-426.344,320.697-427.729,319.313-429.438,319.313z"
						/>
					</g>
				</svg>
			</div>
			<span>
			{appStore.translationStore.translate("For each statement, move the central blue octagon to rate this team.")}
				{renderCantSay && (
					<Trans
						 i18nKey="If you are unsure how to rate the team against a statement, choose <b>Can’t Say</b>."
					/>
				)}
			</span>
		</div>
	);
});

export default SurveyNotificationBar;
