import {action, makeObservable, observable, runInAction} from "mobx";
import {IApiClient} from "../common/api.client";
import {DropdownDto} from "../common/webapicall";
import {StoreBase} from "./store.base";

export class TeamTypeStore extends StoreBase {
	constructor(private apiClient: IApiClient) {
		super(apiClient);
		makeObservable(this);
	}

	@observable teamTypesDropdown: DropdownDto[] = [];

	@action
	async getTeamTypesForDropdown() {
		const response = await this.apiClient.teamTypeClient.getTeamTypeForDropdown();
		runInAction(() => {
			if (response) {
				this.teamTypesDropdown = response;
			}
		});
	}
}
