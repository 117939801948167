import React, {useEffect, useState} from "react";
import {useAppStore} from "../../common/app.store.consumer";
import DataGrid, {
	Button,
	Column,
	Editing,
	EmailRule,
	FilterRow,
	Form,
	HeaderFilter,
	Label,
	LoadPanel,
	Lookup,
	Pager,
	Paging,
	Popup,
	RequiredRule,
    Scrolling,
	Sorting,
	StateStoring,
} from "devextreme-react/data-grid";
import {Item} from "devextreme-react/form";
import {observer} from "mobx-react";

export interface AdminUserListProps {
	clientId?: string;
}

export default observer(({clientId}: AdminUserListProps) => {
	const {adminUserStore, clientStore} = useAppStore();
	const [rowKey, setRowKey] = useState<string>("");

	useEffect(() => {
		adminUserStore.init(clientId || "");

		if (clientId) {
			clientStore.setCurrentClientId(clientId);
		}
	}, [clientId, adminUserStore, clientStore]);

	const onRowUpdating = (e: any) => {
		let modifiedNewData = {};
		["firstName", "email", "lastName", "isEnabled"].forEach(item => {
			modifiedNewData[item] = e.newData[item] ? e.newData[item] : e.oldData[item];
		});

		e.newData = modifiedNewData;
	};

	const onToolbarPreparing = (e: any) => {
		e.toolbarOptions.items.forEach((item: any) => {
			if (item.name === "addRowButton") {
				item.showText = "always";
				item.options.text = "Add New";
				item.options.hint = "Add New";
			}
		});
	};

	const onEditorPreparing = (e: any) => {
		if (e.dataField === "isEnabled") {
			e.editorOptions.disabled = true;
		}
	};

	const onInitNewRow = (e: any) => {
		setRowKey("");
		e.data.clientName = clientStore.getClientName(clientId);
	};

	const onEditingStart = (e: any) => {
		setRowKey(e.key);
		e.data.clientName = clientStore.getClientName(clientId);
	};

	const onContentReady = (e: any) => {
		const popupTitle = rowKey === "" ? "New Administrator" : "Administrator Details";
		e.component.option("title", popupTitle);

		const saveButtonText = rowKey === "" ? "Create" : "Save";
		e.component.option("toolbarItems[0].options.text", saveButtonText);

		// add class to popup to hide * from required fields
		e.component.content().classList.add("mt-admin-list-popup");
	};

	const dataSource = adminUserStore.dataSource;

	if (!dataSource) {
		return null;
	}

	return (
		<React.Fragment>
			<div className="mt--hide mt--block-title mt--pb-0">List of all admin users</div>
			<div className="mt--datagrid">
				<DataGrid
					dataSource={adminUserStore.dataSource}
					columnAutoWidth={true}
					showBorders
					showRowLines
					columnHidingEnabled={false}
					allowColumnReordering={false}
					remoteOperations={true}
					onRowUpdating={onRowUpdating}
					onToolbarPreparing={onToolbarPreparing}
					onInitNewRow={onInitNewRow}
					onEditingStart={onEditingStart}
					onEditorPreparing={onEditorPreparing}
				>
					<Scrolling showScrollbar="always" useNative={false} />
					<Sorting mode="multiple" />
					<FilterRow visible={true} />
					<HeaderFilter visible={true} />
					<StateStoring enabled={true} type="localStorage" storageKey="meta-adminusers" />
					<Paging defaultPageSize={20} />
					<Pager visible={true} showPageSizeSelector={true} allowedPageSizes={[20, 50, 100]} />

					<Editing useIcons={true} mode="popup" allowAdding={true} allowUpdating={true}>
						<Popup showTitle={true} minWidth={320} maxWidth={640} height={"auto"} maxHeight={700} onContentReady={onContentReady} />
						<Form colCount={1}>
							<Item dataField="clientName" disabled cssClass="mt--disabled-display">
								<Label text="Company"></Label>
							</Item>
							<Item dataField="firstName">
								<RequiredRule message="First Name is required" />
							</Item>
							<Item dataField="lastName">
								<RequiredRule message="Last Name is required" />
							</Item>
							<Item dataField="email">
								<RequiredRule message="Email is required" />
								<EmailRule />
							</Item>
							<Item
								dataField="isEnabled"
								editorType="dxRadioGroup"
								editorOptions={{layout: "horizontal"}}
								cssClass="mt--radiogroup mt--label-icon"
							>
								<Label text="Status" />
							</Item>
						</Form>
					</Editing>

					<LoadPanel enabled={true} />

					<Column type="buttons" minWidth={60} visible alignment="center">
						<Button cssClass="mt--icon-datagrid" name="edit" />
					</Column>

					{/* EDIT FIELDS */}
					<Column dataField="clientName" visible={false} />
					<Column dataField="firstName" />
					<Column dataField="lastName" />
					<Column dataField="email" />
					<Column dataField="isEnabled" caption="Status" alignment="center" width={100} visible={false}>
						<Lookup dataSource={adminUserStore.getStatuses} displayExpr={"name"} valueExpr={"value"} />
					</Column>
				</DataGrid>
			</div>
		</React.Fragment>
	);
});
