import React from "react";
import {injectAppStore} from "../../common/app.store.consumer";
import {guard, isLoggedIn} from "../../common/guard";
import {getSurveyNavigationItems} from "../../common/navigation-items/public.submenu.items";
import {AppStore} from "../../stores/app.store";
import {PublicMapRoutes} from "../../stores/site.map";
import CallToActionArea from "../cta/call.to.action";
import PublicPageHeader from "../page-header/page.header";

import {RouteComponentProps, withRouter} from "react-router";
import HowtoIllustration from "../../common/images/howtoIllustration";

type Props = {appStore?: AppStore} & RouteComponentProps<{pSurveyId: string}>;
interface PublicSurveyHowToState {}

@guard(isLoggedIn)
@injectAppStore()
class PublicSurveyHowTo extends React.Component<Props, PublicSurveyHowToState> {
	async componentDidMount() {
		const pSurveyIdFromParam = this.props.match.params.pSurveyId;
		if (pSurveyIdFromParam) {
			await this.props.appStore.teamSurveyStore.getTeamSurvey(pSurveyIdFromParam);
		}
	}

	async componentDidUpdate(prevProps: Props) {
		const pSurveyIdFromParam = this.props.match.params.pSurveyId;
		if (pSurveyIdFromParam) {
			await this.props.appStore.teamSurveyStore.getTeamSurvey(pSurveyIdFromParam);
		}
	}

	render() {
		const contextStore = this.props.appStore.contextStore.contextModel;
		const teamId = contextStore.teamModel.teamId;
		const pSurveyId = this.props.appStore.contextStore.contextModel.teamModel.loggedInUserParticipantSurveyId;
		const isTeamLeaderOrAdmin = this.props.appStore.teamSurveyStore.isCurrentUserTeamLeader || this.props.appStore.userStore.isAdmin;
		const isDiagnosticCompleted =
			contextStore.progress.isDiagnosticSubmitted || contextStore.progress.surveyCompleted || contextStore.progress.surveyFailed;

		return (
			<React.Fragment>
				<PublicPageHeader
					title={this.props.appStore.translationStore.translate("Team Diagnostic")}
					navigationItems={getSurveyNavigationItems({
						translation: this.props.appStore.translationStore,
						teamId,
						pSurveyId,
						isTeamLeaderOrAdmin,
					})}
				/>

				<div className="mt--area-wrap mt--fill-white mt--howto">
					<div className="mt--layout mt--max-width mt--m-auto">
						<div className="mt--content">
							<div className="mt--flex mt--flex-wrap">
								<div className="mt--two-thirds">
									<div>
										<h1 className="mt--pt-0">{this.props.appStore.translationStore.translate("Why should I take part?")}</h1>
										<p className="mt--paragraph-lead">
											{this.props.appStore.translationStore.translate(
												"Meta Team see organisations falling short of their true potential by under-leveraging the power of their teams. Investment is often limited to the development of individual leaders, in the hope that team performance will follow.",
											)}
										</p>
										<p>
											{this.props.appStore.translationStore.translate(
												"But transforming organisational performance doesn’t work that way. We know this from our extensive experience working closely with thousands of teams, hundreds of leading companies and the best consultants and organisational psychologists in business.",
											)}
										</p>
										<p>
											{this.props.appStore.translationStore.translate(
												"Meta Team have found that 30% of teams either derail or drag organisational performance down, whilst only 13% drive performance. The very best teams achieve sustained, optimal performance and have a greater economic impact, delivering 23% more than average teams.",
											)}
										</p>
										<p>
											{this.props.appStore.translationStore.translate(
												"So it’s great teams that drive successful organisations. There are no quick fixes or one-off interventions. It takes a collective commitment to a development journey that fits the team, fits in with their work and transforms what they are capable of.",
											)}
										</p>
									</div>
								</div>
							</div>
							<div className="mt--bb-0 mt--pb-0 mt--flex mt--flex-wrap">
								<div className="mt--two-thirds">
									<h1>{this.props.appStore.translationStore.translate("What is the process and why complete the diagnostic?")}</h1>
									<p>
										{this.props.appStore.translationStore.translate(
											"Each team’s success comes from both a deep understanding of themselves and from constantly challenging, evolving and achieving to go to their next level.",
										)}
									</p>
									<p>
										{this.props.appStore.translationStore.translate(
											"In order to understand the team on a deeper level, we ask each member, including the team leader, line manager and key influencers to score the team on a 1-10 scale against 32 statements. This takes about 15 minutes to complete.",
										)}
									</p>
									<p>
										{this.props.appStore.translationStore.translate(
											"You will be sent a link and some simple instructions via email. The results from the diagnostic will enable the team to understand what they need to work on to improve their performance, and how to do so as quickly as possible.",
										)}
									</p>

									<div>
										<h2>{this.props.appStore.translationStore.translate("Important points to note before completing the diagnostic")}</h2>
										<ul className="mt--list">
											<li>
												{this.props.appStore.translationStore.translate(
													"All feedback is confidential and anonymous if you are a team member or stakeholder. Your feedback is secure, encrypted and treated as confidential.",
												)}
											</li>
											<li>
												{this.props.appStore.translationStore.translate(
													"You can only be identified in the Meta Team diagnostic if you are the team leader or the team leader’s line manager.",
												)}
											</li>
											<li>
												{this.props.appStore.translationStore.translate(
													"Your immediate response is usually the most reliable. If you can’t answer any of the questions just select 'Can't Say.'",
												)}
											</li>
											<li>
												{this.props.appStore.translationStore.translate(
													"If you find yourself responding to most questions at one end of the scale your probably being either too harsh or too lenient.",
												)}
											</li>
											<li>
												{this.props.appStore.translationStore.translate(
													"Focus on one question at a time and try not to allow your overall opinion or relationship with the team to influence your individual responses.",
												)}
											</li>
											<li>
												{this.props.appStore.translationStore.translate(
													"Your responses can be influenced by a recent event that may have had a positive or negative impact. When providing feedback, try to focus on what you’ve observed over the last year.",
												)}
											</li>
										</ul>
									</div>

									<h2 className="tint-txt-grey">
										{this.props.appStore.translationStore.translate("Below is an example of a team’s results.")}
									</h2>
									<div className="mt--pb-2 mt--flex mt--flex-wrap mt--align-items-center">
										<div className="mt--illustration mt--my-1">
											<div className="mt--img-retina">
												<HowtoIllustration alt={this.props.appStore.translationStore.translate("Summary Chart")}/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<CallToActionArea
					title={
						isDiagnosticCompleted
							? this.props.appStore.translationStore.translate("Review Diagnostic Result")
							: this.props.appStore.translationStore.translate("Start the diagnostic")
					}
					btnTitle={this.props.appStore.translationStore.translate("Team Diagnostic")}
					btnSubtitle={
						isDiagnosticCompleted
							? this.props.appStore.translationStore.translate("Review Diagnostic Result")
							: this.props.appStore.translationStore.translate("Start diagnostic now")
					}
					link={`${PublicMapRoutes.Diagnostic}/${pSurveyId}`}
				></CallToActionArea>
			</React.Fragment>
		);
	}
}

export default withRouter(PublicSurveyHowTo);
