import {action, computed, makeObservable, observable, runInAction} from "mobx";
import {IApiClient} from "../common/api.client";
import {DropdownDto} from "../common/webapicall";
import {StoreBase} from "./store.base";

export class PackageStore extends StoreBase {
	constructor(private apiClient: IApiClient) {
		super(apiClient);
		makeObservable(this);
	}

	@observable packagesDropdown: DropdownDto[] = [];

	@action
	async getPackagesForDropdown() {
		const response = await this.apiClient.packageClient.getPackagesForDropdown();
		runInAction(() => {
			if (response) {
				this.packagesDropdown = response;
			}
		});
	}

	@computed
	get getDefaultPackageId(): string {
		return this.packagesDropdown.find(p => p.name.trim().includes("Team Insights Only")).value;
	}
}
