import * as React from "react";
import {AppStore} from "../stores/app.store";
import {injectAppStore} from "./app.store.consumer";
import {ModalRenderer} from "./modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ErrorModal: React.FC<{errorMessage: string; reloadCallback: () => void; logoutAndRestartCallback: () => void; appStore?: AppStore}> = ({
	errorMessage,
	reloadCallback,
	logoutAndRestartCallback,
	appStore,
}) => {
	return <ModalRenderer content={getErrorModalLayout(errorMessage, reloadCallback, logoutAndRestartCallback, appStore)} isShow={true} />;
};

const getErrorModalLayout = (errorMessage: string, reloadCallback: () => void, logoutAndRestartCallback: () => void, appStore: AppStore) => (
	<div className="info-modal-content">
		<div className="info-modal-header paint-lgrey">
			<div className="info-modal-title">{appStore.translationStore.translate("System notification")}</div>
		</div>
		<div className="info-modal-border"></div>
		<div className="info-modal-body">
			<div>
				{appStore.translationStore.translate(
					"There was a network communication error or another issue on this screen. The support team has been notified and the issue will be reviewed shortly.",
				)}
			</div>
			<div className="vspace20"></div>

			{errorMessage && (
				<>
					<textarea className="form-fit paint-lgrey" defaultValue={appStore.translationStore.translate(errorMessage)} rows={4} disabled />
					<div className="vspace10"></div>
				</>
			)}
			<div className="info-modal-buttons">
				<button onClick={logoutAndRestartCallback} className="mt--btn btn-med">
					<FontAwesomeIcon icon={["far", "power-off"]} />
					<div>Restart app</div>
				</button>
				<div className="hspace15"></div>
				<button onClick={reloadCallback} className="mt--btn btn-med">
					<FontAwesomeIcon icon={["far", "sync"]} />
					<div>{appStore.translationStore.translate("Reload page")}</div>
				</button>
			</div>
		</div>
	</div>
);

@injectAppStore()
export class Error extends React.Component<{appStore?: AppStore}> {
	render() {
		return (
			this.props.appStore.errorVisible && (
				<ErrorModal
					errorMessage={this.props.appStore.errorMessage}
					reloadCallback={() => window.location.reload(true)}
					logoutAndRestartCallback={() => this.props.appStore.userStore.logout()}
					appStore={this.props.appStore}
				/>
			)
		);
	}
}
