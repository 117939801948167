import React from "react";
import {injectAppStore} from "../../common/app.store.consumer";
import {AppStore} from "../../stores/app.store";
import {guard, isAdmin} from "../../common/guard";
import AdminPageHeader from "../page-header/page.header";
import AdminClientList from "./clients.list";
import {RouteComponentProps, withRouter} from "react-router";
import BackButton from "../navigation/backButton";
import {UserPermissions} from "../../stores/user.permissions";

type IProps = {appStore?: AppStore} & RouteComponentProps<{clientId: string; forceNew?: string}>;

@guard(isAdmin)
@injectAppStore()
class AdminClients extends React.Component<IProps> {
	async componentDidMount() {
		await this.props.appStore.userStore.getUsersForDropdown();

		// if the clientId is not specified, the server handles it as our 'home' client
		const clientId = this.props?.match?.params?.clientId;
		if (clientId) {
			await this.props.appStore.clientStore.getRolesForDropdown(clientId);
			this.props.appStore.clientStore.setCurrentClientId(clientId);
		}
	}

	async componentDidUpdate(prevProps: Readonly<IProps>) {
		if (prevProps.match.params.clientId === this.props.match?.params?.clientId) {
			return;
		}

		// if the clientId is not specified, the server handles it as our 'home' client
		const clientId = this.props.match?.params?.clientId;

		this.props.appStore.clientStore.setCurrentClientId(clientId ?? "");
		await this.props.appStore.clientStore.getRolesForDropdown(clientId ?? "");
	}

	render() {
		return (
			<>
				<AdminPageHeader clientId={this.props.match.params.clientId} hasSimpleHeader title={`Companies`} />
				<div className="mt--area-wrap mt--fill-white">
					<div className="mt--layout mt--min-height">
						<div className="mt--content">
							<BackButton
								isCompanyPage
								isOnDXToolbar={
									this.props.appStore.userStore.hasPermission(UserPermissions.CreateChildCompany) &&
									this.props.appStore.dashboardStore.dashboardInfo.currentClientTypeId !== "Client"
								}
							/>
							<AdminClientList clientId={this.props.match?.params?.clientId} forceNew={this.props.match?.params?.forceNew} />
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withRouter(AdminClients);
