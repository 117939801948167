import * as React from "react";
import {AppStore} from "../../../stores/app.store";
import {guard, isLoggedIn} from "../../../common/guard";
import PublicSurveyResultsContainer from "../survey.results.wrapper";
import PublicSurveyResultsTeamJourney from "./survey.results.team.journey";

interface Props {
	appStore?: AppStore;
}

@guard(isLoggedIn)
export default class PublicSurveyResultsTeamJourneyContainer extends React.Component<Props> {
	render() {
		return (
			<PublicSurveyResultsContainer>
				<PublicSurveyResultsTeamJourney />
			</PublicSurveyResultsContainer>
		);
	}
}
