import * as React from "react";
import {AppStore} from "../../stores/app.store";
import {injectAppStore} from "../app.store.consumer";
import {SurveyResultBandIndex} from "../webapicall";

interface IBarProps {
	appStore?: AppStore;
	barValue: number;
	bandIndex: SurveyResultBandIndex;
}

const MiniBar: React.FC<IBarProps> = injectAppStore()((props: IBarProps) => {
	const barConfig = props.appStore.barConfigStore;
	let barClasses = ["mini-chart-bar"];
	barClasses.push(barConfig.getBandIndexClass(props.bandIndex));

	return (
		<div className="mini-chart-container">
			<div className={barClasses.join(" ")} style={{width: `${barConfig.calculateWidth(props.barValue)}%`}}></div>
		</div>
	);
});

export default MiniBar;
