import { LoadIndicator } from "devextreme-react/load-indicator";
import React from "react";
import { injectAppStore } from "../../../common/app.store.consumer";
import { AppStore } from "../../../stores/app.store";

interface Props {
	appStore?: AppStore;
}

@injectAppStore()
export default class PublicSurveyResultsPectus extends React.Component<Props> {
	render() {
		if (this.props.appStore.surveyResultsStore.getPectusData.length === 0) {
			return (
				<div className="mt--preloader">
					<LoadIndicator id="large-indicator" height={60} width={60} />
				</div>
			);
		}

		const getTextAnchhor = (side) => {
			if (side === "r") {
				if (isRTL) return "end"
				else return "start"
			}
			else {
				if (isRTL) return "start"
				else return "end"
            }
		}
		// Print font-size for Arabic version is in in main.css print section
		const fontSize = this.props.appStore.translationStore.getLanguageCode === "ar" ? 16 : 12;
		const fontFamily = this.props.appStore.translationStore.getLanguageCode === "ar" ? "Almarai" : "Open-Sans";


		const barConfig = this.props.appStore.barConfigStore;
		const results = this.props.appStore.surveyResultsStore.getPectusData.map(s => ({...s, habitName: this.props.appStore.translationStore.translate(s.habitName)}));
		const isRTL = this.props.appStore.translationStore.isRTL;
		return (
			<div className="mt--full mt--bring-front">
				<span className="mt--key-detailed mt--flex mt--flex-col mt--mx-auto">
					<div className="mt--flex mt--pos-rel mt-shift-r">
						<div className="key low-key">{this.props.appStore.translationStore.translate("Weak")}</div>
						<div className="key mt--pos-abs"></div>
						<div className="key medium-key"></div>
						<div className="key good-key"></div>
						<div className="key best-key">{this.props.appStore.translationStore.translate("Strong")}</div>
					</div>
				</span>
				<div className="mt--pectus_container">
					<svg version="1.1" x="0px" y="0px" viewBox="-75 0 680 457" textAnchor={ this.props.appStore.translationStore.isRTL ? "end" : "start" }>
						<g id="cover">
							<rect x="80" y="170" fill="#FFFFFF" width="400" height="100"></rect>
						</g>
						<g id="Petal_bg" fill="#CFD6DC">
							<polygon points="253.271,227.471 253.271,225.927 165.022,225.927 165.846,226.75 165.022,227.471" />
							<polygon points="307.848,227.471 307.848,225.927 396.098,225.927 395.273,226.75 396.098,227.471" />
							<path
								d="M412.985,244.463l63.433,63.432h-89.691l-60.24-60.24h-25.023v20.492l-3.191-3.192v-17.3H276.44l85.263,85.264v89.69
			l-63.432-63.433v-85.16l-17.712-17.711l-14.52,14.52v-4.531l12.254-12.254l-15.446-15.446v120.583l-63.432,63.433v-89.69
			l60.24-60.24v-25.023h-20.492l3.192-3.191h17.3v-21.831l-85.263,85.263H84.701l63.433-63.432h85.16l17.712-17.713l-14.52-14.519
			h4.531l12.254,12.254l15.446-15.446H148.134l-63.433-63.433h89.691l60.24,60.24h25.023v-20.492l3.192,3.192v17.3h21.831
			l-85.263-85.263V30.79l63.432,63.433v85.16l17.712,17.711l14.52-14.52v4.531l-12.254,12.254l15.446,15.446V94.222l63.432-63.433
			v89.691l-60.24,60.24v25.023h20.492l-3.191,3.192h-17.301v21.831l85.264-85.263h89.691l-63.433,63.536h-85.16l-17.712,17.711
			l14.52,14.52h-4.531l-12.254-12.254l-15.445,15.447H412.985L412.985,244.463z M330.914,205.744h80.732l57.048-57.048h-80.731
			L330.914,205.744z M301.67,175.778l57.047-57.048V38.101L301.67,95.149V175.778z M259.656,176.293V95.561l-57.048-57.048v80.732
			L259.656,176.293z M229.896,205.847l-57.048-57.048H92.116l0,0l0,0l57.048,57.048H229.896z M230.102,247.551H149.37L92.321,304.6
			h80.732L230.102,247.551z M259.656,277.311l-57.048,57.049v80.629l57.048-57.048V277.311L259.656,277.311z M301.36,277.002v80.732
			l57.048,57.048v-80.731L301.36,277.002z M287.871,244.463l10.4-10.401v-14.725l-10.4-10.297h-14.623l-10.4,10.297l0,0v14.623l0,0
			l0,0l10.4,10.4l0,0h14.623V244.463z M468.694,304.703l-57.048-57.049h-80.732l0,0l0,0l57.049,57.049H468.694z"
							/>
						</g>
						<g>
							<polygon
								className="petal"
								fill={barConfig.getBandIndexHexColor(results[0].bandIndex)}
								points="171.921,151.167 224.335,203.581 150.193,203.581 97.779,151.167"
							/>
							<polygon
								className="petal"
								fill={barConfig.getBandIndexHexColor(results[1].bandIndex)}
								points="204.976,44.073 204.976,118.215 257.39,170.629 257.39,96.59"
							/>
							<polygon
								className="petal"
								fill={barConfig.getBandIndexHexColor(results[2].bandIndex)}
								points="303.832,96.487 303.832,170.629 356.246,118.215 356.246,44.073"
							/>
							<polygon
								className="petal"
								fill={barConfig.getBandIndexHexColor(results[3].bandIndex)}
								points="463.031,151.064 388.889,151.064 336.475,203.479 410.617,203.479"
							/>
							<polygon
								className="petal"
								fill={barConfig.getBandIndexHexColor(results[4].bandIndex)}
								points="336.783,250.229 389.301,302.643 463.34,302.643 410.926,250.229"
							/>
							<polygon
								className="petal"
								fill={barConfig.getBandIndexHexColor(results[5].bandIndex)}
								points="356.143,409.222 356.143,335.08 303.729,282.666 303.729,356.808"
							/>
							<polygon
								className="petal"
								fill={barConfig.getBandIndexHexColor(results[6].bandIndex)}
								points="257.287,357.22 257.287,283.181 204.873,335.595 204.873,409.737"
							/>
							<polygon
								className="petal"
								fill={barConfig.getBandIndexHexColor(results[7].bandIndex)}
								points="172.127,302.23 224.541,249.816 150.399,249.816 97.985,302.23"
							/>
						</g>
						<g>
							<g>
								<g id="ADAPTING">
									<g transform="matrix(1 0 0 1 115.8818 9.6328)">
										<text
											className="habit-name"
											textAnchor={getTextAnchhor("r")}
											transform="matrix(1 0 0 1 402 140)"
											fill="#0F3250"
											fontFamily={fontFamily}
											fontWeight="600"
											fontSize={fontSize}
										>
											{this.props.appStore.translationStore.translate("ADAPTING")}
										</text>
									</g>
									<g>

										<g
											transform={this.props.appStore.translationStore.isRTL ? "scale(-1,1)" : null}
											transform-origin={this.props.appStore.translationStore.isRTL ? "500" : null }
										>
											<polygon fill="#0F3250" points="494.955,148.545 488.805,150.03 493.471,154.697" />
											<polygon fill="#0F3250" points="499.621,143.878 492.622,146.212 497.289,150.879" />
											<polygon fill="#0F3250" points="506.41,137.091 497.289,141.546 501.956,146.212" />
											<polygon
												fill="#0F3250"
												points="505.502,141.272 505.502,142.686 505.502,142.687 505.502,149.313 500.813,154 494.188,154
						489.502,149.313 489.502,142.687 494.188,138 500.813,138 500.813,138 502.23,138 501.229,137 493.771,137 488.5,142.271
						488.5,149.729 493.771,155 501.229,155 506.502,149.729 506.502,142.271"
											/>
										</g>
									</g>
								</g>
								<g id="FOCUS">
									<g transform="matrix(1 0 0 1 115.8818 9.6328)">
										<text
											className="habit-name"
											textAnchor={getTextAnchhor("r")}
											transform="matrix(1 0 0 1 400 305)"
											fill="#0F3250"
											fontFamily={fontFamily}
											fontWeight="600"
											fontSize={fontSize}
										>
											{this.props.appStore.translationStore.translate("FOCUS")}
										</text>
									</g>
									<g>
										<g>
											<path
												fill="#0F3250"
												d="M500.813,302l4.688,4.688v6.627l-4.688,4.691h-6.627l-4.687-4.691v-6.627l4.687-4.688H500.813z
						 M501.229,301h-7.457l-5.271,5.271v7.455l5.271,5.271h7.457l5.27-5.271v-7.455L501.229,301z"
											/>
											<polygon fill="#0F3250" points="506.5,306.701 498.5,310 506.5,313.299" />
											<polygon fill="#0F3250" points="500.799,301 494.201,301 497.5,309.002" />
											<polygon fill="#0F3250" points="488.5,306.701 488.5,313.299 496.5,310" />
											<polygon fill="#0F3250" points="497.5,311 494.201,319 500.799,319" />
										</g>
									</g>
								</g>
								<g id="PURPOSE">
									<text
										className="habit-name"
										textAnchor={getTextAnchhor("r")}
										transform="matrix(1 0 0 1 395 445)"
										fill="#0F3250"
										fontFamily={fontFamily}
										fontWeight="600"
										fontSize={fontSize}
									>
									{this.props.appStore.translationStore.translate("PURPOSE")}
									</text>
									<g>
										<g>
											<path
												fill="#0F3250"
												d="M379.313,431.999l4.688,4.688v6.627l-4.688,4.688h-6.627L368,443.313v-6.627l4.687-4.688H379.313z
						 M379.729,431h-7.457L367,436.271v7.457l5.271,5.27h7.457l5.27-5.27v-7.457L379.729,431z"
											/>
											<polygon fill="#0F3250" points="376,433.999 372.3,449 379.7,449" />
										</g>
									</g>
								</g>
								<g id="RESILIENCE">
									<g transform="matrix(1 0 0 1 115.8818 9.6328)">
										<text
											className="habit-name"
											textAnchor={getTextAnchhor("l")}
											transform="matrix(1 0 0 1 53 435)"
											fill="#0F3250"
											fontFamily={fontFamily}
											fontWeight="600"
											fontSize={fontSize}
										>
											{this.props.appStore.translationStore.translate("RESILIENCE")}
										</text>
									</g>
									<g>
										<polygon
											fill="#0F3250"
											points="177.771,435.499 177.032,436.239 178,439.901 177,443.583 177,443.727 177.645,444.372
					186,439.901"
										/>
										<polygon
											fill="#0F3250"
											points="195,443.583 194,439.901 194.968,436.239 194.229,435.5 186,439.901 194.355,444.372
					195,443.727"
										/>
										<path
											fill="#0F3250"
											d="M189.314,431.999l4.686,4.686v6.629l-4.686,4.688h-6.629L178,443.313v-6.629l4.686-4.686H189.314z
					 M189.728,430.999h-7.454l-5.272,5.271v7.457l5.272,5.272h7.454l5.272-5.272v-7.457L189.728,430.999z"
										/>
									</g>
								</g>
								<g id="TRUST">
									<text
										className="habit-name"
										textAnchor={getTextAnchhor("l")}
										transform="matrix(1 0 0 1 48 315)"
										fill="#0F3250"
										fontFamily={fontFamily}
										fontWeight="600"
										fontSize={fontSize}
									>
									{this.props.appStore.translationStore.translate("TRUST")}
									</text>
									<g>
										<path
											fill="#0F3250"
											d="M69.314,302l4.688,4.688v6.63l-4.688,4.687h-6.629l-4.688-4.687v-6.63L62.686,302H69.314z M69.729,301
					h-7.457L57,306.272v7.455L62.271,319h7.457L75,313.728v-7.455L69.729,301z"
										/>
										<polygon fill="#0F3250" points="72.365,303.636 66,306.606 69.395,310.001" />
										<polygon fill="#0F3250" points="62.605,310.001 59.636,316.365 66,313.395" />
										<polygon fill="#0F3250" points="69.395,310.001 66,313.395 72.365,316.365" />
										<polygon fill="#0F3250" points="59.636,303.636 62.605,310.001 66,306.606" />
									</g>
								</g>
								<g id="TAKING ACTION">
									<g transform="matrix(1 0 0 1 115.8818 9.6328)">
										<text
											className="habit-name"
											textAnchor={getTextAnchhor("l")}
											transform="matrix(1 0 0 1 -70 140)"
											fill="#0F3250"
											fontFamily={fontFamily}
											fontWeight="600"
											fontSize={fontSize}
										>
											{this.props.appStore.translationStore.translate("TAKING ACTION")}
										</text>
									</g>
									<g>
										<g
											transform={this.props.appStore.translationStore.isRTL ? "scale(-1,1)" : null}
											transform-origin={this.props.appStore.translationStore.isRTL ? "64" : null}
										>
											<polygon fill="#0F3250" points="57,141.304 57,148.503 62.999,144.903" />
											<polygon fill="#0F3250" points="63,141.304 63,148.503 68.999,144.903" />
											<polygon
												fill="#0F3250"
												points="74,146.67 74,148.313 69.314,153 62.687,153 58,148.313 58,141.686 62.687,137 69.314,137
						74,141.686 74,143.137 75,143.737 75,141.271 69.729,136 62.271,136 57,141.271 57,148.728 62.271,154 69.729,154 75,148.728
						75,146.07"
											/>
											<polygon fill="#0F3250" points="68.999,141.304 68.999,148.503 75,144.903" />
										</g>
									</g>
								</g>
								<g id="INFLUENCING">
									<g transform="matrix(1 0 0 1 115.8818 9.6328)">
										<text
											className="habit-name"
											textAnchor={getTextAnchhor("l")}
											transform="matrix(1 0 0 1 52 8)"
											fill="#0F3250"
											fontFamily={fontFamily}
											fontWeight="600"
											fontSize={fontSize}
										>
											{this.props.appStore.translationStore.translate("INFLUENCING")}
										</text>
									</g>
									<g>
										<g>
											<polygon fill="#0F3250" points="189.7,4.79 182.3,4.79 186,15.59" />
											<polygon
												fill="#0F3250"
												points="190.494,5.556 190.133,6.61 194,10.476 194,17.103 189.313,21.79 182.688,21.79 178,17.103
						178,10.476 181.866,6.61 181.506,5.556 177,10.061 177,17.518 182.271,22.79 189.729,22.79 195,17.518 195,10.061"
											/>
											<polygon fill="#0F3250" points="186,15.59 182.3,22.79 189.7,22.79" />
										</g>
									</g>
								</g>
								<g id="AGILITY">
									<g transform="matrix(1 0 0 1 115.8818 9.6328)">
										<text
											className="habit-name"
											textAnchor={getTextAnchhor("r")}
											transform="matrix(1 0 0 1 280 8)"
											fill="#0F3250"
											fontFamily={fontFamily}
											fontWeight="600"
											fontSize={fontSize}
										>
											{this.props.appStore.translationStore.translate("AGILITY")}
										</text>
									</g>
									<g>
										<g>
											<g>
												<polygon fill="#0F3250" points="384.395,6.585 376.995,6.585 380.698,13.185" />
												<polygon fill="#0F3250" points="383.001,14.566 376.398,18.268 383.001,21.967" />
												<polygon fill="#0F3250" points="368.998,5.188 368.998,12.589 375.601,8.889" />
												<polygon fill="#0F3250" points="371.315,13.984 367.616,20.584 375.015,20.584" />
												<polygon
													fill="#0F3250"
													points="371.245,7.019 372.687,5.577 379.313,5.577 380.82,7.084 382.232,7.084 379.728,4.578
							372.271,4.578 370.337,6.511"
												/>
												<polygon
													fill="#0F3250"
													points="383.075,7.922 382.565,8.828 384,10.264 384,16.891 384,16.891 384,18.306 385,17.307
							385,9.85"
												/>
												<polygon
													fill="#0F3250"
													points="369.449,18.339 367.999,16.891 367.999,10.264 367.999,10.264 367.999,8.851 367,9.85
							367,17.307 368.941,19.244"
												/>
												<polygon
													fill="#0F3250"
													points="380.755,20.138 379.313,21.578 372.687,21.578 371.192,20.086 369.778,20.086 372.271,22.578
							379.728,22.578 381.661,20.645"
												/>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
					</svg>
				</div>
			</div>
		);
	}
}
