import {IconDefinition, library} from "@fortawesome/fontawesome-svg-core";

//REGULAR
import {
	faAlignRight,
	faAngleLeft,
	faAngleRight,
	faEllipsisV,
	faArrowToRight,
	faArrowLeft,
	faArrowFromBottom,
	faArrowRight,
	faChartNetwork,
	faCheck,
	faCheckCircle,
	faInfoCircle,
	faLockOpenAlt,
	faPowerOff,
	faSpinner,
	faSync,
	faTimesCircle,
	faUserFriends,
	faUsers,
} from "@fortawesome/pro-regular-svg-icons";
//SOLID
import {
	faAngleDown,
	faAngleUp,
	faArrowDown as faArrowDownSolid,
	faBars,
	faBellExclamation,
	faCheckCircle as faCheckCircleSolid,
	faCheck as faCheckSolid,
	faCogs,
	faInfoCircle as faInfoCircleSolid,
	faOctagon,
	faPen,
	faSpinnerThird,
	faSync as faSyncSolid,
	faTimesCircle as faTimesCircleSolid,
	faUserCheck,
	faUser as faUserSolid,
	faUsers as faUsersSolid,
} from "@fortawesome/pro-solid-svg-icons";
//LIGHT
import {
	faCheckCircle as faCheckCircleLight,
	faCheck as faCheckLight,
	faInfoCircle as faInfoCircleLight,
	faTimesCircle as faTimesCircleLight,
	faUser,
	faUsersCog,
	faUsers as faUsersLight,
} from "@fortawesome//pro-light-svg-icons";

library.add(
	faEllipsisV as IconDefinition,
	faArrowToRight,
	faAlignRight,
	faAngleUp,
	faAngleDown,
	faAngleRight,
	faAngleLeft,
	faArrowRight,
	faArrowFromBottom,
	faAngleRight,
	faArrowLeft,
	faBars,
	faBellExclamation,
	faCogs,
	faChartNetwork,
	faOctagon,
	faCheck,
	faCheckSolid,
	faCheckLight,
	faCheckCircleLight,
	faCheckCircle,
	faCheckCircleSolid,
	faInfoCircle,
	faInfoCircleSolid,
	faInfoCircleLight,
	faLockOpenAlt,
	faUser,
	faUserSolid,
	faUserCheck,
	faUsersLight,
	faUsers,
	faUserFriends,
	faUsersSolid,
	faUsersCog,
	faPen,
	faSpinner,
	faTimesCircle,
	faTimesCircleLight,
	faTimesCircleSolid,
	faArrowDownSolid,
	//faRightFromBracket,
	faSyncSolid,
	faSpinnerThird,
	faPowerOff,
	faSync,
);
