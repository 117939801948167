import React from "react";
import {injectAppStore} from "../../../common/app.store.consumer";
import TeamJourneyOctagon from "../../../common/team-journey-octagon/team.journey.octagon";
import {TeamJourneyHabit, TeamJourneyStatus} from "../../../common/webapicall";
import {AppStore} from "../../../stores/app.store";

interface IProps {
	appStore?: AppStore;
	habits: TeamJourneyHabit[];
	hideAllProgress?: boolean;
}

const PublicSurveyResultsExcludedTeamJourney: React.FC<IProps> = injectAppStore()(({appStore, habits, hideAllProgress = false}) => {
	const resultsStore = appStore.surveyResultsStore;

	return (
		<React.Fragment>
			<ul className="mt--journey-container mt--fill-bgrey">
				{habits.map(h => (
					<li key={h.habitId}>
						<div className="mt--habit-name">{appStore.translationStore.translate(h.habitName)}</div>
						<TeamJourneyOctagon habitName={h.habitName} isMastered={h.habitStatus === TeamJourneyStatus.Mastered} hideProgress={hideAllProgress} />
						<div className="status">
							<span>{resultsStore.getStatusText(h.habitStatus)}</span>
						</div>
					</li>
				))}
			</ul>
		</React.Fragment>
	);
});

export default PublicSurveyResultsExcludedTeamJourney;
