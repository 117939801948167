import {LoadIndicator} from "devextreme-react/load-indicator";
import React from "react";
import {injectAppStore} from "../../../../common/app.store.consumer";
import {RoleAverage} from "../../../../common/webapicall";
import {AppStore} from "../../../../stores/app.store";
import RadarChart from "./radar.chart";
import {formatForResultTables} from "../../../../utils/string.utils";

interface IProps {
	appStore?: AppStore;
}

@injectAppStore()
export default class PublicSurveyResultsSummaryRadar extends React.Component<IProps> {
	private surveyResultsStore = this.props.appStore.surveyResultsStore;
	private surveyResultsRadarStore = this.props.appStore.surveyResultsRadarStore;

	private handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.surveyResultsRadarStore.toggleVisibility(
			event.target.name,
			this.surveyResultsStore.getOrderedSummaryListForRadarChart.map(s => ({
				...s,
				habitName: this.props.appStore.translationStore.translate(s.habitName),
			})),
		);
	};

	render() {
		if (this.props.appStore.surveyResultsStore.getOrderedSummaryListByParticipantRoleAverage.length === 0) {
			return (
				<div className="mt--preloader">
					<LoadIndicator id="large-indicator" height={60} width={60} />
				</div>
			);
		}

		const resultsForRole = this.surveyResultsStore.getOrderedSummaryListByParticipantRoleAverage;
		const resultsForChart = this.surveyResultsStore.getOrderedSummaryListForRadarChart;
		const visibility = this.surveyResultsRadarStore.radarVisibility;

		return (
			<React.Fragment>
				<div className="mt--area-wrap mt--fill-white mt--break-large">
					<div className="mt--layout">
						<div className="mt--content mt--pt-0">
							<div className="summary-container mt--2-cols mt--flex mt--flex-wrap">
								<div className="col">
									<div className="radar-selector mt--input-group mt--flex mt--flex-wrap">
										<div>
											<input
												type="checkbox"
												id="tm"
												name="TMVisible"
												className="mt--chkbox"
												checked={visibility.TMVisible}
												onChange={this.handleCheckboxChange}
											/>
											<label htmlFor="tm">{this.props.appStore.translationStore.translate("Team Leader (TL)")}</label>
										</div>
										<div>
											<input
												type="checkbox"
												id="s"
												name="SVisible"
												className="mt--chkbox"
												checked={visibility.SVisible}
												onChange={this.handleCheckboxChange}
											/>
											<label htmlFor="s">{this.props.appStore.translationStore.translate("Stakeholders (S)")}</label>
										</div>
										<div>
											<input
												type="checkbox"
												id="o"
												name="OVisible"
												className="chk-btn"
												checked={visibility.OVisible}
												onChange={this.handleCheckboxChange}
											/>
											<label htmlFor="o">{this.props.appStore.translationStore.translate("Overall (O)")}</label>
										</div>
										<div>
											<input
												type="checkbox"
												id="t"
												name="TVisible"
												className="mt--chkbox"
												checked={visibility.TVisible}
												onChange={this.handleCheckboxChange}
											/>
											<label htmlFor="t">{this.props.appStore.translationStore.translate("Team (T)")}</label>
										</div>
										<div>
											<input
												type="checkbox"
												id="m"
												name="MVisible"
												className="mt--chkbox"
												checked={visibility.MVisible}
												onChange={this.handleCheckboxChange}
											/>
											<label htmlFor="m">{this.props.appStore.translationStore.translate("Line Manager (LM)")}</label>
										</div>
									</div>
									<div className="tbl-wrapper">
										<table className="tbl-summary">
											<thead>
												<tr>
													<th>{this.props.appStore.translationStore.translate("Habit")}</th>
													<th>{this.props.appStore.translationStore.translate("TL")}</th>
													<th>{this.props.appStore.translationStore.translate("T")}</th>
													<th>{this.props.appStore.translationStore.translate("S")}</th>
													<th>{this.props.appStore.translationStore.translate("LM")}</th>
													<th>{this.props.appStore.translationStore.translate("Overall")}</th>
												</tr>
											</thead>
											<tbody>
												{resultsForRole.map(item => (
													<tr key={item.habitId}>
														<td>{this.props.appStore.translationStore.translate(item.habitName)}</td>
														{item.roleAverages.map((r: RoleAverage) => (
															<td key={r.participantRoleId}>{formatForResultTables(r.value)}</td>
														))}
														<td>{formatForResultTables(item.overallAverage)}</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
								</div>
								<div className="spc"></div>
								<div className="summary-radar-wrap col">
									<RadarChart data={resultsForChart}/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
