import * as React from "react";
import {AppStore} from "../stores/app.store";
import {injectAppStore} from "./app.store.consumer";
import {DebugPanelOperation} from "../stores/debug.panel.store";

export type ActionDatePickerProps = React.InputHTMLAttributes<HTMLInputElement> & {
	appStore?: AppStore;
	operation: DebugPanelOperation;
	disabled?: boolean;
};

export const ActionDatePicker: React.FC<ActionDatePickerProps> = injectAppStore()((props: ActionDatePickerProps) => {
	let store = props.appStore.debugPanelStore;
	let disabled = !!props.disabled || store.isLoading;
	let inputProps = {
		...props,
	};

	delete inputProps.appStore;
	delete inputProps.operation;
	return <input {...inputProps} disabled={props.operation === store.operation && disabled} type="date" />;
});
