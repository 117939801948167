import {LoadIndicator} from "devextreme-react/load-indicator";
import React from "react";
import {injectAppStore} from "../../../common/app.store.consumer";
import Bar from "../../../common/bar/bar";
import BarHeader from "../../../common/bar/bar.header";
import IndividualValuesBar from "../../../common/bar/individual.values.bar";
import {AboveTabletLandscape, BelowTabletLandscape, Mobile} from "../../../common/responsive.queries";
import {AppStore} from "../../../stores/app.store";
import {formatForResultTables, formatForResultTablesHighLow} from "../../../utils/string.utils";

interface IProps {
	appStore?: AppStore;
	habitId: string;
}

@injectAppStore()
export default class PublicSurveyResultsMicroHabitsDetails extends React.Component<IProps> {
	private surveyResultsStore = this.props.appStore.surveyResultsStore;
	state = {
		selectedId: null,
	};

	onSelectHabit = (selectedHabitId?: string) => {
		this.setState({selectedId: selectedHabitId});
	};

	getTitle = (microHabitName: string, questionText: string) => {
		let title = ` > ${this.props.appStore.translationStore.translate(microHabitName)}`;
		if (questionText) {
			title += ` > ${this.props.appStore.translationStore.translate(questionText)}`;
		}

		return title;
	};

	render() {
		const result = this.surveyResultsStore.getHabitDetails(this.props.habitId);
		const habitName = result.habitName;

		if (!result) {
			return (
				<div className="mt--preloader">
					<LoadIndicator id="large-indicator" height={60} width={60} />
				</div>
			);
		}

		return (
			<React.Fragment>
				{result.microHabits.map(mh => (
					<div key={mh.microHabitId}>
						<div className="survey-micro-habits-details-table mt--mt-2">
							<div className="tbl-header tbl-row">
								<div className="cell cell-title cell-full">
									<span className="habit-title">{this.props.appStore.translationStore.translate(habitName)}</span>
									<span>{this.getTitle(mh.microHabitName, mh.questionText)}</span>
								</div>
							</div>
							<div className="tbl-row-group">
								<div className="tbl-header tbl-header-inside tbl-row mt--mb-1 mt--p-0 ">
									<div className="cell"></div>
									<div className="cell" style={{display: "none"}}></div>
									<div className="cell">
										<BarHeader includeAll />
									</div>
									<div className="cell">
										<BelowTabletLandscape>{this.props.appStore.translationStore.translate("Avg")}</BelowTabletLandscape>
										<AboveTabletLandscape>{this.props.appStore.translationStore.translate("Average")}</AboveTabletLandscape>
									</div>
									<div className="cell">{this.props.appStore.translationStore.translate("High")}</div>
									<div className="cell">{this.props.appStore.translationStore.translate("Low")}</div>
								</div>
								<div className="tbl-row">
									<div className="cell"></div>
									<div className="cell">{this.props.appStore.translationStore.translate("Overall for Micro Habit")}</div>
									<div className="cell chart-hide-details">
										<div className="mt--align-self-center">
											<Mobile>
												<b>{this.props.appStore.translationStore.translate("Overall for Micro Habit")}</b>
											</Mobile>
										</div>
										<Bar barValue={mh.average} bandIndex={mh.bandIndex} showAllTicks showValueOnBar />
									</div>
									<div className="cell">
										<Mobile>{this.props.appStore.translationStore.translate("Avg")}: </Mobile>
										{formatForResultTables(mh.average)}
									</div>
									<div className="cell">
										<Mobile>{this.props.appStore.translationStore.translate("High")}: </Mobile>
										{formatForResultTablesHighLow(mh.highestValue)}
									</div>
									<div className="cell">
										<Mobile>{this.props.appStore.translationStore.translate("Low")}: </Mobile>
										{formatForResultTablesHighLow(mh.lowestValue)}
									</div>
								</div>
								{mh.participantRoleDetails.map(r => {
									const roleName = this.surveyResultsStore.getRoleNameByRoleId(r.roleId);
									return (
										<div key={r.roleId} className="tbl-row">
											<div className="cell"></div>
											<div className="cell">{`${roleName} ${r.answersCount > 1 ? `(${r.answersCount})` : ""}`}</div>
											<div className="cell">
												<IndividualValuesBar values={r.answersDetails} participantRole={r.roleId} />
												{/*<div className={"individual-bar-short "}>{r.average}</div>*/}
											</div>
											<div className="cell ">{r.average !== 0 ? r.average.toFixed(1) : "-"}</div>
											<div className="cell ">{r.highestValue !== 0 ? formatForResultTablesHighLow(r.highestValue) : "-"}</div>
											<div className="cell ">{r.lowestValue !== 0 ? formatForResultTablesHighLow(r.lowestValue) : "-"}</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				))}
			</React.Fragment>
		);
	}
}
