import React from "react";
import AdminPageHeader from "../page-header/page.header";
import {getTeamDetailsNavigationItems} from "../../common/navigation-items/admin.submenu.items";

interface IProps {
	teamId: string;
	teamName: string;
	isTeamSurveyCompleted: boolean;
}

const AdminTeamDetailsWrapper: React.FC<IProps> = ({teamId, teamName, isTeamSurveyCompleted, children}) => {
	return (
		<React.Fragment>
			<AdminPageHeader hasSimpleHeader={false} title={teamName} navigationItems={getTeamDetailsNavigationItems(teamId, isTeamSurveyCompleted)} />
			{children}
		</React.Fragment>
	);
};

export default AdminTeamDetailsWrapper;
