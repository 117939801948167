import React from "react";
import {injectAppStore} from "../../../common/app.store.consumer";
import {AppStore} from "../../../stores/app.store";
import DataGrid, {AsyncRule, Button, Column, Editing, EmailRule, Form, Label, LoadPanel, Lookup, Popup, RequiredRule, Scrolling, Texts} from "devextreme-react/data-grid";
import {ParticipantEmailStatus, ParticipantSurveyStatus, SharingDetailsDto, TeamStatus} from "../../../common/webapicall";
import DataSource from "devextreme/data/data_source";
import {confirm} from "devextreme/ui/dialog";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Item} from "devextreme-react/form";
import {toJS} from "mobx";
import PublicParticipantsListEmailStatus from "../../../public/participants/participants.list.email.status";

interface IProps {
	appStore?: AppStore;
	teamId: string;
	isTeamSurveyCompleted: boolean;
	teamStatus: TeamStatus;
}

interface IStates {
	isSaveButtonDisabled: boolean;
	participants: DataSource;
	orderValue: string;
}

@injectAppStore()
class AdminTeamDetailsParticipantList extends React.Component<IProps, IStates> {
	private fpStore = this.props.appStore.feedbackProvidersStore;
	private userStore = this.props.appStore.userStore;

	private dataGridRef = React.createRef<DataGrid>();
	private rowKey = "";

	state = {
		participants: null,
		isSaveButtonDisabled: true,
		orderValue: "orderValue",
	};

	async componentDidMount() {
		await this.props.appStore.participantRoleStore.getParticipantRolesForDropdown(this.props.isTeamSurveyCompleted);
		const participantsDataSource = this.props.appStore.feedbackProvidersStore.getAdminFeedbackProvidersDataSource(this.props.teamId);
		this.setState({participants: participantsDataSource});

		if (this.props.isTeamSurveyCompleted) {
			this.setState({orderValue: "sharingOrderValue"});
		}
	}

	async componentDidUpdate(prevProps: Readonly<IProps>): Promise<void> {
		if (prevProps.isTeamSurveyCompleted !== this.props.isTeamSurveyCompleted) {
			await this.props.appStore.participantRoleStore.getParticipantRolesForDropdown(this.props.isTeamSurveyCompleted);
		}
	}

	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = () => {
			return;
		};
	}

	private get IsTeamSurveyRunningOrCompleted(): boolean {
		return this.props.teamStatus === TeamStatus.TeamInsightsInProgress || this.props.teamStatus === TeamStatus.TeamInsightsCompleted;
	}

	private get IsTeamSurveyRunning(): boolean {
		return this.props.teamStatus === TeamStatus.TeamInsightsInProgress;
	}

	private renderCheckbox = (cellData: any) => {
		const initialData = this.dataGridRef.current.instance
			.getDataSource()
			.items()
			.find(p => p.id === cellData.data.id);

		if (initialData && initialData[cellData.column.dataField]) {
			return (
				<div>
					<span className="mt--mr-05 tint-chk-grey">
						<FontAwesomeIcon icon={["fas", "check"]} transform="down-1" />
					</span>
					Shared
				</div>
			);
		}

		// empty cells if the participant is not team leader
		//20221005,AU, removed by Byron request, from now, admin can share with everybody
		//NOTE!: this must be in sync with the following instance configuration: AdminCanShareWith
		//
		// if (cellData.data.relationshipId !== "TL") {
		// 	return;
		// }

		return (
			<div>
				<input
					type="checkbox"
					onChange={() => this.onChangeShareCheckbox(cellData.rowIndex, cellData.column.dataField, cellData.value)}
					checked={cellData.value}
				/>
			</div>
		);
	};

	private onChangeShareCheckbox = (rowIndex: number, dataField: string, value: boolean) => {
		this.dataGridRef.current.instance.cellValue(rowIndex, dataField, !value);
		this.setState({isSaveButtonDisabled: false});
	};

	private saveSharing = async () => {
		let result = await confirm("<div>"+this.props.appStore.translationStore.translate("Are you ready to share Team Results with the selected participants?")+"</div>", this.props.appStore.translationStore.translate("Share Team Results"));
		if (result) {
			const participantIds = this.dataGridRef.current.instance
				.getDataSource()
				.items()
				//20221005,AU, removed by request - admin can share with everybody, see also the renderCheckbox method, they must be in sync
				//.filter((p) => p.relationshipId === "TL")
				.map(p => p.id);

			const instance = this.dataGridRef.current.instance as any;
			const results = await instance.getDataByKeys(participantIds);

			if (results.length > 0) {
				const participants: SharingDetailsDto[] = [];
				results.forEach((r: any) =>
					participants.push({
						participantId: r.id,
						participantRoleId: r.relationshipId,
						isRecommendationsShared: r.isRecommendationShared,
						isSummaryShared: r.isSummaryShared,
						isMicroHabitsShared: r.isMicroHabitsShared,
					}),
				);

				const res = await this.props.appStore.apiClient.resultsSharingClient.adminShare(this.props.teamId, {
					teamId: this.props.teamId,
					shareDetails: participants,
				});

				if (res) {
					this.dataGridRef.current.instance.refresh();
				}
				this.setState({isSaveButtonDisabled: true});
			}
		}
	};

	private onToolbarPreparing = (e: any) => {
		e.toolbarOptions.items.forEach((item: any) => {
			if (item.name === "addRowButton") {
				item.showText = this.props.appStore.translationStore.translate("always");
				item.options.text = this.props.appStore.translationStore.translate("Add New Feedback Provider");
				item.options.hint = this.props.appStore.translationStore.translate("Add New Feedback Provider");
			}
		});
	};

	private onInitNewRow = () => {
		this.rowKey = "";
	};

	private onEditingStart = (e: any) => {
		this.rowKey = e.key;
	};

	private onEditorPreparing = (e: any) => {
		if (e.dataField === "relationshipId" && e.parentType === "dataRow") {
			e.editorOptions.disabled = e.row.data && e.row.data.relationship !== undefined && this.IsTeamSurveyRunning;
		}
	};

	private onRowUpdating = (e: any) => {
		let modifiedNewData = {};
		["firstName", "lastName", "email", "relationshipId"].forEach(item => {
			modifiedNewData[item] = e.newData[item] ? e.newData[item] : e.oldData[item];
		});
		e.newData = modifiedNewData;
	};

	private allowUpdating = () => {
		return this.IsTeamSurveyRunning;
	};

	private onRowInserted = async () => {
		if (this.props.teamStatus !== TeamStatus.TeamInsightsCompleted) {
			await this.updateCounts();
			this.refreshGrid();
		}
	};

	private onRowRemoved = async () => {
		if (this.IsTeamSurveyRunning) {
			await this.updateCounts();
			this.refreshGrid();
		}
	};

	private allowDeleting = (e: any) => {
		const relationshipId = e.row.data.relationshipId;
		return this.IsTeamSurveyRunning && this.fpStore.canBeDeleted(relationshipId);
	};

	private onContentReady = (e: any) => {
		const popupTitle = this.rowKey === "" ? this.props.appStore.translationStore.translate("New Feedback Provider") : this.props.appStore.translationStore.translate("Feedback Provider Details");
		e.component.option("title", popupTitle);
	};

	private customizeItem = (e: any) => {
		if (this.rowKey !== "" && e.dataField === "relationshipId") {
			e.visible = false;
		}
	};

	private emailUnique = async (params: any) => {
		const participantId = this.rowKey !== "" ? this.rowKey : null;
		const result = await this.props.appStore.feedbackProvidersStore.isEmailUnique(this.props.teamId, params.value, participantId);
		return result;
	};

	private updateCounts = async () => {
		if (this.props.teamId) {
			await this.props.appStore.feedbackProvidersStore.getFeedbackProvidersInfo(this.props.teamId);
		}
	};

	private emailsCellRender = (cellData: any) => {
		// empty cell if for the logged in user
		if (cellData.data.userId === this.userStore.userId) return;

		return (
			<PublicParticipantsListEmailStatus
				teamId={this.props.teamId}
				participantId={cellData.data.id}
				participantSurveyStatus={cellData.data.participantSurveyStatus as ParticipantSurveyStatus}
				teamSurveyInvitationStatus={cellData.data.teamSurveyInvitationStatus as ParticipantEmailStatus}
				teamSurveyReminderStatus={cellData.data.teamSurveyReminderStatus as ParticipantEmailStatus}
				refreshGrid={this.refreshGrid}
			/>
		);
	};

	private refreshGrid = () => {
		this.dataGridRef.current.instance.refresh();
	};

	render() {
		const participantRoles = toJS(this.props.appStore.participantRoleStore.participantRolesDropdown.map(s => ({value: s.value, name: this.props.appStore.translationStore.translate(s.name)})));

		return (
			<React.Fragment>
				<div className="mt--datagrid">
					<div className="mt--flex mt--flex-wrap mt--align-items-start mt--justify-content-between">
						<div className="mt--block-title">{this.props.appStore.translationStore.translate("Feedback providers")}</div>
						<button className="btn-grid-refresh mt--mb-2" onClick={this.refreshGrid}>
							<div className="mt--ico">
								<i className="far fa-sync-alt"></i>
							</div>
							{this.props.appStore.translationStore.translate("Refresh Grid")}
						</button>
					</div>
					<DataGrid
						ref={this.dataGridRef}
						remoteOperations={true}
						dataSource={this.state.participants}
						showBorders={false}
						onToolbarPreparing={this.onToolbarPreparing}
						onInitNewRow={this.onInitNewRow}
						onEditorPreparing={this.onEditorPreparing}
						onEditingStart={this.onEditingStart}
						onRowUpdating={this.onRowUpdating}
						onRowInserted={this.onRowInserted}
						onRowRemoved={this.onRowRemoved}
					>
						<Scrolling showScrollbar="always" useNative={false} />
						<Editing
							mode="popup"
							useIcons={true}
							allowAdding={this.IsTeamSurveyRunningOrCompleted}
							allowUpdating={this.allowUpdating}
							allowDeleting={this.allowDeleting}
							confirmDelete
						>
							<Texts confirmDeleteMessage={this.props.appStore.translationStore.translate("Are you sure you want to remove this participant?")} confirmDeleteTitle={this.props.appStore.translationStore.translate("Remove Feedback Provider")} />
							<Popup showTitle={true} minWidth={320} maxWidth={640} height={"auto"} dragEnabled={false} onContentReady={this.onContentReady} />
							<Form customizeItem={this.customizeItem}>
								<Item itemType="group" colCount={1} colSpan={2}>
									<Item dataField="firstName">
										<RequiredRule message={this.props.appStore.translationStore.translate("First name is required")} />
									</Item>
									<Item dataField="lastName">
										<RequiredRule message={this.props.appStore.translationStore.translate("Last name is required")} />
									</Item>
									<Item dataField="email">
										<RequiredRule message={this.props.appStore.translationStore.translate("Email is required")} />
										<EmailRule />
										<AsyncRule validationCallback={this.emailUnique} />
									</Item>
									<Item dataField="relationshipId">
										<Label text="Relationship" />
										<RequiredRule message={this.props.appStore.translationStore.translate("Relationship is required")} />
									</Item>
								</Item>
							</Form>
						</Editing>

						<LoadPanel enabled={true} />

						<Column type="buttons" width={80} alignment="left" visible={this.IsTeamSurveyRunning}>
							<Button cssClass="mt--icon-datagrid" name="edit" />
							<Button cssClass="mt--icon-datagrid" name="delete" hint={this.props.appStore.translationStore.translate("Remove")} />
						</Column>

						<Column dataField="name" />
						<Column dataField="relationship" caption="Role" />
						<Column dataField="participantSurveyStatus" caption={this.props.appStore.translationStore.translate("Status")} />

						{/* EMAIL STATUS */}
						<Column caption={this.props.appStore.translationStore.translate("Email Status")} cellRender={this.emailsCellRender} visible={this.IsTeamSurveyRunning} width={310} />

						{/* SHARE CHECKBOXES */}
						<Column
							dataField="isRecommendationShared"
							caption={this.props.appStore.translationStore.translate("Recommendations")}
							width="12.5%"
							cellRender={this.renderCheckbox}
							visible={this.props.isTeamSurveyCompleted}
						/>
						<Column
							dataField="isSummaryShared"
							caption={this.props.appStore.translationStore.translate("Summary")}
							width="12.5%"
							cellRender={this.renderCheckbox}
							visible={this.props.isTeamSurveyCompleted}
						/>
						<Column
							dataField="isMicroHabitsShared"
							caption={this.props.appStore.translationStore.translate("Micro Habits")}
							width="12.5%"
							cellRender={this.renderCheckbox}
							visible={this.props.isTeamSurveyCompleted}
						/>

						{/* EDIT FIELDS */}
						<Column dataField="firstName" visible={false} />
						<Column dataField="lastName" visible={false} />
						<Column dataField="email" visible={false} />
						<Column dataField="relationshipId" visible={false}>
							<Lookup dataSource={participantRoles} displayExpr={"name"} valueExpr={"value"} />
						</Column>

						<Column dataField={this.state.orderValue} visible={false} defaultSortOrder="asc" />
					</DataGrid>
				</div>

				{this.props.isTeamSurveyCompleted && (
					<div className="mt--flex mt--justify-content-center mt--mt-3">
						<button
							className="mt--btn btn-med wide mt--justify-content-center"
							disabled={this.state.isSaveButtonDisabled}
							onClick={this.saveSharing}
						>
							{this.props.appStore.translationStore.translate("Save")}
						</button>
					</div>
				)}
			</React.Fragment>
		);
	}
}

export default AdminTeamDetailsParticipantList;
