import React from "react";
import {injectAppStore} from "../../common/app.store.consumer";
import {AppStore} from "../../stores/app.store";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import imageCheckCircle from "../../assets/img/fa-check-circle.svg";
import imageExclamationCircle from "../../assets/img/fa-exclamation-circle.svg";
import {formatDateOnly} from "../../utils/date.utils";
import {Link} from "react-router-dom";
import AdminTeamDetailsDeadline from "../../admin/team-details/overview/team.details.deadline";
import DiagnosticNotPaid from "./diagnostic.notpaid";
import {Trans} from "react-i18next";
import saveAs from "file-saver";

interface ParticipantsListOverviewProps {
	appStore?: AppStore;
	onStartDiagnostic: () => void;
	finishSurveyNow: () => void;
	teamId: string;
	isLoading: boolean;
}

@injectAppStore()
export default class PublicParticipantsListOverview extends React.Component<ParticipantsListOverviewProps> {
	render() {
		const fpStore = this.props.appStore.feedbackProvidersStore;
		const contextModel = this.props.appStore.contextStore.contextModel;
		const defaultResultsPage = this.props.appStore.surveyResultsStore.getDefaultResultPage(this.props.appStore.contextStore, this.props.teamId);

		return (
			<React.Fragment>
				<div className="mt--area-wrap mt--fill-bgrey mt--overview-area">
					<div className="mt--layout">
						<div className="mt--content mt--pb-0">
							<div className="mt--flex mt--flex-wrap mt--justify-content-between">
								<div className="mt--block-title mt--pb-0">{this.props.appStore.translationStore.translate("Overview")}</div>
								<div className="mt--three-quarters">
									{!contextModel.progress.isSurveyRunning && !contextModel.progress.surveyCompleted && !contextModel.progress.surveyFailed ? (
										<>
											<div className="mt--notification-status">
												<span>
													<FontAwesomeIcon icon={["fas", "bell-exclamation"]} />
												</span>
												<span>
													{this.props.appStore.translationStore.translate("Recommended diagnostics start date: {{date}}", {
														date: formatDateOnly(contextModel.teamModel.feedbackProvidersDeadline),
													})}
												</span>
											</div>
											<div className="mt--notification-status">
												<span>
													<FontAwesomeIcon icon={["fas", "bell-exclamation"]} />
												</span>
												<span>
													<span style={{color: fpStore.isTeamSurveyDeadlinePassed ? "#D6083B" : "inherit"}}>
														{this.props.appStore.translationStore.translate("Diagnostics deadline: {{date}}", {
															date: contextModel.teamModel.teamSurveyDeadline
																? formatDateOnly(contextModel.teamModel.teamSurveyDeadline)
																: this.props.appStore.translationStore.translate("Not set"),
														})}
													</span>
												</span>
											</div>
										</>
									) : contextModel.progress.isSurveyRunning &&
									  !contextModel.progress.surveyCompleted &&
									  !contextModel.progress.surveyFailed ? (
										<div className="mt--notification-status">
											<span>
												<FontAwesomeIcon icon={["fas", "bell-exclamation"]} />
											</span>
											<span>
												{this.props.appStore.translationStore.translate("Your Team Diagnostic is in progress.")}{" "}
												<span>
													{this.props.appStore.translationStore.translate("The deadline is: {{date}}", {
														date: formatDateOnly(contextModel.teamModel.teamSurveyDeadline),
													})}
												</span>
											</span>
										</div>
									) : !contextModel.progress.isSurveyRunning && contextModel.progress.surveyCompleted && contextModel.teamModel.isPaid ? (
										<div className="mt--notification-status">
											<span>
												<FontAwesomeIcon icon={["fas", "check-circle"]} transform="grow-1" />
											</span>
											{defaultResultsPage === "" ? (
												<span>{this.props.appStore.translationStore.translate("Your Team Diagnostic is completed.")}</span>
											) : (
												<span>
													<Trans i18nKey="<span>Your Team Diagnostic is completed, you can </span><0><u>review the results here</u></0><span>.</span>">
														<Link to={defaultResultsPage}></Link>
													</Trans>
												</span>
											)}
										</div>
									) : !contextModel.progress.isSurveyRunning && contextModel.progress.surveyFailed ? (
										<div className="mt--notification-status">
											<span>
												<FontAwesomeIcon icon={["fas", "times-circle"]} />
											</span>
											<span>
												{this.props.appStore.translationStore.translate(
													"Unfortunately too few assessments were completed to evaluate the results, the Team Diagnostic failed.",
												)}
											</span>
										</div>
									) : (
										""
									)}
									<div className="mt--notification-status">
										<AdminTeamDetailsDeadline
											isTeamSurveyStarted={fpStore.isTeamSurveyStarted}
											isTeamSurveyFinished={fpStore.isTeamSurveyFinished}
											deadline={contextModel.teamModel.teamSurveyDeadline}
											teamId={this.props.teamId}
										/>
									</div>
								</div>
								{(fpStore.isTeamSurveyStarted || fpStore.isTeamSurveyFinished) && this.props.appStore.userStore.isAdmin && (
									<div className="mt--notification-status mt--mt-0">
										<span
											title={this.props.appStore.translationStore.translate("Download Progress Report")}
											onClick={async () => {
												const file = await this.props.appStore.apiClient.teamSurveyClient.progressReport(this.props.teamId);

												if (file) {
													saveAs(file.data, file.fileName);
												}
											}}
										>
											<button className="mt--btn-linkalike mt--mt-0">
												<span className="dx-icon mt--mr-025">
													<FontAwesomeIcon icon={["fas", "arrow-down"]} />
												</span>
												<div className="mt--linkalike-label">
													{this.props.appStore.translationStore.translate("Download Progress Report")}
												</div>
											</button>
										</span>
									</div>
								)}
							</div>

							{contextModel.progress.surveyCompleted && !contextModel.teamModel.isPaid && (
								<div className="">
									<DiagnosticNotPaid></DiagnosticNotPaid>
								</div>
							)}

							<div className="mt--flex mt--flex-col mt--align-items-center mt--mt-1">
								<div className="mt--participant-display">
									{fpStore.isTMLimit ? (
										<div className="mt--participant-indicator">
											<div className="mt--bgicon users blue">
												<img
													src={imageCheckCircle}
													className="ico-circle"
													alt={this.props.appStore.translationStore.translate("Users check")}
												/>
											</div>
											<div className="info-group">
												<div className="title">
													{fpStore.FeedbackProvidersInfoDto.teamMember.total}{" "}
													{this.props.appStore.translationStore.translate("Team members")}
												</div>
												<div className="counter">
													{fpStore.FeedbackProvidersInfoDto.teamMember.completed > 0 && (
														<div className="status-completed">
															{fpStore.FeedbackProvidersInfoDto.teamMember.completed}{" "}
															{this.props.appStore.translationStore.translate("Completed")}
														</div>
													)}
													{fpStore.FeedbackProvidersInfoDto.teamMember.inProgress > 0 && (
														<div className="status-in-progress">
															{fpStore.FeedbackProvidersInfoDto.teamMember.inProgress}{" "}
															{this.props.appStore.translationStore.translate("In Progress")}
														</div>
													)}
													{fpStore.FeedbackProvidersInfoDto.teamMember.notStarted > 0 && (
														<div className="status-not-started">
															{fpStore.FeedbackProvidersInfoDto.teamMember.notStarted}{" "}
															{this.props.appStore.translationStore.translate("Not Started")}
														</div>
													)}
												</div>
											</div>
										</div>
									) : (
										<div className="mt--participant-indicator">
											<div className="mt--bgicon users grey">
												<img
													src={imageExclamationCircle}
													className="ico-circle"
													alt={this.props.appStore.translationStore.translate("Users Warning")}
												/>
											</div>
											<div className="info-group">
												<div className="title">
													{fpStore.FeedbackProvidersInfoDto.teamMember.total}{" "}
													{this.props.appStore.translationStore.translate("Team members")}
												</div>
												<div className="desc">
													<Trans
														i18nKey="Please specify at least <b>{{TMMin}} team members</b> for the Team Diagnostic by clicking on the <b>+ ADD NEW FEEDBACK PROVIDER</b> button below."
														values={{TMMin: fpStore.FeedbackProvidersInfoDto.teamMember.min}}
													></Trans>
												</div>
											</div>
										</div>
									)}
									{fpStore.isSHLimit ? (
										<div className="mt--participant-indicator">
											<div className="mt--bgicon users blue">
												<img
													src={imageCheckCircle}
													className="ico-circle"
													alt={this.props.appStore.translationStore.translate("Users check")}
												/>
											</div>
											<div className="info-group">
												<div className="title">
													{fpStore.FeedbackProvidersInfoDto.stakeholder.total}{" "}
													{this.props.appStore.translationStore.translate("Stakeholders")}
												</div>
												<div className="counter">
													{fpStore.FeedbackProvidersInfoDto.stakeholder.completed > 0 && (
														<div className="status-completed">
															{fpStore.FeedbackProvidersInfoDto.stakeholder.completed}{" "}
															{this.props.appStore.translationStore.translate("Completed")}
														</div>
													)}
													{fpStore.FeedbackProvidersInfoDto.stakeholder.inProgress > 0 && (
														<div className="status-in-progress">
															{fpStore.FeedbackProvidersInfoDto.stakeholder.inProgress}{" "}
															{this.props.appStore.translationStore.translate("In Progress")}
														</div>
													)}
													{fpStore.FeedbackProvidersInfoDto.stakeholder.notStarted > 0 && (
														<div className="status-not-started">
															{fpStore.FeedbackProvidersInfoDto.stakeholder.notStarted}{" "}
															{this.props.appStore.translationStore.translate("Not Started")}
														</div>
													)}
												</div>
											</div>
										</div>
									) : (
										<div className="mt--participant-indicator">
											<div className="mt--bgicon users grey">
												<img
													src={imageExclamationCircle}
													className="ico-circle"
													alt={this.props.appStore.translationStore.translate("Users Warning")}
												/>
											</div>
											<div className="info-group">
												<div className="title">
													{fpStore.FeedbackProvidersInfoDto.stakeholder.total}{" "}
													{this.props.appStore.translationStore.translate("Stakeholders")}
												</div>
												<div className="desc">
													<Trans
														i18nKey="Please specify at least <b>{{SHMin}} stakeholders</b> for the Team Diagnostic by clicking on the <b>+ ADD NEW FEEDBACK PROVIDER</b> button below."
														values={{SHMin: fpStore.FeedbackProvidersInfoDto.stakeholder.min}}
													></Trans>
												</div>
											</div>
										</div>
									)}
									{fpStore.isLMLimit ? (
										<div className="mt--participant-indicator">
											<div className="mt--bgicon user blue">
												<img
													src={imageCheckCircle}
													alt={this.props.appStore.translationStore.translate("User Check")}
													className="ico-circle"
												/>
											</div>
											<div className="info-group">
												<div className="title">
													{fpStore.FeedbackProvidersInfoDto.lineManager.total}{" "}
													{this.props.appStore.translationStore.translate("Line Manager")}
												</div>
												<div className="counter">
													{fpStore.FeedbackProvidersInfoDto.lineManager.completed > 0 && (
														<div className="status-completed">
															{fpStore.FeedbackProvidersInfoDto.lineManager.completed}{" "}
															{this.props.appStore.translationStore.translate("Completed")}
														</div>
													)}
													{fpStore.FeedbackProvidersInfoDto.lineManager.inProgress > 0 && (
														<div className="status-in-progress">
															{fpStore.FeedbackProvidersInfoDto.lineManager.inProgress}{" "}
															{this.props.appStore.translationStore.translate("In Progress")}
														</div>
													)}
													{fpStore.FeedbackProvidersInfoDto.lineManager.notStarted > 0 && (
														<div className="status-not-started">
															{fpStore.FeedbackProvidersInfoDto.lineManager.notStarted}{" "}
															{this.props.appStore.translationStore.translate("Not Started")}
														</div>
													)}
												</div>
											</div>
										</div>
									) : (
										<div className="mt--participant-indicator">
											<div className="mt--bgicon user grey">
												<img
													src={imageExclamationCircle}
													className="ico-circle"
													alt={this.props.appStore.translationStore.translate("Users Warning")}
												/>
											</div>
											<div className="info-group">
												<div className="title">
													{fpStore.FeedbackProvidersInfoDto.lineManager.total}{" "}
													{this.props.appStore.translationStore.translate("Line Manager")}
												</div>
												<div className="desc">
													<Trans
														i18nKey="Please specify at least <b>{{LMMin}} line manager</b> for the Team Diagnostic by clicking on the <b>+ ADD NEW FEEDBACK PROVIDER</b> button below."
														values={{LMMin: fpStore.FeedbackProvidersInfoDto.lineManager.min}}
													></Trans>
												</div>
											</div>
										</div>
									)}
								</div>
								<div className="mt--btn-wrap">
									{this.props.appStore.userStore.isAdmin &&
									this.props.appStore.contextStore.contextModel.teamModel.isInstantCloseAvailable &&
									this.props.appStore.contextStore.contextModel.teamModel.isMinimalFeedbackCountConditionsMet ? (
										<button className="mt--btn btn-med mt--flex" onClick={this.props.finishSurveyNow} disabled={this.props.isLoading}>
											{this.props.isLoading && <FontAwesomeIcon className="mt--mr-05" icon={["fas", "sync"]} spin />}
											{this.props.appStore.translationStore.translate("End diagnostic and generate results")}
										</button>
									) : !this.props.appStore.contextStore.contextModel.progress.isSurveyRunning &&
									  !this.props.appStore.contextStore.contextModel.progress.surveyCompleted ? (
										<button
											className={"mt--btn btn-med mt--flex " + (!fpStore.canDiagnosticStart || this.props.isLoading ? " disabled" : "")}
											disabled={!fpStore.canDiagnosticStart}
											onClick={this.props.onStartDiagnostic}
										>
											<div className="mt--ico">
												<FontAwesomeIcon
													icon={["far", "arrow-right"]}
													flip={this.props.appStore.translationStore.isRTL ? "horizontal" : null}
												/>
											</div>
											<div>{this.props.appStore.translationStore.translate("Start Team Diagnostic")}</div>
										</button>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
