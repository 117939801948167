import React from "react";
import {Link} from "react-router-dom";
import {AdminMapRoutes} from "../../stores/site.map";
import icon from "../../assets/img/ico-dashb-export.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export class ReportsTile extends React.Component {
	async componentDidMount() {}

	render() {
		return (
			<div className="dashboard-tile">
				<div>
					<div className="tile-icon"><img src={icon} alt="" /></div>
				</div>
				<div className="tile-title">Data Export</div>
				<div className="tile-link">
					<Link to={`${AdminMapRoutes.Report}`}>
						<span>Download</span>
						<FontAwesomeIcon icon={["far", "angle-right"]} />
					</Link>
				</div>
			</div>
		);
	}
}
