import React from "react";
import {injectAppStore} from "../../common/app.store.consumer";
import {AppStore} from "../../stores/app.store";
import {guard, isLoggedIn} from "../../common/guard";
import {Redirect} from "react-router";
import {AuthorizableActions} from "../../common/webapicall";
import {authorizableActionsContains} from "../../common/helpers";
import {PublicMapRoutes, AdminMapRoutes} from "../../stores/site.map";

type PublicRedirectProps = {appStore?: AppStore; teamId?: string};
interface PublicRedirectState {
	redirectTo: string;
}

@guard(isLoggedIn)
@injectAppStore()
class PublicRedirect extends React.Component<PublicRedirectProps, PublicRedirectState> {
	constructor(props: any) {
		super(props);
		this.state = {
			redirectTo: "",
		};
	}

	async componentDidMount() {
		const userStore = this.props.appStore.userStore;
		const contextStore = this.props.appStore.contextStore;

		const teamId = this.props.teamId ? this.props.teamId : userStore.defaultTeamId;
		if (teamId && teamId !== contextStore.contextModel.teamModel.teamId) {
			// save last visited team id
			await userStore.saveLastVisitedId(teamId);
			await contextStore.loadContext(teamId);
		}

		const pSurveyId = contextStore.contextModel.teamModel.loggedInUserParticipantSurveyId;
		const defaultResultsPage = this.props.appStore.surveyResultsStore.getDefaultResultPage(contextStore, teamId);

		if (userStore.isAdmin) {
			this.setState({redirectTo: AdminMapRoutes.Teams});
		}
		else if (teamId) {
			if (
				authorizableActionsContains(contextStore, [AuthorizableActions.CanViewRecommendationsResults]) ||
				authorizableActionsContains(contextStore, [AuthorizableActions.CanViewSummaryResults]) ||
				authorizableActionsContains(contextStore, [AuthorizableActions.CanViewMicroHabitResults])
			) {
				this.setState({redirectTo: defaultResultsPage});
			} else if (pSurveyId) {
				this.setState({redirectTo: `${PublicMapRoutes.Diagnostic}/${pSurveyId}`});
			} else if (authorizableActionsContains(contextStore, [AuthorizableActions.CanViewParticipants])) {
				this.setState({redirectTo: `${PublicMapRoutes.ParticipantsList}/${teamId}`});
			}
		} 
	}

	render() {
		if (this.state.redirectTo) {
			return <Redirect to={this.state.redirectTo} />;
		}

		return (
			<>
				<div className="mt--area-wrap mt--fill-dblue mt--pattern mt--admin">
					<div className="mt--layout mt--flex mt--justify-content-between mt--py-0">
						<div className="head">
							<div className="mt--lead mt--px-0">
								<div>{this.props.appStore.translationStore.translate("Information")}</div>
							</div>
						</div>
					</div>
				</div>
				<div className="mt--area-wrap  mt--fill-white  mt--min-height">
					<div className="mt--layout  mt--min-height">
						<div className="mt--content">
							<p>{this.props.appStore.translationStore.translate("Up to this point you have no teams.")}</p>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default PublicRedirect;
