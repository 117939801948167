import * as React from "react";
import {IAppStore} from "../stores/app.store";
import {observer} from "mobx-react";
import {AppContext} from "./mainframe";
import { withTranslation } from "react-i18next";

/** Decorator to be used for injecting AppStore to React component. */
export const injectAppStore = () => {
	return <TAppStore extends IAppStore, T extends {appStore?: TAppStore}, C extends React.ComponentType<T> | {(props: T): JSX.Element}>(Component: C): C => {
		let ObserverComponent: any = observer(Component);
		const Wrapper: React.FC<T> = (props: T) => {
			const appStore = React.useContext(AppContext);
			return <ObserverComponent appStore={appStore} {...props} />;
		};

		return withTranslation()(Wrapper as any) as C;
	};
};

/** Hook used to retrieve the AppStore in React functional components */
export const useAppStore = () => React.useContext(AppContext);
