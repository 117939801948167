import React from "react";
import {RouteComponentProps, withRouter} from "react-router";
import {injectAppStore} from "../../common/app.store.consumer";
import {authorizableActionsContains} from "../../common/helpers";
import {getSurveyResultsNavigationItems} from "../../common/navigation-items/public.submenu.items";
import {AuthorizableActions} from "../../common/webapicall";
import {AppStore} from "../../stores/app.store";
import PublicPageHeader from "../page-header/page.header";

type Props = {appStore?: AppStore} & RouteComponentProps<{teamId: string}>;

@injectAppStore()
class PublicSurveyResultsWrapper extends React.Component<Props> {
	async componentDidMount() {
		const teamIdFromParam = this.props.match.params.teamId;
		if (teamIdFromParam) {
			await this.props.appStore.surveyResultsStore.getSurveyResults(teamIdFromParam);
		}
	}

	async componentDidUpdate(prevProps: Props) {
		const teamId = this.props.match.params.teamId;
		if (teamId !== prevProps.match.params.teamId) {
			this.props.appStore.surveyResultsStore.isLoaded = false;
			if (teamId) {
				await this.props.appStore.surveyResultsStore.getSurveyResults(teamId);
			}
		}
	}

	componentWillUnmount(): void {
		this.props.appStore.surveyResultsStore.clearRecommendationsPdfTimeout();
	}

	render() {
		const contextStore = this.props.appStore.contextStore;
		const teamId = contextStore.contextModel.teamModel.teamId;
		const canViewRecommendationsResults = authorizableActionsContains(contextStore, [AuthorizableActions.CanViewRecommendationsResults]);
		const canViewSummaryResults = authorizableActionsContains(contextStore, [AuthorizableActions.CanViewSummaryResults]);
		const canViewMicroHabitResults = authorizableActionsContains(contextStore, [AuthorizableActions.CanViewMicroHabitResults]);
		const isTeamLeadeOrAdmin = this.props.appStore.surveyResultsStore.isCurrentUserTeamLeader || this.props.appStore.userStore.isAdmin;
		const navItems = getSurveyResultsNavigationItems(
			this.props.appStore.translationStore,
			teamId,
			canViewRecommendationsResults,
			canViewSummaryResults,
			canViewMicroHabitResults,
			isTeamLeadeOrAdmin,
		);
		return (
			<React.Fragment>
				<PublicPageHeader title={this.props.appStore.translationStore.translate("Team Results")} navigationItems={navItems} />
				{this.props.children}
			</React.Fragment>
		);
	}
}

export default withRouter(PublicSurveyResultsWrapper);
