import * as React from "react";
import {guard, isLoggedIn} from "../../../common/guard";
import PublicSurveyResultsContainer from "../survey.results.wrapper";
import PublicSurveyResultsSummary from "./survey.results.summary";

@guard(isLoggedIn)
export default class PublicSurveyResultsSummaryContainer extends React.Component {
	render() {
		return (
			<div className="mt--page">
				<PublicSurveyResultsContainer>
					<PublicSurveyResultsSummary />
				</PublicSurveyResultsContainer>
			</div>
		);
	}
}
