import React from "react";
import {injectAppStore} from "../../common/app.store.consumer";
import {AppStore} from "../../stores/app.store";
import {confirm} from "devextreme/ui/dialog";
import {ParticipantEmailStatus, ParticipantSurveyStatus} from "../../common/webapicall";

interface Props {
	appStore?: AppStore;
	teamSurveyInvitationStatus: ParticipantEmailStatus | null;
	teamSurveyReminderStatus: ParticipantEmailStatus | null;
	participantSurveyStatus: ParticipantSurveyStatus | null;
	teamId: string;
	participantId: string;
	refreshGrid: () => void;
}

const PublicParticipantsListEmailStatus = injectAppStore()(
	({appStore, teamId, participantId, teamSurveyInvitationStatus, teamSurveyReminderStatus, participantSurveyStatus, refreshGrid}: Props) => {
		const resendSurveyInvitation = async () => {
			let result = await confirm(
				`<div>${appStore.translationStore.translate("Are you sure you want to {{status}} the diagnostic invitation?", {
					status: getSendOrResendText(teamSurveyInvitationStatus),
				})}</div>`,
				appStore.translationStore.translate("{{status}} Diagnostic Invitation", {status: getSendOrResendText(teamSurveyInvitationStatus)}),
			);
			if (result) {
				await appStore.emailStore.resendSurveyInvitation(teamId, participantId);
				refreshGrid();
			}
		};

		const resendSurveyReminder = async () => {
			let result = await confirm(
				`<div>${appStore.translationStore.translate("Are you sure you want to {{status}} the diagnostic reminder?", {
					status: getSendOrResendText(teamSurveyReminderStatus),
				})}</div>`,
				appStore.translationStore.translate("{{status}} Diagnostic Reminder", {status: getSendOrResendText(teamSurveyReminderStatus)}),
			);
			if (result) {
				await appStore.emailStore.resendSurveyReminder(teamId, participantId);
				refreshGrid();
			}
		};

		const getEmailStatusTextReminder = (status: ParticipantEmailStatus) => {
			if (status == null) return appStore.translationStore.translate("Reminder Not sent");

			switch (status) {
				case ParticipantEmailStatus[ParticipantEmailStatus[ParticipantEmailStatus.Failed]]:
					return appStore.translationStore.translate("Reminder Failed");
				case ParticipantEmailStatus[ParticipantEmailStatus[ParticipantEmailStatus.InProgress]]:
					return appStore.translationStore.translate("Reminder In Progress");
				case ParticipantEmailStatus[ParticipantEmailStatus[ParticipantEmailStatus.Sent]]:
					return appStore.translationStore.translate("Reminder Sent");
			}
		};

		const getEmailStatusTextInvitation = (status: ParticipantEmailStatus) => {
			if (status == null) return appStore.translationStore.translate("Invitation Not sent");

			switch (status) {
				case ParticipantEmailStatus[ParticipantEmailStatus[ParticipantEmailStatus.Failed]]:
					return appStore.translationStore.translate("Invitation Failed");
				case ParticipantEmailStatus[ParticipantEmailStatus[ParticipantEmailStatus.InProgress]]:
					return appStore.translationStore.translate("Invitation In Progress");
				case ParticipantEmailStatus[ParticipantEmailStatus[ParticipantEmailStatus.Sent]]:
					return appStore.translationStore.translate("Invitation Sent");
			}
		};

		const getSendOrResendText = (status: ParticipantEmailStatus) => {
			return status === null ? appStore.translationStore.translate("Send") : appStore.translationStore.translate("Resend");
		};

		const isDisabled = (status: ParticipantEmailStatus, surveyStatus: ParticipantSurveyStatus) => {
			return status === ParticipantEmailStatus.InProgress || surveyStatus === ParticipantSurveyStatus.Completed;
		};

		return (
			<>
				<div className="mt--email-stats">
					<div className="mt--email-stat">{getEmailStatusTextInvitation(teamSurveyInvitationStatus)}</div>
					{participantSurveyStatus !== ParticipantSurveyStatus.Completed && (
						<button
							className="mt--btn btn-med wide resend"
							disabled={isDisabled(teamSurveyInvitationStatus, participantSurveyStatus)}
							onClick={resendSurveyInvitation}
						>
							{getSendOrResendText(teamSurveyInvitationStatus)} {appStore.translationStore.translate("Invitation")}
						</button>
					)}
				</div>
				<div className="mt--email-stats">
					<div className="mt--email-stat">{getEmailStatusTextReminder(teamSurveyInvitationStatus)}</div>
					{participantSurveyStatus !== ParticipantSurveyStatus.Completed && (
						<button
							className="mt--btn btn-med wide resend"
							disabled={isDisabled(teamSurveyReminderStatus, participantSurveyStatus)}
							onClick={resendSurveyReminder}
						>
							{getSendOrResendText(teamSurveyReminderStatus)} {appStore.translationStore.translate("Reminder")}
						</button>
					)}
				</div>
			</>
		);
	},
);

export default PublicParticipantsListEmailStatus;
