import React from "react";
import {injectAppStore} from "../../common/app.store.consumer";
import {AppStore} from "../../stores/app.store";
import DataGrid, {Column, FilterRow, HeaderFilter, Lookup, Pager, Paging, Sorting, StateStoring} from "devextreme-react/data-grid";
import {getDevExtremeDateFormat} from "../../utils/date.utils";
import {Link} from "react-router-dom";
import {AdminMapRoutes, PublicMapRoutes} from "../../stores/site.map";
import {Scrolling} from "devextreme-react/tree-list";
import {AdminTeamListView, TeamStatus} from "../../common/webapicall";
import saveAs from "file-saver";

interface AdminTeamListProps {
	appStore?: AppStore;
	clientId: string;
}

@injectAppStore()
export default class AdminTeamAllList extends React.Component<AdminTeamListProps> {
	private dataGridRef = React.createRef<DataGrid>();

	private teamNameCellRender = (param: {data: AdminTeamListView; value: any}) => {
		return (
			<u>
				<Link to={`${PublicMapRoutes.ParticipantsList}/${param.data.id}`}>{param.value}</Link>
			</u>
		);
	};

	private statusCellRender = (param: {data: AdminTeamListView; value: any; text: string}) => {
		if (param.data.status === TeamStatus.TeamInsightsNotStarted) {
			return param.text;
		}

		return (
			<span
				className="link"
				title="Download Progress Report"
				onClick={async () => {
					const file = await this.props.appStore.apiClient.teamSurveyClient.progressReport(param.data.id);

					if (file) {
						saveAs(file.data, file.fileName);
					}
				}}
			>
				{param.text}
			</span>
		);
	};

	private completionCellRender = (param: {data: AdminTeamListView}) => {
		return (
			<span className="mt--completion-grid-display">
				<span>{param.data.completedParticipants}</span>/<span>{param.data.inProgressParticipants}</span>/
				<span>{param.data.notStartedParticipants}</span>/
				<span>{param.data.completedParticipants + param.data.inProgressParticipants + param.data.notStartedParticipants}</span>
			</span>
		);
	};

	private companyCellRender = (param: {data: AdminTeamListView; value: any}) => {
		return (
			<u>
				<Link to={`${AdminMapRoutes.Teams}/${param.data.clientId}`}>{param.value}</Link>
			</u>
		);
	};

	render() {
		const teams = this.props.appStore.teamStore.getTeamsDataSource(this.props.clientId || "", true);
		const dateFormat = getDevExtremeDateFormat();

		return (
			<React.Fragment>
				<div className="mt--completion-status">
					<span>Completion status: </span>
					<span>Completed </span> /<span>In progress </span> /<span>Not started </span> /<span>Invited </span>
				</div>
				<div className="mt--hide mt--block-title mt--pb-0">List of all teams</div>
				<div className="mt--datagrid">
					<DataGrid
						ref={this.dataGridRef}
						dataSource={teams}
						columnAutoWidth={true}
						showBorders
						showRowLines
						columnHidingEnabled={false}
						allowColumnReordering={false}
						remoteOperations={true}
						wordWrapEnabled
					>
						<Scrolling showScrollbar="always" useNative={false} />
						<Sorting mode="multiple" />
						<FilterRow visible={false} />
						<HeaderFilter visible={true} />
						<StateStoring enabled={true} type="localStorage" storageKey="meta-teams-all" />
						<Paging defaultPageSize={20} />
						<Pager visible={true} showPageSizeSelector={true} allowedPageSizes={[20, 50, 100]} />

						<Column dataField="clientName" caption="Company" cellRender={this.companyCellRender} width={180} />
						<Column dataField="teamName" caption="Team Name" cellRender={this.teamNameCellRender} minWidth={175} />
						<Column dataField="teamLeadersFullName" caption="Team Leaders" width={240} />
						<Column dataField="participants" alignment="center" caption="No." width={110}>
							<HeaderFilter dataSource={this.props.appStore.teamStore.getTeamParticipantsDataSource} />
						</Column>
						<Column
							dataField="completion"
							caption="Completion"
							cellRender={this.completionCellRender}
							minWidth={110}
							alignment="center"
							allowFiltering={false}
							allowSorting={false}
						/>

						<Column dataField="assessmentStartDate" width={150} alignment="center" caption="Start Date" dataType="date" format={dateFormat} />
						<Column dataField="assessmentDeadline" width={150} alignment="center" caption="Deadline" dataType="date" format={dateFormat} />
						<Column dataField="status" caption="Status" name="statusText" width={120} cellRender={this.statusCellRender}>
							<Lookup dataSource={this.props.appStore.teamStore.getTeamStatusDataSource} displayExpr={"text"} valueExpr={"value"} />
						</Column>
						<Column dataField="purchased" caption="Paid?" width={90}>
							<Lookup dataSource={this.props.appStore.teamStore.getPaymentStatuses} displayExpr={"name"} valueExpr={"value"} />
						</Column>
					</DataGrid>
				</div>
			</React.Fragment>
		);
	}
}
