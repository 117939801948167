import DataSource from "devextreme/data/data_source";
import {observable, action, runInAction, makeObservable} from "mobx";
import {IApiClient} from "../common/api.client";
import {DropdownDto} from "../common/webapicall";
import {WebErrorMessage} from "./weberrormessage";
import {StoreBase} from "./store.base";
import { TranslationStore } from "./translation/translation.store";

export class ClientStore extends StoreBase {
	@observable clientsDropdown: DropdownDto[] = [];
	@observable rolesDropdown: DropdownDto[] = [];
	@observable allRolesDropdown: DropdownDto[] = [];
	@observable currentClientId: string;

	constructor(private apiClient: IApiClient, private translation: TranslationStore) {
		super(apiClient);
		makeObservable(this);
	}

	@action
	async getClientsForDropdown() {
		const response = await this.apiClient.clientClient.getClientsForDropdown();
		runInAction(() => {
			if (response) {
				this.clientsDropdown = response;
			}
		});
	}

	@action
	async getRolesForDropdown(clientId: string) {
		const response = await this.apiClient.clientClient.getRolesForNewClient(clientId);
		runInAction(() => {
			if (response) {
				this.rolesDropdown = response;
			}
		});
	}

	@action
	async getAllRolesForDropdown() {
		const response = await this.apiClient.clientClient.getRoles();
		runInAction(() => {
			if (response) {
				this.allRolesDropdown = response;
			}
		});
	}

	@action
	setCurrentClientId(clientId: string) {
		this.currentClientId = clientId;
	}

	getClientName(clientId: string): string {
		return this.clientsDropdown.find(dd => dd.value.toLowerCase() === clientId)?.name;
	}

	getRoleName(roleId: string): string {
		return this.allRolesDropdown.find(dd => dd.value.toLowerCase() === roleId?.toLowerCase())?.name;
	}

	getClientsDataSource(clientId: string) {
		return new DataSource({
			key: "id",
			load: async loadOptions => {
				return await this.apiClient.clientClient.getClients(clientId, loadOptions);
			},
			insert: async values => {
				try {
					return await this.apiClient.clientClient.addClient(values);
				} catch (error) {
					if (error.isWebApiErrorResponse && error.statusCode === 400) {
						throw new Error(WebErrorMessage.TranslateBadRequest(this.translation));
					} else {
						throw error;
					}
				}
			},
			update: async (key, values) => {
				try {
					return await this.apiClient.clientClient.updateClient(key, {id: key, ...values});
				} catch (error) {
					if (error.isWebApiErrorResponse && error.statusCode === 400) {
						throw new Error(WebErrorMessage.TranslateBadRequest(this.translation));
					} else {
						throw error;
					}
				}
			},
			remove: async key => {
				try {
					return await this.apiClient.clientClient.deleteClient(key);
				} catch (error) {
					if (error.isWebApiErrorResponse && error.statusCode === 400) {
						throw new Error(WebErrorMessage.TranslateBadRequest(this.translation));
					} else {
						throw error;
					}
				}
			}
		});
	}

	get getNumberOfTeamFilters() {
		return [
			{
				text: this.translation.translate("has teams"),
				value: ["numberOfTeams", ">", 0],
			},
			{
				text: this.translation.translate("no teams"),
				value: ["numberOfTeams", "=", 0],
			},
		];
	}
}
