import * as React from "react";
import {TopHeader} from "./top.header";
import {Switch, Route} from "react-router-dom";
import {RouteComponentProps, StaticContext, withRouter, Redirect} from "react-router";
import {AppStore} from "../stores/app.store";
import {injectAppStore} from "./app.store.consumer";
import {MainBlank} from "./blank";
import {NotFound} from "./not.found";
import {LoginState, Login} from "../login/login";
import {UserDropdown} from "../user-dropdown/user.dropdown";
import {AdminDebugPanel} from "../admin/debug-panel/debug.panel";
import {PublicDebugPanel} from "../public/debug-panel/debug.panel";
import AdminNavigation from "../admin/navigation/navigation";
import AdminFooter from "../admin/footer/footer";
import {AuthorizableActions} from "./webapicall";
import PublicNavigation from "../public/navigation/navigation";
import PublicFooter from "../public/footer/footer";
import {SiteMapRoutes} from "../stores/site.map";
import PublicSurveyResultsSummaryContainer from "../public/survey-results/summary/survey.results.summary.container";
import AdminTeam from "../admin/team/team";
import AdminTeamDetailsOverview from "../admin/team-details/overview/team.details.overview";
import AdminTeamDetailsResultsRecommendations from "../admin/team-details/results/team.details.results.recommendations";
import AdminTeamDetailsResultsSummary from "../admin/team-details/results/team.details.results.summary";
import AdminTeamDetailsResultsMicroHabits from "../admin/team-details/results/team.details.results.micro.habits";
import AdminTeamDetailsResultsTeamJourney from "../admin/team-details/results/team.details.results.team.journey";
import AdminTeamAll from "../admin/team-all/team.all";

import PublicHome from "../public/home/home";
import PublicParticipantsHowTo from "../public/participants/participants.howto";
import PublicSurveyHowTo from "../public/survey/survey.howto";
import PublicSurveyResultsRecommendationsContainer from "../public/survey-results/recommendations/survey.results.recommendations.container";
import PublicSurveyResultsMicroHabitsContainer from "../public/survey-results/micro-habits/survey.results.micro.habits.container";
import PublicSurveyResultsTeamJourneyContainer from "../public/survey-results/team-journey/survey.results.team.journey.container";
import PublicParticipantsList from "../public/participants/participants.list";
import PublicSurvey from "../public/survey/survey";
import PublicWrapper from "../public/wrapper/public.wrapper";
import AdminWrapper from "../admin/wrapper/admin.wrapper";
import AdminClients from "../admin/clients/clients";
import PublicSurveyResultsRecommendationsPdf from "../public/survey-results/recommendations/survey.results.recommendations.pdf";
import ProtectedPageWithoutMenu from "./protected.page.without.menu";
import dashboard from "../admin/dashboard/dashboard";
import Reports from "../admin/report/reports";
import AdminUsers from "../admin/adminuser/adminuser";
import {defaultLangCode} from "../i18n";
import {flowResult} from "mobx";

const Home = React.lazy(() => import("../home/home"));

interface ProtectedPageProps extends RouteComponentProps<any, StaticContext, any> {
	appStore?: AppStore;
	authorizableActions?: AuthorizableActions[];
	component: React.ExoticComponent | React.ComponentType;
	isAdminPage: boolean;
}

interface ProtectedPageState {
	initialized: boolean;
	pathname: string;
	redirect: boolean;
}

@injectAppStore()
class ProtectedPageWithoutRouter extends React.Component<ProtectedPageProps, ProtectedPageState> {
	state: ProtectedPageState = {
		initialized: false,
		pathname: null,
		redirect: false,
	};

	private _prevComponent: JSX.Element = null;

	async componentDidMount() {
		document.title = "Meta Team";
		await this.handleUrlChange();
		this.changeLanguage(false);
	}

	private async handleUrlChange() {
		this.setState({
			initialized: false,
			pathname: this.props.location.pathname,
			redirect: false,
		});

		const teamIdFromParam = this.props.match.params.teamId;
		const pSurveyIdFromParam = this.props.match.params.pSurveyId;

		const teamId = teamIdFromParam !== undefined ? teamIdFromParam : null;
		const pSurveyId = pSurveyIdFromParam !== undefined ? pSurveyIdFromParam : null;
		const contextStore = this.props.appStore.contextStore;

		// save last visited team id
		if (teamId !== null && teamId !== contextStore.contextModel?.teamModel.teamId) {
			await this.props.appStore.userStore.saveLastVisitedId(teamId);
		}

		if (this.props.authorizableActions) {
			contextStore
				.loadContext(teamId, pSurveyId)
				.then(() => {
					if (!this.props.authorizableActions.every(aa => contextStore.contextModel.authorizedActions.includes(aa))) {
						this.setState({redirect: true});
					}
				})
				.catch(() => this.setState({redirect: true}));
		}

		this.setState({
			initialized: true,
			pathname: this.props.location.pathname,
		});
		window.scrollTo(0, 0);
	}

	static getDerivedStateFromProps(nextProps: Readonly<ProtectedPageProps>, prevState: Readonly<ProtectedPageState>): ProtectedPageState {
		if (nextProps.location.pathname !== prevState.pathname) {
			return {
				initialized: false,
				pathname: nextProps.location.pathname,
				redirect: false,
			};
		} else {
			return null;
		}
	}

	async componentDidUpdate(prevProps: ProtectedPageProps) {
		if (prevProps.location.pathname !== this.props.location.pathname) {
			await this.handleUrlChange();
			this.changeLanguage(true);
		}
	}

	private async changeLanguage(onUpdate: boolean) {		
		const currentLang = this.props.appStore.translationStore.getLanguageCode;
		this.props.appStore.translationStore.setForceDefaultLanguage(this.props.isAdminPage);

		// There is no workaround for onthefly locale change without page reload
		// https://supportcenter.devexpress.com/ticket/details/t1168621/re-is-it-possible-to-change-the-current-locale-at-runtime-without-page-reloading
		if (onUpdate && currentLang !== this.props.appStore.translationStore.getLanguageCode) {
			window.location.reload();
		}

		if (this.props.isAdminPage) {
			await flowResult(this.props.appStore.translationStore.Load(defaultLangCode, "Default"));
		} else {
			await flowResult(this.props.appStore.translationStore.Load(this.props.appStore.translationStore.selectedLanguage, "Selected"));
		}

		await this.props.appStore.translationStore.changeLanguage();	
	}

	render() {
		let children: JSX.Element = null;
		let Component = this.props.component;
		if (this.state.initialized) {
			if (this.state.redirect) {
				children = (
					<React.Fragment>
						{alert(this.props.appStore.translationStore.translate("Invalid url! You will be redirected to your team's page."))}
						<Redirect to={SiteMapRoutes.Public} />
					</React.Fragment>
				);
			} else {
				children = <Component />;
				this._prevComponent = children;
			}
		} else {
			children = this._prevComponent ? this._prevComponent : <MainBlank />;
		}

		if (this.props.isAdminPage) {
			return (
				<React.Fragment>
					<AdminDebugPanel />
					<UserDropdown />
					<AdminWrapper>
						<TopHeader />
						<AdminNavigation />
						{children}
						<AdminFooter />
					</AdminWrapper>
				</React.Fragment>
			);
		} else {
			return (
				<React.Fragment>
					<PublicDebugPanel />
					<UserDropdown />
					<PublicWrapper>
						<TopHeader />
						<PublicNavigation />
						{children}
						<PublicFooter />
					</PublicWrapper>
				</React.Fragment>
			);
		}
	}
}

const ProtectedPage = withRouter(ProtectedPageWithoutRouter);

/**
 * Wraps protected element with header and footer.
 * @param Component The component that is the main component of the page.
 */
const renderProtectedElement = (
	Component: React.ExoticComponent | React.ComponentType,
	isAdminPage?: boolean,
	authorizableActions?: AuthorizableActions[],
	renderWithoutMenu?: boolean,
) => {
	if (renderWithoutMenu) {
		return () => {
			return <ProtectedPageWithoutMenu component={Component} authorizableActions={authorizableActions} />;
		};
	}

	return () => {
		return <ProtectedPage component={Component} isAdminPage={isAdminPage ?? false} authorizableActions={authorizableActions} />;
	};
};

const renderAdminElement = (Component: React.ExoticComponent | React.ComponentType, authorizableActions?: AuthorizableActions[]) => {
	return renderProtectedElement(Component, true, authorizableActions);
};

const renderPublicElement = (
	Component: React.ExoticComponent | React.ComponentType,
	authorizableActions?: AuthorizableActions[],
	renderWithoutMenu?: boolean,
) => {
	return renderProtectedElement(Component, false, authorizableActions, renderWithoutMenu);
};

const getLoginComponent = (state: LoginState) => {
	return () => <Login initialState={state} />;
};

export const AdminRoutes = (appStore: AppStore, baseUrl: string) => {
	return (
		<Switch>
			<Route exact path={`${baseUrl}/${SiteMapRoutes.TeamDetailsOverview}/:teamId`} render={renderAdminElement(AdminTeamDetailsOverview)} />
			<Route
				exact
				path={`${baseUrl}/${SiteMapRoutes.TeamDetailsResultsRecommendations}/:teamId`}
				render={renderAdminElement(AdminTeamDetailsResultsRecommendations, [AuthorizableActions.CanViewRecommendationsResults])}
			/>
			<Route
				exact
				path={`${baseUrl}/${SiteMapRoutes.TeamDetailsResultsSummary}/:teamId`}
				render={renderAdminElement(AdminTeamDetailsResultsSummary, [AuthorizableActions.CanViewSummaryResults])}
			/>
			<Route
				exact
				path={`${baseUrl}/${SiteMapRoutes.TeamDetailsResultsMicroHabits}/:teamId`}
				render={renderAdminElement(AdminTeamDetailsResultsMicroHabits, [AuthorizableActions.CanViewMicroHabitResults])}
			/>
			<Route
				exact
				path={`${baseUrl}/${SiteMapRoutes.TeamDetailsResultsTeamJourney}/:teamId`}
				render={renderAdminElement(AdminTeamDetailsResultsTeamJourney, [AuthorizableActions.CanViewRecommendationsResults])}
			/>

			<Route
				exact
				path={`${baseUrl}/${SiteMapRoutes.Teams}`}
				render={() => <Redirect to={`${SiteMapRoutes.Teams}/${appStore.clientStore.currentClientId ?? appStore.userStore.clientId}`} />}
			/>
			<Route exact path={`${baseUrl}/${SiteMapRoutes.Teams}/:clientId`} render={renderAdminElement(AdminTeam)} />
			<Route exact path={`${baseUrl}/${SiteMapRoutes.Teams}/:clientId/:forceNew`} render={renderAdminElement(AdminTeam)} />

			<Route
				exact
				path={`${baseUrl}/${SiteMapRoutes.TeamsAll}`}
				render={() => <Redirect to={`${SiteMapRoutes.TeamsAll}/${appStore.userStore.clientId}`} />}
			/>
			<Route exact path={`${baseUrl}/${SiteMapRoutes.TeamsAll}/:clientId`} render={renderAdminElement(AdminTeamAll)} />

			<Route
				exact
				path={`${baseUrl}/${SiteMapRoutes.Dashboard}`}
				render={() => <Redirect to={`${SiteMapRoutes.Dashboard}/${appStore.clientStore.currentClientId ?? appStore.userStore.clientId}`} />}
			/>
			<Route exact path={`${baseUrl}/${SiteMapRoutes.Dashboard}/:clientId`} render={renderAdminElement(dashboard)} />

			<Route
				exact
				path={`${baseUrl}/${SiteMapRoutes.Clients}`}
				render={() => <Redirect to={`${SiteMapRoutes.Clients}/${appStore.clientStore.currentClientId ?? appStore.userStore.clientId}`} />}
			/>
			<Route exact path={`${baseUrl}/${SiteMapRoutes.Clients}/:clientId`} render={renderAdminElement(AdminClients)} />
			<Route exact path={`${baseUrl}/${SiteMapRoutes.Clients}/:clientId/:forceNew`} render={renderAdminElement(AdminClients)} />

			<Route
				exact
				path={`${baseUrl}/${SiteMapRoutes.AdminUsers}`}
				render={() => <Redirect to={`${SiteMapRoutes.AdminUsers}/${appStore.clientStore.currentClientId ?? appStore.userStore.clientId}`} />}
			/>
			<Route exact path={`${baseUrl}/${SiteMapRoutes.AdminUsers}/:clientId`} render={renderAdminElement(AdminUsers)} />

			<Route
				exact
				path={`${baseUrl}/${SiteMapRoutes.Report}`}
				render={() => <Redirect to={`${SiteMapRoutes.Report}/${appStore.clientStore.currentClientId ?? appStore.userStore.clientId}`} />}
			/>
			<Route exact path={`${baseUrl}/${SiteMapRoutes.Report}/:clientId`} render={renderAdminElement(Reports)} />
		</Switch>
	);
};

export const PublicRoutes = (baseUrl: string) => {
	return (
		<Switch>
			<Route exact path={`${baseUrl}/`} render={renderPublicElement(PublicHome)} />
			<Route
				exact
				path={`${baseUrl}/${SiteMapRoutes.ParticipantsHowTo}/:teamId`}
				render={renderPublicElement(PublicParticipantsHowTo, [AuthorizableActions.CanViewParticipants])}
			/>
			<Route
				exact
				path={`${baseUrl}/${SiteMapRoutes.ParticipantsList}/:teamId`}
				render={renderPublicElement(PublicParticipantsList, [AuthorizableActions.CanViewParticipants])}
			/>
			<Route
				exact
				path={`${baseUrl}/${SiteMapRoutes.DiagnosticHowTo}/:pSurveyId`}
				render={renderPublicElement(PublicSurveyHowTo, [AuthorizableActions.CanViewTeamSurvey])}
			/>
			<Route
				exact
				path={`${baseUrl}/${SiteMapRoutes.Diagnostic}/:pSurveyId`}
				render={renderPublicElement(PublicSurvey, [AuthorizableActions.CanViewTeamSurvey])}
			/>
			<Route
				exact
				path={`${baseUrl}/${SiteMapRoutes.DiagnosticResultsRecommendations}/:teamId`}
				render={renderPublicElement(PublicSurveyResultsRecommendationsContainer, [AuthorizableActions.CanViewRecommendationsResults])}
			/>
			<Route
				exact
				path={`${baseUrl}/${SiteMapRoutes.DiagnosticResultsRecommendations}/pdf/:teamId/:language`}
				render={renderPublicElement(PublicSurveyResultsRecommendationsPdf, [AuthorizableActions.CanViewRecommendationsResults], true)}
			/>
			<Route
				exact
				path={`${baseUrl}/${SiteMapRoutes.DiagnosticResultsSummary}/:teamId`}
				render={renderPublicElement(PublicSurveyResultsSummaryContainer, [AuthorizableActions.CanViewSummaryResults])}
			/>
			<Route
				exact
				path={`${baseUrl}/${SiteMapRoutes.DiagnosticResultsMicroHabits}/:teamId`}
				render={renderPublicElement(PublicSurveyResultsMicroHabitsContainer, [AuthorizableActions.CanViewMicroHabitResults])}
			/>
			<Route
				exact
				path={`${baseUrl}/${SiteMapRoutes.DiagnosticResultsTeamJourney}/:teamId`}
				render={renderPublicElement(PublicSurveyResultsTeamJourneyContainer, [AuthorizableActions.CanViewRecommendationsResults])}
			/>
		</Switch>
	);
};

/** Defines the routes of the application. */
export const Routes = injectAppStore()((props: {appStore?: AppStore}) => {
	return (
		<Switch>
			<Route exact path="/" render={renderProtectedElement(Home)} />
			<Route path={SiteMapRoutes.Admin} render={({match}) => AdminRoutes(props.appStore, match.url)} />
			<Route path={SiteMapRoutes.Public} render={({match}) => PublicRoutes(match.url)} />
			<Route path="/not-found" component={NotFound} />
			<Route path="/login/:redirectUrl?" component={getLoginComponent(LoginState.Default)} />
			<Route path="/session-expired/:redirectUrl?" component={getLoginComponent(LoginState.SessionExpired)} />
			<Route path="/access-link-expired/:redirectUrl?" component={getLoginComponent(LoginState.AccessLinkExpired)} />
			<Route path="/forced-logout/:redirectUrl?" component={getLoginComponent(LoginState.ForcedLogout)} />
			<Route path="/access-link-not-found/:redirectUrl?" component={getLoginComponent(LoginState.AccessLinkNotFound)} />
			<Route path="/access-denied/:redirectUrl?" component={getLoginComponent(LoginState.AccessDenied)} />
			<Redirect to="/not-found" />
		</Switch>
	);
});
