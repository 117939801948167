import React from "react";
import {injectAppStore} from "../../common/app.store.consumer";
import {AppStore} from "../../stores/app.store";
import {guard, isAdmin} from "../../common/guard";
import AdminPageHeader from "../page-header/page.header";
import {RouteComponentProps, withRouter} from "react-router";
import {ClientsTile} from "./clients.tile";
import {TeamsTile} from "./teams.tile";
import {ReportsTile} from "./reports.tile";
import {ManageTile} from "./manage.tile";

type IProps = {appStore?: AppStore} & RouteComponentProps<{clientId: string}>;

@guard(isAdmin)
@injectAppStore()
class Dashboard extends React.Component<IProps> {
	async componentDidMount() {
		this.props.appStore.clientStore.setCurrentClientId(this.props.match.params.clientId);

		this.props.appStore.dashboardStore.getDashboardInfo(this.props.match?.params?.clientId);
		this.props.appStore.dashboardStore.getBreadCrumbInfo(this.props.match?.params?.clientId);
	}

	async componentDidUpdate(prevProps: Readonly<IProps>) {
		if (prevProps.match.params.clientId !== this.props.match?.params?.clientId) {
			this.props.appStore.clientStore.setCurrentClientId(this.props.match.params.clientId);
			this.props.appStore.dashboardStore.getDashboardInfo(this.props.match?.params?.clientId);
			this.props.appStore.dashboardStore.getBreadCrumbInfo(this.props.match?.params?.clientId);
		}
	}

	render() {
		return (
			<>
				<AdminPageHeader clientId={this.props.match.params.clientId} hasSimpleHeader title={`Dashboard`} />
				<div className="mt--area-wrap mt--fill-white">
					<div className="mt--layout mt--dashboard mt--min-height">
						<div className="mt--content">
							<div className="dashboard-tiles-wrap">
								<ClientsTile
									count={this.props.appStore.dashboardStore.dashboardInfo.clientCount}
									clientId={this.props.match?.params?.clientId || ""}
								></ClientsTile>
								<TeamsTile
									count={this.props.appStore.dashboardStore.dashboardInfo.teamCount}
									clientId={this.props.match?.params?.clientId || ""}
								></TeamsTile>
								<ReportsTile></ReportsTile>
								<ManageTile clientId={this.props.match.params.clientId || ""}></ManageTile>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withRouter(Dashboard);
