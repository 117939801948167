import React from "react";
import {guard, isAdmin} from "../../common/guard";
import {NavLink, Link, withRouter} from "react-router-dom";
import {injectAppStore} from "../../common/app.store.consumer";
import {AppStore} from "../../stores/app.store";
import {PublicMapRoutes, AdminMapRoutes} from "../../stores/site.map";
import imageMetaTeamLogo from "../../assets/img/logo_metateam.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import OutsideClick from "../../common/outside.click";
import * as H from "history";
import {RouteComponentProps} from "react-router";
import {BreadCrumb} from "./breadcrumb";
import Profile from "../../common/profile/profile";

type Props = {appStore?: AppStore; location: H.Location} & RouteComponentProps<{teamId: string}>;

interface IState {
	isOpen: boolean;
}

@injectAppStore()
@guard(isAdmin)
class AdminNavigation extends React.Component<Props, IState> {
	state = {isOpen: false};

	private toggleNavigationMenu = () => {
		this.setState({isOpen: !this.state.isOpen});
	};

	private closeNavigationMenu = () => {
		this.setState({isOpen: false});
	};

	private getMenuToggleClasses = (classes: string[]): string => {
		const className = this.state.isOpen ? "d-expanded" : "d-collapsed";

		classes.push(className);
		return classes.join(" ");
	};

	private logout = () => {
		this.props.appStore.userStore.logout();
	};

	private navigateToPublic = () => {
		this.props.appStore.surveyResultsStore.isLoaded = false;
		const defaultTeamId = this.props.appStore.userStore.defaultTeamId;
		if (defaultTeamId !== null) {
			this.props.appStore.contextStore.loadContext(defaultTeamId);
		}
		this.props.history.push(PublicMapRoutes.Home);
	};

	render() {
		const hasTeams = this.props.appStore.userStore.hasMoreTeam;

		return (
			<OutsideClick handleOutsideClick={this.closeNavigationMenu}>
				<div className="mt--area-wrap mt--fill-white">
					<div className="mt--layout padding-header">
						<div className="mt--navbar navbar-admin">
							<div className="mt--flex mt--top-navbar">
								<div className="mt--logo-wrap logo-admin">
									<Link className="mt--navbar-brand" to={`${AdminMapRoutes.Clients}/${this.props.appStore.userStore.clientId}`}>
										<img src={imageMetaTeamLogo} alt="meta-team-logo" width="175" className="mt--align-top" />
									</Link>
								</div>
								<button className="mt--navbar-toggler mobile" type="button" data-target="#navbarNav" onClick={() => this.toggleNavigationMenu()}>
									<div className={this.getMenuToggleClasses(["mt--hamburger mt--navbar-user"])}>
										<FontAwesomeIcon icon={["fas", "bars"]} />
										<div className={this.getMenuToggleClasses(["mt--navbar-collapse mobile"])} id="mobileNavbarNav">
											<ul className="mt--navigation">
												{hasTeams ? (
													<li>
														<NavLink
															className="link"
															exact
															activeClassName="active"
															onClick={this.closeNavigationMenu}
															to={PublicMapRoutes.Home}
														>
															<FontAwesomeIcon icon={["far", "users"]} />
															My Teams
														</NavLink>
													</li>
												) : null}
												<li>
													<NavLink
														exact
														className="link"
														activeClassName="active"
														onClick={this.closeNavigationMenu}
														to={AdminMapRoutes.TeamsAll}
													>
														<FontAwesomeIcon icon={["far", "chart-network"]} />
														All Teams
													</NavLink>
												</li>
												<li>
													<div className="link" onClick={this.logout}>
														<FontAwesomeIcon icon={["far", "arrow-to-right"]} />
														Logout
													</div>
												</li>
											</ul>
										</div>
									</div>
								</button>
							</div>
							<div className={this.getMenuToggleClasses(["mt--navbar-collapse"])} id="navbarNav">
								{/* DEBUG ONLY this navigation must be removed later */}
								{/* <ul className="mt--navbar-nav mt--flex mt--flex-col">
									<ul className="mt--nav-item">
										<div className="mt--btn-corner active">Team Details</div>
									</ul>
									<li className="mt--nav-item">
										<NavLink className="mt--btn-corner" to={AdminMapRoutes.Teams} onClick={this.closeNavigationMenu}>
											All Teams
										</NavLink>
									</li>
									<li className="mt--nav-item">
										<NavLink className="mt--btn-corner" to={AdminMapRoutes.Dashboard} onClick={this.closeNavigationMenu}>
											Dashboard
										</NavLink>
									</li>
									<li className="mt--nav-item">
										<NavLink className="mt--btn-corner" to={AdminMapRoutes.Clients} onClick={this.closeNavigationMenu}>
											Clients
										</NavLink>
									</li>
									{this.props.appStore.userStore.defaultTeamId !== null && (
										<li className="mt--nav-item">
											<div className="mt--btn-corner" onClick={this.navigateToPublic}>
												{hasTeams ? "Go to My Teams" : "Go to My Team"}
											</div>
										</li>
									)}
								</ul> */}
							</div>


							<Profile />


							<button
								className={this.getMenuToggleClasses(["mt--navbar-user"])}
								type="button"
								data-target="#desktopNavbarNav"
								onClick={() => this.toggleNavigationMenu()}
							>
								<div>
									<FontAwesomeIcon icon={["fas", "bars"]} />
								</div>
								<div className={this.getMenuToggleClasses(["mt--navbar-collapse"])} id="desktopNavbarNav">
									<ul className="mt--navigation">
										{hasTeams ? (
											<li>
												<NavLink
													className="link"
													exact
													activeClassName="active"
													onClick={this.closeNavigationMenu}
													to={PublicMapRoutes.Home}
												>
													<FontAwesomeIcon icon={["far", "users"]} />
													My Teams
												</NavLink>
											</li>
										) : null}
										<li>
											<NavLink
												exact
												className="link"
												activeClassName="active"
												onClick={this.closeNavigationMenu}
												to={AdminMapRoutes.TeamsAll}
											>
												<FontAwesomeIcon icon={["far", "chart-network"]} />
												All Teams
											</NavLink>
										</li>
										<li>
											<div className="link" onClick={this.logout}>
												<FontAwesomeIcon icon={["far", "arrow-to-right"]} />
												Logout
											</div>
										</li>
									</ul>
								</div>
							</button>
						</div>
					</div>
				</div>
				<div className="mt--fill-bgreen">
					<div className="mt--layout mt--area-wrap mt--breadcrumb-area">
						<BreadCrumb></BreadCrumb>
					</div>
				</div>
			</OutsideClick>
		);
	}
}

export default withRouter(AdminNavigation);
