import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SelectBox from "devextreme-react/select-box";
import {flowResult} from "mobx";
import * as React from "react";
import {useEffect} from "react";
import {RouteComponentProps, withRouter} from "react-router";
import * as Yup from "yup";
import imageMetaTeamLogo from "../assets/img/logo_metateam.svg";
import imageFooterLogo from "../assets/img/logo_on_blue.svg";
import {injectAppStore} from "../common/app.store.consumer";
import {ValidatableForm, ValidatableInput, ValidationMessage} from "../common/validation";
import {AppStore} from "../stores/app.store";

interface loginModel {
	email?: string;
	languageId: string;
}

interface LoginProps {
	appStore?: AppStore;
	initialState: LoginState;
}

export enum LoginState {
	Default,
	SessionExpired,
	AccessLinkExpired,
	ForcedLogout,
	AccessLinkNotFound,
	AccessDenied,
}

type LoginPropsWithRouting = LoginProps & RouteComponentProps<{redirectUrl?: string}>;

const emailModel = Yup.object().shape<loginModel>({
	email: Yup.string().required("Email field is required.").email("Please enter a valid email address."),
	languageId: Yup.string().required("Language field is required."),
});

export const LoginWithoutRouter = injectAppStore()((props: LoginPropsWithRouting) => {
	useEffect(() => {
		props.appStore.userStore.setIsLoginVisible(true);
		return () => props.appStore.userStore.setIsLoginVisible(false);
	}, [props.appStore.userStore]);

	const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		props.appStore.loginStore.setisSuccessfulVisible(false);
		props.appStore.loginStore.setisUnsuccessfulVisible(false);

		let userStore = props.appStore.userStore;
		let res = await userStore.loginByEmail(props.appStore.loginStore.email, props.appStore.loginStore.languageId, "/#/");

		props.appStore.loginStore.setisSuccessful(res);
		props.appStore.loginStore.setisSuccessfulVisible(true);
		props.appStore.loginStore.setisUnsuccessfulVisible(props.appStore.configuration.showLoginUnsuccessfulMessage);

		window.scrollTo(0, 0);
	};

	const onEmailChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		props.appStore.loginStore.setemail(e.target.value);
	};

	const onEmailFocused = () => {
		props.appStore.loginStore.setisSuccessfulVisible(false);
		props.appStore.loginStore.setisUnsuccessfulVisible(false);
		props.appStore.loginStore.sethideInitialState(true);
	};

	const onLanguageInnerChanged = async (lang: string) => {
		props.appStore.loginStore.setlanguageId(lang);

		await flowResult(props.appStore.translationStore.Load(lang, "Selected"));
		props.appStore.translationStore.setSelectedLanguage(lang);
		await props.appStore.translationStore.changeLanguage(lang);
	};

	let loginSuccessfulPart = props.appStore.loginStore.isSuccessful && props.appStore.loginStore.isSuccessfulVisible && (
		<div>
			{props.appStore.translationStore.translate(
				"An email with a single access link has been sent to your email address. If you don't see the email in a few minutes, please check your spam/junk mail folder.",
			)}
		</div>
	);
	let loginUnsuccessfulPart = !props.appStore.loginStore.isSuccessful && props.appStore.loginStore.isUnsuccessfulVisible && (
		<div>
			{props.appStore.translationStore.translate("The email address you have entered is not found.")}
			<br />
			{props.appStore.translationStore.translate("Please contact your Meta Team team representative or enter a different email address.")}
		</div>
	);
	return (
		<div className="mt--login">
			<div className="mt--area-wrap mt--fill-white">
				<div className="mt--layout">
					<div className="mt--flex mt--flex-wrap mt--align-items-center">
						<div className="mt--py-1">
							<img src={imageMetaTeamLogo} alt="meta-team-logo" width="175" className="mt--align-top" />
						</div>
					</div>
				</div>
			</div>
			<div className="mt--area-wrap mt--login-area">
				<div className="mt--layout mt--min-height">
					<div className="mt--flex">
						<div className="col-login">
							<div>
								<div className="mt--title-login">{props.appStore.translationStore.translate("Welcome to Meta Team")}</div>
								<div className="mt--txt">
									<p>
										{props.appStore.translationStore.translate(
											"If your email is registered with Meta Team, the platform will send you an email with a single access link so please make sure to check your mailbox.",
										)}
									</p>
								</div>
							</div>
							<ValidatableForm
								onSubmit={(e: React.FormEvent<HTMLFormElement>) => onSubmit(e)}
								model={{email: props.appStore.loginStore.email, languageId: props.appStore.loginStore.languageId}}
								validateOnBlur={false}
								schema={emailModel}
							>
								{validationResult => (
									<div className="mt--login-form">
										<form>
											<div className="mt--input-wrap mt--dropdown mt--language-selector">
												<span>{props.appStore.translationStore.translate("Preferred Language:")}</span>

												<SelectBox
													dataSource={props.appStore.configuration.availableLanguages.map(s => {
														return {
															Id: s,
															Name: props.appStore.translationStore.translate(s),
														};
													})}
													valueExpr="Id"
													displayExpr="Name"
													placeholder={props.appStore.translationStore.translate("Please select")}
													defaultValue={props.appStore.loginStore.languageId}
													value={props.appStore.loginStore.languageId}
													onValueChanged={e => onLanguageInnerChanged(e.value)}
													searchEnabled={false}
													rtlEnabled={props.appStore.translationStore.isRTL}
													stylingMode="filled"
													width={110}
												/>
											</div>
											<div className="mt--input-wrap mt--dropdown">
												<ValidatableInput
													model={{email: props.appStore.loginStore.email, languageId: props.appStore.loginStore.languageId}}
													fieldName="email"
													onChanged={(e: React.ChangeEvent<HTMLInputElement>) => onEmailChanged(e)}
												>
													<input
														type="text"
														id="email"
														name="email"
														placeholder={props.appStore.translationStore.translate("Enter your email")}
														onFocus={() => onEmailFocused()}
													/>
												</ValidatableInput>

												<button className="mt--btn btn-med mt--my-0">
													<FontAwesomeIcon
														icon={["far", "arrow-right"]}
														flip={props.appStore.translationStore.isRTL ? "horizontal" : null}
													/>
													<div>{props.appStore.translationStore.translate("SEND")}</div>
												</button>
											</div>
										</form>
										<ValidationMessage className="mt--notification-small" fieldName="email" validationResult={validationResult} />
									</div>
								)}
							</ValidatableForm>
							{loginSuccessfulPart && (
								<div className="mt--notification-small success">
									<div>
										<FontAwesomeIcon icon={["fal", "check-circle"]} />
									</div>
									<span>{loginSuccessfulPart}</span>
								</div>
							)}
							{loginUnsuccessfulPart && (
								<div className="mt--notification-small warning">
									<div>
										<FontAwesomeIcon icon={["fal", "times-circle"]} />
									</div>
									<span>{loginUnsuccessfulPart}</span>
								</div>
							)}
						</div>
						<div></div>
					</div>
				</div>
			</div>

			<div className="mt--area-wrap mt--pattern-footer">
				<div className="mt--layout">
					<div className="mt--footer">
						<div>
							<img src={imageFooterLogo} alt="footer" width="175" className="mt-inline-block mt--align-top" />
						</div>
						<div className="txt-legal">
							<span>
								{props.appStore.translationStore.translate("© Meta Team Limited {{CurrentYear}}", {CurrentYear: new Date().getFullYear()})}.
							</span>
							<span>{props.appStore.translationStore.translate("All rights reserved.")}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
});

export const Login = withRouter(LoginWithoutRouter);
