import React from "react";
import {injectAppStore} from "../../../common/app.store.consumer";
import {AppStore} from "../../../stores/app.store";
import PublicSurveyResultsSummaryNavigation from "./survey.results.summary.navigation";
import PublicSurveyResultsSummaryRadar from "./radar/survey.results.summary.radar";
import PublicSurveyResultsSummaryBars from "./survey.results.summary.bars";
import PublicSurveyResultsKeys from "../common/survey.results.keys";

interface PublicSurveyResultsSummaryProps {
	appStore?: AppStore;
}
interface PublicSurveyResultsSummaryStates {
	isRadarOpen: boolean;
}

@injectAppStore()
export default class PublicSurveyResultsSummary extends React.Component<PublicSurveyResultsSummaryProps, PublicSurveyResultsSummaryStates> {
	state = {
		isRadarOpen: true,
	};

	changeSelectedSummaryType = () => {
		this.setState({isRadarOpen: !this.state.isRadarOpen});
	};

	render() {
		return (
			<React.Fragment>
				<div className="mt--area-wrap mt--fill-white mt--break-large">
					<div className="mt--layout">
						<div className="mt--content mt--pb-0">
							<div className={this.state.isRadarOpen ? "mt--2-cols" : "mt--half-width"}>
								<div className="col mt--options-bar bar-with-radios">
									<div>
										<PublicSurveyResultsSummaryNavigation
											changeSummaryType={this.changeSelectedSummaryType}
											isRadarOpen={this.state.isRadarOpen}
										/>
									</div>
									<div>
										<PublicSurveyResultsKeys />
									</div>
								</div>
								<div className="col"></div>
							</div>
						</div>
					</div>
				</div>

				{this.state.isRadarOpen ? <PublicSurveyResultsSummaryRadar /> : <PublicSurveyResultsSummaryBars />}
			</React.Fragment>
		);
	}
}
