import {DateBox} from "devextreme-react/date-box";
import {Popup, ToolbarItem} from "devextreme-react/popup";
import {confirm} from "devextreme/ui/dialog";
import moment, {Moment} from "moment";
import React from "react";
import {injectAppStore} from "../../../common/app.store.consumer";
import {AppStore} from "../../../stores/app.store";
import {getDevExtremeDateFormat, getTeamDetailsDateFormat} from "../../../utils/date.utils";

interface Props {
	appStore?: AppStore;
	isTeamSurveyFinished: boolean;
	isTeamSurveyStarted: boolean;
	deadline: Moment;
	teamId: string;
	isAdminPart?: boolean;
}

interface State {
	isLoading: boolean;
	popupVisible: boolean;
	newDate: Moment | null;
	sendReminders: boolean;
}

@injectAppStore()
export default class AdminTeamDetailsDeadline extends React.Component<Props, State> {
	state = {
		isLoading: false,
		popupVisible: false,
		newDate: null,
		sendReminders: true,
	};

	componentDidMount() {
		this.setState({newDate: this.calculateDefaultValue()});
	}

	private onDeadlineChangeButtonClicked = () => {
		this.setState({popupVisible: true});
	};

	private onModalHiding = () => {
		this.setState({popupVisible: false, newDate: null, sendReminders: true});
	};

	private saveNewDeadline = async () => {
		if (!this.state.newDate) {
			return;
		}

		if (this.props.isTeamSurveyStarted || this.props.isTeamSurveyFinished) {
			const title = this.getTitle();
			let confirmQuestion = this.props.isTeamSurveyFinished
				? this.props.appStore.translationStore.translate(
						"Just checking that you want to reopen the diagnostic as any updated or new entries will change the diagnostic results which may have already been viewed by the team.",
				  )
				: this.props.appStore.translationStore.translate("The new deadline will be changed to {{date}}. Are you sure?", {
						date: getTeamDetailsDateFormat(this.state.newDate),
				  });

			const fullConfirmText = this.props.isTeamSurveyFinished
				? "<div style='min-width: 300px;'>" +
				  this.props.appStore.translationStore.translate("Are you sure?") +
				  "<div class='mt--notification warning'>" +
				  confirmQuestion +
				  "</div></div>"
				: "<div style='min-width: 300px;'>" + confirmQuestion + "</div>";

			let result = await confirm(fullConfirmText, title);

			if (!result) {
				this.onModalHiding();
				return;
			}
		}

		this.setState({isLoading: true});

		this.props.isAdminPart
			? await this.props.appStore.teamStore.changeTeamDeadlineForAdmin(this.props.teamId, this.state.newDate, this.state.sendReminders)
			: await this.props.appStore.feedbackProvidersStore.changeTeamDeadlineForTL(this.props.teamId, this.state.newDate, this.state.sendReminders);
		await this.props.appStore.teamStore.reloadCurrentTeam();
		this.setState({isLoading: false});
		this.onModalHiding();
	};

	private closeButtonOptions = {
		text: this.props.appStore.translationStore.translate("Close"),
		onClick: this.onModalHiding,
	};

	private changeDeadlineButtonOptions = {
		text: this.props.appStore.translationStore.translate("Save"),
		onClick: this.saveNewDeadline,
	};

	private onDateValueChanged = (e: any) => {
		if (e.value) {
			const newDate = moment(e.value);
			newDate.utc(true);
			this.setState({newDate: newDate.startOf("day")});
		} else {
			this.setState({newDate: e.value});
		}
	};

	private onSendRemindersValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({sendReminders: e.target.checked});
	};

	private calculateDefaultValue = () => {
		let defaultDate = this.props.isTeamSurveyFinished ? moment() : this.props.deadline;
		return defaultDate;
	};

	private onShowing = () => {
		if (!this.state.newDate) {
			const defaultDate = this.calculateDefaultValue();
			this.setState({newDate: defaultDate});
		}
	};

	private getTitle(): string {
		let title = this.props.appStore.translationStore.translate("Set Diagnostic Deadline");

		if (this.props.isTeamSurveyFinished) {
			title = this.props.appStore.translationStore.translate("Reopen Diagnostic");
		} else if (this.props.isTeamSurveyStarted) {
			title = this.props.appStore.translationStore.translate("Extend Diagnostic Deadline");
		}

		return title;
	}

	render() {
		let title = this.getTitle();

		const defaultValue = this.calculateDefaultValue();
		const minVaLue = moment().add(1, "day").endOf("day");
		const checkboxVisible = this.props.isTeamSurveyStarted || this.props.isTeamSurveyFinished;

		return (
			<>
				<button className="mt--btn-linkalike" onClick={this.onDeadlineChangeButtonClicked}>
					<span className="dx-icon-edit" /> <span className="mt--linkalike-label">{title}</span>
				</button>
				<Popup
					visible={this.state.popupVisible}
					onHiding={this.onModalHiding}
					dragEnabled={false}
					closeOnOutsideClick={true}
					//showCloseButton={true}
					showTitle={true}
					title={title}
					//width={600}
					height={checkboxVisible ? 290 : 260}
					onShowing={this.onShowing}
				>
					<ToolbarItem
						widget="dxButton"
						toolbar="bottom"
						visible={!this.state.isLoading}
						location="center"
						options={this.changeDeadlineButtonOptions}
					/>
					<ToolbarItem widget="dxButton" toolbar="bottom" visible={!this.state.isLoading} location="center" options={this.closeButtonOptions} />
					<p>
						{this.props.appStore.translationStore.translate(
							"Please choose a new date for the participants to complete the diagnostic on your team.",
						)}
					</p>
					<div className="dx-fieldset">
						<div className="dx-field">
							<DateBox
								disabled={this.state.isLoading}
								value={this.state.newDate}
								defaultValue={defaultValue}
								type="date"
								min={minVaLue.toISOString()}
								onValueChanged={this.onDateValueChanged}
								displayFormat={getDevExtremeDateFormat()}
							/>
						</div>
						{checkboxVisible && (
							<div className="dx-field mt--flex">
								<input
									type="checkbox"
									className="mt--chkbox"
									onChange={this.onSendRemindersValueChanged}
									name="sendRemindersChb"
									id="sendRemindersChb"
									checked={this.state.sendReminders}
								/>
								<label htmlFor="sendRemindersChb" className="mt--ml-05">
									{this.props.appStore.translationStore.translate("Notify participants by email")}
								</label>
							</div>
						)}
					</div>
				</Popup>
			</>
		);
	}
}
