import React from "react";
import {HashRouter} from "react-router-dom";
import {AppStore} from "../stores/app.store";
import {Routes} from "./top.level.routes";
import {MainBlank} from "./blank";
import {OutdatedClient} from "./outdated.client";
import {Error} from "./error.component";
import {observer} from "mobx-react";

export const AppContext: React.Context<AppStore | null> = React.createContext<AppStore | null>(null);

export const MainFrame = observer((props: {appStore: AppStore}) => {
	return (
		<AppContext.Provider value={props.appStore}>
			<React.Suspense fallback={<MainBlank />}>
				<React.Fragment>
					<HashRouter>
						<Routes />
						<Error />
						<OutdatedClient />
					</HashRouter>
				</React.Fragment>
			</React.Suspense>
		</AppContext.Provider>
	);
});
