import React from "react";
import {injectAppStore} from "../../../common/app.store.consumer";
import {AppStore} from "../../../stores/app.store";
import HabitDetailsPdf from "./survey.results.recommendations.habit.details.pdf";
import PublicSurveyResultsRecommendationsRadar from "./survey.results.recommendations.radar";
import RecommendedTeamJourney from "../common/survey.results.recommended.team.journey";
import ExcludedTeamJourney from "../common/survey.results.excluded.team.journey";
import TeamJourneyOctagon from "../../../common/team-journey-octagon/team.journey.octagon";
import PublicSurveyResultsPectus from "../common/survey.results.pectus";
import {RouteComponentProps, withRouter} from "react-router";
import Facilitators from "../../../common/images/facilitators";
import HabitsHive from "../../../common/images/habitsHive";
import {flowResult} from "mobx";

type Props = {appStore?: AppStore} & RouteComponentProps<{teamId: string; language: string}>;

@injectAppStore()
class PublicSurveyResultsRecommendationsPdf extends React.Component<Props> {
	async componentDidMount() {
		// console.log("language", this.props.match.params.language)
		const lang = this.props.match.params.language;
		await flowResult(this.props.appStore.translationStore.Load(lang, "Selected"));
		this.props.appStore.translationStore.setSelectedLanguage(lang);
		this.props.appStore.translationStore.changeLanguage();

		const teamIdFromParam = this.props.match.params.teamId;
		if (teamIdFromParam) {
			await this.props.appStore.surveyResultsStore.getSurveyResults(teamIdFromParam);
		}
	}

	render() {
		const surveyResultsStore = this.props.appStore.surveyResultsStore;
		if (!surveyResultsStore.isLoaded) {
			return <p>Loading</p>;
		}

		const radarResultsForRole = surveyResultsStore.getOrderedSummaryListByParticipantRoleAverage;
		const radarResultsForChart = surveyResultsStore.getOrderedSummaryListForRadarChart;
		const strongHabits = surveyResultsStore.results.resultRecommendations.strongHabits;
		const moderateHabits = surveyResultsStore.results.resultRecommendations.moderateHabits;
		const weakHabits = surveyResultsStore.results.resultRecommendations.weakHabits;

		return (
			<div className="mt--area-wrap mt--fill-white">
				<div className="mt--layout">
					<div className="mt--content mt--recommendations">
						<div>
							<h1 className="mt--pt-0 pdf-title">
								{this.props.appStore.translationStore.translate("Your Meta Team report on {{teamName}}", {
									teamName: surveyResultsStore.teamName,
								})}
							</h1>
							<div className="mt--two-thirds">
								<p className="mt--paragraph-lead">
									{this.props.appStore.translationStore.translate(
										"We know that great teams drive the most successful businesses, now more than ever. They become great because they understand themselves on a deeper level and are committed to improve. We’ve found there are no quick fixes or one-off interventions. It takes a collective commitment to develop in a way that fits the team, fits in with their work and transforms what they are capable of – effortlessly and efficiently.",
									)}
								</p>
								<p>
									{this.props.appStore.translationStore.translate(
										"We created Meta Team to take your team on this journey – to bring the science of successful teams to a new world of work, making it simple to master. By shining a light on underlying habits that drive your team’s output and performance, Meta Team provides you access to new choices and possibilities to improve your ways of working.",
									)}
								</p>
								<p>
									{this.props.appStore.translationStore.translate(
										"We’re delighted you’ve taken the first step in your Meta Team journey. You’re now one step closer to making success second nature for your team.",
									)}
								</p>
							</div>
							<hr />
							<div className="mt--flex mt--flex-wrap mt--pectus-wrap">
								<div className="mt--flex mt--flex-col mt--justify-content-between mt--pos-rel">
									<div>
										<h4>{this.props.appStore.translationStore.translate("THE OUTER HABITS")}</h4>
										<p>
											{this.props.appStore.translationStore.translate(
												"The top half of the diagnostic maps the external habits that contribute to your Team’s impact. They measure key behaviours and ways of working that drives high performance for your team.",
											)}
										</p>
									</div>
									<div className="pectus-divider">
										<div>{this.props.appStore.translationStore.translate("OUTER HABITS")}</div>
										<div className="dashed-line"></div>
										<div>{this.props.appStore.translationStore.translate("INNER HABITS")}</div>
									</div>
									<div>
										<h4>{this.props.appStore.translationStore.translate("THE INNER HABITS")}</h4>
										<p>
											{this.props.appStore.translationStore.translate(
												"The lower half of the diagnostic maps the internal habits that are the foundations of your Team’s effectiveness. The inner habits reflect how your team works together to unlock their full potential.",
											)}
										</p>
									</div>
								</div>
								<div className="mt--flex">
									<PublicSurveyResultsPectus />
								</div>
							</div>
							<div className="page-break"></div>
							<h1 className="mt--pt-0 mt--mt-1">{this.props.appStore.translationStore.translate("What are the results showing me?")}</h1>
							<div className="mt--two-thirds">
								<p>
									{this.props.appStore.translationStore.translate(
										"The report below gives you an overview of the habits that your team needs to work on together to achieve lasting change that drives results. There is a wealth of detailed data that you will have access to once you’ve met with your team coach and they’ve explained what the data means for your team.",
									)}
								</p>
							</div>
						</div>

						<div>
							<HabitDetailsPdf
								containerClass="mt--bb-0"
								sectionTitle={this.props.appStore.translationStore.translate("Diagnostic headlines for your team")}
								title={this.props.appStore.translationStore.translate("Habits that are strong")}
								recommendationsHabitDetails={strongHabits}
								emptyText={this.props.appStore.translationStore.translate("None…yet! There’s work to do.")}
							/>
							<hr className="dark" />
							<HabitDetailsPdf
								title={this.props.appStore.translationStore.translate("Habits that are moderately good")}
								recommendationsHabitDetails={moderateHabits}
								emptyText={this.props.appStore.translationStore.translate("No moderately good habits.")}
							/>
							<hr className="dark" />
							<HabitDetailsPdf
								title={this.props.appStore.translationStore.translate("Habits that are weak")}
								recommendationsHabitDetails={weakHabits}
								emptyText={this.props.appStore.translationStore.translate("No weak habits.")}
							/>
						</div>

						<div className="radar-tbl-container page-break-inside-avoid">
							<div className="mt--section-title">{this.props.appStore.translationStore.translate("Diagnostic results for your team")}</div>
							<PublicSurveyResultsRecommendationsRadar radarResultsForRole={radarResultsForRole} radarResultsForChart={radarResultsForChart} />
						</div>
						<hr />
						<div>
							<div className="page-break-inside-avoid">
								<h1 className="mt--pt-0">{this.props.appStore.translationStore.translate("Why is this important?")}</h1>
								<div className="mt--two-thirds">
									<p>
										{this.props.appStore.translationStore.translate(
											"Team development is not as simple as working on the team’s weakest habit first. Working on your team habits in the optimal sequence means your team can take its performance to the next level in the shortest possible time. Meta Team uses our algorithm to sequence your team journey in the order in which you need to work on your habits to build momentum quickly, not always starting from your lowest score.",
										)}
									</p>
								</div>
							</div>
							<div className="mt--two-thirds">
								<div className="mt--py-1">
									<p>
										{this.props.appStore.translationStore.translate(
											"We’ve analysed data from hundreds of teams to understand which habits need to be developed for different types of teams, and in which order. Our algorithm lays out the optimal sequence for your team journey, based on 10 years of research and validated through our hands-on experience working with teams around the world.",
										)}
									</p>
								</div>
								<div className="mt--pb-1">
									<p>
										{this.props.appStore.translationStore.translate(
											"It also takes into account what type of team you are – a team that runs things, makes or do things, recommends things, or a blend of all three. This is combined with the diagnostic results for your team and benchmarked against the team data we’ve collected from hundreds of other teams.",
										)}
									</p>
								</div>
							</div>

							<div className="page-break-inside-avoid">
								<h2 className="tint-txt-grey mt--two-thirds mt--ext-line-height variable-spacing">
									{this.props.appStore.translationStore.translate(
										"Based on the results of your team diagnostic, the habits that your team needs to work on for you to make the quickest gains are shown below.",
									)}
									<br />
									{this.props.appStore.translationStore.translate("Start with the first habit on the left.")}
								</h2>
								<div className="mt--journey-wrap mt--flex mt--justify-content-between page-break-inside-avoid">
									{surveyResultsStore.getRecommendationsTeamJourneyGroupedByStatus.recommendedHabits.length !== 0 && (
										<RecommendedTeamJourney
											habits={surveyResultsStore.getRecommendationsTeamJourneyGroupedByStatus.recommendedHabits}
											hideAllProgress
											inverted
										/>
									)}
									{surveyResultsStore.getRecommendationsTeamJourneyGroupedByStatus.excludedHabits.length !== 0 && (
										<ExcludedTeamJourney
											habits={surveyResultsStore.getRecommendationsTeamJourneyGroupedByStatus.excludedHabits}
											hideAllProgress
										/>
									)}
								</div>
							</div>

							<div className="mt--improvements mt--two-thirds">
								{surveyResultsStore.results.resultRecommendations.recommendedHabitsWithDescription.map((habit, index) => (
									<>
										{index === 0 && (
											<div className="page-break-inside-avoid">
												<h2 className="tint-txt-grey mt--pt-2 mt--pb-2">
													{this.props.appStore.translationStore.translate(
														"By working on your priority habits your team can achieve the following gains",
													)}
												</h2>
												<div key={habit.habitId} className="mt--2-cols narrow-wide mt--flex-nowrap page-break-inside-avoid mt--gap-1">
													<div className="col">
														<div className="mt--flex mt--align-self-center">
															<TeamJourneyOctagon habitName={habit.habitName} isMastered hideProgress />
														</div>
													</div>
													<div className="col">
														<div className="mt--habit-name mt--mb-05">
															{this.props.appStore.translationStore.translate(habit.habitName)}
														</div>
														<p>{this.props.appStore.translationStore.translate(habit.description)}</p>
													</div>
												</div>
											</div>
										)}
										{index !== 0 && (
											<div key={habit.habitId} className="mt--2-cols narrow-wide mt--flex-nowrap page-break-inside-avoid mt--gap-1">
												<div className="col">
													<div className="mt--flex mt--align-self-center">
														<TeamJourneyOctagon habitName={habit.habitName} isMastered hideProgress />
													</div>
												</div>
												<div className="col">
													<div className="mt--habit-name mt--mb-05">
														{this.props.appStore.translationStore.translate(habit.habitName)}
													</div>
													<p>{this.props.appStore.translationStore.translate(habit.description)}</p>
												</div>
											</div>
										)}
									</>
								))}
							</div>
						</div>
						<div className="mt--mt-3">
							<div>
								<div className="page-break-inside-avoid  mt--pb-1">
									<h1 className="mt--pt-0">{this.props.appStore.translationStore.translate("How do you achieve these gains?")}</h1>
									<div className="mt--two-thirds">
										<p>
											{this.props.appStore.translationStore.translate(
												"Meta Team believes every team can improve. Our algorithm takes the output from your diagnostic and works out the optimal order of habits for you to work through - so you experience the biggest impact as soon as possible.",
											)}
										</p>
									</div>
								</div>
								<div className="mt--two-thirds">
									<p>
										{this.props.appStore.translationStore.translate(
											"Over the last 12 years Meta Team have developed and tested the precise exercises needed to strengthen each particular habit. For each of your team’s priority habits, we offer four 90-minute micro-habit workouts for the team to work through together. We have a total of 32 micro-habit workouts to accelerate your team’s progress at speed.",
										)}
									</p>
								</div>
							</div>
							<HabitsHive className="mt--full mt--my-3" alt="" />
							<div>
								<p>
									{this.props.appStore.translationStore.translate(
										"Each workout is customised from your team’s diagnostic output, and designed to be delivered virtually, in hybrid or face-to-face. The 90-minute sessions are immersive, experiential, all about your team and grounded in behavioural science.",
									)}
								</p>
								<p>
									{this.props.appStore.translationStore.translate(
										"We partner with your team on a journey of accelerated development - built on our tried and tested experience of exercises that work with each habit delivered in the most impactful sequence.",
									)}
								</p>
							</div>
							<div className="mt--my-3 mt--two-thirds mt--flex mt--justify-content-center mt--illustration page-break-inside-avoid">
								<Facilitators className="page-break-inside-avoid" alt="" />
							</div>
							<p>
								{this.props.appStore.translationStore.translate(
									"Typically, the time from diagnostic results to end of the first habit is 8 – 12 weeks. Delivering small changes that make a big impact, a Meta Team journey is up to 4 times more impactful than best–in–class leadership programmes. All workouts can be accessed by your team through an annual license fee.",
								)}
							</p>
						</div>
						<div className="page-break-inside-avoid">
							<h1 className="mt--mt-1">{this.props.appStore.translationStore.translate("What if you don’t take this forward?")}</h1>
							<div className="mt--two-thirds mt--pb-1">
								<p>
									{this.props.appStore.translationStore.translate(
										"The ultimate goal of the Meta Team journey is to make success second nature to you team. Not taking this forward and working on the different habits could limit your team’s scope for improvement and may even hold you back.",
									)}
								</p>
							</div>
						</div>
						<div className="mt--two-thirds">
							<p>
								{this.props.appStore.translationStore.translate(
									"Our work has shown that teams who are strong at all 8 habits are 23% more productive than those that are not. We hope you embrace this programme to develop strong team habits for your team and take your performance to the next level.",
								)}
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(PublicSurveyResultsRecommendationsPdf);
