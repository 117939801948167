import { HttpResponse, fetchJsonResource } from "../../utils/fetch.resource";

export class TranslationJsonStore {
	public static async loadSystemResource(url: string, language: string, version: string | null = null) {
		const translations = await this.loadJsonResource(url, language, version);

		if (translations) {
			return translations;
		}

		return {};
	}

	private static async loadJsonResource(url: string, language: string, version: string | null = null) {
		try {
			let urlToCall = `${url}/${language}.json`;

			if (version) {
				urlToCall += `?v=${version}`;
			}

			const result: HttpResponse<any> | null = await fetchJsonResource<any>(urlToCall);

			if (result?.ok) {
				return result.parsedBody;
			}

			return null;
		} catch (error) {
			console.error(`${language} - ${url} - ${error}`);
			return null;
		}
	}
}
