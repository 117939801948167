import {action, makeObservable, observable, runInAction} from "mobx";
import {IApiClient} from "../common/api.client";
import {DropdownDto} from "../common/webapicall";
import {StoreBase} from "./store.base";

export class ParticipantRoleStore extends StoreBase {
	constructor(private apiClient: IApiClient) {
		super(apiClient);
		makeObservable(this);
	}

	AllowedRolesPostDiagnostic = ["TM", "SH", "LM", "TL"]; // this const must be in sync with the instance configuration: AllowedParticipantRoleIdsForPostDiagnostic

	@observable participantRolesDropdown: DropdownDto[] = [];

	@action
	async getParticipantRolesForDropdown(filterForAllowedRoles: boolean) {
		const response = await this.apiClient.participantRoleClient.getParticipantRolesForDropdown();
		let roles: DropdownDto[];
		if (response) {
			if (filterForAllowedRoles) {
				roles = response.filter(value => this.AllowedRolesPostDiagnostic.indexOf(value.value) !== -1);
			} else {
				roles = response;
			}
		}
		runInAction(() => {
			this.participantRolesDropdown = roles;
		});

	}
}
