import DataSource from "devextreme/data/data_source";
import {IApiClient} from "../common/api.client";
import {WebErrorMessage} from "./weberrormessage";
import {action, makeObservable, observable} from "mobx";
import {StoreBase} from "./store.base";
import {AdminUserCreateOrUpdateResponse, WebApiErrorResponse} from "../common/webapicall";
import {TranslationStore} from "./translation/translation.store";

export class AdminUserStore extends StoreBase {
	@observable dataSource: DataSource;

	constructor(private apiClient: IApiClient, private translation: TranslationStore) {
		super(apiClient);
		makeObservable(this);

		this.dataSource = null;
	}

	@action
	public init(clientId: string) {
		this.dataSource = new DataSource({
			key: "id",
			load: async loadOptions => {
				return await this.apiClient.adminUserClient.getList(clientId, loadOptions);
			},
			insert: async values => {
				try {
					return await this.apiClient.adminUserClient.addAdminUser(clientId, values);
				} catch (error) {
					if (error instanceof WebApiErrorResponse) {
						if (error.status === 400) {
							const response = JSON.parse(error.response) as AdminUserCreateOrUpdateResponse;

							switch (response) {
								case AdminUserCreateOrUpdateResponse.EmailNotUnique:
									throw await this.getLoggedException("Email is already in use.", error);
								case AdminUserCreateOrUpdateResponse.InvalidUser:
									throw await this.getLoggedException("You are not able to perform admin user creation.", error);
								default:
									throw await this.getLoggedException(response.toString(), error);
							}
						}
					}

					throw await this.getLoggedException(WebErrorMessage.TranslateBadRequest(null), error);
				}
			},
			update: async (key, values) => {
				try {
					return await this.apiClient.adminUserClient.updateAdminUser({id: key, ...values});
				} catch (error) {
					if (error instanceof WebApiErrorResponse) {
						if (error.status === 400) {
							const response = JSON.parse(error.response) as AdminUserCreateOrUpdateResponse;

							switch (response) {
								case AdminUserCreateOrUpdateResponse.EmailNotUnique:
									throw await this.getLoggedException("Email is already in use.", error);
								case AdminUserCreateOrUpdateResponse.InvalidUser:
									throw await this.getLoggedException("You are not able to perform admin user update.", error);
								default:
									throw await this.getLoggedException(response.toString(), error);
							}
						}
					}

					throw await this.getLoggedException(WebErrorMessage.TranslateBadRequest(null), error);
				}
			},
			remove: async key => {
				try {
					return await this.apiClient.clientClient.deleteClient(key);
				} catch (error) {
					if (error.isWebApiErrorResponse && error.statusCode === 400) {
						throw new Error(WebErrorMessage.TranslateBadRequest(this.translation));
					} else {
						throw error;
					}
				}
			},
		});
	}

	@action
	unload() {
		this.dataSource = null;
	}

	get getStatuses() {
		return [
			{
				name: this.translation.translate("Active"),
				value: true,
			},
			{
				name: this.translation.translate("Inactive"),
				value: false,
			},
		];
	}
}
