import React from "react";
import {injectAppStore} from "../../common/app.store.consumer";
import {AppStore} from "../../stores/app.store";
import {guard, isAdmin} from "../../common/guard";
import AdminUserList from "./adminuser.list";
import AdminPageHeader from "../page-header/page.header";
import {RouteComponentProps, withRouter} from "react-router";
import BackButton from "../navigation/backButton";

type IProps = {appStore?: AppStore} & RouteComponentProps<{clientId: string}>;

@guard(isAdmin)
@injectAppStore()
class AdminUsers extends React.Component<IProps> {
	async componentDidMount() {
		this.props.appStore.clientStore.getClientsForDropdown();
	}

	render() {
		return (
			<>
				<AdminPageHeader clientId={this.props.match.params.clientId} hasSimpleHeader title={`Administrators`} />
				<div className="mt--area-wrap mt--fill-white">
					<div className="mt--layout mt--min-height">
						<div className="mt--content">
							<BackButton />
							<AdminUserList clientId={this.props.match?.params?.clientId}></AdminUserList>
						</div>
					</div>
				</div>
			</>
		);
	}
}
export default withRouter(AdminUsers);
