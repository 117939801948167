import {IApiClient} from "../common/api.client";
import {AuthorizableActions, ContextModel} from "../common/webapicall";
import {action, makeObservable, observable} from "mobx";
import moment from "moment";
import {StoreBase} from "./store.base";

export class AppContextStore extends StoreBase {
	@observable contextModel: ContextModel;

	constructor(private apiClient: IApiClient) {
		super(apiClient);
		makeObservable(this);
	}

	async loadContext(teamId?: string, pSurveyId?: string): Promise<void> {
		let contextResponse = await this.apiClient.contextClient.getContext({
			teamId: teamId,
			participantSurveyId: pSurveyId,
		});
		this.handleContextResponse(contextResponse);
	}

	@action
	private handleContextResponse(contextResponse: ContextModel): void {
		this.contextModel = contextResponse;
	}

	@action
	removeAuthorizableAction(authorizableAction: AuthorizableActions) {
		const aaList = this.contextModel.authorizedActions;
		this.contextModel.authorizedActions = aaList.filter(aa => aa !== authorizableAction);
	}

	@action changeTeamSurveyDeadline(newDeadline: moment.Moment) {
		this.contextModel.teamModel.teamSurveyDeadline = newDeadline;
	}
}
