import {action, computed, makeObservable, observable, runInAction} from "mobx";
import {IApiClient} from "../common/api.client";
import {authorizableActionsContains} from "../common/helpers";
import {IConfigurationProvider} from "../common/public.configuration";
import {
	AuthorizableActions,
	HabitDetails,
	RecommendationsPdf,
	SummaryDetails,
	TeamJourneyHabit,
	TeamJourneyStatus,
	TeamSurveyResultSnapshot,
} from "../common/webapicall";
import {PectusData} from "../public/survey-results/models/pectus";
import {ITeamJourneys} from "../public/survey-results/models/team.journeys";
import {AppContextStore} from "./app.context.store";
import {PublicMapRoutes} from "./site.map";
import {StoreBase} from "./store.base";
import {TranslationStore} from "./translation/translation.store";

export class SurveyResultsStore extends StoreBase {
	constructor(
		private apiClient: IApiClient,
		private contextStore: AppContextStore,
		private configurationProvider: IConfigurationProvider,
		private translation: TranslationStore,
	) {
		super(apiClient);
		makeObservable(this);
	}

	@observable isLoaded: boolean = false;
	@observable isLoadedForTeam: string | undefined = undefined;
	@observable teamName: string = "";
	@observable recommendationsPdf: RecommendationsPdf = null;
	@observable results: TeamSurveyResultSnapshot = null;
	@observable teamJourney: TeamJourneyHabit[] = [];
	@observable isCurrentUserTeamLeader: boolean = false;

	@observable recommendationsPdfTimeout: NodeJS.Timeout = null;

	@action
	async getSurveyResults(teamId: string) {
		const contextTeamId = this.contextStore.contextModel.teamModel.teamId;
		if (contextTeamId !== teamId || !this.isLoaded || this.isLoadedForTeam !== teamId) {
			const response = await this.apiClient.teamSurveyResultsClient.getTeamSurveyResults(teamId);
			runInAction(() => {
				if (response) {
					this.teamName = response.teamName;
					this.results = response.surveyResults;
					this.teamJourney = response.teamJourney;
					this.recommendationsPdf = response.recommendationsPdf;
					this.isCurrentUserTeamLeader = response.isCurrentUserTeamLeader;
					this.isLoaded = true;
					this.isLoadedForTeam = teamId;
				}
			});
		}

		await this.getRecommendationsPdfDownloadLink(teamId);
	}

	@action
	async getRecommendationsPdfDownloadLink(teamId: string) {
		if (this.recommendationsPdf && this.recommendationsPdf.canDownload && !this.recommendationsPdf.recommendationsPdfDownloadLink) {
			this.recommendationsPdfTimeout = setTimeout(async () => {
				const res = await this.apiClient.teamSurveyResultsClient.getRecommendationsPdfDownloadUrl(teamId);
				runInAction(() => {
					this.recommendationsPdf = res;
				});
			}, this.configurationProvider.configuration.exportPdfRefreshIntervalInSec * 1000);

			// (async () => {
			// 	while (!this.recommendationsPdf.recommendationsPdfDownloadLink) {
			// 		await new Promise((resolve) => {
			// 			this.recommendationsPdfTimeout = setTimeout(resolve, this.configurationProvider.configuration.exportPdfRefreshIntervalInSec * 1000);
			// 		});
			// 		const res = await this.apiClient.teamSurveyResultsClient.getRecommendationsPdfDownloadUrl(teamId);
			// 		runInAction(() => {
			// 			this.recommendationsPdf = res;
			// 		});
			// 	}
			// })();
		}
	}

	@action
	clearRecommendationsPdfTimeout() {
		clearTimeout(this.recommendationsPdfTimeout);
		this.recommendationsPdfTimeout = null;
	}

	@computed get getOrderedSummaryListByParticipantRoleAverage(): SummaryDetails[] {
		let summaries = [];
		if (this.isLoaded) {
			const sortingArray = ["TL", "TM", "SH", "LM"];
			for (let summary of this.results.summaries.slice()) {
				const orderedRoleAverages = summary.roleAverages.slice().sort(function (a, b) {
					return sortingArray.indexOf(a.participantRoleId) - sortingArray.indexOf(b.participantRoleId);
				});

				summaries.push({
					...summary,
					roleAverages: orderedRoleAverages,
				});
			}
		}

		return summaries;
	}

	@computed get getOrderedSummaryListForRadarChart(): SummaryDetails[] {
		let orderedSummaries: SummaryDetails[] = [];
		if (this.isLoaded) {
			const summaries = this.getOrderedSummaryListByParticipantRoleAverage.slice();
			const habitOrder = ["influencing", "agility", "adapting", "focus", "purpose", "resilience", "trust", "taking action"];
			orderedSummaries = summaries.sort(
				(a, b) => habitOrder.indexOf(a.habitName.toLocaleLowerCase()) - habitOrder.indexOf(b.habitName.toLocaleLowerCase()),
			);
		}

		return orderedSummaries;
	}

	@computed get getRecommendationsTeamJourneyGroupedByStatus(): ITeamJourneys {
		const teamJourneys = {} as ITeamJourneys;
		teamJourneys.recommendedHabits = this.teamJourney.filter(
			tj =>
				tj.habitStatus === TeamJourneyStatus.ToComplete ||
				tj.habitStatus === TeamJourneyStatus.InProgress ||
				tj.habitStatus === TeamJourneyStatus.Completed,
		);
		teamJourneys.excludedHabits = this.teamJourney.filter(
			tj => tj.habitStatus === TeamJourneyStatus.Mastered || tj.habitStatus === TeamJourneyStatus.Excluded,
		);
		return teamJourneys;
	}

	@computed get getPectusData(): PectusData[] {
		const habitOrder = ["taking action", "influencing", "agility", "adapting", "focus", "purpose", "resilience", "trust"];
		let data: PectusData[] = [];
		if (this.isLoaded) {
			const strong = this.results.resultRecommendations.strongHabits;
			const moderate = this.results.resultRecommendations.moderateHabits;
			const weak = this.results.resultRecommendations.weakHabits;
			const weightedHabits = strong.concat(moderate, weak);
			weightedHabits
				.sort((a, b) => habitOrder.indexOf(a.habitName.toLocaleLowerCase()) - habitOrder.indexOf(b.habitName.toLocaleLowerCase()))
				.forEach(s => {
					data.push({
						habitId: s.habitId,
						habitName: s.habitName,
						bandIndex: s.bandIndex,
					});
				});
		}

		return data;
	}

	getStatusText(status: TeamJourneyStatus): string {
		switch (status) {
			case TeamJourneyStatus.ToComplete:
				return this.translation.translate("To Complete");
			case TeamJourneyStatus.InProgress:
				return this.translation.translate("In Progress");
			case TeamJourneyStatus.Completed:
				return this.translation.translate("Completed");
			case TeamJourneyStatus.Excluded:
				return this.translation.translate("Excluded");
			case TeamJourneyStatus.Mastered:
				return this.translation.translate("Mastered");
			default:
				return "";
		}
	}

	getHabitDetails(habitId: string): HabitDetails {
		const habit = this.results.habitDetails.find(h => h.habitId === habitId);
		if (habit) {
			return habit;
		}

		return null;
	}

	getRoleNameByRoleId(roleId: string): string {
		switch (roleId) {
			case "TL":
				return this.translation.translate("Team Leader");
			case "TM":
				return this.translation.translate("Team");
			case "SH":
				return this.translation.translate("Stakeholders");
			case "LM":
				return this.translation.translate("Line Manager");
		}

		return roleId;
	}

	getDefaultResultPage = (contextStore: AppContextStore, teamId: string): string => {
		const canViewRecommendationsResults = authorizableActionsContains(contextStore, [AuthorizableActions.CanViewRecommendationsResults]);
		const canViewSummaryResults = authorizableActionsContains(contextStore, [AuthorizableActions.CanViewSummaryResults]);
		const canViewMicroHabitResults = authorizableActionsContains(contextStore, [AuthorizableActions.CanViewMicroHabitResults]);

		if (canViewRecommendationsResults) return `${PublicMapRoutes.DiagnosticResultsRecommendations}/${teamId}`;
		if (canViewSummaryResults) return `${PublicMapRoutes.DiagnosticResultsSummary}/${teamId}`;
		if (canViewMicroHabitResults) return `${PublicMapRoutes.DiagnosticResultsMicroHabits}/${teamId}`;

		return "";
	};
}
