import React from "react";
import {injectAppStore} from "../../common/app.store.consumer";
import {AppStore} from "../../stores/app.store";
import {guard, isAdmin} from "../../common/guard";
import AdminPageHeader from "../page-header/page.header";
import {RouteComponentProps, withRouter} from "react-router";
import BackButton from "../navigation/backButton";

type IProps = {appStore?: AppStore} & RouteComponentProps<{clientId: string}>;

@guard(isAdmin)
@injectAppStore()
class Reports extends React.Component<IProps> {
	async componentDidMount() {}

	render() {
		console.log("Render report:", this.props.match, this.props.match?.params?.clientId);
		return (
			<>
				<AdminPageHeader clientId={this.props.match.params.clientId} hasSimpleHeader={false} title="Data Export" />
				<div className="mt--area-wrap mt--fill-white">
					<div className="mt--layout mt--min-height">
						<div className="mt--content">
							<BackButton />
							<div className="mt--mt-4">The Data Export function will be added in the future</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withRouter(Reports);
