import * as React from "react";
import {Redirect} from "react-router-dom";
import {AppStore} from "../stores/app.store";
import {UserStore} from "../stores/user.store";
import {injectAppStore} from "./app.store.consumer";
import {MainBlank} from "./blank";

export interface GuardOptions {
	checkUser: (userStore: UserStore) => GuardResult;
	appStore?: AppStore;
	redirectUrl?: string;
}

enum GuardResult {
	Allowed,
	RedirectToLogin,
	RedirectToHome,
}

export const isLoggedIn = (userStore: UserStore) => (userStore.isLoggedIn ? GuardResult.Allowed : GuardResult.RedirectToLogin);

export const isAdmin = (userStore: UserStore) =>
	userStore.isAdmin ? GuardResult.Allowed : userStore.isLoggedIn ? GuardResult.RedirectToHome : GuardResult.RedirectToLogin;

/** Component that should be used for handling access rights. It requires a function that is run upon creation, and if the function returns false, it navigates the user to the login page. */
@injectAppStore()
export default class Guard extends React.Component<GuardOptions, {redirect: GuardResult}> {
	constructor(props: Readonly<GuardOptions>) {
		super(props);
		let userStore = props.appStore.userStore;
		this.state = {
			redirect: props.checkUser(userStore),
		};
	}

	render() {
		if (this.state.redirect === GuardResult.RedirectToLogin) {
			let redirectUrl = this.props.redirectUrl ? this.props.redirectUrl : this.props.appStore.userStore.getLoginUrl();
			if (redirectUrl.startsWith("#/")) {
				return <Redirect to={redirectUrl.substr(1)} />;
			} else if (redirectUrl.startsWith("/#/")) {
				return <Redirect to={redirectUrl.substr(2)} />;
			} else {
				window.location.href = redirectUrl;
				return <MainBlank />;
			}
		} else if (this.state.redirect === GuardResult.RedirectToHome) {
			return <Redirect to={"/"} />;
		} else {
			return this.props.children;
		}
	}
}

/**
 * Decorator that wraps the component with Guard component.
 * @param checkUser Function to decide whether the user is allowed to stay on this page.
 */
export const guard = (checkUser: (userStore: UserStore) => GuardResult) => {
	return <T extends any, C extends React.ComponentType<T> | {(props: T): JSX.Element}>(WrappedComponent: C): C => {
		let RenderedComponent = WrappedComponent as any;
		return (((props: T) => {
			return (
				<Guard checkUser={checkUser}>
					<RenderedComponent {...props as {}} />
				</Guard>
			);
		}) as any) as C;
	};
};
