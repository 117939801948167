import {AppStore} from "../stores/app.store";
import {AuthConfig} from "./webapicall.extensions";

/** Implements auth config, which gives the token to the API Client library, and implements a fetch function which orchestrates the refresh token function. */
export class AuthConfigImpl implements AuthConfig {
	token: string = "";
	refreshToken: string = "";

	constructor(protected appStore: AppStore) {}

	async fetch(url: RequestInfo, init?: RequestInit): Promise<Response> {
		try {
			let res = await window.fetch(url, init).catch(s => {
				if (s?.status) {
					return s;
				}
				throw s;
			});
			if (res.status === 401 && url !== "/api/user/refresh-token") {
				if (res.headers.get("x-auth-token-expired") === "true") {
					let result = await this.appStore.userStore.refreshToken(this.refreshToken);
					if (result) {
						init.headers["Authorization"] = `Bearer ${this.token}`;
						res = await window.fetch(url, init);
					}
				}
			}
			return res;
		} catch (error) {
			console.log("Request cancelled", url);
		}
	}
}
