import {WebApiErrorResponse} from "../common/webapicall";
import {IApiClient} from "../common/api.client";

export abstract class StoreBase {
	constructor(private baseApiClient: IApiClient) {}

	public async getLoggedException(message: string, error?: WebApiErrorResponse) {
		await this.baseApiClient.reportClient.sendClientException({
			message: JSON.stringify({
				message: message,
				error: error,
			}),
			url: window.location.href,
		});
		return new Error(message);
	}
}
