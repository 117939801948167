import * as crosstab from "crosstab";
import {action, makeObservable, observable} from "mobx";
import {IApiClient} from "../common/api.client";
import {AuthConfigImpl} from "../common/auth.config";
import {AppPublicConfiguration, IConfigurationProvider} from "../common/public.configuration";
import {flow} from "mobx";
import {
	AdminUserClient,
	ClientClient,
	ContextClient,
	DashboardClient,
	EmailClient,
	InsightResultsSharingClient,
	LookupClient,
	PackageClient,
	ParticipantRoleClient,
	PublicDebugPanelClient,
	ReportClient,
	TeamClient,
	TeamParticipantClient,
	TeamSurveyClient,
	TeamSurveyResultsClient,
	TeamTypeClient,
	UserClient,
} from "../common/webapicall";
import {defaultLangCode} from "../i18n";
import {AdminUserStore} from "./adminuser.store";
import {AppContextStore} from "./app.context.store";
import {BarConfigStore} from "./bar.config.store";
import {ClientStore} from "./client.store";
import {DashboardStore} from "./dashboard.store";
import {DebugPanelStore} from "./debug.panel.store";
import {EmailStore} from "./email.store";
import {FeedbackProvidersStore} from "./feedback.providers.store";
import {PackageStore} from "./package.store";
import {ParticipantRoleStore} from "./participant.role.store";
import {SurveyResultsRadarStore} from "./survey.results.radar.store";
import {SurveyResultsStore} from "./survey.results.store";
import {TeamStore} from "./team.store";
import {TeamSurveyStore} from "./team.survey.store";
import {TeamTypeStore} from "./team.type.store";
import {TranslationStore} from "./translation/translation.store";
import {UserStore} from "./user.store";
import {LoginStore} from "./login.store";

export interface IAppStore extends IConfigurationProvider {
	init(isAdmin: boolean);
}

const RESIZE_OBSERVER_ERROR_MESSAGES = ["ResizeObserver loop limit exceeded", "ResizeObserver loop completed with undelivered notifications."];

abstract class AppStoreBase implements IAppStore {
	abstract userStore: UserStore;
	abstract teamStore: TeamStore;
	abstract feedbackProvidersStore: FeedbackProvidersStore;
	abstract clientStore: ClientStore;
	abstract packageStore: PackageStore;
	abstract teamTypeStore: TeamTypeStore;
	abstract participantRoleStore: ParticipantRoleStore;
	abstract teamSurveyStore: TeamSurveyStore;
	abstract surveyResultsStore: SurveyResultsStore;
	abstract surveyResultsRadarStore: SurveyResultsRadarStore;
	abstract barConfigStore: BarConfigStore;
	abstract emailStore: EmailStore;
	abstract configuration: AppPublicConfiguration;
	abstract outDatedClient: boolean;
	abstract errorVisible: boolean;
	abstract errorMessage: string;

	abstract init(isAdmin: boolean);

	protected subscribeEventHandlers() {
		window.addEventListener("error", async ev => {
			console.error(ev);
			if (ev && RESIZE_OBSERVER_ERROR_MESSAGES.includes(ev.message)) {
				return;
			}
			if (typeof ev?.error?.stack === "string" && ev?.error?.stack.indexOf("crosstab.js")) {
				//console.log("supress crosstab.js errors.");
				return true;
			}

			const stringifiedError = JSON.stringify(ev, Object.getOwnPropertyNames(ev));

			await this.reportError(`${stringifiedError}\n${ev?.message}\n${ev?.error?.stack}`);
		});
		window.onunhandledrejection = async ev => await this.reportUnhandledRejection(ev?.reason);
	}

	protected setupCrosstab(tokenToCheck: string) {
		if (crosstab.supported) {
			let ctEventName: string = "crossTabOpenNewTab";
			crosstab.on(ctEventName, message => {
				if (crosstab.id === message.origin) {
					//console.log("Crosstab receiving event on the same tab, skip it", ct.id, JSON.stringify(message));
				} else {
					//console.log("Crosstab receiving event on a different tab, process it", ct.id, JSON.stringify(message));
					if (tokenToCheck !== message.data) {
						window.location.assign("#/forced-logout");
						return;
					}
				}
			});
			//console.log("Crosstab broadcast event from source tab", ct.id);
			crosstab.broadcast(ctEventName, tokenToCheck);
		}
	}

	private async reportUnhandledRejection(reason: any) {
		if (reason.isWebApiErrorResponse) {
			if (reason.statusCode === 406) {
				this.outDatedClient = true;
			} else if (reason.statusCode === 401) {
				if (this.userStore.isLoggedIn) {
					window.location.href = "#/session-expired";
					await this.userStore.logout();
				} else {
					window.location.href = this.userStore.getLoginUrl();
				}
			} else {
				await this.reportError(`Unhandled web api error:
					URL: ${reason.url} (${reason.statusCode})

					Body: ${reason.body}

					Stack: ${reason?.stack}`);
			}
		} else if (reason?.stack) {
			await this.reportError(`Unhandled rejection: ${reason.stack}`);
		} else {
			await this.reportError(`Unhandled rejection: ${reason}`);
		}
	}

	@action
	private async reportError(reason: any) {
		try {
			this.errorVisible = true;
			if (this.configuration.showErrorDetails) {
				this.errorMessage = JSON.stringify(reason);
			}

			await this.sendClientException(reason);
		} catch (e) {
			// Just log it console as reporting it would be recursive.
			console.log("Error occurred during reporting JS error");
			console.log(e);
		}
	}

	protected abstract sendClientException(reason: any): Promise<void>;
}

export class AppStore extends AppStoreBase implements IConfigurationProvider {
	private readonly authConfig: AuthConfigImpl;

	apiClient: IApiClient;
	userStore: UserStore;
	teamStore: TeamStore;
	feedbackProvidersStore: FeedbackProvidersStore;
	clientStore: ClientStore;
	packageStore: PackageStore;
	teamTypeStore: TeamTypeStore;
	participantRoleStore: ParticipantRoleStore;
	teamSurveyStore: TeamSurveyStore;
	surveyResultsStore: SurveyResultsStore;
	surveyResultsRadarStore: SurveyResultsRadarStore;
	barConfigStore: BarConfigStore;
	emailStore: EmailStore;
	contextStore: AppContextStore;
	debugPanelStore: DebugPanelStore;
	dashboardStore: DashboardStore;
	adminUserStore: AdminUserStore;
	translationStore: TranslationStore;
	loginStore: LoginStore;

	configuration: AppPublicConfiguration;

	@observable outDatedClient: boolean = false;
	@observable errorVisible: boolean = false;
	@observable errorMessage: string = null;

	constructor() {
		super();
		makeObservable(this);

		this.authConfig = new AuthConfigImpl(this);
		this.apiClient = {
			setAuthToken: (newToken: string, refreshToken: string) => {
				this.authConfig.token = newToken;
				this.authConfig.refreshToken = refreshToken;
			},
			contextClient: new ContextClient(this.authConfig, null, this.authConfig),
			lookupClient: new LookupClient(this.authConfig, null, this.authConfig),
			reportClient: new ReportClient(this.authConfig, null, this.authConfig),
			userClient: new UserClient(this.authConfig, null, this.authConfig),
			teamClient: new TeamClient(this.authConfig, null, this.authConfig),
			teamParticipantClient: new TeamParticipantClient(this.authConfig, null, this.authConfig),
			clientClient: new ClientClient(this.authConfig, null, this.authConfig),
			packageClient: new PackageClient(this.authConfig, null, this.authConfig),
			participantRoleClient: new ParticipantRoleClient(this.authConfig, null, this.authConfig),
			publicDebugPanelClient: new PublicDebugPanelClient(this.authConfig, null, this.authConfig),
			teamSurveyClient: new TeamSurveyClient(this.authConfig, null, this.authConfig),
			teamSurveyResultsClient: new TeamSurveyResultsClient(this.authConfig, null, this.authConfig),
			teamTypeClient: new TeamTypeClient(this.authConfig, null, this.authConfig),
			resultsSharingClient: new InsightResultsSharingClient(this.authConfig, null, this.authConfig),
			emailClient: new EmailClient(this.authConfig, null, this.authConfig),
			dashboardClient: new DashboardClient(this.authConfig, null, this.authConfig),
			adminUserClient: new AdminUserClient(this.authConfig, null, this.authConfig),
		};
		this.translationStore = new TranslationStore(this);
		this.loginStore = new LoginStore();

		this.userStore = new UserStore(this.apiClient, this, this.translationStore);
		this.contextStore = new AppContextStore(this.apiClient);
		this.clientStore = new ClientStore(this.apiClient, this.translationStore);
		this.packageStore = new PackageStore(this.apiClient);
		this.teamTypeStore = new TeamTypeStore(this.apiClient);
		this.participantRoleStore = new ParticipantRoleStore(this.apiClient);
		this.teamSurveyStore = new TeamSurveyStore(this.apiClient, this.contextStore);
		this.barConfigStore = new BarConfigStore();
		this.surveyResultsStore = new SurveyResultsStore(this.apiClient, this.contextStore, this, this.translationStore);
		this.surveyResultsRadarStore = new SurveyResultsRadarStore(this.translationStore);
		this.feedbackProvidersStore = new FeedbackProvidersStore(
			this.apiClient,
			this.contextStore,
			this.userStore,
			this.participantRoleStore,
			this.translationStore,
		);
		this.teamStore = new TeamStore(this.apiClient, this.feedbackProvidersStore, this.translationStore, this);
		this.emailStore = new EmailStore(this.apiClient);
		this.debugPanelStore = new DebugPanelStore(this.userStore, this.apiClient, this.feedbackProvidersStore, this.teamSurveyStore, this.surveyResultsStore);
		this.dashboardStore = new DashboardStore(this.apiClient);
		this.adminUserStore = new AdminUserStore(this.apiClient, this.translationStore);
	}

	@flow
	*init(isAdmin: boolean) {
		yield this.loadApiVersion();
		this.subscribeEventHandlers();

		this.loginStore.init();

		yield this.loadPublicConfiguration();
		yield this.translationStore.init(this.userStore.language ?? defaultLangCode);

		yield this.userStore.init();

		yield this.translationStore.init(this.userStore.isAdmin && isAdmin ? defaultLangCode : this.userStore.language ?? defaultLangCode);
		yield this.translationStore.changeLanguage();

		const defaultTeamId = this.userStore.defaultTeamId ? this.userStore.defaultTeamId : null;
		yield this.contextStore.loadContext(defaultTeamId);

		this.setupCrosstab(this.userStore.getAuthToken());
	}

	async loadPublicConfiguration() {
		let config = await this.apiClient.lookupClient.getPublicConfiguration();
		this.configuration = {
			...config,
			loginUrl: "/#/login",
		};
	}

	protected async sendClientException(reason: any): Promise<void> {
		await this.apiClient.reportClient.sendClientException({
			message: reason,
			url: window.location.href,
		});
	}

	@flow
	private *loadApiVersion() {
		(window as any).apiVersion = yield this.apiClient.lookupClient.getApiVersion();
	}
}
