import React from "react";
import {injectAppStore} from "../../../common/app.store.consumer";
import {AppStore} from "../../../stores/app.store";
import MiniBar from "../../../common/bar/mini.bar";
import OutsideClick from "../../../common/outside.click";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

interface IProps {
	appStore?: AppStore;
	selectedId?: string;
	onSelectHabit: (selectedId?: string) => void;
}

interface IState {
	showDropdownContent: boolean;
}

@injectAppStore()
export default class PublicSurveyResultsMicroHabitsDropdown extends React.Component<IProps, IState> {
	state = {
		showDropdownContent: false,
	};

	toggleDropdownContent = () => {
		this.setState({showDropdownContent: !this.state.showDropdownContent});
	};

	closeDropdownContent = () => {
		this.setState({showDropdownContent: false});
	};

	onSelectHabit = (selectedId?: string) => {
		this.props.onSelectHabit(selectedId);
		this.closeDropdownContent();
	};

	render() {
		if (!this.props.appStore.surveyResultsStore.isLoaded) {
			return null;
		}

		const results = this.props.appStore.surveyResultsStore.results.habitDropdown;
		const selectedHabit = this.props.selectedId ? results.find(i => i.habitId === this.props.selectedId) : null;

		let dropdownContentClasses = ["dropdown-content"];
		let dropdownButtonClasses = ["drop-btn", "drop-with-minibar"];
		let angle: IconProp = ["fas", "angle-down"];
		if (this.state.showDropdownContent) {
			dropdownContentClasses.push("show");
			dropdownButtonClasses.push("show");
			angle = ["fas", "angle-up"];
		}

		return (
			<OutsideClick handleOutsideClick={this.closeDropdownContent}>
				<div className="mt--dropdown">
					<div className={dropdownButtonClasses.join(" ")} onClick={this.toggleDropdownContent}>
						{selectedHabit ? (
							<div className="mt--flex">
								<div>{this.props.appStore.translationStore.translate(selectedHabit.habitName)}</div>
								<div className="dropdown-minibar">
									{" "}
									<MiniBar barValue={selectedHabit.average} bandIndex={selectedHabit.bandIndex} />{" "}
								</div>
							</div>
						) : (
							<div>{this.props.appStore.translationStore.translate("Micro Habit Detail")}</div>
						)}
						<div className="mt--ico tint-orange">
							<FontAwesomeIcon transform="grow-3" icon={angle} />
						</div>
					</div>
					<div className={dropdownContentClasses.join(" ")}>
						<div className="dropdown-instruction" onClick={() => this.onSelectHabit(null)}>
							{this.props.appStore.translationStore.translate(
								"Click on the habit below to see the detailed responses from all your feedback providers",
							)}
						</div>
						{results.map(item => (
							<div onClick={() => this.onSelectHabit(item.habitId)} key={item.habitId}>
								<div>{this.props.appStore.translationStore.translate(item.habitName)}</div>{" "}
								<MiniBar barValue={item.average} bandIndex={item.bandIndex} />
							</div>
						))}
					</div>
				</div>
			</OutsideClick>
		);
	}
}
