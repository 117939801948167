import React from "react";
import {injectAppStore} from "../../../common/app.store.consumer";
import {AppStore} from "../../../stores/app.store";
import PublicSurveyResultsKeys from "../common/survey.results.keys";
import PublicSurveyResultsMicroHabitsList from "./survey.results.micro.habits.list";
import PublicSurveyResultsMicroHabitsDropdown from "./survey.results.micro.habits.dropdown";
import PublicSurveyResultsMicroHabitsDetails from "./survey.results.micro.habits.details";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
	appStore?: AppStore;
}
interface States {
	selectedId?: string;
}

@injectAppStore()
export default class PublicSurveyResultsMicroHabits extends React.Component<Props, States> {
	state = {
		selectedId: null,
	};

	onSelectHabit = (selectedHabitId?: string, scrollToTop: boolean = false) => {
		this.setState({selectedId: selectedHabitId});

		if (scrollToTop) {
			window.scrollTo(0, 0);
		}
	};

	render() {
		return (
			<div className="mt--area-wrap mt--fill-white">
				<div className="mt--layout">
					<div className="mt--content">
						<div className="mt--options-bar bar-with-dropdown">
							<div className="options-selector">
								<PublicSurveyResultsMicroHabitsDropdown selectedId={this.state.selectedId} onSelectHabit={this.onSelectHabit} />
								{this.state.selectedId && (
									<button className="mt--btn btn-med" onClick={() => this.onSelectHabit(null)}>
										<div className="mt--ico">
											<FontAwesomeIcon className="tint-blue" icon={["far", "arrow-left"]} flip={this.props.appStore.translationStore.isRTL ? "horizontal" : null} />
										</div>
										{this.props.appStore.translationStore.translate("Back")}
									</button>
								)}
							</div>
							<div className="col mt--flex">
								<PublicSurveyResultsKeys />
							</div>
						</div>
						{this.state.selectedId ? (
							<PublicSurveyResultsMicroHabitsDetails habitId={this.state.selectedId} />
						) : (
							<PublicSurveyResultsMicroHabitsList  onSelectHabit={this.onSelectHabit}/>
						)}

						{this.state.selectedId && (
							<React.Fragment>
								<div className="vspace20"></div>
								<button className="mt--btn btn-med" onClick={() => this.onSelectHabit(null, true)}>
									<div className="mt--ico">
										<FontAwesomeIcon icon={["far", "arrow-left"]} flip={this.props.appStore.translationStore.isRTL ? "horizontal" : null} />
									</div>
									<div className="btn-txt">
										<strong>{this.props.appStore.translationStore.translate("Back")}</strong>
									</div>
								</button>
							</React.Fragment>
						)}
					</div>
				</div>
			</div>
		);
	}
}
