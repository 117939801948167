import * as React from "react";
import {AppStore} from "../../stores/app.store";
import {injectAppStore} from "../app.store.consumer";
import {SurveyResultBandIndex} from "../webapicall";

interface IBarProps {
	appStore?: AppStore;
	barValue: number;
	bandIndex: SurveyResultBandIndex;
	showValueOnBar?: boolean;
	showAllTicks?: boolean;
	adjustWidth?: boolean;
	fixed?: number;
}

const Bar: React.FC<IBarProps> = injectAppStore()((props: IBarProps) => {
	const barConfig = props.appStore.barConfigStore;
	const ticks = props.showAllTicks ? barConfig.getAllTicksPosition : barConfig.getTicksPosition;
	const fixed = props.fixed ? props.fixed : 1;

	let barClasses = [];
	props.showValueOnBar ? barClasses.push("chart-bar bar-wide") : barClasses.push("chart-bar");
	barClasses.push(barConfig.getBandIndexClass(props.bandIndex));

	return (
		<div className="chart">
			<div className="tick" style={{ marginInlineStart: 0 }}></div>

			{ticks.map((tickWidth, i) => (
				<div key={i} className="tick" style={{ marginInlineStart: `${tickWidth}%` }}></div>
			))}

			<div className={barClasses.join(" ")} style={{width: `${barConfig.calculateWidth(props.barValue)}%`}}>
				{(props.showValueOnBar && !!props.barValue) ? <span className="bar-value">{props.barValue.toFixed(fixed)}</span> : null}
			</div>
			<div className={barClasses.join(" ") + " bar-short"}>
				<div className="bar-value">{props.barValue.toFixed(fixed)}</div>
			</div>
		</div>
	);
});

export default Bar;
