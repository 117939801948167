import React from "react";
import Two from "two.js";
import {injectAppStore} from "../../../../common/app.store.consumer";
import {AppStore} from "../../../../stores/app.store";
import { SummaryDetails } from "../../../../common/webapicall";

interface IProps {
	appStore?: AppStore;
	data:SummaryDetails[];
}

@injectAppStore()
export default class RadarChart extends React.Component<IProps> {
	private radarStore = this.props.appStore.surveyResultsRadarStore;

	private twoRef = React.createRef<HTMLDivElement>();
	private lang = this.props.appStore.translationStore.getLanguageCode;

	constructor(props: any) {
		super(props);
		this.radarStore.two = new Two();
	}

	componentDidMount() {
		const two = this.radarStore.two;
		this.initTwo(two);
	}

	componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any): void {
		if (this.lang !== this.props.appStore.translationStore.getLanguageCode || prevProps.data !== this.props.data) {
			this.lang = this.props.appStore.translationStore.getLanguageCode;
			const two = this.radarStore.two;

			this.twoRef.current.replaceChildren();
			two.clear();

			this.initTwo(two);
		}
	}

	private initTwo(two: Two) {
		const results = this.props.data.map(s => ({
			...s,
			habitName: this.props.appStore.translationStore.translate(s.habitName),
		}));
		const args = this.radarStore.getArgs(results);

		this.radarStore.getOctagon();
		this.radarStore.getSpokes(args);
		this.radarStore.getCharts(results);
		two.appendTo(this.twoRef.current);

		this.twoRef.current.firstElementChild.removeAttribute("width");
		this.twoRef.current.firstElementChild.removeAttribute("height");
		this.twoRef.current.firstElementChild.setAttribute("viewBox", `0 0 ${this.radarStore.size.height} ${this.radarStore.size.width}`);

		two.update();
	}

	render() {
		return <div className="mt--radar" ref={this.twoRef} />;
	}
}
