import React from "react";
import {injectAppStore} from "../../common/app.store.consumer";
import {AppStore} from "../../stores/app.store";
import {guard, isAdmin} from "../../common/guard";
import AdminTeamList from "./team.list";
import AdminPageHeader from "../page-header/page.header";
import {RouteComponentProps, withRouter} from "react-router";
import BackButton from "../navigation/backButton";

type IAdminTeamProps = {appStore?: AppStore} & RouteComponentProps<{clientId: string; forceNew?: string}>;

@guard(isAdmin)
@injectAppStore()
class AdminTeam extends React.Component<IAdminTeamProps> {
	async componentDidMount() {
		await this.props.appStore.clientStore.getClientsForDropdown();
		await this.props.appStore.packageStore.getPackagesForDropdown();
		await this.props.appStore.teamTypeStore.getTeamTypesForDropdown();
	}

	render() {
		return (
			<>
				<AdminPageHeader clientId={this.props.match.params.clientId} hasSimpleHeader title={`Teams`} />
				<div className="mt--area-wrap mt--fill-white">
					<div className="mt--layout mt--min-height">
						<div className="mt--content">
							<BackButton isOnDXToolbar={this.props.appStore.userStore.canAddTeam} />
							<AdminTeamList clientId={this.props.match.params.clientId} forceNew={this.props.match.params.forceNew} />
						</div>
					</div>
				</div>
			</>
		);
	}
}
export default withRouter(AdminTeam);
