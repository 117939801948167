import {PublicMapRoutes} from "../../stores/site.map";
import {TranslationStore} from "../../stores/translation/translation.store";
import {NavigationItem} from "./navigation.item";

export const getParticipantsNavigationItems = ({
	translation,
	teamId,
	pSurveyId = "",
	isTeamLeader = false,
	isRecommendationsShared = false,
	isSummaryShared = false,
	isMicroHabitsShared = false,
}: {
	translation: TranslationStore;
	teamId: string;
	pSurveyId?: string;
	isTeamLeader?: boolean;
	isRecommendationsShared?: boolean;
	isSummaryShared?: boolean;
	isMicroHabitsShared?: boolean;
}): NavigationItem[] => {
	const items: NavigationItem[] = [];

	if (isRecommendationsShared) {
		items.push({name: translation.translate("Recommendations"), to: `${PublicMapRoutes.DiagnosticResultsRecommendations}/${teamId}`, align: "left"});
		items.push({name: translation.translate("Team Journey"), to: `${PublicMapRoutes.DiagnosticResultsTeamJourney}/${teamId}`, align: "left"});
	}

	if (isSummaryShared) {
		items.push({name: translation.translate("Summary"), to: `${PublicMapRoutes.DiagnosticResultsSummary}/${teamId}`, align: "left"});
	}

	if (isMicroHabitsShared) {
		items.push({name: translation.translate("Micro Habits"), to: `${PublicMapRoutes.DiagnosticResultsMicroHabits}/${teamId}`, align: "left"});
	}

	if (pSurveyId && isTeamLeader && !isRecommendationsShared && !isSummaryShared && !isMicroHabitsShared) {
		items.push({name: translation.translate("Welcome"), to: `${PublicMapRoutes.DiagnosticHowTo}/${pSurveyId}`, align: "left"});
		items.push({name: translation.translate("My Diagnostic"), to: `${PublicMapRoutes.Diagnostic}/${pSurveyId}`, align: "left"});
	}

	items.push({name: translation.translate("Guide"), to: `${PublicMapRoutes.ParticipantsHowTo}/${teamId}`, align: "right"});
	items.push({name: translation.translate("Team Setup"), to: `${PublicMapRoutes.ParticipantsList}/${teamId}`, align: "right"});
	return items;
};

export const getSurveyNavigationItems = ({
	translation,
	teamId,
	pSurveyId,
	isTeamLeaderOrAdmin = false,
}: {
	translation: TranslationStore;
	teamId: string;
	pSurveyId: string;
	isTeamLeaderOrAdmin?: boolean;
}): NavigationItem[] => {
	const items: NavigationItem[] = [];

	items.push({name: translation.translate("Welcome"), to: `${PublicMapRoutes.DiagnosticHowTo}/${pSurveyId}`, align: "left"});
	items.push({name: translation.translate("My Diagnostic"), to: `${PublicMapRoutes.Diagnostic}/${pSurveyId}`, align: "left"});

	if (isTeamLeaderOrAdmin) {
		items.push({name: translation.translate("Guide"), to: `${PublicMapRoutes.ParticipantsHowTo}/${teamId}`, align: "right"});
		items.push({name: translation.translate("Team Setup"), to: `${PublicMapRoutes.ParticipantsList}/${teamId}`, align: "right"});
	}

	return items;
};

export const getSurveyResultsNavigationItems = (
	translation: TranslationStore,
	teamId: string,
	isRecommendationsShared: boolean = false,
	isSummaryShared: boolean = false,
	isMicroHabitsShared: boolean = false,
	isTeamLeaderOrAdmin: boolean = false,
): NavigationItem[] => {
	const items: NavigationItem[] = [];

	if (isRecommendationsShared) {
		items.push({name: translation.translate("Recommendations"), to: `${PublicMapRoutes.DiagnosticResultsRecommendations}/${teamId}`, align: "left"});
		items.push({name: translation.translate("Team Journey"), to: `${PublicMapRoutes.DiagnosticResultsTeamJourney}/${teamId}`, align: "left"});
	}

	if (isSummaryShared) {
		items.push({name: translation.translate("Summary"), to: `${PublicMapRoutes.DiagnosticResultsSummary}/${teamId}`, align: "left"});
	}

	if (isMicroHabitsShared) {
		items.push({name: translation.translate("Micro Habits"), to: `${PublicMapRoutes.DiagnosticResultsMicroHabits}/${teamId}`, align: "left"});
	}

	if (isTeamLeaderOrAdmin) {
		items.push({name: translation.translate("Guide"), to: `${PublicMapRoutes.ParticipantsHowTo}/${teamId}`, align: "right"});
		items.push({name: translation.translate("Team Setup"), to: `${PublicMapRoutes.ParticipantsList}/${teamId}`, align: "right"});
	}
	return items;
};
