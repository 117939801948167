import * as React from "react";
import {injectAppStore} from "./app.store.consumer";
import {AppStore} from "../stores/app.store";

interface IProps {
	appStore?: AppStore;
}

export const MainBlank: React.FC<IProps> = injectAppStore()(props => {
	return <div style={{display: "inline-flex"}}>{props.appStore.translationStore.translate("Loading...")}</div>;
});
