import React from "react";
import {guard, isLoggedIn} from "../../common/guard";
import imageFooterLogo from "../../assets/img/logo_on_blue.svg";
import {PublicMapRoutes} from "../../stores/site.map";
import {Link} from "react-router-dom";
import {injectAppStore} from "../../common/app.store.consumer";
import {AppStore} from "../../stores/app.store";
import {RouteComponentProps, withRouter} from "react-router";

type Props = {appStore?: AppStore} & RouteComponentProps<{}>;

@guard(isLoggedIn)
@injectAppStore()
class PublicFooter extends React.Component<Props> {
	render() {
		return (
			<div className="mt--area-wrap mt--pattern-footer">
				<div className="mt--layout">
					<div className="mt--footer">
						<div className="mt--flex">
							<div className="mt--footer-brand mt--mb-1 mt--py-2">
								<Link to={PublicMapRoutes.Home}>
									<img src={imageFooterLogo} alt="footer" width="120" className="mt-inline-block mt--align-top" />
								</Link>
							</div>
							<div className="mt--footer-nav mt--flex">
								<div className="col"></div>
							</div>
						</div>
						<div className="txt-legal">
							<span>{this.props.appStore.translationStore.translate("© Meta Team Limited {{CurrentYear}}", {CurrentYear: new Date().getFullYear()})}. </span>
							<span>{this.props.appStore.translationStore.translate("All rights reserved.")}</span>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(PublicFooter);
