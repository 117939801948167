import React from "react";
import {guard, isLoggedIn} from "../../../common/guard";
import PublicSurveyResultsContainer from "../survey.results.wrapper";
import PublicSurveyResultsMicroHabits from "./survey.results.micro.habits";

@guard(isLoggedIn)
export default class PublicSurveyResultsMicroHabitsContainer extends React.Component {
	render() {
		return (
			<PublicSurveyResultsContainer>
				<PublicSurveyResultsMicroHabits />
			</PublicSurveyResultsContainer>
		);
	}
}
